export const API_ERROR = "API_ERROR"
export const GET_NUMBERS = "GET_NUMBERS"
export const GET_NUMBERS_SUCCESS = "GET_NUMBERS_SUCCESS"
export const GET_FILTER_NUMBERS = "GET_FILTER_NUMBERS"
export const GET_FILTER_NUMBERS_SUCCESS = "GET_FILTER_NUMBERS_SUCCESS"
export const EDIT_NUMBER = "EDIT_NUMBER"
export const EDIT_NUMBER_SUCCESS = "EDIT_NUMBER_SUCCESS"
export const ADD_NUMBER = "ADD_NUMBER"
export const ADD_NUMBER_SUCCESS = "ADD_NUMBER_SUCCESS"
export const DELETE_NUMBER = "DELETE_NUMBER"
export const DELETE_NUMBER_SUCCESS = "DELETE_NUMBER_SUCCESS"
export const ACTIVATE_NUMBER = "ACTIVATE_NUMBER"
export const ACTIVATE_NUMBER_SUCCESS = "ACTIVATE_NUMBER_SUCCESS"
export const DEACTIVATE_NUMBER = "DEACTIVATE_NUMBER"
export const DEACTIVATE_NUMBER_SUCCESS = "DEACTIVATE_NUMBER_SUCCESS"

