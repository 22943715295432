import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
GET_NOTIFICATIONS
} from "./actionTypes"

import {
  getNotificationError, getNotificationsSuccess,

} from "./actions"

import {getNotifications} from "../../helpers/fakebackend_helper";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getNotificationsApi() {
  try {
    const response = yield call(getNotifications)
    yield put(getNotificationsSuccess(response.data));
  } catch (error) {
    yield put(getNotificationError(error))
  }
}

export function* watchNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotificationsApi)
}

function* NotificationsSaga() {
  yield all([fork(watchNotifications)])
}

export default NotificationsSaga
