import React, { useState, useEffect } from "react"
import moment from "moment"

import {
  Row,
  Col,
  Modal,
  Button,
  Spinner,
  BreadcrumbItem,
  FormGroup,
  Label,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import MetaTags from "react-meta-tags"
import axios from "axios"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link, withRouter } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import DataTable from "react-data-table-component"
import "chartist/dist/scss/chartist.scss"
import SweetAlert from "react-bootstrap-sweetalert"
import { withTranslation } from "react-i18next"
import close from "../../assets/images/close.svg"

const notify = (type, text) => {
  switch (type) {
    case "success":
      toast.success(text)
      break
    case "error":
      if (typeof text !== "string") {
        if (text?.message && typeof text?.message === "string") {
          toast.error(text?.message)
        } else {
          toast.error("An unexpected error occurred")
        }
      } else {
        toast.error(text)
      }
      break

    default:
      break
  }
}

const Notes = props => {
  const [notes, setNotes] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [totalNotes, setTotalNotes] = useState()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [currentNote, setCurrentNote] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [deleteAlert, setDeleteAlert] = useState({ show: false, id: null })

  const authUser = localStorage.getItem("authUser")
  const token = authUser ? JSON.parse(authUser)?.user?.token : undefined
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const openEditModal = note => {
    setCurrentNote(note)
    setEditModalOpen(true)
  }

  const getNotes = async (page = currentPage, limit = perPage) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          `/api/notes/getAllNotes?page=${page}&limit=${limit}`,
        config
      )

      if (response?.data) {
        setNotes(response?.data?.data?.notes)
        setIsLoading(false)
        setTotalNotes(response?.data?.data?.totalCount)
      }
    } catch (err) {
      notify("error", err)
      setIsLoading(false)
    }
  }

  const handlePageChange = page => {
    setCurrentPage(page)
    setIsLoading(true)
    getNotes(page, perPage)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage)
    setIsLoading(true)
    getNotes(page, newPerPage)
  }

  const handleDeleteNote = async id => {
    setDeleteAlert({ show: true, id: id })
  }

  const confirmDeleteNote = async () => {
    setIsLoading(true)
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT + `/api/notes/deleteNote/${deleteAlert.id}`,
        config
      )

      if (response?.data) {
        setDeleteAlert({ show: false, id: null })
        notify("success", "Note deleted successfully")
        getNotes()
      }
    } catch (err) {
      setDeleteAlert({ show: false, id: null })
      notify("error", err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateNote = async (event, values) => {
    setIsLoading(true)
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + `/api/notes/updateNote/${currentNote._id}`,
        { notes: values.note },
        config
      )

      if (response?.data) {
        setEditModalOpen(false)
        notify("success", "Note updated successfully")
        getNotes()
      }
    } catch (err) {
      setEditModalOpen(false)
      notify("error", err)
    } finally {
      setIsLoading(false)
    }
  }

  const notesData =
    notes && notes.length
      ? notes?.map(item => ({
          prospect: (
            <Link
              to={`/prospect/${item?.prospect._id}`}
              style={{ color: "#007bff", textDecoration: "none" }}
            >
              {item?.prospect?.first_name + " " + item?.prospect?.last_name}
            </Link>
          ),
          note: <span className="reminder-table-note">{item.notes}</span>,
          actions: (
            <>
              <Button
                onClick={() => openEditModal(item)}
                className={"has-icon me-2"}
                color={"outline-primary"}
              >
                <i className="fas fa-edit"></i>
              </Button>
              <Button
                onClick={() => {
                  handleDeleteNote(item._id)
                }}
                className={"has-icon me-2"}
                color={"outline-danger"}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ),
          createdAt: moment(item?.createdAt)
            .local()
            .format("MM-DD-YYYY | hh:mm A"),
        }))
      : []

  const notesColumns = [
    {
      name: "Prospect",
      selector: row => row.prospect,
      minWidth: "200px",
      maxWidth: "200px",
    },
    {
      name: "Creation Date",
      selector: row => row.createdAt,
      minWidth: "100px",
      sortable: true,
    },
    {
      name: "Note",
      selector: row => row.note,
      grow: 2,
    },
    {
      name: "Actions",
      selector: row => row.actions,
      maxWidth: "150px",
    },
  ]

  const customStyles = {
    table: {
      width: "100%",
    },
  }

  useEffect(() => {
    getNotes(currentPage, perPage)
  }, [])

  return (
    <React.Fragment>
      {isLoading && (
        <div className="spinner-wr">
          <Spinner style={{ color: "#5932ea" }} />
        </div>
      )}
      <Toaster />

      <div className="page-content">
        <MetaTags>
          <title>Notes | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Toaster />

          <Row className="align-items-center breadcrumb-box mb-5">
            <div className="col-md-8 col-12">
              <div className="page-title-box">
                <h4 className="font-size-18" style={{ display: "inline" }}>
                  {totalNotes ? ` ${totalNotes} ` : null} Notes
                </h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Notes</BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Row>
          <DataTable
            columns={notesColumns}
            data={notesData}
            fixedHeader
            pagination
            paginationServer
            paginationTotalRows={totalNotes}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            className="c-table"
            customStyles={customStyles}
          />
        </div>
        <Modal
          isOpen={editModalOpen}
          toggle={() => {
            setEditModalOpen(false)
            setCurrentNote(null)
          }}
          centered
          className="c-modal"
        >
          <div className="modal-body">
            <h5 className="modal-title mt-0 mb-3">Edit Note</h5>
            <button
              type="button"
              onClick={() => {
                setEditModalOpen(false)
                setCurrentNote(null)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>

            <AvForm
              onValidSubmit={handleUpdateNote}
              className="needs-validation"
            >
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="prospectName">Prospect Name</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="prospectName"
                      value={`${currentNote?.prospect.first_name} ${currentNote?.prospect.last_name}`}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label htmlFor="note">Note</Label>
                    <AvField
                      name="note"
                      type="textarea"
                      className="form-control"
                      id="note"
                      rows="5"
                      value={currentNote?.notes}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter a note",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setEditModalOpen(false)
                    setCurrentNote(null)
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Button>
                <Button type="submit" color="primary" className="main-btn">
                  <span>Update</span>
                </Button>
              </div>
            </AvForm>
          </div>
        </Modal>
        {deleteAlert.show && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={confirmDeleteNote}
            onCancel={() => setDeleteAlert({ show: false, id: null })}
            focusCancelBtn
          >
            You won't be able to revert this!
          </SweetAlert>
        )}
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Notes)
