export const API_ERROR = "API_ERROR"
export const GET_CAMPAIGNS = "GET_CAMPAIGNS"
export const GET_FOLLOW_UP_CAMPAIGNS = "GET_FOLLOW_UP_CAMPAIGNS"
export const GET_FOLLOW_UP_CAMPAIGNS_SUCCESS = "GET_FOLLOW_UP_CAMPAIGNS_SUCCESS"
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS"
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN"
export const EDIT_CAMPAIGN_SUCCESS = "EDIT_CAMPAIGN_SUCCESS"
export const ADD_CAMPAIGN = "ADD_CAMPAIGN"
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS"
export const GET_MARKETS = "GET_MARKETS"
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS"
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS"

/* this action type  for campaign detail page  */
export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS"

export const GET_COUNT_STATS = "GET_COUNT_STATS"
export const GET_COUNT_STATS_SUCCESS = "GET_COUNT_STATS_SUCCESS"
export const DOWNLOAD_COUNT_STATS_STATS = "DOWNLOAD_COUNT_STATS_STATS"

export const GET_CAMPAIGN_METRICS = "GET_CAMPAIGN_METRICS"
export const GET_CAMPAIGN_METRICS_SUCCESS = "GET_CAMPAIGN_METRICS_SUCCESS"

export const GET_CAMPAIGN_COUNT_PROSPECTS  = "GET_CAMPAIGN_COUNT_PROSPECTS"
export const GET_CAMPAIGN_COUNT_PROSPECTS_SUCCESS = "GET_CAMPAIGN_COUNT_PROSPECTS_SUCCESS"

export const GET_BATCH  = "GET_BATCH"
export const GET_BATCH_SUCCESS = "GET_BATCH_SUCCESS"

export const GET_CLIENT_DATA  = "GET_CLIENT_DATA"
export const GET_CLIENT_DATA_SUCCESS = "GET_CLIENT_DATA_SUCCESS"

export const COUNT_TEMPLATE  = "COUNT_TEMPLATE"
export const COUNT_TEMPLATE_SUCCESS = "COUNT_TEMPLATE_SUCCESS"

export const SEND_MESSAGE  = "SEND_MESSAGE"
export const SEND_MESSAGE_SUCCESS  = "SEND_MESSAGE_SUCCESS"
export const UPDATE_MESSAGE_STATUS_PROS  = "UPDATE_MESSAGE_STATUS_PROS"
export const AUTO_SEND_CAMP  = "AUTO_SEND_CAMP"
export const AUTO_SEND_CAMP_SUCCESS  = "AUTO_SEND_CAMP_SUCCESS"
export const CANCEL_AUTO_SEND_CAMP  = "CANCEL_AUTO_SEND_CAMP"


export const GET_UPLOADS_HISTROY  = "GET_UPLOADS_HISTROY"
export const GET_UPLOADS_HISTROY_SUCCESS  = "GET_UPLOADS_HISTROY_SUCCESS"

export const GET_SCHEDULER  = "GET_SCHEDULER"
export const GET_SCHEDULER_SUCCESS  = "GET_SCHEDULER_SUCCESS"
export const CREATE_SCHEDULER  = "CREATE_SCHEDULER"
export const UPDATE_SCHEDULER  = "UPDATE_SCHEDULER"
export const CANCEL_SCHEDULE  = "CANCEL_SCHEDULE"
export const PAUSE_SCHEDULE  = "PAUSE_SCHEDULE"
export const START_SCHEDULE  = "START_SCHEDULE"

export const EXPORT_CAMP_PROS_DATA  = "EXPORT_CAMP_PROS_DATA"
export const EXPORT_CAMP_PROS_DATA_SUCCESS  = "EXPORT_CAMP_PROS_DATA_SUCCESS"

export const EXIST_PROSPECTS  = "EXIST_PROSPECTS"
export const EXIST_PROSPECTS_SUCCESS  = "EXIST_PROSPECTS_SUCCESS"

export const UPLOAD_PROSPECTS = "UPLOAD_PROSPECTS"
export const UPLOAD_PROSPECTS_SUCCESS = "UPLOAD_PROSPECTS_SUCCESS"

export const UPLOAD_PROSPECTS_FILE = "UPLOAD_PROSPECTS_FILE"
export const UPLOAD_PROSPECTS_FILE_SUCCESS = "UPLOAD_PROSPECTS_FILE_SUCCESS"

export const CAMPAIGN_FILTER_PROSPECTS = "CAMPAIGN_FILTER_PROSPECTS"
export const CAMPAIGN_FILTER_PROSPECTS_SUCCESS = "CAMPAIGN_FILTER_PROSPECTS_SUCCESS"


// for update props
export const UPDATE_PROPS = "UPDATE_PROPS"
