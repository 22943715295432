//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"

//export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_JWT_LOGIN = "/api/auth/login"

export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
//export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/api/auth/sendtoken"
export const POST_RECOVER_PASSWORD = "/api/auth/change/"
export const POST_RECOVER_PASSWORD_VERIFY = "/api/auth/verifytoken/"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
//export const GET_CATEGORIES = "/categories"

//CAMPAIGNS
export const GET_CAMPAIGNS = "/api/campaign/pullCampaigns"
export const GET_FOLLOW_UP_CAMPAIGNS = "/api/campaign/pullFollowUpCampaigns"
export const EDIT_CAMPAIGN = "/api/campaign/editcampname/"
export const ADD_CAMPAIGN = "/api/campaign/createCampaign/"
export const DELETE_CAMPAIGN = "/api/campaign/deleteCampaign/"
export const GET_CAMPAIGN = "/api/campaign/pullCampaign/"
export const GET_CAMPAIGN_COUNT_STATUS = "/api/prospect/countstat/"
export const GET_CAMPAIGN_METRICS = "/api/campaign/pullBatches/"
export const DOWNLOAD_CAMPAIGN_COUNT_STATUS = "/api/prospect/downloadstats/"
export const GET_CAMPAIGN_COUNT_PROSPECTS = "/api/prospect/countProspect/"
export const GET_BATCH = "/api/template/batch/"
export const GET_TEMPLATE_BY_CAT = "/api/template/catmapdata/"
export const COUNT_TEMPLATE_BY_CAT = "/api/template/counttemp/"

export const SEND_MESSAGE = "/api/sendmessage/"
export const PROSPECT_UPDATESTATUS = "/api/prospect/updatestatus/"
export const CAMPAIGN_UPDATECAMPSKIP = "/api/campaign/updatecampskip/"
export const CAMPAIGN_UPDATECAMP = "/api/campaign/updatecamp/"
export const AUTO_SEND_CAMP = "/api/campaign/autosend/"
export const CANCEL_AUTO_SEND_CAMP = "/api/campaign/autocancel/"
export const GET_CAMPAIGN_UPLOADS_HISTROY = "/api/campaign/pullUploads/"
export const GET_CAMPAIGN_GETSCHEDULE = "/api/campaign/getschedule/"
export const UPDATE_SCHEDULER = "/api/campaign/schedule/"
export const EXPORT_CAMP_PROS_DATA = "/api/prospect/exportcamppros/"
export const EXISTS_PROSPECTS = "/api/prospect/existProspects/"

export const CANCEL_SCHEDULE = "/api/campaign/schedulecancel/"
export const PAUSE_SCHEDULE = "/api/campaign/schedulepause/"
export const START_SCHEDULE = "/api/campaign/schedulenextdate/"

//CATEGORIES
export const GET_CATEGORIES = "/api/template/pullCategories"
export const EDIT_CATEGORY = "/api/template/updateCategory/"
export const ADD_CATEGORY = "/api/template/createCategory/"
export const DELETE_CATEGORY = "/api/template/deleteCategory/"

//Quick Replies
export const GET_QUICK_REPLIES = "/api/template/pullQRS"
export const EDIT_QUICK_REPLY = "/api/template/updateQR/"
export const ADD_QUICK_REPLY = "/api/template/createQR/"
export const DELETE_QUICK_REPLY = "/api/template/deleteQR/"

//Templates
export const GET_TEMPLATE = "/api/template/pullTemplate/"
export const GET_TEMPLATES = "/api/template/pullTemplates"
export const GET_TEMPLATES_GROUPED_BY_CATEGORY = "/api/template/getAllTemplates"
export const EDIT_TEMPLATE = "/api/template/updateTemplate/"
export const ADD_TEMPLATE = "/api/template/createTemplate/"
export const DELETE_TEMPLATE = "/api/template/deleteTemplate/"
export const GET_NEGATIVE_KEYWORDS = "/api/template/listNegativeKeywords/"
export const MANAGE_NEGATIVE_KEYWORDS = "/api/template/manageNegativeKeyword"

//Optouts
export const GET_OPT_OUTS = "/api/template/pullOptOut"
export const EDIT_OPT_OUT = "/api/template/optout/"
export const ADD_OPT_OUT = "/api/template/saveoptout/"
export const DELETE_OPT_OUT = "/api/template/optout/"

//Numbers
export const GET_NUMBERS = "/api/numbers"
export const GET_FILTER_NUMBERS = "/api/filternumbers"
export const DELETE_NUMBER = "/api/releaseNumber/"
export const DEACTIVATE_NUMBER = "/api/deactivateNumber/"
export const ACTIVATE_NUMBER = "/api/activateNumber/"
export const ADD_NUMBER = "/api/addNumbers/"
export const EDIT_NUMBER = "/api/template/number/"

//Lead Stages
export const GET_LEAD_STAGES = "/api/setting/getLeadStage"
export const ADD_LEAD_STAGE = "/api/setting/create"
export const DELETE_LEAD_STAGE = "/api/setting/deleteLeadStage/"

//Markets
export const GET_MARKETS = "/api/getMarkets"
export const GET_AVAILABLE_MARKETS = "/api/search"
export const EDIT_MARKET = "/api/updateMarket/"
export const ADD_MARKET = "/api/market"
export const DELETE_MARKET = "/api/deleteMarket/"

//Push to CRM
export const GET_PTCRM_SETTING = "/api/setting/pullPTCRM"
export const SAVE_PTCRM_SETTING = "/api/setting/updatePTCRM"
export const SAVE_PTCRM_FILTER = "/api/setting/filtersave"

//Notifications
export const GET_NOTIFICATIONS = "/api/prospect/notification/"
export const DOWNLOAD_NOTIFICATION_FILE = "/api/prospect/downloadnotfic/"

//DNC List
export const EXPORT_DNC_LIST = "/api/campaign/importdnc/"
export const RESET_DNC_LIST = "/api/campaign/dncreset/"
export const IMPORT_DNC_LIST = "/api/campaign/importdnc/"

//Prospects
export const GET_PROSPECTS = "/api/prospect/pullProspects"
export const GET_NOTES = "/api/notes/getNotes/"
export const GET_PROSPECT = "/api/prospect/getProspect/"
export const FILTER_PROSPECTS = "/api/prospect/filter"
export const GET_CAMPAIGNS_BY_MARKET = "/api/campaign/getCampaignsByMarket"
export const EXPORT_PROSPECTS = "/api/prospect/exportpros"
export const SEARCH_PROSPECTS_BY_MSG = "/api/prospect/getProspectByMsg"
export const SEARCH_PROSPECTS_FOR = "/api/prospect/getProspect"
export const COUNT_ALL_PROSPECTS = "/api/prospect/countprospects"
export const ADD_PROSPECT = "/api/prospect/saveprospect"
export const DELETE_PROSPECTS = "/api/prospect/deleteprospects"
export const REMOVE_PROSPECT_FROM_DNC = "/api/prospect/DNCmultiple"
export const UPDATE_PROSPECT = "/api/prospect/updateProspect/"
export const UPDATE_PROSPECT_DNC = "/api/prospect/prospectDNC/"
export const UPDATE_PROSPECT_VERIFIED = "/api/prospect/verifiednumber/"
export const REMOVE_PROSPECT_VERIFIED = "/api/prospect/removeverifiednumber/"

export const UPDATE_PROSPECT_WRONG_NUMBER = "/api/prospect/wrongnumber/"
export const REMOVE_PROSPECT_WRONG_NUMBER = "/api/prospect/removewrongnumber/"

export const UPDATE_PROSPECT_QUALIFIED = "/api/prospect/isqualified/"
export const REMOVE_PROSPECT_QUALIFIED = "/api/prospect/wasqualified/"

export const UPDATE_PROSPECT_PRIORITY = "/api/prospect/isPriority/"
export const REMOVE_PROSPECT_PRIORITY = "/api/prospect/wasPriority/"

export const UPDATE_PROSPECT_LEAD_STAGE = "/api/prospect/prospectlead/"
export const UPLOAD_PROSPECTS = "/api/campaign/upload"

export const CREATE_NOTE = "/api/notes/createNote/"
export const DELETE_NOTE = "/api/notes/deleteNote/"
export const UPDATE_NOTE = "/api/notes/updateNote/"
export const SEND_CONVERSATION = "/api/sendconv"

export const PUSH_TO_WEBHOOK = "/api/prospect/pushToWH/"
export const CHANGE_PASSWORD = "/api/auth/change"

export const USER_API_URL = "/api/user"

export const CAMPAIGN_FILTER_PROSPECTS = "/prospect/filterprospects/"
