import React, { useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Label,
  Card,
  CardBody,
  FormGroup,
  Alert,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, Redirect } from "react-router-dom"
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios"

const ContactCart = () => {
  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user.user.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  // const navigate = useHistory();
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [res, setRes] = useState("")
  const [shouldRedirect, setShouldRedirect] = useState({
    state: false,
    path: "",
  })
  //upload file
  const upload_file = async () => {
    if (!file) {
      return
    }
    if (file.type !== "text/csv") {
      setRes("file type must be csv")
      setTimeout(() => {
        setRes("")
      }, 2000)
      return
    }

    const formData = new FormData()
    formData.append("file", file)
    setLoading(true)
    const res = await axios.post(
      process.env.REACT_APP_PORT + "/api/verifiedcontact/VerifiedContactsfile",
      formData,
      config
    )
    if (res.status === 200) {
      setRes("file uploaded successfully")
      setLoading(false)
      setTimeout(() => {
        setRes("")
        // navigate.push("/contact-cart", {state: {from: 2}})
        setShouldRedirect({
          state: true,
          path: `/contact-cart`,
        })
      }, 2000)
    } else {
      setRes("file upload failed")
      setTimeout(() => {
        setRes("")
        setLoading(false)
      }, 2000)
    }
  }

  return (
    <React.Fragment>
      {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="page-title-box">
                <h4 className="font-size-18">Upload Bulk</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/#">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // navigate.push("/contact-cart", {state: {from: 2}})
                      setShouldRedirect({
                        state: true,
                        path: `/contact-cart`,
                      })
                    }}
                  >
                    Contact Cart
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Upload Bulk</BreadcrumbItem>
                </ol>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center pt-4">
            <Col xl="6">
              {res && (
                <Alert
                  color={
                    res == "file uploaded successfully" ? "success" : "danger"
                  }
                >
                  {res}
                </Alert>
              )}
              <Card>
                <CardBody>
                  <FormGroup className="mb-3">
                    <Label htmlFor="uploadContact01">Upload File</Label>
                    <input
                      name="upload_file"
                      type="file"
                      // validate={{required:{value:true}}}
                      className="form-control"
                      id="uploadContact01"
                      onChange={e => {
                        setFile(e.target.files[0])
                      }}
                    />
                  </FormGroup>
                  <Button
                    disabled={loading}
                    onClick={() => {
                      upload_file()
                    }}
                    className="btn btn-primary-contact"
                  >
                    {loading ? "Uploading..." : "Save"}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContactCart
