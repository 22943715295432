import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import { connect } from "react-redux"
import {
  Form,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logodarkImg from "../../assets/images/logo-dark.png"
import logosmImg from "../../assets/images/logo-sm.png"
import logolightImg from "../../assets/images/logo-light.png"

import InfiniteScroll from "react-infinite-scroll-component"

import collapseYog from '../../assets/images/menu-collapse-icon.svg'
import logoiconCollapse from '../../assets/images/strech-logo.svg'

import logoIconStrech from  '../../assets/images/logoMainStreched.png'

import { get } from "../../helpers/api_helper"

import logoicon from '../../assets/images/logo-icon.svg'

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const [isShowListMessages, setIsShowListMessages] = useState(false)
  const [dataUnreadMessages, setDataUnreadMessages] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [totalDocs, setTotalDocs] = useState(0)

  const [params, setParams] = useState({
    page: 1,
    limit: 20,
  })

  // call api unreadMessages
  async function ongGetListUnreadMessages() {
    const resp = await get(
      process.env.REACT_APP_PORT + "/api/notification/list",
      { params }
    )
    if (resp && Array.isArray(resp?.data?.docs)) {
      let listArr = []
      if (params.page > 1) {
        listArr = dataUnreadMessages.concat(resp?.data?.docs)
      } else {
        listArr = resp?.data?.docs
      }
      setDataUnreadMessages(listArr)
      setTotalPage(resp?.data?.totalPages)
      setTotalDocs(resp?.data?.totalDocs)
    }
  }

  useEffect(()=>{
    var body = document.body
    if (window.screen.width >= 767) {
      body.classList.add("vertical-collpsed");
    }
  }, [])

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  const [menuCollapse, setMenuCollapse] = useState(false)

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
    setMenuCollapse((state)=>!state)
  }

  function onShowList() {
    setIsShowListMessages(!isShowListMessages)
  }

  function onLoadMore() {
    setTimeout(() => {
      setParams(prevState => ({
        ...prevState,
        page: prevState.page + 1,
      }))
    }, 250)
  }

  useEffect(() => {
    //ongGetListUnreadMessages()
  }, [isShowListMessages, params])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              {/* <Link to="/" className="logo logo-dark">
                <span className="logo-sm logo-text">TextLaunchPad</span>
                <span className="logo-lg logo-text">TextLaunchPad</span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm logo-text">TextLaunchPad</span>
                <span className="logo-lg logo-text">TextLaunchPad</span>
              </Link> */}
              <Link to="/" className="head-logo">
                {menuCollapse ? <img src={logoIconStrech} className="Strech-Logo"  alt="logo" />  :<img src={logoicon} alt="logo" />  } 
                
                

                {/* <span>TextLaunchpad</span> */}
              </Link>
            </div>
            <button
              type="button"
              className={`btn btn-sm px-3 font-size-24 text-white header-item waves-effect ${!menuCollapse?'coll':''}`}
              id="vertical-menu-btn"
              onClick={() => {
                tToggle()
              }}
              data-target="#topnav-menu-content"
            >
              {/* <i className="mdi mdi-menu"></i> */}
              <img src={collapseYog} alt="" />
            </button>
          </div>

          <div className="d-flex">
            <Dropdown
              direction="left"
              isOpen={isShowListMessages}
              toggle={onShowList}
              style={{
                display: "flex",
                alignItems: "center",
                width: "56px",
                justifyContent: "center",
              }}
            >
              {/* <DropdownToggle
                tag="span"
                onClick={onShowList}
                data-toggle="dropdown"
                aria-expanded={isShowListMessages}
              >
                <div className="group-icon-header-noti">
                  {totalDocs > 0 && <div className="dot-noti" />}
                  <i
                    className="ion-md-notifications-outline"
                    style={{ fontSize: "22px", color: "white" }}
                  />
                </div>
              </DropdownToggle> */}
              {/* <DropdownMenu
                id="scrollTargetDiv"
                className="dropdown-menu-list-message"
                style={{ fontSize: "22px", color: "white", left: "-500px" }}
              >
                <DropdownItem
                  header
                  style={{ padding: 0, marginBottom: "16px" }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: " 0 16px",
                      color: "#5b626b",
                    }}
                  >
                    UnreadMessages
                  </div>
                </DropdownItem>
                {dataUnreadMessages?.length > 0 && (
                  <InfiniteScroll
                    dataLength={dataUnreadMessages?.length}
                    next={onLoadMore}
                    hasMore={dataUnreadMessages?.length !== totalPage}
                    scrollThreshold={0.95}
                    scrollableTarget="scrollTargetDiv"
                    loader={
                      <div className="warper-loader">
                        <div class="loader"></div>
                      </div>
                    }
                  >
                    {dataUnreadMessages?.map((read, index) => (
                      <Link
                        key={index}
                        className="dropdown-content-list-message"
                        onClick={onShowList}
                        style={{
                          color: "rgb(28, 28, 40)",
                          fontSize: "14px",
                          display: "grid",
                          "grid-gap": "4px",
                        }}
                        to={"/prospect/" + read?.prospect?._id}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#5b626b",
                          }}
                        >
                          FULL NAME :
                          {` ${read?.prospect?.first_name}  ${read?.prospect?.last_name}`}
                        </div>
                        <div
                          style={{
                            "font-size": "12px",
                            fontWeight: "400",
                            color: "#707070",
                          }}
                        >
                          PHONE : {read?.prospect?.phone}{" "}
                        </div>
                        <div
                          style={{
                            "font-size": "12px",
                            fontWeight: "400",
                            color: "#707070",
                          }}
                        >
                          CONTENT : {read?.body}
                        </div>
                      </Link>
                    ))}
                  </InfiniteScroll>
                )}
              </DropdownMenu> */}
            </Dropdown>
            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect "
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen fa-lg text-white"></i>
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
