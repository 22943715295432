import {
  ADD_OPT_OUT, ADD_OPT_OUT_SUCCESS,
  API_ERROR, DELETE_OPT_OUT, DELETE_OPT_OUT_SUCCESS, EDIT_OPT_OUT, EDIT_OPT_OUT_SUCCESS,
  GET_OPT_OUTS,
  GET_OPT_OUTS_SUCCESS
} from "./actionTypes"

const initialState = {
  loading: true,
  optOuts: [],
  apiError: null,
}

const OptOut = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPT_OUTS:
      state = {
        ...state,
        optOuts: [],
        loading: true,
        apiError: null,
      }
      break
    case GET_OPT_OUTS_SUCCESS:
      state = {
        ...state,
        loading:false,
        optOuts: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload,loading:false }
      break
    case EDIT_OPT_OUT:
      state = {
        ...state,
        optOut: action.payload,
      }
      break
    case EDIT_OPT_OUT_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break

    case ADD_OPT_OUT:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_OPT_OUT_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_OPT_OUT:
      state = {
        ...state,
        optOut: action.payload,
      }
      break
    case DELETE_OPT_OUT_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default OptOut
