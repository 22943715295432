import {
  ADD_LEAD_STAGE, ADD_LEAD_STAGE_SUCCESS,
  API_ERROR, DELETE_LEAD_STAGE, DELETE_LEAD_STAGE_SUCCESS,
  GET_LEAD_STAGES,
  GET_LEAD_STAGES_SUCCESS
} from "./actionTypes"

const initialState = {
  loading: true,
  leadStages: [],
  apiError: null,
}

const LeadStage = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAD_STAGES:
      state = {
        ...state,
        loading: true,
        leadStages: [],
        apiError: null,
      }
      break
    case GET_LEAD_STAGES_SUCCESS:
      state = {
        ...state,
        leadStages: action.payload,
        loading: false,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload,loading: false }
      break
    case ADD_LEAD_STAGE:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_LEAD_STAGE_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_LEAD_STAGE:
      state = {
        ...state,
        optOut: action.payload,
      }
      break
    case DELETE_LEAD_STAGE_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default LeadStage
