import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import moment from "moment"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  CardHeader,
  BreadcrumbItem,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import PropTypes from "prop-types"
import {
  Link,
  withRouter,
  BrowserRouter as Router,
  Route,
} from "react-router-dom"
import { connect } from "react-redux"

import {
  getCampaign,
  getUploadsHistroy,
  getLeadStages,
} from "../../store/campaigns/actions"
import CampaignHeader from "./CampaignHeader"
import DataTable from "react-data-table-component"

class CampaignUploadHistory extends React.Component {
  componentDidMount() {
    let id = this.props.match.params.id
    this.props.getCampaign(id)
    this.props.getUploadsHistroy(id)
    this.props.getLeadStages()
  }

  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id ? this.props.match.params.id : null,
    }
  }

  render() {
    const uploadColumns = [
      {
        name: "#",
        selector: row => row.batch,
        sortable: true,
      },
      {
        name: "File Name",
        selector: row => row.fileName,
        minWidth: "230px",
        sortable: true,
      },
      {
        name: "Prospects",
        minWidth: "150px",
        selector: row => row.prospects,
        sortable: true,
      },
      {
        name: "Duplicate Prospects",
        minWidth: "170px",
        selector: row => row.duplicateProspect,
        sortable: true,
      },
      {
        name: "DNC",
        selector: row => row.dnc,
        sortable: true,
      },
      {
        name: "Litigator",
        selector: row => row.litigator,
        sortable: true,
      },
      {
        name: "Wrong Numbers",
        selector: row => row.wrongNumber,
        minWidth: "150px",
        sortable: true,
      },
      {
        name: "Mobile",
        selector: row => row.mobile,
        sortable: true,
      },
      {
        name: "Landline",
        selector: row => row.landline,
        sortable: true,
      },
      {
        name: "Other",
        selector: row => row.other,
        sortable: true,
      },
      {
        name: "Date",
        selector: row => row.createdAt,
        minWidth: "180px",
        sortable: true,
      },
    ]

    const uploadData = this.props?.uploads?.map((item, index) => ({
      batch: index + 1,
      fileName: item.file,
      prospects: item.prospects,
      duplicateProspect:
        item?.duplicateProspectData?.count &&
        item?.duplicateProspectData?.action ? (
          <span style={{ textTransform: "capitalize" }}>
            {item?.duplicateProspectData?.count} (
            {item?.duplicateProspectData?.action})
          </span>
        ) : (
          "-"
        ),
      dnc: item.dnc,
      litigator: item?.hasOwnProperty("litigator") ? item?.litigator : "-",
      wrongNumber: item?.hasOwnProperty("wrongNumber")
        ? item?.wrongNumber
        : "-",
      mobile: item.mobile,
      landline: item.landline,
      other: item.other,
      createdAt: moment(item?.createdAt).local().format("MM-DD-YYYY | hh:mm A"),
    }))

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Campaign | TextLaunchPad.com </title>
          </MetaTags>
          <div className="container-fluid">
            <CampaignHeader
              campaign={this.props.campaign}
              leadStages={this.props.leads}
              campaignFilterProspects={this.props.campaignFilterProspects}
            />
            <Row className={"pt-2"}>
              <Col lg={12}>
                {this.props.apiError &&
                typeof this.props.apiError === "string" ? (
                  <Alert color="danger">{this.props.apiError}</Alert>
                ) : null}

                {this.props.apiError &&
                typeof this.props.apiError === "object" ? (
                  this.props.apiError.response ? (
                    <Alert color="danger">
                      {this.props.apiError.response.data.error}
                    </Alert>
                  ) : (
                    <Alert color="danger">{this.props.apiError.message}</Alert>
                  )
                ) : null}

                {this.props.successMsg ? (
                  <Alert color="success">{this.props.successMsg}</Alert>
                ) : null}
              </Col>
            </Row>

            {/* <Row>
                            <Col lg={12} className='mb-3'>
                                <Nav tabs className="nav-tabs-custom">
                                    <NavItem>
                                        <Link
                                            className={'nav-link '}
                                            to={'/campaign/' +this.state.id }
                                        >
                                                <span className="d-sm-block">Metrics</span>

                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link
                                            className={'nav-link'}
                                            to={'/campaign/' +this.state.id + '/send'}
                                        >
                                                <span className="d-sm-block">Send</span>

                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link
                                            className={'nav-link active'}
                                            to={'/campaign/' +this.state.id + '/upload_history'}
                                        >
                                            <span className="d-sm-block">Upload History</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link
                                            className={'nav-link '}
                                            to={'/campaign/' +this.state.id + '/prospects_messages_notes'}
                                        >
                                            <span className="d-sm-block">Prospects & Messages</span>
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link
                                            className={'nav-link '}
                                            to={'/campaign/' +this.state.id + '/scheduler'}
                                        >
                                            <span className="d-sm-block">Scheduler</span>
                                        </Link>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row> */}

            <Row>
              <Col lg={12} className="mb-3">
                <div className="c-card">
                  <Nav tabs className="nav-tabs-custom cc-tabs">
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link"}
                        to={"/campaign/" + this.state.id}
                      >
                        <span className="d-sm-block">Metrics</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/send"}
                      >
                        <span className="d-sm-block">Send</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link active"}
                        to={"/campaign/" + this.state.id + "/upload_history"}
                      >
                        <span className="d-sm-block">Upload History</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={
                          "/campaign/" +
                          this.state.id +
                          "/prospects_messages_notes"
                        }
                      >
                        <span className="d-sm-block">Prospects & Messages</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/scheduler"}
                      >
                        <span className="d-sm-block">Scheduler</span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>

            {/* <Row>
              <Col md={12}>
                <Table className="table veltrix-table">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>File Name</Th>
                      <Th>Prospects</Th>
                      <Th>DNC</Th>
                      <Th>Mobile</Th>
                      <Th>Landline</Th>
                      <Th>Other</Th>
                    </Tr>
                  </Thead>
                  <tbody>
                    {this.props.uploads.map((item, index) => (
                      <Tr key={item._id}>
                        <Th scope="row">{index + 1}</Th>
                        <Td>{item.file}</Td>
                        <Td>{item.prospects}</Td>
                        <Td>{item.dnc}</Td>
                        <Td>{item.mobile}</Td>
                        <Td>{item.landline}</Td>
                        <Td>{item.other}</Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row> */}

            <DataTable
              // title="Campaign Metrics"
              columns={uploadColumns}
              data={uploadData}
              pagination
              fixedHeader
              // fixedHeaderScrollHeight="500px"
              className="c-table"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
CampaignUploadHistory.propTypes = {
  apiError: PropTypes.any,
  successMsg: PropTypes.any,
  campaign: PropTypes.object,
  getCampaign: PropTypes.func,
  uploads: PropTypes.array,
  getUploadsHistroy: PropTypes.func,
  leads: PropTypes.array,
  getLeadStages: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    apiError,
    leads,
    campaign,
    successMsg,
    getCampaign,
    uploads,
    getUploadsHistroy,
    getLeadStages,
  } = state.Campaign
  return {
    apiError,
    leads,
    campaign,
    successMsg,
    getCampaign,
    uploads,
    getUploadsHistroy,
    getLeadStages,
  }
}

export default withRouter(
  connect(mapStatetoProps, { getCampaign, getUploadsHistroy, getLeadStages })(
    CampaignUploadHistory
  )
)
