import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import { Row, Col, Alert, Label, Card, CardBody, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, Redirect } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import {
  userRecoverPassword,
  userVerifyRecoverPasswordToken,
} from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo-sm.png"

class Recoverpw extends React.Component {
  componentDidMount() {
    this.props.userVerifyRecoverPasswordToken(
      this.props.location.pathname.replace("/authentication/restpassword/", "")
    )
  }

  handleValidSubmit(event, values) {
    this.props.userRecoverPassword(values, this.props.history)
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Recover Password | TextLaunchPad.com</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="dashboard" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={4}>
                <Card className="overflow-hidden">
                  <div className="bg-primary">
                    <div className="text-primary text-center p-4">
                      <h5 className="text-white font-size-20 p-2">
                        Reset Password
                      </h5>
                      <Link to="dashboard" className="logo logo-admin">
                        <img src={logoSm} height="24" alt="logo" />
                      </Link>
                    </div>
                  </div>
                  {this.props.recoverPasswordSuccess ? (
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: this.props.location },
                      }}
                    />
                  ) : null}
                  <CardBody className="p-4">
                    {this.props.apiError &&
                    typeof this.props.apiError === "string" ? (
                      <Alert color="danger">{this.props.apiError}</Alert>
                    ) : null}

                    {this.props.apiError &&
                    typeof this.props.apiError === "object" ? (
                      <Alert color="danger">
                        {this.props.apiError.response.data.error
                          ? this.props.apiError.response.data.error
                          : this.props.apiError.response.data.message
                          ? this.props.apiError.response.data.message
                          : null}
                      </Alert>
                    ) : null}

                    <div className="p-3">
                      <div className="alert alert-success mt-5" role="alert">
                        Enter your Email and instructions will be sent to you!
                      </div>

                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                      >
                        <AvField
                          type="hidden"
                          name="token"
                          value={this.props.location.pathname.replace(
                            "/authentication/restpassword/",
                            ""
                          )}
                        />

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="confirm_password"
                            label="Confirm Password"
                            type="password"
                            validate={{
                              required: { value: true },
                              match: {
                                value: "password",
                                errorMessage: "Confirm password does not match",
                              },
                            }}
                            placeholder="Confirm Password"
                          />
                        </div>

                        <div className="row mb-0">
                          <Col xs={12} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-5 text-center">
                  <p>
                    Remember It ?{" "}
                    <Link to="login" className="fw-medium text-primary">
                      {" "}
                      Sign In here{" "}
                    </Link>{" "}
                  </p>
                  <p className="mb-0">
                    © {new Date().getFullYear()} TextLaunchPad. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger"></i> by TechXperts
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>{" "}
      </React.Fragment>
    )
  }
}

Recoverpw.propTypes = {
  apiError: PropTypes.any,
  history: PropTypes.object,
  recoverPasswordSuccess: PropTypes.any,
  userRecoverPassword: PropTypes.func,
  userVerifyRecoverPasswordToken: PropTypes.func,
}

const mapStatetoProps = state => {
  const { apiError, recoverPasswordSuccess } = state.ForgetPassword
  return { apiError, recoverPasswordSuccess }
}

export default withRouter(
  connect(mapStatetoProps, {
    userRecoverPassword,
    userVerifyRecoverPasswordToken,
  })(Recoverpw)
)
