import React from "react";
import _debounce from "lodash.debounce"
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { createBrowserHistory } from 'history';

import {
    Alert, BreadcrumbItem,
    Button,
    Card,
    CardBody, CardText,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Modal, Nav, NavItem, NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import moment from 'moment';
import {Link, Redirect, withRouter} from "react-router-dom";
import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {
    getProspect, getNotes, createNotes, updateNote, deleteNote
} from "../../store/prospects/actions";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

class ProspectNotes extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            modal_center:false,
            note:"",
            note_id:"",
            confirm_alert: false,
        };
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    handleValidSubmit(event, values) {

        if(this.state.note_id) {
            this.props.updateNote({
                notes:values.note,
                id:this.state.note_id,
                prospect_id:this.props.prospect._id
            });
        } else {
            this.props.createNotes({
                notes:values.note,
                id:this.props.prospect._id
            });
        }

        this.setState({
            modal_center:false,
            note:"",
            note_id:""
        });

    }


    deleteNote(note_id) {
        this.setState({
            confirm_alert:true,
            note_id:note_id
        });
    }


    tog_center() {
        this.removeBodyCss()
        this.setState({
            modal_center:!this.state.modal_center
        });
    }

    componentDidMount() {
        this.props.getProspect(this.props.match.params.id);
        this.props.getNotes(this.props.match.params.id);
    }

    render()
    {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Prospect Details | TextLaunchPad.com </title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Prospects</h4>
                                    <ol className="breadcrumb mb-0">
                                        <BreadcrumbItem>
                                            <Link to="/">TextLaunchPad</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <Link to="/prospects">Prospects</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            Prospect Detail
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="float-end">
                                    <Button
                                        type={'button'}
                                        onClick={() => {
                                            this.setState({
                                                modal_center:true,
                                                note:"",
                                                note_id:""
                                            });
                                        }}
                                        color={'primary'}><i className="mdi mdi-plus-box me-2"></i> Add Note</Button>
                                </div>
                            </Col>
                            <Col md={12} className={'pb-2'}>
                                <span className={'border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block'}></span>
                            </Col>
                        </Row>



                        <Nav tabs className="nav-tabs-custom pt-4">
                            <NavItem>
                                <Link to={`/prospect/${this.props.prospect._id}`} className="nav-link">
                                    <span className="d-none d-sm-block">Detail</span>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: true,
                                    })}
                                >
                                    <span className="d-none d-sm-block">Notes</span>
                                </NavLink>
                            </NavItem>

                        </Nav>
                        <TabContent activeTab="1" className="p-3 text-muted">
                            <TabPane tabId="1">
                                <Row>
                                    <Col xl="12" className={'ps-0 pe-0'}>
                                        {this.props.apiError && typeof this.props.apiError === 'string' ? (
                                            <Alert color="danger" >
                                                {this.props.apiError}
                                            </Alert>
                                        ) : null}
                                        {this.props.apiError && typeof this.props.apiError === 'object' ? (
                                            (this.props.apiError.response ? (
                                                <Alert color="danger" >
                                                    {this.props.apiError.response.data.error}
                                                </Alert>
                                            ):(
                                                <Alert color="danger" >
                                                    {this.props.apiError.message}
                                                </Alert>
                                            ))
                                        ) : null}
                                        {this.props.successMsg ? (
                                            <Alert color="success" >
                                                {this.props.successMsg}
                                            </Alert>
                                        ) : null}


                                        <Row>
                                            { this.props.notes ? (this.props.notes.map((note, index) => (
                                                <Col md={6} key={note._id}>
                                                    <Card>
                                                        <CardBody>
                                                            <CardText className="mt-2">
                                                                {note.notes}
                                                            </CardText>
                                                            <CardText>
                                                                <small className={'txet-muted'}>{moment(note.createdAt).format('LLL')}</small>
                                                            </CardText>
                                                        </CardBody>
                                                        <CardBody className={'pt-0 text-end'}>
                                                            <a
                                                                href={'#'}
                                                                className={'btn has-icon btn-outline-primary me-3'}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_center:true,
                                                                        note:note.notes,
                                                                        note_id:note._id
                                                                    })
                                                                }}><i className={'fas fa-edit'}></i>
                                                            </a>

                                                            <a
                                                                href={'#'}
                                                                className={'btn has-icon btn-outline-danger'}
                                                                onClick={() => {
                                                                    this.deleteNote(note._id);
                                                                }}><i className={'fas fa-trash'}></i>
                                                            </a>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )) ) : null }
                                        </Row>


                                        <Modal
                                            isOpen={this.state.modal_center}
                                            toggle={() => {
                                                this.tog_center()
                                            }}
                                            backdrop="static"
                                            centered={true}
                                        >
                                            <AvForm
                                                className="needs-validation"
                                                onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                                            >
                                                <div className="modal-header">
                                                    { this.state.note_id ? (
                                                        <h5 className="modal-title mt-0">Edit Note</h5>
                                                    ) : (
                                                        <h5 className="modal-title mt-0">Add Note</h5>
                                                    ) }
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            this.setState({
                                                                modal_center:false,
                                                                note:"",
                                                                note_id:""
                                                            });
                                                        }}
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <Row>
                                                        <Col>
                                                            <FormGroup className="mb-3">
                                                                <AvField
                                                                    type="textarea"
                                                                    name="note"
                                                                    rows={5}
                                                                    label="Note"
                                                                    validate={{ required: { value: true } }}
                                                                    value={this.state.note}
                                                                >
                                                                </AvField>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className={'text-end'}>
                                                        <Col md={12}>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark me-2"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modal_center:false,
                                                                        note:"",
                                                                        note_id:""
                                                                    });
                                                                }}>Cancel</button>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary">
                                                                Submit
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </AvForm>
                                        </Modal>

                                        {this.state.confirm_alert ? (
                                            <SweetAlert
                                                title="Are you sure?"
                                                warning
                                                showCancel
                                                confirmBtnText="Yes, remove it"
                                                confirmBtnBsStyle="primary"
                                                cancelBtnBsStyle="dark"
                                                onConfirm={() => {
                                                    this.setState({confirm_alert:false});

                                                    this.props.deleteNote({
                                                        id:this.state.note_id,
                                                        prospect_id:this.props.prospect._id
                                                    });
                                                }}
                                                onCancel={() => {
                                                    this.setState({confirm_alert:false});
                                                }}
                                            >
                                                You won't be able to revert this!
                                            </SweetAlert>
                                        ) : null}

                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ProspectNotes.propTypes = {
    apiError: PropTypes.any,
    successMsg: PropTypes.any,
    prospect: PropTypes.object,
    notes: PropTypes.array,
    getProspect: PropTypes.func,
    getNotes: PropTypes.func,
    createNotes: PropTypes.func,
    updateNote: PropTypes.func,
    deleteNote: PropTypes.func,
}

const mapStatetoProps = state => {
    const { apiError, successMsg,  prospect, notes, getProspect, getNotes, createNotes, updateNote, deleteNote
    } = state.Prospects
    return { apiError, successMsg, prospect, notes, getProspect, getNotes, createNotes, updateNote, deleteNote }
}

export default withRouter(
    connect(mapStatetoProps, { getProspect, getNotes, createNotes, updateNote, deleteNote })(ProspectNotes)
)
