import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  ADD_PROSPECT,
  COUNT_ALL_PROSPECTS,
  EXPORT_PROSPECTS,
  GET_PROSPECTS,
  SEARCH_PROSPECTS_FOR,
  SEARCH_PROSPECTS_BY_MESSAGE,
  GET_CAMPAIGNS_BY_MARKET,
  FILTER_PROSPECTS,
  DELETE_PROSPECTS,
  REMOVE_PROSPECT_FROM_DNC,
  GET_PROSPECT,
  UPDATE_PROSPECT_DNC,
  UPDATE_PROSPECT_VERIFIED,
  REMOVE_PROSPECT_VERIFIED,
  UPDATE_PROSPECT_WRONG_NUMBER,
  REMOVE_PROSPECT_WRONG_NUMBER,
  UPDATE_PROSPECT_QUALIFIED,
  REMOVE_PROSPECT_QUALIFIED,
  UPDATE_PROSPECT_PRIORITY,
  REMOVE_PROSPECT_PRIORITY,
  UPDATE_PROSPECT_LEAD_STAGE,
  PUSH_TO_WEBHOOK,
  UPDATE_PROSPECT,
  GET_NOTES, ADD_NOTE, EDIT_NOTE, DELETE_NOTE, SEND_CONVERSATION
} from "./actionTypes"

import {
  addProspectSuccess,
  countProspectsSuccess, createNotesSuccess,
  deleteProspectsSuccess,
  removeProspectsFromDncSuccess,
  exportProspectsSuccess,
  getCampaignsByMarketSuccess, getNotesSuccess,
  getProspectsError,
  getProspectsSuccess,
  getProspectSuccess, pushToWebhookSuccess,
  removeProspectPrioritySuccess,
  removeProspectQualifiedSuccess,
  removeProspectVerifiedSuccess,
  removeProspectWrongNumberSuccess, sendConversationSuccess, updateNoteSuccess,
  updateProspectDNSSuccess,
  updateProspectLeadStageSuccess,
  updateProspectPrioritySuccess,
  updateProspectQualifiedSuccess, updateProspectSuccess,
  updateProspectVerifiedSuccess,
  updateProspectWrongNumberSuccess
} from "./actions"

import {
  addProspect,
  countAllProspects,
  exportProspects,
  getProspects,
  searchProspectsFor,
  searchProspectsByMsg,
  getCampaignsByMarket,
  filterProspects,
  deleteProspects,
  removeProspectsFromDnc,
  getProspect,
  updateProspectDNC,
  updateProspectVerified,
  removeProspectVerified,
  updateProspectWrongNumber,
  removeProspectWrongNumber,
  updateProspectQualified,
  removeProspectQualified,
  updateProspectPriority,
  removeProspectPriority,
  updateProspectLeadStage, pushToWebhook, updateProspect, getNotes, addNote, editNote, deleteNote, sendConversation
} from "../../helpers/fakebackend_helper";

const delay = (ms) => new Promise(res => setTimeout(res, ms))
//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* countProspectsApi({payload:data}) {
  try {
    const response = yield call(countAllProspects,data)
    yield put(countProspectsSuccess(response.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* getProspectsApi({ payload : offset }) {
  try {
    const response = yield call(getProspects,'?offset='+offset)
    yield put(getProspectsSuccess(response.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* getNotesApi({ payload : prospectId }) {
  try {
    const response = yield call(getNotes,prospectId)
    yield put(getNotesSuccess(response.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* getProspectApi({ payload : prospectId }) {
  try {
      const response = yield call(getProspect,prospectId)
      yield put(getProspectSuccess(response.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* filterProspectsApi({ payload : filter }) {
  try {
    const response = yield call(filterProspects,filter)
    yield put(getProspectsSuccess(response.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* getCampaignsByMarketApi({ payload : market }) {
  try {
    const response = yield call(getCampaignsByMarket,market)
    yield put(getCampaignsByMarketSuccess(response.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* searchProspectsForApi({ payload : sfor }) {
  try {
    const response = yield call(searchProspectsFor,'?search='+sfor)
    yield put(getProspectsSuccess(response.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* searchProspectsByMsgApi({ payload : msg }) {
  try {
    const response = yield call(searchProspectsByMsg,'?search='+msg)
    yield put(getProspectsSuccess(response.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* exportProspectsApi({ payload : data }) {
  try {
    const response = yield call(exportProspects, data)
    yield put(exportProspectsSuccess('File is Processing, Please See Notification'));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* addProspectApi({ payload: prospect }) {
  try {
    const response = yield call(addProspect,prospect)
    yield put(addProspectSuccess('Prospect added successfully.'));
    yield delay(800);
    const response1 = yield call(getProspects)
    yield put(getProspectsSuccess(response1.data));
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* addNoteApi({ payload: note }) {
  try {
    const response = yield call(addNote,note)
    yield put(createNotesSuccess('Note added successfully.'));
    yield delay(800);

    const response1 = yield call(getNotes,note.id)
    yield put(getNotesSuccess(response1.data));

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* editNoteApi({ payload: note }) {
  try {
    const response = yield call(editNote,note)
    yield put(updateNoteSuccess('Note added successfully.'));
    yield delay(800);

    const response1 = yield call(getNotes,note.prospect_id)
    yield put(getNotesSuccess(response1.data));

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* deleteNoteApi({ payload: note }) {
  try {
    const response = yield call(deleteNote,note)
    yield put(updateNoteSuccess('Note deleted successfully.'));
    yield delay(800);

    const response1 = yield call(getNotes,note.prospect_id)
    yield put(getNotesSuccess(response1.data));

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* sendConversationApi({ payload: message }) {
  try {
    const response = yield call(sendConversation,message)
   if(response?.data==='Sent') {
    yield put(sendConversationSuccess('Conversation sent successfully.'));
   }
   else if(response?.data==='blocked') {
    //error message for DNC person
    yield put(getProspectsError('Unable to send message because the phone was added to the DNC list.'))
   }
   else {
    yield put(getProspectsError('Conversation sent failed.'))
   }
    
  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* deleteProspectApi({ payload: ids }) {
  try {
    const response = yield call(deleteProspects,ids)
    yield put(deleteProspectsSuccess('Prospects deleted successfully.'));
    /*yield delay(800);
    const response1 = yield call(getProspects)
    yield put(getProspectsSuccess(response1.data));*/
  } catch (error) {
    yield put(getProspectsError(error))
  }
}
function* removeProspectFromDncApi({ payload: ids }) {
  try {
    const response = yield call(removeProspectsFromDnc,ids)
    yield put(removeProspectsFromDncSuccess('Prospects remove from DNC successfully.'));
  } catch (error) {
    console.log(error,'eeeeeeeeeeeeeeeeeeeeeee')
    yield put(getProspectsError("Something went wrong"))
  }
}

function* updateProspectApi({ payload: data }) {
  try {
    const response = yield call(updateProspect,data)
    yield put(updateProspectSuccess('Prospects updated successfully.'));

    const response1 = yield call(getProspect,data.id)
    yield put(getProspectSuccess(response1.data));

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* updateProspectDNSApi({ payload: data }) {
  try {
    yield call(updateProspectDNC,data)
    yield put(updateProspectDNSSuccess('Prospects updated successfully.'));
    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      console.log(response1,"pppppppppppppppppp")
      yield put(getProspectSuccess(response1.data));
    }


  } catch (error) {
    console.log(error,"0000000000000000000000")
    yield put(getProspectsError(error))
  }
}

function* updateProspectVerifiedApi({ payload: data }) {
  try {
    const response = yield call(updateProspectVerified,data)
    yield put(updateProspectVerifiedSuccess('Prospects updated successfully.'));

    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      yield put(getProspectSuccess(response1.data));
    }

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* removeProspectVerifiedApi({ payload: data }) {
  try {
    const response = yield call(removeProspectVerified,data)
    yield put(removeProspectVerifiedSuccess('Prospects updated successfully.'));

    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      yield put(getProspectSuccess(response1.data));
    }

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* updateProspectWrongNumberApi({ payload: data }) {
  try {
    const response = yield call(updateProspectWrongNumber,data)
    yield put(updateProspectWrongNumberSuccess('Prospects updated successfully.'));

    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      yield put(getProspectSuccess(response1.data));
    }

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* removeProspectWrongNumberApi({ payload: data }) {
  try {
    const response = yield call(removeProspectWrongNumber,data)
    yield put(removeProspectWrongNumberSuccess('Prospects updated successfully.'));

    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      yield put(getProspectSuccess(response1.data));
    }

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* updateProspectQualifiedApi({ payload: data }) {
  try {
    const response = yield call(updateProspectQualified,data)
    yield put(updateProspectQualifiedSuccess('Prospects updated successfully.'));

    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      yield put(getProspectSuccess(response1.data));
    }

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* removeProspectQualifiedApi({ payload: data }) {
  try {
    const response = yield call(removeProspectQualified,data)
    yield put(removeProspectQualifiedSuccess('Prospects updated successfully.'));

    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      yield put(getProspectSuccess(response1.data));
    }

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* updateProspectPriorityApi({ payload: data }) {
  try {
    const response = yield call(updateProspectPriority,data)
    yield put(updateProspectPrioritySuccess('Prospects updated successfully.'));

    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      yield put(getProspectSuccess(response1.data));
    }

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* updateProspectLeadStageApi({ payload: data }) {
  try {
    const response = yield call(updateProspectLeadStage,data)
    yield put(updateProspectLeadStageSuccess('Prospects updated successfully.'));

    if(data.list) {
    } else {
      const response1 = yield call(getProspect,data.id)
      yield put(getProspectSuccess(response1.data));
    }

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* pushToWebhookApi({ payload: data }) {
  try {
    const response = yield call(pushToWebhook,data)
    yield put(pushToWebhookSuccess('Prospects updated successfully.'));

  } catch (error) {
    yield put(getProspectsError(error))
  }
}

function* removeProspectPriorityApi({ payload: data }) {
  try {
    const response = yield call(removeProspectPriority,data)
    yield put(removeProspectPrioritySuccess('Prospects updated successfully.'));

    const response1 = yield call(getProspect,data.id)
    yield put(getProspectSuccess(response1.data));

  } catch (error) {
    yield put(getProspectsError(error))
  }
}


export function* watchProspects() {
  yield takeEvery(SEARCH_PROSPECTS_BY_MESSAGE, searchProspectsByMsgApi)
  yield takeEvery(EXPORT_PROSPECTS, exportProspectsApi)
  yield takeEvery(GET_PROSPECTS, getProspectsApi)
  yield takeEvery(GET_PROSPECT, getProspectApi)
  yield takeEvery(FILTER_PROSPECTS, filterProspectsApi)
  yield takeEvery(GET_CAMPAIGNS_BY_MARKET, getCampaignsByMarketApi)
  yield takeEvery(SEARCH_PROSPECTS_FOR, searchProspectsForApi)
  yield takeEvery(COUNT_ALL_PROSPECTS, countProspectsApi)
  yield takeEvery(ADD_PROSPECT, addProspectApi)
  yield takeEvery(DELETE_PROSPECTS, deleteProspectApi)
  yield takeEvery(REMOVE_PROSPECT_FROM_DNC, removeProspectFromDncApi)
  yield takeEvery(UPDATE_PROSPECT, updateProspectApi)
  yield takeEvery(UPDATE_PROSPECT_DNC, updateProspectDNSApi)

  yield takeEvery(UPDATE_PROSPECT_VERIFIED, updateProspectVerifiedApi)
  yield takeEvery(REMOVE_PROSPECT_VERIFIED, removeProspectVerifiedApi)

  yield takeEvery(UPDATE_PROSPECT_WRONG_NUMBER, updateProspectWrongNumberApi)
  yield takeEvery(REMOVE_PROSPECT_WRONG_NUMBER, removeProspectWrongNumberApi)

  yield takeEvery(UPDATE_PROSPECT_QUALIFIED, updateProspectQualifiedApi)
  yield takeEvery(REMOVE_PROSPECT_QUALIFIED, removeProspectQualifiedApi)

  yield takeEvery(UPDATE_PROSPECT_PRIORITY, updateProspectPriorityApi)
  yield takeEvery(REMOVE_PROSPECT_PRIORITY, removeProspectPriorityApi)

  yield takeEvery(PUSH_TO_WEBHOOK, pushToWebhookApi)
  yield takeEvery(UPDATE_PROSPECT_LEAD_STAGE, updateProspectLeadStageApi)

  yield takeEvery(GET_NOTES, getNotesApi)
  yield takeEvery(ADD_NOTE, addNoteApi)
  yield takeEvery(EDIT_NOTE, editNoteApi)
  yield takeEvery(DELETE_NOTE, deleteNoteApi)

  yield takeEvery(SEND_CONVERSATION, sendConversationApi)


}

function* ProspectsSaga() {
  yield all([fork(watchProspects)])
}

export default ProspectsSaga
