import React, { useEffect, useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
// import "./WrongNumbers.scss"
import classnames from "classnames"
import axios from "axios"
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import dripIconBorder from "../../assets/images/drip-icon-border.svg"
import dripIconFilled from "../../assets/images/drip-icon-filled.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import debounce from "lodash.debounce"
import incomingMessage from "../../assets/images/incomingMessage.svg"
import outgoingMessage from "../../assets/images/outgoingMessage.svg"

const LeadStage = ({ props }) => {
  useEffect(() => {
    getLeadStages()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState("") // for success or error response
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedRows, setSelectedRows] = useState([])
  const [totalLeadStage, setTotalLeadStage] = useState()
  const [tableReloadKey, setTableReloadKey] = useState(Math.random())
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [dripModal, setDripModal] = useState(false)
  const [filterData, setFilterData] = useState({ searchBy: "prospect" })
  const [filterReloadKey, setFilterReloadKey] = useState(Math.random())
  const [resetFilter, setResetFilter] = useState({})
  const [deleteLeadStage, setDeleteLeadStage] = useState({ isOpen: false })
  const [addCustomLeadStage, setAddCustomLeadStage] = useState({
    isOpen: false,
  })

  const getLeadStages = async (
    crrPage = page,
    limit = rowsPerPage,
    data = filterData
  ) => {
    const queryParams = new URLSearchParams({
      page: crrPage,
      limit,
      ...data,
    })
    setIsLoading(true)
    try {
      // https://www.textlaunchpad.com/api/prospect/filter
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/setting/getLeadStage?" + queryParams,
        config
      )

      if (response?.data) {
        setPhoneNumbers(response?.data?.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const handleClearSelection = () => {
    const clearData = {
      campaign: "",
      market: "",
      lead: "",
      action: "",
      search: "",
      searchBy: "prospect",
    }
    setResetFilter({ ...clearData })
    handleFilter(clearData)
    setFilterReloadKey(Math.random())
    setTableReloadKey(Math.random())
  }

  const handleDeleteLeadStage = async id => {
    setIsLoading(true)
    const data = {}

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/setting/deleteLeadStage/${id}`,
        data,
        config
      )
      if (response) notify("success", "Lead Stage Deleted Successfully")
      getLeadStages()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.error)
    }
  }

  const handleAddCustomLeadStage = async (e, value) => {
    setIsLoading(true)
    const data = {
      name: value.title,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/setting/create",
        data,
        config
      )
      if (response) notify("success", "Lead Stage Created Successfully")
      setIsLoading(false)
      setAddCustomLeadStage({ isOpen: false })
      getLeadStages()
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }

  //delete contact by id

  //delete selected contact
  const DeleteSelectedProspect = async () => {
    setIsLoading(true)
    const data = {
      id: selectedRows,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/deleteprospects",
        data,
        config
      )
      if (response?.status == 200) {
        notify("success", "Phone Numbers deleted successfully")
        getLeadStages()
        setAddCustomLeadStage({ isOpen: false })

        setSelectedRows([])
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err)
      setIsLoading(false)
    }
  }

  const phoneNumberColumns = [
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      cell: row => row?.lead_Stage,
      minWidth: "170px",
    },
    {
      name: "Type",
      selector: row => row.type,
      sortable: true,
      cell: row => {
        if (row.include) {
          return <div className="d-flex gap-1 align-items-center">Custom</div>
        } else {
          return <div className="d-flex gap-1 align-items-center">Default</div>
        }
      },
      minWidth: "170px",
      maxWidth: "170px",
    },
    {
      name: "Delete",
      selector: row => row._id,
      sortable: false,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            onClick={() => {
              setDeleteLeadStage({
                isOpen: true,
                id: row._id,
                data: row,
              })
            }}
            className="has-icon"
            color="outline-danger"
            disabled={!row.include}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      ),
      minWidth: "170px",
    },
  ]

  const customStyles = {
    table: {
      width: "100%", // Set the overall table width to 100% to cover the screen
    },
  }

  const handleFilter = (data = {}) => {
    const allKey = ["status", "market"]

    const newObj = {}

    for (const key of allKey) {
      if (key in data && data[key]) {
        newObj[key] = data[key]
      }
    }

    setFilterData({ ...newObj })
    getLeadStages(1, rowsPerPage, newObj)
    setTableReloadKey(Math.random())
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Phone Numbers | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2" key={filterReloadKey}>
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    {totalLeadStage ? `${totalLeadStage}` : null} Lead Stages
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/#">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to="/#">Account Settings</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Lead Stages</BreadcrumbItem>
                  </ol>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="float-end">
                  <div className={"button-items d-flex st flex-wrap gap-2"}>
                    {selectedRows && selectedRows.length ? (
                      <button
                        onClick={() => DeleteSelectedProspect()}
                        disabled={isLoading}
                        className="main-btn error-btn"
                        role="button"
                      >
                        <i className="fas fa-trash-alt text-white"></i>

                        <span>Delete ({selectedRows.length})</span>
                      </button>
                    ) : null}

                    <button
                      className="main-btn"
                      onClick={() => {
                        setAddCustomLeadStage({ isOpen: true })
                      }}
                      type="button"
                    >
                      <img src={plus} alt="" />
                      <span> Add Custom Lead Stage</span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {response && (
              <Alert
                color={
                  response == "Something went wrong" ? "danger" : "success"
                }
              >
                {response}
              </Alert>
            )}

            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <DataTable
              // title="Campaign Metrics"
              key={tableReloadKey}
              columns={phoneNumberColumns}
              data={phoneNumbers}
              fixedHeader
              className="c-table"
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationTotalRows={phoneNumbers?.length}
              // paginationPerPage={rowsPerPage}
              // onChangeRowsPerPage={rows => {
              //   setRowsPerPage(rows)
              //   getLeadStages(1, rows)
              //   setPage(1)
              //   setTableReloadKey(Math.random())
              // }}
              // onChangePage={page => {
              //   setPage(page)
              //   getLeadStages(page)
              // }}
              keyField={"id"}
              // selectableRows
              // onSelectedRowsChange={state => {
              //   const selectedIds = state.selectedRows?.map(row => row?.id)
              //   setSelectedRows(selectedIds)
              // }}
              // selectedRows={selectedRows}
              customStyles={customStyles}
            />
          </div>
        </Container>
      </div>

      {/* Add Lead Stage modal  */}
      <Modal
        isOpen={addCustomLeadStage?.isOpen}
        toggle={() => {
          setAddCustomLeadStage({ isOpen: !addCustomLeadStage?.isOpen })
        }}
        className="c-modal"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">Add Custom Lead Stage</h5>
          <button
            type="button"
            onClick={() => {
              setAddCustomLeadStage({ isOpen: !addCustomLeadStage?.isOpen })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleAddCustomLeadStage(e, v)}
              className="needs-validation"
            >
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <AvField
                      name="title"
                      placeholder="Lead Stage"
                      type="text"
                      errorMessage="Enter Lead Stage"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[A-Za-z0-9 ]+$",
                          errorMessage: "Enter valid lead stage name",
                        },
                      }}
                      id="validationCustom01"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setAddCustomLeadStage({ isOpen: false })
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Button>
                <Button type="submit" color="primary" className="main-btn">
                  <span>Submit</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>

      {/* Delete lead Stage sweetAlert  */}

      {deleteLeadStage?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            // this.setState({ confirm_alert: false })
            // this.props.deleteNumber(this.state.number_id)
            handleDeleteLeadStage(deleteLeadStage?.id)
            setDeleteLeadStage({ isOpen: false })
          }}
          onCancel={() => {
            setDeleteLeadStage({ isOpen: false })
          }}
        >
          You won't be able to revert{" "}
          <strong>{deleteLeadStage?.data?.lead_Stage}</strong>
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default LeadStage
