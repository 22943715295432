import {
  GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS,
  API_ERROR
} from "./actionTypes"

const initialState = {
  loading: true,
  notifications: [],
  apiError: null,
}

const Notifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      state = {
        ...state,
        loading: true,
        notifications: [],
        apiError: null,
      }
      break
    case GET_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        notifications: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Notifications
