import {
  ADD_TEMPLATE,
  ADD_TEMPLATE_SUCCESS,
  GET_TEMPLATES_GROUPED_BY_CATEGORY,
  GET_TEMPLATES_GROUPED_BY_CATEGORY_SUCCESS,
  API_ERROR,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_SUCCESS,
  GET_TEMPLATE,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_NEGATIVE_KEYWORDS,
  GET_NEGATIVE_KEYWORDS_SUCCESS,
  MANAGE_NEGATIVE_KEYWORDS_SUCCESS,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
} from "./actionTypes"
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
} from "../categories/actionTypes"
import { GET_OPT_OUTS, GET_OPT_OUTS_SUCCESS } from "../optouts/actionTypes"

const initialState = {
  loading: true,
  categories: [],
  optOuts: [],
  templates: [],
  template: {},
  apiError: null,
}

const Template = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE:
      state = {
        ...state,
        template: {},
        apiError: null,
      }
      break
    case GET_TEMPLATE_SUCCESS:
      state = {
        ...state,
        template: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_TEMPLATES_GROUPED_BY_CATEGORY:
      state = {
        ...state,
        groupedTemplates: {},
        apiError: null,
        loading: true,
      }
      break
    case GET_TEMPLATES_GROUPED_BY_CATEGORY_SUCCESS:
      state = {
        ...state,
        loading: false,
        groupedTemplates: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_TEMPLATES:
      state = {
        ...state,
        loading: true,
        templates: [],
        apiError: null,
      }
      break
    case GET_TEMPLATES_SUCCESS:
      state = {
        ...state,
        loading: false,
        templates: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_NEGATIVE_KEYWORDS:
      state = {
        ...state,
        loading: true,
        negativeKeywords: [],
        apiError: null,
      }
      break
    case GET_NEGATIVE_KEYWORDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        negativeKeywords: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      loading: false, (state = { ...state, apiError: action.payload })
      break
    case GET_CATEGORIES:
      state = {
        ...state,
        categories: [],
        apiError: null,
        successMsg: null,
      }
      break
    case GET_CATEGORIES_SUCCESS:
      state = {
        ...state,
        categories: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_OPT_OUTS:
      state = {
        ...state,
        optOuts: [],
        apiError: null,
        successMsg: null,
      }
      break
    case GET_OPT_OUTS_SUCCESS:
      state = {
        ...state,
        optOuts: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case EDIT_CATEGORY:
      state = {
        ...state,
        template: action.payload,
      }
      break
    case EDIT_CATEGORY_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break
    case EDIT_TEMPLATE:
      state = {
        ...state,
        template: action.payload,
      }
      break
    case EDIT_TEMPLATE_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break

    case ADD_TEMPLATE:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_TEMPLATE_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case MANAGE_NEGATIVE_KEYWORDS_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_TEMPLATE:
      state = {
        ...state,
        template: action.payload,
      }
      break
    case DELETE_TEMPLATE_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Template
