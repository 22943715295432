import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  GET_NUMBERS,
  EDIT_NUMBER,
  ADD_NUMBER,
  DELETE_NUMBER,
  DEACTIVATE_NUMBER,
  ACTIVATE_NUMBER,
  GET_FILTER_NUMBERS
} from "./actionTypes"

import {
  activatedNumberSuccess,
  addNumberSuccess,
  deactivateNumberSuccess,
  deleteNumberSuccess,
  getNumbersError,
  getNumbersSuccess,
  getFiltersNumbersSuccess
} from "./actions"

import {
  activateNumber,
  addNumber,
  deleteNumber,
  editNumber,
  getNumbers,
  deactivateNumber,
  getFiltersNumbers
} from "../../helpers/fakebackend_helper";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getNumbersApi() {
  try {

    const response = yield call(getNumbers)

    yield put(getNumbersSuccess(response.data));

  } catch (error) {

    yield put(getNumbersError(error))
  }
}

function* getFilterNumbersApi({ payload: filters }) {
  try {

    const response = yield call(getFiltersNumbers, filters)

    yield put(getFiltersNumbersSuccess(response.data));

  } catch (error) {

    yield put(getNumbersError(error))
  }
}

function* addNumberApi({ payload: number }) {
  try {
    const response = yield call(addNumber,number)
    yield put(addNumberSuccess('Number added successfully'));
    yield delay(800);
    const response1 = yield call(getNumbers)
    yield put(getNumbersSuccess(response1.data));
  } catch (error) {
    yield put(getNumbersError(error))
  }
}

function* editNumberApi({ payload: optOut }) {
  try {
    const response = yield call(editNumber,optOut)
    yield put(editNumberSuccess('Number updated successfully'));
    // yield delay(1000);
    const response1 = yield call(getNumbers)
    yield put(getNumbersSuccess(response1.data));

  } catch (error) {
    yield put(getNumbersError(error))
  }
}

function* deleteNumberApi({ payload: number }) {
  try {
    const response = yield call(deleteNumber, number)
    yield put(deleteNumberSuccess('Number deleted successfully'));
    // yield delay(1000);
    /*const response1 = yield call(getNumbers)
    yield put(getNumbersSuccess(response1.data));*/
  } catch (error) {
    yield put(getNumbersError(error))
  }
}

function* deactivateNumberApi({ payload: optOut }) {
  try {
    const response = yield call(deactivateNumber,optOut)
    yield put(deactivateNumberSuccess('Number deactivated successfully'));
    // yield delay(1000);
    const response1 = yield call(getNumbers)
    yield put(getNumbersSuccess(response1.data));

  } catch (error) {
    yield put(getNumbersError(error))
  }
}

function* activateNumberApi({ payload: optOut }) {
  try {
    const response = yield call(activateNumber,optOut)
    yield put(activatedNumberSuccess('Number activated successfully'));
    // yield delay(1000);
    const response1 = yield call(getNumbers)
    yield put(getNumbersSuccess(response1.data));

  } catch (error) {
    yield put(getNumbersError(error))
  }
}

export function* watchNumbers() {
  yield takeEvery(GET_NUMBERS, getNumbersApi)
  yield takeEvery(GET_FILTER_NUMBERS, getFilterNumbersApi)
  yield takeEvery(EDIT_NUMBER, editNumberApi)
  yield takeEvery(ADD_NUMBER, addNumberApi)
  yield takeEvery(DELETE_NUMBER, deleteNumberApi)
  yield takeEvery(ACTIVATE_NUMBER, activateNumberApi)
  yield takeEvery(DEACTIVATE_NUMBER, deactivateNumberApi)
}

function* NumbersSaga() {
  yield all([fork(watchNumbers)])
}

export default NumbersSaga
