import {
  ADD_NOTE, ADD_NOTE_SUCCESS,
  ADD_PROSPECT,
  ADD_PROSPECT_SUCCESS,
  API_ERROR,
  COUNT_ALL_PROSPECTS,
  COUNT_ALL_PROSPECTS_SUCCESS, DELETE_NOTE, DELETE_NOTE_SUCCESS,
  DELETE_PROSPECTS,
  DELETE_PROSPECTS_SUCCESS,
  REMOVE_PROSPECT_FROM_DNC,
  REMOVE_PROSPECT_FROM_DNC_SUCCESS,
  EDIT_NOTE,
  EDIT_NOTE_SUCCESS,
  EXPORT_PROSPECTS,
  EXPORT_PROSPECTS_SUCCESS,
  FILTER_PROSPECTS,
  GET_CAMPAIGNS_BY_MARKET,
  GET_CAMPAIGNS_BY_MARKET_SUCCESS, GET_NOTES, GET_NOTES_SUCCESS,
  GET_PROSPECT,
  GET_PROSPECT_SUCCESS,
  GET_PROSPECTS,
  GET_PROSPECTS_SUCCESS, PUSH_TO_WEBHOOK, PUSH_TO_WEBHOOK_SUCCESS,
  REMOVE_PROSPECT_PRIORITY,
  REMOVE_PROSPECT_PRIORITY_SUCCESS,
  REMOVE_PROSPECT_QUALIFIED,
  REMOVE_PROSPECT_QUALIFIED_SUCCESS,
  REMOVE_PROSPECT_VERIFIED,
  REMOVE_PROSPECT_VERIFIED_SUCCESS,
  REMOVE_PROSPECT_WRONG_NUMBER,
  REMOVE_PROSPECT_WRONG_NUMBER_SUCCESS,
  SEARCH_PROSPECTS_BY_MESSAGE,
  SEARCH_PROSPECTS_FOR, SEND_CONVERSATION, SEND_CONVERSATION_SUCCESS, UPDATE_PROSPECT,
  UPDATE_PROSPECT_DNC,
  UPDATE_PROSPECT_DNC_SUCCESS, UPDATE_PROSPECT_LEAD_STAGE, UPDATE_PROSPECT_LEAD_STAGE_SUCCESS,
  UPDATE_PROSPECT_PRIORITY,
  UPDATE_PROSPECT_PRIORITY_SUCCESS,
  UPDATE_PROSPECT_QUALIFIED,
  UPDATE_PROSPECT_QUALIFIED_SUCCESS, UPDATE_PROSPECT_SUCCESS,
  UPDATE_PROSPECT_VERIFIED,
  UPDATE_PROSPECT_VERIFIED_SUCCESS,
  UPDATE_PROSPECT_WRONG_NUMBER,
  UPDATE_PROSPECT_WRONG_NUMBER_SUCCESS
} from "./actionTypes"
import {GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS} from "../campaigns/actionTypes";
import {GET_LEAD_STAGES, GET_LEAD_STAGES_SUCCESS} from "../leadStages/actionTypes";
import {GET_MARKETS, GET_MARKETS_SUCCESS} from "../markets/actionTypes";
import {GET_QUICK_REPLIES, GET_QUICK_REPLIES_SUCCESS} from "../quick-replies/actionTypes";

const initialState = {
  loading: true,
  refreshList: false,
  prospectsCount: null,
  leadStages: [],
  markets: [],
  notes: [],
  prospect: {},
  prospects: [],
  quickReplies: [],
  campaigns: [],
  successMsg: null,
  apiError: null,
}

const Prospects = (state = initialState, action) => {
  switch (action.type) {
    case COUNT_ALL_PROSPECTS:
      state = {
        ...state,
        prospectsCount: null,
        apiError: null,
      }
      break
    case COUNT_ALL_PROSPECTS_SUCCESS:
      state = {
        ...state,
        prospectsCount: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_NOTES:
      state = {
        ...state,
        notes: [],
        apiError: null,
      }
      break
    case GET_NOTES_SUCCESS:
      state = {
        ...state,
        notes: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_MARKETS:
      state = {
        ...state,
        markets: [],
        apiError: null,
      }
      break
    case GET_MARKETS_SUCCESS:
      state = {
        ...state,
        markets: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_CAMPAIGNS_BY_MARKET:
      state = {
        ...state,
        campaigns: [],
        apiError: null,
      }
      break
    case GET_CAMPAIGNS_BY_MARKET_SUCCESS:
      state = {
        ...state,
        campaigns: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_LEAD_STAGES:
      state = {
        ...state,
        leadStages: [],
        apiError: null,
      }
      break
    case GET_LEAD_STAGES_SUCCESS:
      state = {
        ...state,
        leadStages: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_CAMPAIGNS:
      state = {
        ...state,
        campaigns: [],
        apiError: null,
      }
      break
    case GET_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        campaigns: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case FILTER_PROSPECTS:
      state = {
        ...state,
        prospects: [],
        apiError: null,
      }
      break
    case GET_PROSPECTS:
      state = {
        ...state,
        loading: true,
        prospects: [],
        apiError: null,
      }
      break
    case SEARCH_PROSPECTS_BY_MESSAGE:
      state = {
        ...state,
        prospects: [],
        apiError: null,
      }
      break
    case SEARCH_PROSPECTS_FOR:
      state = {
        ...state,
        prospects: [],
        apiError: null,
      }
      break
    case GET_PROSPECTS_SUCCESS:
      state = {
        ...state,
        prospects: action.payload,
        loading:false,
        apiError: null,
        successMsg: null,
      }
      break
    case EXPORT_PROSPECTS:
      state = {
        ...state,
        apiError: null,
      }
      break
    case EXPORT_PROSPECTS_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload,loading: false }
      break
    case ADD_PROSPECT:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_PROSPECT_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_PROSPECTS:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case DELETE_PROSPECTS_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_FROM_DNC:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_FROM_DNC_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case GET_PROSPECT:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_PROSPECT_SUCCESS:
      state = {
        ...state,
        prospect: action.payload,
      }
      break
    case UPDATE_PROSPECT:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
    case UPDATE_PROSPECT_DNC:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_DNC_SUCCESS:
      state = {
        ...state,
        refreshList : true,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_VERIFIED:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_VERIFIED_SUCCESS:
      state = {
        ...state,
        refreshList:true,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_WRONG_NUMBER:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_WRONG_NUMBER_SUCCESS:
      state = {
        ...state,
        refreshList:true,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_QUALIFIED:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_QUALIFIED_SUCCESS:
      state = {
        ...state,
        refreshList:true,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_PRIORITY:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_PRIORITY_SUCCESS:
      state = {
        ...state,
        refreshList:true,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_VERIFIED:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_VERIFIED_SUCCESS:
      state = {
        ...state,
        refreshList:true,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_WRONG_NUMBER:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_WRONG_NUMBER_SUCCESS:
      state = {
        ...state,
        refreshList:true,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_QUALIFIED:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_QUALIFIED_SUCCESS:
      state = {
        ...state,
        refreshList:true,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_PRIORITY:
      state = {
        ...state,
        refreshList:false,
        apiError: null,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_PRIORITY_SUCCESS:
      state = {
        ...state,
        refreshList:true,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_LEAD_STAGE:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_LEAD_STAGE_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case ADD_NOTE:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_NOTE_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case EDIT_NOTE:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case EDIT_NOTE_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case DELETE_NOTE:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case DELETE_NOTE_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case PUSH_TO_WEBHOOK:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case PUSH_TO_WEBHOOK_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case GET_QUICK_REPLIES:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_QUICK_REPLIES_SUCCESS:
      state = {
        ...state,
        quickReplies: action.payload,
      }
      break
    case SEND_CONVERSATION:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case SEND_CONVERSATION_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Prospects
