import {
  API_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS
} from "./actionTypes"

export const changePassword = (ptcrmSetting) => {
  return {
    type: CHANGE_PASSWORD,
    payload: ptcrmSetting,
  }
}

export const changePasswordSuccess = (message) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const getChangePasswordError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
