import React from "react"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Alert,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Modal,
  Row,
  Progress,
} from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import { Link, Redirect, withRouter } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  uploadProspectsFile,
  getCampaign,
  uploadProspects,
} from "../../store/campaigns/actions"
////socket functions
import io from "socket.io-client"
const socket = io.connect(process.env.REACT_APP_PORT)

class AddProspects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      select_file: false,
      file: null,
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      mailing_address: "",
      mailing_state: "",
      mailing_city: "",
      mailing_zip: "",
      property_address: "",
      property_state: "",
      property_city: "",
      property_zip: "",
      custom1: "",
      csv_progress: 0,
      csv_total_count: 0,
      csv_status: "",
    }

    let authUser = localStorage.getItem("authUser")
    if (authUser) {
      let user = JSON.parse(authUser)
      this.socket = io(process.env.REACT_APP_PORT, {
        query: {
          email: user.user.name,
        },
      })
    }

    this.socket.on("CSVfileprogressmessages", async (email, i, total) => {
      this.setState({
        csv_progress: i,
        csv_total_count: total,
      })
    })
  }

  componentDidMount() {
    this.props.getCampaign(this.props.match.params.id)
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleValidSubmit(event, values) {
    if (values.upload_file === "") {
      this.setState({
        select_file: true,
      })
      return
    } else {
      this.setState({
        select_file: false,
      })
    }

    const formdata = new FormData()
    formdata.append("file", this.state.file)
    this.props.uploadProspectsFile(formdata)
  }

  handleValidSubmit1(event, values) {
    const {
      firstname,
      lastname,
      phone,
      email,
      mailing_address,
      mailing_state,
      mailing_city,
      mailing_zip,
      property_address,
      property_state,
      property_city,
      property_zip,
      custom1,
    } = this.state
    const uploadfile = this.state.file
    const campaign_id = this.props.match.params.id

    this.props.uploadProspects({
      firstname,
      lastname,
      phone,
      // email,
      // mailing_address,
      // mailing_state,
      // mailing_city,
      // mailing_zip,
      property_address,
      property_state,
      property_city,
      property_zip,
      uploadfile,
      campaign_id,
      // custom1,
    })
  }

  render() {
    // if(this.props.successMsg) {
    //     return <Redirect to="/campaigns"></Redirect>
    // }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Add Campaign | TextLaunchPad.com </title>
          </MetaTags>
          <div className="container-fluid">
            <Row className="align-items-center breadcrumb-box">
              <Col xs={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Add New Campaign</h4>
                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/campaigns">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to="/campaigns">Campaigns</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Add New Campaign</BreadcrumbItem>
                  </ol>
                </div>
              </Col>
              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>

            <Row className={"justify-content-center pt-4"}>
              <Col xl="6">
                <Card>
                  <CardBody>
                    {this.props.apiError &&
                    typeof this.props.apiError === "string" ? (
                      <Alert color="danger">
                        {"Problem found in the process"}
                      </Alert>
                    ) : null}

                    {this.props.apiError &&
                    typeof this.props.apiError === "object" &&
                    !this.state.select_file ? (
                      this.props.apiError.response ? (
                        <Alert color="danger">
                          {/* {this.props.apiError.response.data.error=="only csv and xlsx format"?
                                                    "Wrong file format selected. Select only csv & xlsx format":"Problem found in the process"} */}
                          {this.props.apiError.response.data.error ==
                          "only csv and xlsx format"
                            ? "Wrong file format selected. Select only csv & xlsx format"
                            : "Problem found in the process"}
                        </Alert>
                      ) : (
                        <Alert color="danger">
                          {this.props.apiError.message + 3}
                        </Alert>
                      )
                    ) : null}

                    {this.state.select_file ? (
                      <Alert color="danger">{"Please select file"}</Alert>
                    ) : null}

                    {this.props.successMsg ? (
                      <Alert color="success">{this.props.successMsg}</Alert>
                    ) : null}
                    <AvForm
                      onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                      className="needs-validation"
                    >
                      <Row className={"px-2"}>
                        <Col md={12}>
                          {this.state.csv_total_count !== 0 && (
                            <>
                              {(
                                (this.state.csv_progress * 100) /
                                this.state.csv_total_count
                              ).toFixed(2)}
                              %
                            </>
                          )}
                        </Col>
                        <Col md={12}>
                          {this.state.csv_total_count !== 0 && (
                            <Progress
                              style={{
                                height: "16px",
                                "border-radius": "20px",
                              }}
                              color="success"
                              value={
                                isNaN(
                                  (this.state.csv_progress * 100) /
                                    this.state.csv_total_count
                                )
                                  ? 0
                                  : (
                                      (this.state.csv_progress * 100) /
                                      this.state.csv_total_count
                                    ).toFixed(2)
                              }
                            ></Progress>
                          )}
                        </Col>
                        <Col md={12}>
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Upload File
                            </Label>
                            <AvField
                              name="upload_file"
                              type="file"
                              // validate={{required:{value:true}}}
                              className="form-control"
                              id="validationCustom02"
                              onChange={e => {
                                this.setState({
                                  file: e.target.files[0],
                                })
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <div className="text-end">
                            <Link
                              to="/campaigns"
                              className="btn btn-dark me-2"
                              role="button"
                            >
                              Cancel
                            </Link>
                            <Button type="submit" color="primary" className="">
                              Submit
                            </Button>{" "}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {this.state.file &&
                    this.props.campaign &&
                    this.props.upload ? (
                        
                      <AvForm
                        onValidSubmit={(e, v) => this.handleValidSubmit1(e, v)}
                        className="needs-validation"
                      >
                        <div>
                          <Row className="py-4 px-2">
                            <Col md="12">
                              <Label>Campaign Name</Label>
                              <AvField
                                type="text"
                                disabled
                                name="name"
                                value={this.props.campaign.name}
                              ></AvField>
                            </Col>
                          </Row>
                          <Row className="py-4 px-2">
                            <Col md="6">
                              <Label>First Name</Label>
                              <AvField
                                type="select"
                                onChange={this.onChange}
                                validate={{ required: { value: true } }}
                                name="firstname"
                              >
                                <option>Select First Name</option>
                                {this.props.upload.map((item, index) => (
                                  <option key={index} value={index}>
                                    {item}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                            <Col md="6">
                              <Label>Last Name</Label>
                              <AvField
                                type="select"
                                validate={{ required: { value: true } }}
                                onChange={this.onChange}
                                name="lastname"
                              >
                                <option>Select Last Name</option>
                                {this.props.upload.map((item, index) => (
                                  <option key={index} value={index}>
                                    {item}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                          </Row>

                          <Row className="py-4 px-2">
                            <Col md="6">
                              <Label>Phone</Label>
                              <AvField
                                type="select"
                                validate={{ required: { value: true } }}
                                onChange={this.onChange}
                                name="phone"
                              >
                                <option>Select Phone</option>
                                {this.props.upload.map((item, index) => (
                                  <option key={index} value={index}>
                                    {item}
                                  </option>
                                ))}
                              </AvField>
                            </Col>

                            {/* <Col md="6">
                                                            <Label>Email</Label>
                                                            <AvField type="select"
                                                                     validate={{required:{value:true}}}
                                                                     onChange={this.onChange} name="email">
                                                                <option>Select Email</option>
                                                                {this.props.upload.map((item, index)=>(
                                                                    <option key={index} value={index}>{item}</option>
                                                                ))}
                                                            </AvField>
                                                        </Col> */}

                            <Col md="6">
                              <Label>Property Address</Label>
                              <AvField
                                type="select"
                                validate={{ required: { value: true } }}
                                onChange={this.onChange}
                                name="property_address"
                              >
                                <option>Select Address</option>
                                {this.props.upload.map((item, index) => (
                                  <option key={index} value={index}>
                                    {item}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                          </Row>

                          {/* <Row className="py-4 px-2">
                                                        <Col md="6">
                                                            <Label>Mailing Address</Label>
                                                            <AvField type="select"
                                                                     validate={{required:{value:true}}}
                                                                     onChange={this.onChange} name="mailing_address">
                                                                <option>Select Address</option>
                                                                {this.props.upload.map((item, index)=>(
                                                                    <option key={index} value={index}>{item}</option>
                                                                ))}
                                                            </AvField>

                                                        </Col>
                                                        <Col md="6">
                                                            <Label>Mailing City</Label>
                                                            <AvField type="select"
                                                                     validate={{required:{value:true}}}
                                                                     onChange={this.onChange} name="mailing_city">
                                                                <option>Select City</option>
                                                                {this.props.upload.map((item, index)=>(
                                                                    <option key={index} value={index}>{item}</option>
                                                                ))}
                                                            </AvField>
                                                        </Col>
                                                    </Row> */}

                          {/* <Row className="py-4 px-2">
                                                        <Col md="6">
                                                            <Label>Mailing State</Label>
                                                            <AvField type="select"
                                                                     validate={{required:{value:true}}}
                                                                     onChange={this.onChange}
                                                                     name="mailing_state">
                                                                <option>Select State</option>
                                                                {this.props.upload.map((item, index)=>(
                                                                    <option key={index} value={index}>{item}</option>
                                                                ))}
                                                            </AvField>
                                                        </Col>
                                                        <Col md="6">
                                                            <Label>Mailing Zip</Label>
                                                            <AvField type="select"
                                                                     validate={{required:{value:true}}}
                                                                     onChange={this.onChange}
                                                                     name="mailing_zip">
                                                                <option>Select Zip Code</option>
                                                                {this.props.upload.map((item, index)=>(
                                                                    <option key={index} value={index}>{item}</option>
                                                                ))}
                                                            </AvField>
                                                        </Col>
                                                    </Row> */}

                          <Row className="py-4 px-2">
                            <Col md="6">
                              <Label>Property City</Label>
                              <AvField
                                type="select"
                                validate={{ required: { value: true } }}
                                onChange={this.onChange}
                                name="property_city"
                              >
                                <option>Select City</option>
                                {this.props.upload.map((item, index) => (
                                  <option key={index} value={index}>
                                    {item}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                            <Col md="6">
                              <Label>Property State</Label>
                              <AvField
                                type="select"
                                validate={{ required: { value: true } }}
                                onChange={this.onChange}
                                name="property_state"
                              >
                                <option>Select State</option>
                                {this.props.upload.map((item, index) => (
                                  <option key={index} value={index}>
                                    {item}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                          </Row>

                          <Row className="py-4 px-2">
                            <Col md="6">
                              <Label>Property Zip</Label>
                              <AvField
                                type="select"
                                validate={{ required: { value: true } }}
                                onChange={this.onChange}
                                name="property_zip"
                              >
                                <option>Select Zip Code</option>
                                {this.props.upload.map((item, index) => (
                                  <option key={index} value={index}>
                                    {item}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                            {/* <Col md="6">
                                                            <Label>Custom1</Label>
                                                            <AvField type="select"
                                                                   onChange={this.onChange}
                                                                   name="custom1">
                                                                <option>Select Custom1</option>
                                                                {this.props.upload.map((item, index)=>(
                                                                    <option key={index} value={index}>{item}</option>
                                                                ))}
                                                            </AvField>
                                                        </Col> */}
                          </Row>
                          <Row className="py-4 px-2"></Row>
                          <Row>
                            <Col md={12} className={"text-end"}>
                              <Link
                                to="/campaigns"
                                className="btn btn-dark me-2"
                                role="button"
                              >
                                Cancel
                              </Link>
                              <Button
                                type="submit"
                                color="primary"
                                className=""
                              >
                                Submit
                              </Button>{" "}
                            </Col>
                          </Row>
                        </div>
                      </AvForm>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

AddProspects.propTypes = {
  apiError: PropTypes.any,
  successMsg: PropTypes.any,
  upload: PropTypes.any,
  campaign: PropTypes.object,
}

const mapStatetoProps = state => {
  const {
    apiError,
    successMsg,
    upload,
    campaign,
    getCampaign,
    uploadProspectsFile,
    uploadProspects,
  } = state.Campaign
  return {
    apiError,
    successMsg,
    upload,
    campaign,
    getCampaign,
    uploadProspectsFile,
    uploadProspects,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    uploadProspectsFile,
    getCampaign,
    uploadProspects,
  })(AddProspects)
)
