import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import {
  Row,
  Col,
  BreadcrumbItem,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap"

import { Link } from "react-router-dom"

import { AvForm, AvField } from "availity-reactstrap-validation"

import { put, get, post } from "../../helpers/api_helper"

function ListManagementPhoneNumber(props) {
  const [dataListPhone, setDataListPhone] = useState(null)
  const [successMsg, setSuccessMsg] = useState("")
  async function handleAddSubmit(e, value) {
    if (dataListPhone) {
      await put(process.env.REACT_APP_PORT + "/api/setting/updatePhoneMs", {
        phoneReceive: value.phoneReceive,
        phoneSend: value.phoneSend,
      })
      setSuccessMsg("Phone updated successfully")
    } else {
      await post(process.env.REACT_APP_PORT + "/api/setting/createPhoneMs", {
        phoneReceive: value.phoneReceive,
        phoneSend: value.phoneSend,
      })
      setSuccessMsg("Phone added successfully")
    }
    setTimeout(() => {
      onGetListPhone()
    }, 1000)
  }

  async function onGetListPhone() {
    const resp = await get(
      process.env.REACT_APP_PORT + "/api/setting/getPhoneMs"
    )
    if (resp) {
      setDataListPhone(resp)
      setSuccessMsg("")
    }
  }

  useEffect(() => {
    onGetListPhone()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Mobile Alert | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Row className="align-items-center breadcrumb-box">
            <Col xs={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">
                  {/* Management Phone Number */}
                  Mobile Phone
                  </h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Mobile Phone</BreadcrumbItem>
                </ol>
              </div>
            </Col>
            <Col md={12} className={"pb-2"}>
              <span
                className={
                  "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                }
              ></span>
            </Col>
          </Row>
          {successMsg ? <Alert color="success">{successMsg}</Alert> : null}
          <div
            style={{
              padding: "16px",
              borderRadius: "12px",
              background: "white",
              "max-width": "500px",
              margin: "auto",
              marginTop: "60px",
            }}
          >
            <AvForm onValidSubmit={(e, v) => handleAddSubmit(e, v)}>
              <FormGroup>
                <Label for="examplePhoneReceive"> Text Forward Phone Number</Label>

                <AvField
                  value={dataListPhone?.data?.phoneReceive}
                  name="phoneReceive"
                  placeholder="Please enter phoneReceive"
                  type="text"
                  errorMessage="Enter phoneReceive"
                  className="form-control"
                 
                />
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  "justify-content": "end",
                  marginTop: "16px",
                }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                 
                >
                  Submit
                </button>
              </div>
            </AvForm>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

ListManagementPhoneNumber.propTypes = {}

export default ListManagementPhoneNumber
