import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import sendIcon from "../../assets/images/send.svg"
import { connect } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Popover,
  PopoverHeader,
  PopoverBody,
  PopoverProps,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"

class Conversations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popoverOpen: false,
      message: null,
      send: "",
      textlength: "",
      change_all_unread: 0,
      selected_status: this?.props?.data?.prospect?.lead,
    }
  }

  getdata() {
    let authUser = localStorage.getItem("authUser")
    let user = JSON.parse(authUser)
    this.props.socket.emit(
      "getmessagebypros",
      user.user.name,
      this.props.data.prospect._id
    )
  }

  settextbody(message) {
    let body = message
    body = body.replace(/\[@firstName+\]/g, this.props.data.prospect.first_name)
    body = body.replace(/\[@email+\]/g, this.props.data.prospect.email)
    body = body.replace(/\[@lastName+\]/g, this.props.data.prospect.last_name)
    body = body.replace(
      /\[@address+\]/g,
      this.props.data.prospect.property_address
    )
    body = body.replace(/\[@city+\]/g, this.props.data.prospect.property_city)
    body = body.replace(/\[@zip+\]/g, this.props.data.prospect.property_zip)
    body = body.replace(/\[@state+\]/g, this.props.data.prospect.property_state)
    this.setState({
      send: body,
    })
  }

  handleValidSubmit(event, values) {
    if (values.message.length > 320) {
      return
    }
    if (values.message.trimStart() === "") {
      this.setState({
        textlength: 0,
      })
      return
    }
    const data = {
      from: this.state.message.number,
      to: this.state.message.sender,
      body: this.state.send.trimStart(),
      camp: this.props.data.prospect.campaign,
    }

    this.props.sendConversation(data)

    this.setState({
      send: "",
      // textlength: 0,
    })
  }

  changesstatus(id) {
    let authUser = localStorage.getItem("authUser")
    let user = JSON.parse(authUser)
    this.props.socket.emit("updatestatus", user.user.name, id)
    this.props.ongGetListUnreadMessages()
    //to show total count on sidebar
    setTimeout(() => {
      localStorage.setItem(
        "toggle_update_allReadMsg",
        this.state.change_all_unread + 1
      )
    }, 200)
  }

  componentDidMount() {
    this.setState({
      message: this.props.data,
    })
    this.props.getQuickReplies()
    this.props.socket.on("message", msg => {
      if (msg != null && msg._id == this.props.data._id) {
        this.setState({
          message: msg,
        })
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.message && prevState.message._id != this.props.data._id) {
      this.setState({
        message: this.props.data,
        selected_status: this.props.data.prospect.lead,
      })
    }
  }

  onChange(e, id) {
    this.props?.callExistsProspect &&
      this.props?.callExistsProspect(id, e.target.value)
    this.props?.existsProspect &&
      this.props?.existsProspect({
        _id: id,
        value: e.target.value,
      })
  }

  verifiedNumber() {
    this.props.updateProspectVerified({
      id: this.props.data.prospect._id,
    })
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  rverifiedNumber() {
    this.props.removeProspectVerified({
      id: this.props.data.prospect._id,
    })
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  wrongNumber() {
    this.props.updateProspectWrongNumber({
      id: this.props.data.prospect._id,
    })
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  rwrongNumber() {
    this.props.removeProspectWrongNumber({
      id: this.props.data.prospect._id,
    })
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  qualifiedNumber() {
    this.props.updateProspectQualified({
      id: this.props.data.prospect._id,
    })
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  rqualifiedNumber() {
    this.props.removeProspectQualified({
      id: this.props.data.prospect._id,
    })
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  priorityNumber() {
    this.props.updateProspectPriority({
      id: this.props.data.prospect._id,
    })
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  rpriorityNumber() {
    this.props.removeProspectPriority({
      id: this.props.data.prospect._id,
    })
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  setDNC(dnc) {
    let stDNC = dnc ? true : false
    const data = {
      dnc: stDNC,
      id: this.props.data.prospect._id,
    }
    this.props.updateProspectDNS(data)
    this.getdata()
    this.props?.ongGetListUnreadMessages &&
      this.props?.ongGetListUnreadMessages()
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Row className={"mb-4"}>
              <Col
                md={12}
                className={
                  "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2"
                }
              >
                <label className="card-title pe-3">
                  From:{" "}
                  {this.props.data &&
                    this.props.data.number != undefined &&
                    this.props.data.number}
                  <br />
                </label>
                <label className="card-title">
                  To:{" "}
                  {this.props.data &&
                    this.props.data.sender != undefined &&
                    this.props.data.sender}
                </label>
              </Col>
            </Row>

            <div className="chat-conversation">
              <SimpleBar style={{ height: "365px" }}>
                <ul
                  className="conversation-list"
                  data-simplebar
                  style={{ maxHeight: "367px" }}
                >
                  <li className="clearfix">
                    <div className="conversation-text first-box">
                      <div className="chat-message">
                        <div className="flee-user-name align-start">
                          <div className="user-short-name">
                            {this.props.data.prospect.first_name
                              ?.charAt(0)
                              .toUpperCase() +
                              this.props.data.prospect.last_name
                                ?.charAt(0)
                                .toUpperCase()}
                          </div>
                          <div className="relative">
                            <span className="user-name">
                              {this.props.data.prospect.first_name +
                                " " +
                                this.props.data.prospect.last_name}
                            </span>
                            <p className="text-black">
                              {this.props.data.prospect.property_address +
                                ", " +
                                this.props.data.prospect.property_city +
                                ", " +
                                this.props.data.prospect.property_state +
                                ", " +
                                this.props.data.prospect.property_zip}
                            </p>
                          </div>
                          {/* <div className="message-right-icon">
                            <Button
                              className={"has-icon small me-1 mb-1"}
                              title="Reeded"
                              color={"danger"}
                            >
                              <i class="fas fa-envelope-open"></i>
                            </Button>
                            <Button
                              className={"has-icon small me-1 mb-1"}
                              color={"white"}
                              title="Edit"
                            >
                              <i className="fas fa-edit" />
                            </Button>
                            <Button
                              className={"has-icon small me-1 mb-1"}
                              color={"white"}
                              title="Copy"
                            >
                              <i className="fas fa-copy" />
                            </Button>
                            <Button
                              className={"has-icon small me-1 mb-1"}
                              color={"white"}
                              title="Mail"
                            >
                              <i className="fas fa-print" />
                            </Button>
                          </div> */}
                        </div>
                        <div
                          className={
                            "d-sm-flex justify-content-between align-items-center"
                          }
                        >
                          {!this.props.hide && (
                            <div>
                              {this.props.data.prospect.dnc ? (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"danger"}
                                  onClick={e => {
                                    this.setDNC(false)
                                  }}
                                  title="Remove From DNC"
                                >
                                  <i className="fas fa-times" />
                                  <span>DNC</span>
                                </Button>
                              ) : (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"outline-secondary"}
                                  onClick={e => {
                                    this.setDNC(true)
                                  }}
                                  title="Add To DNC"
                                >
                                  <i className="fas fa-times" />
                                  <span>DNC</span>
                                </Button>
                              )}{" "}
                              {this.props.data.prospect.verified === false ? (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"outline-secondary"}
                                  onClick={() => this.verifiedNumber()}
                                  title="Add to Verified"
                                >
                                  <i className="fas fa-check" />
                                  <span>Verified</span>
                                </Button>
                              ) : (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"success"}
                                  onClick={() => this.rverifiedNumber()}
                                  title="Remove From Verified"
                                >
                                  <i className="fas fa-check" />
                                  <span>Verified</span>
                                </Button>
                              )}{" "}
                              {this.props.data.prospect.Wrong_Number ===
                              false ? (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"outline-secondary"}
                                  onClick={() => this.wrongNumber()}
                                  title="Add To Wrong Number"
                                >
                                  <i className="fas fa-phone-slash" />
                                  <span>Wrong Number</span>
                                </Button>
                              ) : (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"primary"}
                                  onClick={() => this.rwrongNumber()}
                                  title="Remove From Wrong Number"
                                >
                                  <i className="fas fa-phone-slash" />
                                  <span>Wrong Number</span>
                                </Button>
                              )}{" "}
                              {this.props.data.prospect.isLead === false ? (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"outline-secondary"}
                                  onClick={() => this.qualifiedNumber()}
                                  title="Add To Qualified"
                                >
                                  <i className="fas fa-star" />
                                  <span>Qualified</span>
                                </Button>
                              ) : (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"info"}
                                  onClick={() => this.rqualifiedNumber()}
                                  title="Remove From Qualified"
                                >
                                  <i className="fas fa-star" />
                                  <span>Qualified</span>
                                </Button>
                              )}{" "}
                              {this.props.data.prospect.isPriority === false ? (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"outline-secondary"}
                                  onClick={() => this.priorityNumber()}
                                  title="Add To Priority"
                                >
                                  <i className="fas fa-bolt" />
                                  <span>Priority</span>
                                </Button>
                              ) : (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  color={"warning"}
                                  onClick={() => this.rpriorityNumber()}
                                  title="Remove From Priority"
                                >
                                  <i className="fas fa-bolt" />
                                  <span>Priority</span>
                                </Button>
                              )}{" "}
                              {this.state.message &&
                              this.state.message.status == "unread" ? (
                                <Button
                                  className={"botton-btn me-1 mb-1"}
                                  onClick={() =>
                                    this.changesstatus(this.state.message._id)
                                  }
                                  color="primary"
                                >
                                  <i
                                    className={"fas fa-envelope-open pe-1"}
                                  ></i>{" "}
                                  Mark As Read
                                </Button>
                              ) : null}
                            </div>
                          )}
                          {/* <div></div> */}
                        </div>
                        {this?.props?.showLeads && (
                          <Input
                            type="select"
                            className="mt-2 mb-1 bg-transparent"
                            value={this.state.selected_status}
                            onChange={e => {
                              this?.onChange(
                                e,
                                this?.props?.data?.prospect?._id
                              )
                              this.getdata()
                              this.props?.ongGetListUnreadMessages &&
                                this.props?.ongGetListUnreadMessages()
                              this.setState({ selected_status: e.target.value })
                            }}
                            // style={{border: "1px solid red"}}
                          >
                            {this?.props?.leads?.length > 0 &&
                              this?.props?.leads?.map((lead, index) => (
                                <option value={lead?._id} key={index}>
                                  {lead?.lead_Stage}
                                </option>
                              ))}
                          </Input>
                        )}
                      </div>
                    </div>
                  </li>
                  {this.state.message &&
                    this.state.message.messages.map((item, index) => (
                      <li
                        className={`clearfix ${
                          item?.status !== "received" && "odd"
                        }`}
                        key={item._id}
                      >
                        <div className="conversation-text">
                          <div
                            className={
                              index == this.state.message.messages.length - 1 &&
                              this.state.message.status == "unread"
                                ? "chat-message new-mg"
                                : "chat-message"
                            }
                          >
                            <div className="new-text">New</div>
                            <p>{item.body} </p>
                            <div
                              className="mt-2"
                              style={{ fontSize: "10px", fontStyle: "oblique" }}
                            >
                              {moment(item.createdAt)
                                .local()
                                .format("YYYY-MM-DD  hh:mm A")}
                            </div>
                            <div
                              style={{ fontSize: "10px", fontStyle: "oblique" }}
                            >
                              {" "}
                              {item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </SimpleBar>
              {this.props.hide ? null : (
                <AvForm
                  className="needs-validation"
                  onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                >
                  <div className="mt-3 pt-1 d-flex">
                    <div className="flex-grow-1 chat-inputbar">
                      <AvField
                        name={"message"}
                        type="text"
                        className="chat-input"
                        placeholder="Type a message"
                        value={this.state.send}
                        required
                        validate={{
                          required: {
                            value: this.state.textlength ? false : true,
                            errorMessage: "Text field is required",
                          },
                        }}
                        // validate={{ required: { value: this.state.textlength?false:true, errorMessage: 'Text field is required' } }}
                        onChange={e => {
                          this.setState({
                            send: e.target.value,
                            textlength: e.target.value.length,
                          })
                        }}
                      />
                      <span style={{ float: "right", fontSize: "12px" }}>
                        {this.state.textlength <= 160 ? (
                          <i
                            className="fas fa-check-circle fa-sm me-1"
                            style={{ color: "Green" }}
                          ></i>
                        ) : null}
                        {this.state.textlength <= 320 &&
                        this.state.textlength > 160 ? (
                          <i
                            className="fas fa-exclamation-circle fa-sm me-1"
                            style={{ color: "orange" }}
                          ></i>
                        ) : null}
                        {this.state.textlength > 320 ? (
                          <i
                            className="fas fa-times-circle fa-sm me-1"
                            style={{ color: "red" }}
                          ></i>
                        ) : null}{" "}
                        {this.state.textlength ? this.state.textlength : 0}/320
                      </span>
                    </div>
                    <div className="chat-send">
                      <div>
                        <Button
                          id={"Popover1"}
                          type={"button"}
                          className={"has-icon me-3"}
                          color={"primary"}
                          size={"sm"}
                        >
                          <i className={"fas fa-asterisk"}></i>
                        </Button>
                        <Popover
                          placement="bottom"
                          isOpen={this.state.popoverOpen}
                          target="Popover1"
                          toggle={() => {
                            this.setState({
                              popoverOpen: !this.state.popoverOpen,
                            })
                          }}
                        >
                          <PopoverHeader>Quick Reply</PopoverHeader>
                          <PopoverBody>
                            {this.props.quickReplies != null &&
                              this.props.quickReplies.length > 0 &&
                              this.props.quickReplies.map(item => (
                                <div
                                  className="btn btn-link"
                                  onClick={() => this.settextbody(item.message)}
                                  key={item._id}
                                >
                                  {item.message}
                                </div>
                              ))}
                          </PopoverBody>
                        </Popover>
                        {/* <Button
                          type="submit"
                          color="success"
                          className={`btn-block ${this.props.data.prospect.dnc || this.props.data.prospect.Wrong_Number ? 'disabled' : null }`}
                          // title={
                          //   this.props.data.prospect.dnc || this.props.data.prospect.Wrong_Number
                          //     ? this.props.data.prospect.dnc && this.props.data.prospect.Wrong_Number ? 'This Prospect is marked as DNC and Wrong Number' : this.props.data.prospect.dnc
                          //       ? 'This Prospect is marked as DNC'
                          //       : 'This Prospect is marked as wrong number'
                          //     : null
                          // }
                          disabled = {this.props.data.prospect.dnc || this.props.data.prospect.Wrong_Number }
                          
                        >
                          <i className="fas fa-paper-plane pe-1"></i>
                          <span className={"d-none d-lg-inline-block"}>
                            Send
                          </span>
                        </Button> */}
                        <button
                          type="submit"
                          color="success"
                          className={`main-btn success-btn ${
                            this.props.data.prospect.dnc ||
                            this.props.data.prospect.Wrong_Number
                              ? "disabled"
                              : null
                          }`}
                          disabled={
                            this.props.data.prospect.dnc ||
                            this.props.data.prospect.Wrong_Number
                          }
                          title={
                            this.props.data.prospect.dnc ||
                            this.props.data.prospect.Wrong_Number
                              ? this.props.data.prospect.dnc &&
                                this.props.data.prospect.Wrong_Number
                                ? "Prospect marked as DNC and Wrong Number"
                                : this.props.data.prospect.dnc
                                ? "Prospect marked as DNC"
                                : "Prospect marked as wrong number"
                              : null
                          }
                        >
                          <img src={sendIcon} alt="" />
                          <span className={"d-none d-lg-inline-block"}>
                            Send
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </AvForm>
              )}
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}
export default Conversations
