import React,{useState} from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"

const Breadcrumb = props => {
  const [setting_Menu, setsetting_Menu] = useState(false)

  return (
    <Row className="align-items-center">
      <Col sm={6}>
        <div className="page-title-box">
          <h4 className="font-size-18">{props.breadcrumbItem}</h4>
          <ol className="breadcrumb mb-0">
            {
              (props.maintitle) ?
            <>
            <BreadcrumbItem>
                <Link to="/#">{props.maintitle}</Link>
            </BreadcrumbItem>
            </> : ''
            }
              { props.title ? (
                  <BreadcrumbItem>
                      { props.titleurl ? (
                          <Link to={props.titleurl}>{props.title}</Link>
                      ) : (
                          <Link to="/#">{props.title}</Link>
                      ) }
                  </BreadcrumbItem>
              ) : null }
            <BreadcrumbItem active>
              {props.breadcrumbItem}
            </BreadcrumbItem>
          </ol>
        </div>
      </Col>
      <Col sm={6}>
          { (props.breadcrumbItem=='Campaigns') ? (
              <div className="float-end d-none d-md-block">
                  <Link
                      className="btn btn-primary waves-effect waves-light"
                      to="/add-campaign"
                      role="button"
                  >
                    <i className="mdi mdi-plus-box me-2"></i> Add Campaign
                  </Link>

              </div>
          ) : null }
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
