import {
  ADD_LEAD_STAGE, ADD_LEAD_STAGE_SUCCESS,
  API_ERROR, DELETE_LEAD_STAGE, DELETE_LEAD_STAGE_SUCCESS,
  GET_LEAD_STAGES,
  GET_LEAD_STAGES_SUCCESS
} from "./actionTypes"

export const getLeadStages = () => {
  return {
    type: GET_LEAD_STAGES
  }
}

export const getLeadStagesSuccess = leadStages => {
  return {
    type: GET_LEAD_STAGES_SUCCESS,
    payload: leadStages,
  }
}


export const getLeadStagesError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const addLeadStage = (leadStage) => {
  return {
    type: ADD_LEAD_STAGE,
    payload: leadStage,
  }
}

export const addLeadStageSuccess = (message) => {
  return {
    type: ADD_LEAD_STAGE_SUCCESS,
    payload: message,
  }
}
export const deleteLeadStage = (leadStage) => {
  return {
    type: DELETE_LEAD_STAGE,
    payload: leadStage,
  }
}

export const deleteLeadStageSuccess = (message) => {
  return {
    type: DELETE_LEAD_STAGE_SUCCESS,
    payload: message,
  }
}
