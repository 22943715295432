import {
  ADD_QUICK_REPLY, ADD_QUICK_REPLY_SUCCESS,
  API_ERROR, DELETE_QUICK_REPLY, DELETE_QUICK_REPLY_SUCCESS, EDIT_QUICK_REPLY, EDIT_QUICK_REPLY_SUCCESS,
  GET_QUICK_REPLIES,
  GET_QUICK_REPLIES_SUCCESS
} from "./actionTypes"

export const getQuickReplies = () => {
  return {
    type: GET_QUICK_REPLIES
  }
}

export const getQuickRepliesSuccess = categories => {
  return {
    type: GET_QUICK_REPLIES_SUCCESS,
    payload: categories,
  }
}


export const getQuickRepliesError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}


export const editQuickReply = (quickReply) => {
  return {
    type: EDIT_QUICK_REPLY,
    payload: quickReply,
  }
}

export const editQuickReplySuccess = (message) => {
  return {
    type: EDIT_QUICK_REPLY_SUCCESS,
    payload: message,
  }
}

export const addQuickReply = (quickReply) => {
  return {
    type: ADD_QUICK_REPLY,
    payload: quickReply,
  }
}

export const addQuickReplySuccess = (message) => {
  return {
    type: ADD_QUICK_REPLY_SUCCESS,
    payload: message,
  }
}
export const deleteQuickReply = (quickReply) => {
  return {
    type: DELETE_QUICK_REPLY,
    payload: quickReply,
  }
}

export const deleteQuickReplySuccess = (message) => {
  return {
    type: DELETE_QUICK_REPLY_SUCCESS,
    payload: message,
  }
}
