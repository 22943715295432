import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  BreadcrumbItem,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
} from "reactstrap"
import MetaTags from "react-meta-tags"

import { Link, Redirect } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import axios from "axios"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import settingicon from "../../assets/images/settings.svg"

import { connect } from "react-redux"

import plusicon from "../../assets/images/white-plus.svg"
import Whitesettingicon from "../../assets/images/settingWhite.svg"

import drop from "../../assets/images/drop.svg"
import kebabMenu from "../../assets/images/kebab-icon.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"

import plus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"
import sDrop from "../../assets/images/select-drop.svg"
import phoneIcon from "../../assets/images/phone.svg"
import boltIcon from "../../assets/images/bolt.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import toast, { Toaster } from "react-hot-toast"
import DataTable from "react-data-table-component"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"

const notify = (type, text) => {
  switch (type) {
    case "success":
      toast.success(text)
      break
    case "error":
      toast.error(text)
      break

    default:
      break
  }
}

const ManageColors = props => {
  const [modal, setModal] = useState({ isOpen: false })
  const [colors, setColors] = useState([])
  const [tags, setTags] = useState([])
  const [selectedColor, setSelectedColor] = useState("")
  const [deleteAlert, setDeleteAlert] = useState({
    isOpen: false,
  })
  const [isLoading, setIsLoading] = useState(true)

  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const getColors = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/colors/pullColors",
        config
      )

      if (response.data.data) {
        setColors(response.data.data)
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }
  }
  const batchData1 = colors.map((item, index) => ({
    name: item.name,
    // style={{backgroundColor:`${item.color}`}}
    color: (
      <div
        className="tag-color-dis"
        style={{ backgroundColor: `${item.color}` }}
      ></div>
    ),

    edit: (
      <Button
        onClick={() => {
          openModalHandler("updateColor", item)
        }}
        className={"has-icon me-2"}
        color={"outline-primary"}
      >
        <i className="fas fa-pencil-alt"></i>
      </Button>
    ),
    delete: (
      <Button
        onClick={() => {
          setDeleteAlert({ isOpen: true, ...item })
        }}
        className={"has-icon"}
        color={"outline-danger"}
      >
        <i className="fas fa-trash-alt"></i>
      </Button>
    ),
  }))

  const batchesColumns = [
    {
      name: "Name",
      selector: row => row.name,
    },
    {
      name: "Color",
      selector: row => row.color,
    },

    {
      name: "Edit",
      selector: row => row.edit,
    },
    {
      name: "Delete",
      selector: row => row.delete,
    },
  ]

  const openModalHandler = (action, data = {}) => {
    switch (action) {
      case "createColor": {
        setModal({
          isOpen: true,
          title: "Create Color",
          confirmText: "Save",
          action,
        })
        break
      }
      case "updateColor": {
        setModal({
          isOpen: true,
          title: "Update Color",
          confirmText: "Update",
          ...data,
          action,
        })
        if (data?.color) setSelectedColor(data.color)
        break
      }

      default:
        break
    }
  }

  const handleModalSubmit = (e, value) => {
    switch (modal.action) {
      case "createColor":
        handleCreateColor(value)
        break
      case "updateColor":
        handleUpdateColor(value, modal._id)
        break
    }
  }

  const handleCreateColor = async val => {
    try {
      const data = {
        name: val.name?.trim(),
        color: val.color,
      }
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/colors/createColor",
        data,
        config
      )
      if (response) {
        getColors()
        setModal({ isOpen: !modal?.isOpen })
        setSelectedColor("")
        notify("success", "Color Created Successfully")
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }
  }
  const handleUpdateColor = async (val, id) => {
    try {
      const data = {
        name: val.name?.trim(),
        color: val.color,
      }
      const response = await axios.put(
        process.env.REACT_APP_PORT + `/api/colors/updateColor/${id}`,
        data,
        config
      )
      if (response && response.data) {
        setModal({ isOpen: false })
        getColors()
        setSelectedColor("")
        notify("success", "Color Updated Successfully")
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }
  }
  const handleDeleteColor = async () => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT +
          "/api/colors/deleteColor/" +
          deleteAlert._id,
        config
      )
      if (response && response.data) {
        setDeleteAlert({ isOpen: false })
        getColors()
        notify("success", "Color Deleted Successfully")
      }
    } catch (err) {
      setDeleteAlert({ isOpen: false })
      notify("error", err.response.data.error)
    }
  }

  useEffect(() => {
    getColors()
  }, [])
  return (
    <React.Fragment>
      {isLoading && (
        <div className="spinner-wr">
          <Spinner style={{ color: "#5932ea" }} />
        </div>
      )}
      {deleteAlert && deleteAlert.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            handleDeleteColor()
          }}
          onCancel={() => {
            setDeleteAlert({ isOpen: false })
          }}
        >
          You want to delete <strong>{deleteAlert.name}</strong>. You won't be
          able to revert this!
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>Tags | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Toaster />

          <Row className="align-items-center breadcrumb-box mb-5">
            <div className="col-md-8 col-12">
              <div className="page-title-box">
                <h4 className="font-size-18">Manage Colors</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem>Restricted Contact</BreadcrumbItem> */}
                  <BreadcrumbItem active>Manage Colors </BreadcrumbItem>
                </ol>
              </div>
            </div>
            <div className="col-md-4 col-12 d-flex gap-3">
              <button
                className="main-btn border-btn mt-3"
                // to="/add-campaign"
                onClick={() => openModalHandler("createColor")}
                type="button"
              >
                <img src={plusPurple} alt="" />
                <span>Add Color</span>
              </button>
              {/* <button
                            className="main-btn mt-3"
                            // to="/add-campaign"
                            onClick={() => openModalHandler('createColor')}
                            type="button"
                        >
                            <img src={plusicon} alt="" />
                            <span>Add Color</span>
                        </button> */}
              {/* <div className="col-md-4 col-12 ">

                            
                            <button
                                className="main-btn mt-3"
                                // to="/add-campaign"
                                //   onClick={() => this.tog_add()}
                                type="button"
                            >
                                <img src={settingicon} alt="" />
                                <span>Manage Colors</span>
                            </button>
                        </div> */}
              <Link to="/tags" className="main-btn mt-3">
                <img src={Whitesettingicon} alt="" />
                <span>Manage Tags</span>
              </Link>
            </div>
          </Row>
          <DataTable
            // title="Campaign Metrics"
            columns={batchesColumns}
            data={batchData1}
            fixedHeader
            pagination
            className="c-table"
          />
        </div>
      </div>
      <Modal
        isOpen={modal?.isOpen}
        toggle={() => {
          setModal({ isOpen: !modal?.isOpen })
        }}
        className="c-modal"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">{modal?.title}</h5>
          <button
            type="button"
            onClick={() => {
              setModal({ isOpen: false })
              setSelectedColor("")
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleModalSubmit(e, v)}
              // onSubmit={(e, v) => handleModalSubmit(e, v, modal?.title)}
              className="needs-validation"
            >
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      name="name"
                      placeholder="Color Name"
                      type="text"
                      errorMessage="Enter Color Name"
                      className="form-control cc-form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                          errorMessage: "Enter valid Tag name",
                        },
                      }}
                      id="validationCustom01"
                      defaultValue={modal.name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Row>
                      <Col md={3} sm={3} className="ps-3">
                        <label className="pt-1">Select Color :</label>
                      </Col>
                      <Col md={4} sm={4} className="cursor-pointer">
                        <AvField
                          name="color"
                          placeholder="Color "
                          type="color"
                          errorMessage="Enter Color"
                          className="form-control cc-form-control"
                          validate={{
                            required: { value: true },
                            // pattern: {
                            //     value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                            //     errorMessage: "Enter valid color",
                            // },
                          }}
                          id="validationCustom01"
                          onChange={e => {
                            setSelectedColor(e.target.value)
                          }}
                          defaultValue={modal.color}
                        />
                      </Col>
                      <Col md={4} sm={4}>
                        {selectedColor && (
                          <div
                            className="tag-color-dis"
                            style={{ backgroundColor: selectedColor }}
                          ></div>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Link
                  to="/manageColors"
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setModal({ isOpen: false })
                    setSelectedColor("")
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Link>
                <Button type="submit" color="primary" className="main-btn">
                  <span>{modal?.confirmText}</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>
    </React.Fragment>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

export default withTranslation()(ManageColors)
