import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  GET_TEMPLATES,
  EDIT_TEMPLATE,
  ADD_TEMPLATE,
  DELETE_TEMPLATE,
  GET_TEMPLATE,
  GET_NEGATIVE_KEYWORDS,
  MANAGE_NEGATIVE_KEYWORDS,
  EDIT_CATEGORY,
  GET_TEMPLATES_GROUPED_BY_CATEGORY,
} from "./actionTypes"

import {
  addTemplateSuccess,
  editTemplateSuccess,
  getTemplatesError,
  getTemplatesSuccess,
  getTemplateSuccess,
  getNegativeKeywordsSuccess,
  getNegativeKeywordsError,
  manageNegativeKeywordsSuccess,
  manageNegativeKeywordsError,
  editCategorySuccess,
  getCategoriesError,
  getCategoriesSuccess,
  getTemplatesGroupedByCategoryError,
  getTemplatesGroupedByCategorySuccess,
} from "./actions"

import {
  addTemplate,
  deleteTemplate,
  editTemplate,
  getTemplate,
  getTemplates,
  getTemplatesGroupedByCategory,
  getNegativeKeywords,
  manageNegativeKeywords,
  editCategory,
  getCategories,
} from "../../helpers/fakebackend_helper"

const delay = ms => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getTemplatesApi({ payload: categoryId }) {
  try {
    const response = yield call(
      getTemplates,
      categoryId ? "?categoryId=" + categoryId : ""
    )
    yield put(getTemplatesSuccess(response.data))
  } catch (error) {
    yield put(getTemplatesError(error))
  }
}
function* getTemplatesGroupedByCategoryApi({ payload: query }) {
  try {
    const queryParams = new URLSearchParams({ ...query })
    const response = yield call(getTemplatesGroupedByCategory, queryParams)
    yield put(getTemplatesGroupedByCategorySuccess(response.data))
  } catch (error) {
    yield put(getTemplatesGroupedByCategoryError(error))
  }
}

function* getNegativeKeywordsApi() {
  try {
    const response = yield call(getNegativeKeywords)
    yield put(getNegativeKeywordsSuccess(response.data))
  } catch (error) {
    yield put(getNegativeKeywordsError(error))
  }
}

function* getTemplateApi({ payload: template_id }) {
  try {
    const response = yield call(getTemplate, template_id)
    yield put(getTemplateSuccess(response.data))
  } catch (error) {
    yield put(getTemplatesError(error))
  }
}

function* addTemplateApi({ payload: template }) {
  try {
    const response = yield call(addTemplate, template)
    yield put(addTemplateSuccess("Template added successfully"))
    yield delay(800)
    const response1 = yield call(getTemplatesGroupedByCategory)
    yield put(getTemplatesGroupedByCategorySuccess(response1.data))
  } catch (error) {
    yield put(getTemplatesGroupedByCategoryError(error))
  }
}
function* manageNegativeKeywordsApi({ payload: keywords }) {
  try {
    const response = yield call(manageNegativeKeywords, keywords)
    yield put(
      manageNegativeKeywordsSuccess("Negative Keywords saved successfully")
    )
    yield delay(800)
    const response1 = yield call(getNegativeKeywords)
    yield put(getNegativeKeywordsSuccess(response1.data))
  } catch (error) {
    yield put(getTemplatesError(error))
  }
}

function* editTemplateApi({ payload: template }) {
  try {
    const response = yield call(editTemplate, template)
    yield put(editTemplateSuccess("Template updated successfully"))
    yield delay(1000)
    const response1 = yield call(getTemplatesGroupedByCategory)
    yield put(getTemplatesGroupedByCategorySuccess(response1.data))
  } catch (error) {
  
    yield put(getTemplatesGroupedByCategoryError(error))
  }
}

function* editCategoryApi({ payload: category }) {
  try {
    yield call(editCategory, category)
    yield put(editCategorySuccess("Category updated successfully"))
    yield delay(1000)
    yield call(getCategories)
  } catch (error) {
    yield put(getCategoriesError(error))
  }
}

function* deleteTemplateApi({ payload: template }) {
  try {
    const response = yield call(deleteTemplate, template)
    yield put(editTemplateSuccess("Template deleted successfully"))
    yield delay(1000)
    const response1 = yield call(getTemplatesGroupedByCategory)
    yield put(getTemplatesGroupedByCategorySuccess(response1.data))
  } catch (error) {
    yield put(getTemplatesGroupedByCategoryError(error))
  }
}

export function* watchTemplates() {
  yield takeEvery(GET_TEMPLATES, getTemplatesApi)
  yield takeEvery(
    GET_TEMPLATES_GROUPED_BY_CATEGORY,
    getTemplatesGroupedByCategoryApi
  )
  yield takeEvery(GET_TEMPLATE, getTemplateApi)
  yield takeEvery(EDIT_TEMPLATE, editTemplateApi)
  yield takeEvery(EDIT_CATEGORY, editCategoryApi)
  yield takeEvery(ADD_TEMPLATE, addTemplateApi)
  yield takeEvery(DELETE_TEMPLATE, deleteTemplateApi)
  yield takeEvery(GET_NEGATIVE_KEYWORDS, getNegativeKeywordsApi)
  yield takeEvery(MANAGE_NEGATIVE_KEYWORDS, manageNegativeKeywordsApi)
}

function* TemplatesSaga() {
  yield all([fork(watchTemplates)])
}

export default TemplatesSaga
