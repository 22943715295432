export const API_ERROR = "API_ERROR"
export const GET_OPT_OUTS = "GET_OPT_OUTS"
export const GET_OPT_OUTS_SUCCESS = "GET_OPT_OUTS_SUCCESS"
export const EDIT_OPT_OUT = "EDIT_OPT_OUT"
export const EDIT_OPT_OUT_SUCCESS = "EDIT_OPT_OUT_SUCCESS"
export const ADD_OPT_OUT = "ADD_OPT_OUT"
export const ADD_OPT_OUT_SUCCESS = "ADD_OPT_OUT_SUCCESS"
export const DELETE_OPT_OUT = "DELETE_OPT_OUT"
export const DELETE_OPT_OUT_SUCCESS = "DELETE_OPT_OUT_SUCCESS"

