import {
  ADD_CAMPAIGN,
  ADD_CAMPAIGN_SUCCESS,
  API_ERROR,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_SUCCESS,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_FOLLOW_UP_CAMPAIGNS,
  GET_FOLLOW_UP_CAMPAIGNS_SUCCESS,
  GET_MARKETS,
  GET_MARKETS_SUCCESS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_SUCCESS,
  GET_COUNT_STATS,
  GET_COUNT_STATS_SUCCESS,
  GET_CAMPAIGN_METRICS,
  GET_CAMPAIGN_METRICS_SUCCESS,
  GET_CAMPAIGN_COUNT_PROSPECTS,
  GET_CAMPAIGN_COUNT_PROSPECTS_SUCCESS,
  GET_BATCH,
  GET_BATCH_SUCCESS,
  GET_CLIENT_DATA_SUCCESS,
  GET_CLIENT_DATA,
  COUNT_TEMPLATE,
  COUNT_TEMPLATE_SUCCESS,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE,
  AUTO_SEND_CAMP_SUCCESS,
  CANCEL_AUTO_SEND_CAMP,
  GET_UPLOADS_HISTROY,
  GET_UPLOADS_HISTROY_SUCCESS,
  GET_SCHEDULER,
  GET_SCHEDULER_SUCCESS,
  UPDATE_SCHEDULER,
  EXPORT_CAMP_PROS_DATA_SUCCESS,
  EXIST_PROSPECTS,
  EXIST_PROSPECTS_SUCCESS,
  UPLOAD_PROSPECTS_SUCCESS,
  UPLOAD_PROSPECTS_FILE_SUCCESS,
  UPDATE_PROPS,
} from "./actionTypes"

import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
} from "../categories/actionTypes"
import {
  GET_LEAD_STAGES,
  GET_LEAD_STAGES_SUCCESS,
} from "../leadStages/actionTypes"
import { GET_QUICK_REPLIES_SUCCESS } from "../quick-replies/actionTypes"
import {
  REMOVE_PROSPECT_PRIORITY,
  REMOVE_PROSPECT_PRIORITY_SUCCESS,
  REMOVE_PROSPECT_QUALIFIED,
  REMOVE_PROSPECT_QUALIFIED_SUCCESS,
  REMOVE_PROSPECT_VERIFIED,
  REMOVE_PROSPECT_VERIFIED_SUCCESS,
  REMOVE_PROSPECT_WRONG_NUMBER,
  REMOVE_PROSPECT_WRONG_NUMBER_SUCCESS,
  SEND_CONVERSATION_SUCCESS,
  UPDATE_PROSPECT_DNC,
  UPDATE_PROSPECT_DNC_SUCCESS,
  UPDATE_PROSPECT_PRIORITY,
  UPDATE_PROSPECT_PRIORITY_SUCCESS,
  UPDATE_PROSPECT_QUALIFIED,
  UPDATE_PROSPECT_QUALIFIED_SUCCESS,
  UPDATE_PROSPECT_VERIFIED,
  UPDATE_PROSPECT_VERIFIED_SUCCESS,
  UPDATE_PROSPECT_WRONG_NUMBER,
  UPDATE_PROSPECT_WRONG_NUMBER_SUCCESS,
  ADD_NOTE,
  ADD_NOTE_SUCCESS,
  DELETE_PROSPECTS,
  DELETE_PROSPECTS_SUCCESS,
} from "../prospects/actionTypes"

const initialState = {
  loading: true,
  refreshList: false,
  markets: [],
  campaigns: [],
  campaign: null,
  metrics: null,
  campaign_metrics: [],
  countstatus: null,
  apiError: null,
  count: 0,
  send: 0,
  send_count: 0,
  categories: [],
  batchpros: null,
  name: null,
  bulkid: null,
  Prospects: null,
  counttemplate: 0,
  uploads: [],
  scheduler: null,
  leads: [],
  quickReplies: [],
  upload: null,
  previous_total_pros: 0,
  selectedTemplate: null,
}

const Campaign = (state = initialState, action) => {
  switch (action.type) {
    //for update props
    case UPDATE_PROPS:
    
      state = {
        ...state,
        send_count: action.payload.send_count
          ? action.payload.send_count
          : state.send_count,
        previous_total_pros: action.payload.previous_total_pros
          ? action.payload.previous_total_pros
          : state.previous_total_pros,
      }
      break
    case GET_FOLLOW_UP_CAMPAIGNS:
      state = {
        ...state,
        loading: true,
        followUpCampaigns: [],
        apiError: null,
      }
      break
    case GET_FOLLOW_UP_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        followUpCampaigns: action.payload,
        apiError: null,
        successMsg: null,
        loading: false,
      }
      break
    case GET_CAMPAIGNS:
      state = {
        ...state,
        loading: true,
        campaigns: [],
        apiError: null,
      }
      break
    case GET_CAMPAIGNS_SUCCESS:
      state = {
        ...state,
        campaigns: action.payload,
        apiError: null,
        successMsg: null,
        loading: false,
      }
      break
    case GET_MARKETS:
      state = {
        ...state,
        markets: [],
        apiError: null,
      }
      break
    case GET_MARKETS_SUCCESS:
      state = {
        ...state,
        markets: action.payload,
        apiError: null,
        successMsg: null,
      }
      break

    case API_ERROR:
      state = {
        ...state,
        apiError: action.payload,
        loading: false,
        successMsg: null,
      }
      break
    case EDIT_CAMPAIGN:
      state = {
        ...state,
        campaign: action.payload,
      }
      break
    case EDIT_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break

    case ADD_CAMPAIGN:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_CAMPAIGN:
      state = {
        ...state,
        campaign: action.payload,
      }
      break
    case DELETE_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break

    case GET_CAMPAIGN:
      state = {
        ...state,
        campaign: null,
        apiError: null,
      }
      break
    case GET_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        campaign: action.payload,
        apiError: null,
      }
      break

    case GET_COUNT_STATS:
      state = {
        ...state,
        countstatus: null,
        apiError: null,
      }
      break
    case GET_COUNT_STATS_SUCCESS:
      state = {
        ...state,
        countstatus: action.payload,
        apiError: null,
      }
      break

    case GET_CAMPAIGN_METRICS:
      state = {
        ...state,
        campaign_metrics: [],
        apiError: null,
      }
      break
    case GET_CAMPAIGN_METRICS_SUCCESS:
      state = {
        ...state,
        campaign_metrics: action.payload,
        apiError: null,
      }
      break

    case GET_CAMPAIGN_COUNT_PROSPECTS:
      state = {
        ...state,
        count: 0,
        send: 0,
        apiError: null,
      }
      break
    case GET_CAMPAIGN_COUNT_PROSPECTS_SUCCESS:
      state = {
        ...state,
        count: action.payload,
        apiError: null,
      }
      break

    case GET_CATEGORIES:
      state = {
        ...state,
        categories: [],
        apiError: null,
      }
      break
    case GET_CATEGORIES_SUCCESS:
      state = {
        ...state,
        categories: action.payload,
        apiError: null,
        successMsg: null,
      }
      break

    case GET_BATCH:
      state = {
        ...state,
        batchpros: null,
        name: null,
        bulkid: null,
      }
      break
    case GET_BATCH_SUCCESS:
      state = {
        ...state,
        // batchpros: action.payload.prospectcount,
        name: action.payload.name,
        send_count: 0,
        bulkid: action.payload.uid,
        apiError: null,
        successMsg: null,
      }
      break

    case GET_CLIENT_DATA:
      state = {
        ...state,
        Prospects: null,
        selectedTemplate: null,
        apiError: null,
      }
      break
    case GET_CLIENT_DATA_SUCCESS:
      state = {
        ...state,
        Prospects: action.payload.result,
        selectedTemplate: action.payload.template,
        apiError: null,
        successMsg: null,
      }
      break

    case COUNT_TEMPLATE:
      state = {
        ...state,
        counttemplate: null,
        apiError: null,
      }
      break
    case COUNT_TEMPLATE_SUCCESS:
      state = {
        ...state,
        counttemplate: action.payload,
        apiError: null,
        successMsg: null,
      }
      break

    case SEND_MESSAGE:
      state = {
        ...state,
        send: 0,
        apiError: null,
        successMsg: null,
      }
      break
    case SEND_MESSAGE_SUCCESS:
      state = {
        ...state,
        send: initialState.send + 1,
        send_count: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case AUTO_SEND_CAMP_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case CANCEL_AUTO_SEND_CAMP:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break

    case GET_UPLOADS_HISTROY:
      state = {
        ...state,
        uploads: [],
        apiError: null,
        successMsg: null,
      }
      break
    case GET_UPLOADS_HISTROY_SUCCESS:
      state = {
        ...state,
        uploads: action.payload,
        apiError: null,
        successMsg: null,
      }
      break

    case GET_SCHEDULER:
      state = {
        ...state,
        scheduler: null,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_SCHEDULER_SUCCESS:
      state = {
        ...state,
        scheduler: action.payload,
        apiError: null,
        successMsg: null,
      }
      break

    case GET_LEAD_STAGES:
      state = {
        ...state,
        leads: [],
        apiError: null,
        successMsg: null,
      }
      break
    case GET_LEAD_STAGES_SUCCESS:
      state = {
        ...state,
        leads: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case EXPORT_CAMP_PROS_DATA_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case GET_QUICK_REPLIES_SUCCESS:
      state = {
        ...state,
        quickReplies: action.payload,
      }
      break
    case SEND_CONVERSATION_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case EXIST_PROSPECTS:
      state = {
        ...state,
        apiError: null,
        refreshList: false,
        successMsg: null,
      }
      break
    case EXIST_PROSPECTS_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_DNC:
      state = {
        ...state,
        refreshList: false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_DNC_SUCCESS:
      state = {
        refreshList: true,
        ...state,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_VERIFIED:
      state = {
        ...state,
        refreshList: false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_VERIFIED_SUCCESS:
      state = {
        ...state,
        refreshList: true,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_WRONG_NUMBER:
      state = {
        ...state,
        refreshList: false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_WRONG_NUMBER_SUCCESS:
      state = {
        ...state,
        refreshList: true,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_QUALIFIED:
      state = {
        ...state,
        refreshList: false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_QUALIFIED_SUCCESS:
      state = {
        ...state,
        refreshList: true,
        successMsg: action.payload,
      }
      break
    case UPDATE_PROSPECT_PRIORITY:
      state = {
        ...state,
        refreshList: false,
        apiError: null,
        successMsg: null,
      }
      break
    case UPDATE_PROSPECT_PRIORITY_SUCCESS:
      state = {
        ...state,
        refreshList: true,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_VERIFIED:
      state = {
        ...state,
        refreshList: false,
        apiError: null,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_VERIFIED_SUCCESS:
      state = {
        ...state,
        refreshList: true,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_WRONG_NUMBER:
      state = {
        ...state,
        refreshList: false,
        apiError: null,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_WRONG_NUMBER_SUCCESS:
      state = {
        ...state,
        refreshList: true,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_QUALIFIED:
      state = {
        ...state,
        apiError: null,
        refreshList: false,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_QUALIFIED_SUCCESS:
      state = {
        ...state,
        refreshList: true,
        successMsg: action.payload,
      }
      break
    case REMOVE_PROSPECT_PRIORITY:
      state = {
        ...state,
        apiError: null,
        refreshList: false,
        successMsg: null,
      }
      break
    case REMOVE_PROSPECT_PRIORITY_SUCCESS:
      state = {
        ...state,
        refreshList: true,
        successMsg: action.payload,
      }
      break
    case UPLOAD_PROSPECTS_FILE_SUCCESS:
      state = {
        ...state,
        upload: action.payload,
      }
      break
    case UPLOAD_PROSPECTS_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case ADD_NOTE:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_NOTE_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
      }
      break
    case DELETE_PROSPECTS:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case DELETE_PROSPECTS_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Campaign
