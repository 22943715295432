import {
  GET_PTCRM_SETTINGS, GET_PTCRM_SETTINGS_SUCCESS,
  API_ERROR, SAVE_PTCRM_SETTING, SAVE_PTCRM_SETTING_SUCCESS, SAVE_PTCRM_FILTER, SAVE_PTCRM_FILTER_SUCCESS
} from "./actionTypes"

const initialState = {
  ptcrmSettings: {},
  optOuts: [],
  apiError: null,
}

const PtcrmSetting = (state = initialState, action) => {
  switch (action.type) {
    case GET_PTCRM_SETTINGS:
      state = {
        ...state,
        ptcrmSettings: {},
        apiError: null,
      }
      break
    case GET_PTCRM_SETTINGS_SUCCESS:
      state = {
        ...state,
        ptcrmSettings: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload }
      break
    case SAVE_PTCRM_SETTING:
      state = {
        ...state,
        ptcrmSetting: action.payload,
      }
      break
    case SAVE_PTCRM_SETTING_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break
    case SAVE_PTCRM_FILTER:
      state = {
        ...state,
        ptcrmSetting: action.payload,
      }
      break
    case SAVE_PTCRM_FILTER_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PtcrmSetting
