import React, { useEffect, useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
// import "./WrongNumbers.scss"
import classnames from "classnames"
import axios from "axios"
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import dripIconBorder from "../../assets/images/drip-icon-border.svg"
import dripIconFilled from "../../assets/images/drip-icon-filled.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
import Select from "react-select"
import debounce from "lodash.debounce"
import { AvField, AvForm } from "availity-reactstrap-validation"
import incomingMessage from "../../assets/images/incoming.svg"
import outgoingMessage from "../../assets/images/outgoing.svg"
import close from "../../assets/images/close.svg"

const Prospects = ({ props }) => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    // let actions = {}
    // for (var value of queryParams.keys()) {
    // }
    const unreadFilter = queryParams.get("unread")
    let filter = { ...filterData }
    if (unreadFilter) {
      filter = { ...filterData, unread: unreadFilter }
    }
    setFilterData(filter)
    getProspects(null, null, filter)
    getDripTemplates()
    getFilter()
    getCampaigns()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState("") // for success or error response
  const [page, setPage] = useState(1)
  const [searchBy, setSearchBy] = useState("prospect")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedRows, setSelectedRows] = useState([])
  const [campaignList, setCampaignList] = useState([])
  const [totalProspectNumber, setTotalProspectNumber] = useState()
  const [tableReloadKey, setTableReloadKey] = useState(Math.random())
  const [prospects, setProspects] = useState([])
  const [dripTemplates, setDripTemplates] = useState([])
  const [dripModal, setDripModal] = useState(false)
  const [dripProspectId, setDripProspectId] = useState()
  const [dripReloadKey, setDripReloadKey] = useState(() => Math.random())
  const [filter, setFilter] = useState(null)
  const [filterData, setFilterData] = useState({ searchBy: "prospect" })
  const [filterReloadKey, setFilterReloadKey] = useState(Math.random())
  const [resetFilter, setResetFilter] = useState({})
  const [addProspect, setAddProspect] = useState({ isOpen: false })
  const [dripTemplateDetail, setDripTemplateDetail] = useState({
    isOpen: false,
    id: null,
    data: {},
  })
  const [dripRemoveConfirm, setDripRemoveConfirm] = useState({
    isOpen: false,
  })

  const getFilter = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/prospect/getFilters?",
        config
      )

      if (response?.data) {
        setFilter(response.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }
  const getCampaigns = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/campaign/getAllCampaigns",
        config
      )

      if (response?.data) {
        setCampaignList(response?.data?.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const isFilterSet = () => {
    let flag = false
    for (const key in filterData) {
      if (key !== "searchBy" && !!filterData[key]) {
        flag = true
        break
      }
    }
    return flag
  }

  const exportProspects = async () => {
    // const limit = {
    //   dnc: false,
    //   replied: false,
    //   isPriority: false,
    //   Wrong_Number: false,
    //   verified: false,
    //   isLead: false,
    //   isDripAdded: false,
    //   lead: [],
    //   markets: [],
    //   campaigns: [],
    // }
    setIsLoading(true)
    try {
      if (!totalProspectNumber) {
        setIsLoading(false)
        notify("error", "No prospects available to export!")
        return
      }
      const queryParams = new URLSearchParams({ ...filterData })
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/prospect/exportpros?${queryParams}`,
        {},
        config
      )

      if (response?.data) {
        setIsLoading(false)
        notify(
          "success",
          "Prospects Exported Successfully , Please Check On Notification Page"
        )
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const getProspects = async (
    crrPage = page,
    limit = rowsPerPage,
    data = filterData
  ) => {
    const queryParams = new URLSearchParams({
      page: crrPage,
      limit,
      ...data,
    })
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/prospect/filter?" + queryParams,
        config
      )

      if (response?.data) {
        setProspects(response?.data?.prospects)
        setTotalProspectNumber(response?.data?.prospectCount)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }

  const getDripTemplates = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplates",
        config
      )

      if (response.data) {
        setDripTemplates(response.data.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }
  const getDripTemplateDetail = async id => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplate/" + id,
        config
      )

      if (response.data) {
        setDripTemplateDetail({
          isOpen: true,
          id: id,
          data: response.data.data,
        })
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const tog_drip = () => {
    setDripModal(!dripModal)
  }

  const addDrip = async (dripTemplateId = dripTemplateDetail.id) => {
    setIsLoading(true)

    const body = {
      prospectId: dripProspectId,
      dripTemplateId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/addDrip",
        body,
        config
      )
      if (response.data) {
        notify("success", "Drip Added Successfully")
        setIsLoading(false)
        tog_drip()
        setDripTemplateDetail({ isOpen: false, id: null, data: {} })
        setDripProspectId(null)
        getProspects()
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }

  const removeDrip = async prospectId => {
    setIsLoading(true)
    if (!prospectId) {
      prospectId = dripProspectId
    }

    const body = {
      prospectId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/removeDrip",
        body,
        config
      )
      if (response.data) {
        setDripRemoveConfirm({ isOpen: false })
        setDripProspectId(null)
        notify("success", "Drip Removed Successfully")
        getProspects()
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }

  const setDNC = async (id, dnc) => {
    setIsLoading(true)

    const body = {
      dnc: dnc,
      id: id,
      list: true,
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + `/api/prospect/prospectDNC/${id}`,
        body,
        config
      )
      if (response.data) {
        if (dnc) {
          notify("success", "Prospect Updated Successfully")
        } else {
          notify("success", "Prospect Updated Successfully")
        }

        getProspects()
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error?.response?.data?.message)
    }
  }
  const verifiedNumber = async (id, verified) => {
    setIsLoading(true)

    const body = {
      id: id,
      list: true,
    }

    if (verified) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/verifiednumber/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/removeverifiednumber/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
      }
    }
  }
  const wrongNumber = async (id, wrongNumber) => {
    setIsLoading(true)

    const body = {
      id: id,
      list: true,
    }

    if (wrongNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/wrongnumber/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/removewrongnumber/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }
  const qualifiedNumber = async (id, qualifiedNumber) => {
    setIsLoading(true)

    const body = {
      id: id,
      list: true,
    }

    if (qualifiedNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/isqualified/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/wasqualified/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }
  const priorityNumber = async (id, priorityNumber) => {
    setIsLoading(true)

    const body = {
      id: id,
      list: true,
    }

    if (priorityNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/isPriority/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/wasPriority/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }

  const handleClearSelection = () => {
    const clearData = {
      campaign: "",
      market: "",
      lead: "",
      action: "",
      search: "",
      searchBy: "prospect",
    }
    setResetFilter({ ...clearData })
    handleFilter(clearData)
    setFilterReloadKey(Math.random())
    setTableReloadKey(Math.random())
  }

  //delete contact by id

  //delete selected contact
  const DeleteSelectedProspect = async () => {
    setIsLoading(true)
    const data = {
      id: selectedRows,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/deleteprospects",
        data,
        config
      )
      if (response?.status == 200) {
        notify("success", "Prospects deleted successfully")
        getProspects()

        setSelectedRows([])
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err)
      setIsLoading(false)
    }
  }

  //download csv file
  const downloadCsv = data => {
    // Define the keys to include in the CSV
    const keys = ["first_name", "last_name", "phone"]
    // Convert array of objects data to CSV format
    const csv = convertToCsv(data, keys)
    // Create a blob object with the CSV data
    const blob = new Blob([csv], { type: "text/csv" })
    // Create a temporary <a> tag with the download attribute
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.setAttribute("download", "data.csv")
    // Append the <a> tag to the body and click it
    document.body.appendChild(link)
    link.click()
    // Remove the <a> tag from the body
    document.body.removeChild(link)
  }

  const convertToCsv = (data, keys) => {
    // Define the headers for the CSV file
    const headers = keys.join(",")
    // Map over each object in the data array and convert it to a CSV row
    const rows = data?.map(obj => {
      // Extract the values for the desired keys
      const values = keys?.map(key => obj[key])
      // Convert the values to a CSV row
      return values.join(",")
    })
    // Combine the headers and rows into a single CSV string
    return `${headers}\n${rows.join("\n")}`
  }

  // Data table columns and data

  const prospectData =
    prospects && prospects?.length
      ? prospects?.map((item, index) => {
          const prospectIds = prospects.map(item => item._id)?.join(",")
          const queryParams = new URLSearchParams({
            sno: +index + 1 + (+page - 1) * +rowsPerPage,
            total: totalProspectNumber,
            ...filterData,
            prospectIds,
          })
          const linkPath = `/prospect/${item?._id}?${queryParams}`
          return {
            id: item?._id,
            name: (
              <div className="d-flex gap-1 align-items-center">
                {item?.isUnread ? (
                  <i
                    className={"fas fa-circle text-danger fa-sm"}
                    style={{ fontSize: "6px" }}
                  />
                ) : null}
                <Link to={linkPath}>
                  {item?.first_name
                    ? `${item.first_name} ${
                        item?.last_name ? item?.last_name : ""
                      }`
                    : item?.last_name
                    ? item?.last_name
                    : ""}
                </Link>
              </div>
            ),
            phone: item?.phone,
            message: (
              <span className="reminder-table-note">
                {item?.messages[0]?.direction && (
                  <img
                    src={
                      item?.messages[0]?.direction == "outbound-api"
                        ? outgoingMessage
                        : incomingMessage
                    }
                    style={{ width: "16px" }}
                    alt=""
                  ></img>
                )}
                {/* {item?.messages[0]?.direction && item?.messages[0]?.direction == 'outbound-api' ?  <i className="fas  fa-arrow-up" /> : <i className="fas fa-arrow-down" /> }  */}{" "}
                {/* {item?.message[0]} */}
                {item?.messages[0]?.body || "-"}
              </span>
            ),

            action: [
              <span>
                {" "}
                {item.dnc ? (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"danger"}
                    onClick={e => {
                      e.preventDefault()
                      setDNC(item._id, false)
                    }}
                    title="Remove From DNC"
                  >
                    <i className="fas fa-times" />
                  </Button>
                ) : (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"outline-secondary"}
                    onClick={e => {
                      e.preventDefault()
                      setDNC(item._id, true)
                    }}
                    title="Add To DNC"
                  >
                    <i className="fas fa-times" />
                  </Button>
                )}
              </span>,
              <span>
                {item.verified === false ? (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"outline-secondary"}
                    onClick={() => verifiedNumber(item._id, true)}
                    title="Add To Verified"
                  >
                    <i className="fas fa-check" />
                  </Button>
                ) : (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"success"}
                    onClick={() => verifiedNumber(item._id, false)}
                    title="Remove From Verified"
                  >
                    <i className="fas fa-check" />
                  </Button>
                )}
              </span>,
              <span>
                {item.Wrong_Number === false ? (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"outline-secondary"}
                    onClick={() => wrongNumber(item._id, true)}
                    title="Add To Wrong Number"
                  >
                    <i className="fas fa-phone-slash" />
                  </Button>
                ) : (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"primary"}
                    onClick={() => wrongNumber(item._id, false)}
                    title="Remove From Wrong Number"
                  >
                    <i className="fas fa-phone-slash" />
                  </Button>
                )}
              </span>,
              <span>
                {item.isLead === false ? (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"outline-secondary"}
                    onClick={() => qualifiedNumber(item._id, true)}
                    title="Add to Qualified"
                  >
                    <i className="fas fa-star" />
                  </Button>
                ) : (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"info"}
                    onClick={() => qualifiedNumber(item._id, false)}
                    title="Remove From Qualified"
                  >
                    <i className="fas fa-star" />
                  </Button>
                )}{" "}
              </span>,
              <span>
                {item.isPriority === false ? (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"outline-secondary"}
                    onClick={() => priorityNumber(item._id, true)}
                    title="Add To Priority"
                  >
                    <i className="fas fa-bolt" />
                  </Button>
                ) : (
                  <Button
                    className={"has-icon small me-1 mb-1"}
                    color={"warning"}
                    onClick={() => priorityNumber(item._id, false)}
                    title="Remove From Priority"
                  >
                    <i className="fas fa-bolt" />
                  </Button>
                )}{" "}
              </span>,
              <span>
                <Button
                  className={"has-icon small me-1 mb-1 dripIconModal"}
                  onClick={() => {
                    if (item.isDripAdded) {
                      setDripProspectId(item._id)
                      setDripRemoveConfirm({
                        isOpen: true,
                        dripTemplateData: item.dripTemplateId,
                        dripData: item.dripData,
                        dripProspectId: item._id,
                      })
                      // removeDrip(item._id)
                    } else {
                      tog_drip()
                      setDripProspectId(item._id)
                    }
                  }}
                  title={item.isDripAdded ? "Remove From Drip" : "Add Drip"}
                >
                  {/* Bordered Icon */}
                  {item.isDripAdded ? (
                    <img src={dripIconFilled} alt="" />
                  ) : (
                    <img src={dripIconBorder} alt="" />
                  )}
                  {/* Filled Icon */}
                </Button>
              </span>,
            ],
          }
        })
      : []

  const prospectColumns = [
    {
      name: "Name",
      selector: row => row.name,
      minWidth: "200px",
      maxWidth: "200px",
    },
    {
      name: "Phone",
      selector: row => row.phone,
      minWidth: "170px",
      maxWidth: "170px",
    },

    {
      name: "Message",
      selector: row => row.message,
      // minWidth: "400px",
      // maxWidth: "800px",
      grow: 2,
    },

    {
      name: "Action",
      selector: row => row.action,
      maxWidth: "230px",
    },
  ]

  const customStyles = {
    table: {
      width: "100%", // Set the overall table width to 100% to cover the screen
    },
  }

  const dropDownCustomStyle = {
    control: (base, state) => ({
      ...base,
      zIndex: 999, // Adjust this value as needed
    }),
  }

  const actionOptions = [
    { label: "Drip", value: "isDripAdded" },
    { label: "DNC", value: "dnc" },
    { label: "Replied", value: "replied" },
    { label: "Priority", value: "isPriority" },
    { label: "Wrong Number", value: "Wrong_Number" },
    { label: "Verified", value: "verified" },
    { label: "Qualified Lead", value: "isLead" },
    { label: "Unread", value: "unread" },
  ]

  const handleFilter = (data = {}) => {
    const allKey = [
      "dnc",
      "replied",
      "isPriority",
      "Wrong_Number",
      "verified",
      "isLead",
      "isDripAdded",
      "lead",
      "market",
      "campaign",
      "search",
      "searchBy",
      "unread",
    ]

    const newObj = {}

    for (const key of allKey) {
      if (key in data && data[key]) {
        newObj[key] = data[key]
      }
    }
    setFilterData({ ...newObj })
    getProspects(1, rowsPerPage, newObj)
    setTableReloadKey(Math.random())
  }
  const handleSearchFilter = e => {
    const searchValue = e.target.value
    searchFilterDebounce(searchValue)
  }

  const searchFilterDebounce = debounce(value => {
    if (value && value.trim()?.length) {
      const obj = { search: value.trim() }
      const finalData = { ...filterData, ...obj }
      handleFilter(finalData)
    } else if (!value) {
      handleFilter({ searchBy: filterData.searchBy })
    }
  }, 500)

  const handleSelectionChange = (data, name) => {
    const selected = data
    const selectedId = selected?.map((item, index) => {
      return item?.value
    })
    const formattedIds = selectedId?.join(",")
    setFilterData(prevData => ({
      ...prevData,
      [name]: formattedIds,
    }))
    const finalData = { ...filterData, [name]: formattedIds }
    handleFilter(finalData)
  }
  function getSelectedActions(filterData) {
    const selectedActions = []
    for (const property in filterData) {
      const temp = actionOptions.filter(item => item.value === property)
      if (temp && temp.length) {
        selectedActions.push(temp[0])
      }
    }
    return selectedActions
  }

  const toggleAddProspectModal = () => {
    setAddProspect({ isOpen: !addProspect.isOpen })
  }

  const handleValidSubmit = async (event, values) => {
    let prospectData = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      pstate: values.pstate,
      paddress: values.paddress,
      pcity: values.pcity,
      pzip: values.pzip,
      campaign: values.campaign,
    }
    const data = {
      data: prospectData,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/saveprospect",
        data,
        config
      )
      if (response.data) {
        notify("success", "Prospect Added Successfully")
        setIsLoading(false)
        toggleAddProspectModal()
        getProspects()
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Prospects | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2" key={filterReloadKey}>
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    {totalProspectNumber ? `${totalProspectNumber}` : null}{" "}
                    Prospects
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Prospects</BreadcrumbItem>
                  </ol>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="float-end">
                  <div className={"button-items d-flex st flex-wrap gap-2"}>
                    {selectedRows && selectedRows.length ? (
                      <button
                        onClick={() => DeleteSelectedProspect()}
                        disabled={isLoading}
                        className="main-btn error-btn"
                        role="button"
                      >
                        <i className="fas fa-trash-alt text-white"></i>

                        <span>Delete ({selectedRows.length})</span>
                      </button>
                    ) : null}

                    {isFilterSet() ? (
                      <button
                        className="main-btn  border-btn"
                        role="button"
                        onClick={handleClearSelection}
                      >
                        <i className="fas fa-times color-primary"></i>

                        <span>Clear filters</span>
                      </button>
                    ) : null}

                    {/* <Link className={"main-btn"} to="/prospects/add">
                      <img src={plus} alt="" />
                      <span>Add New Prospect</span>
                    </Link> */}
                    <button
                      className="main-btn"
                      // to="/add-campaign"
                      onClick={() => {
                        toggleAddProspectModal()
                      }}
                      type="button"
                    >
                      <img src={plus} alt="" />
                      <span>Add New Prospect</span>
                    </button>
                    <button
                      className="main-btn success-btn"
                      role="button"
                      onClick={exportProspects}
                    >
                      <i className="fas fa-download me-2 text-white"></i>
                      <span>Export</span>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-2 g-3">
              <Col sm={12} md={8} lg={8}>
                <div className="pros-search-box">
                  <svg
                    className="search-icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.6057 21.713L19.0739 17.1945C20.536 15.3317 21.3294 13.0313 21.3265 10.6632C21.3265 8.55425 20.7011 6.49262 19.5294 4.73906C18.3577 2.9855 16.6923 1.61877 14.7439 0.811695C12.7954 0.00462018 10.6514 -0.206547 8.58294 0.204896C6.51448 0.616339 4.61447 1.63191 3.12319 3.12319C1.63191 4.61447 0.616339 6.51448 0.204896 8.58294C-0.206547 10.6514 0.00462018 12.7954 0.811695 14.7439C1.61877 16.6923 2.9855 18.3577 4.73906 19.5294C6.49262 20.7011 8.55425 21.3265 10.6632 21.3265C13.0313 21.3294 15.3317 20.536 17.1945 19.0739L21.713 23.6057C21.8369 23.7307 21.9843 23.8298 22.1468 23.8975C22.3092 23.9652 22.4834 24 22.6594 24C22.8353 24 23.0095 23.9652 23.172 23.8975C23.3344 23.8298 23.4818 23.7307 23.6057 23.6057C23.7307 23.4818 23.8298 23.3344 23.8975 23.172C23.9652 23.0095 24 22.8353 24 22.6594C24 22.4834 23.9652 22.3092 23.8975 22.1468C23.8298 21.9843 23.7307 21.8369 23.6057 21.713ZM2.66581 10.6632C2.66581 9.08149 3.13485 7.53527 4.01362 6.2201C4.89239 4.90494 6.14142 3.87989 7.60275 3.27458C9.06409 2.66927 10.6721 2.5109 12.2235 2.81948C13.7748 3.12806 15.1998 3.88974 16.3183 5.0082C17.4367 6.12666 18.1984 7.55167 18.507 9.10301C18.8156 10.6544 18.6572 12.2624 18.0519 13.7237C17.4466 15.1851 16.4215 16.4341 15.1064 17.3128C13.7912 18.1916 12.245 18.6607 10.6632 18.6607C8.54219 18.6607 6.50801 17.8181 5.0082 16.3183C3.5084 14.8185 2.66581 12.7843 2.66581 10.6632Z"
                      fill="#9197B3"
                    />
                  </svg>
                  <input
                    className="search-pros-input"
                    // value={resetFilter?.search}
                    type="text"
                    onChange={e => {
                      handleSearchFilter(e)
                    }}
                    placeholder="Search Prospect"
                  ></input>
                  <div className="search-drop-dwn-wr">
                    <select
                      className="search-drop-dwn"
                      value={filterData?.searchBy}
                      onChange={e => {
                        handleFilter({
                          ...filterData,
                          searchBy: e.target.value,
                        })
                      }}
                    >
                      <option
                        value="message"
                        className="search-drop-dwn-option"
                      >
                        Message
                      </option>
                      <option
                        value="prospect"
                        className="search-drop-dwn-option"
                      >
                        Prospect
                      </option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={4} lg={4}>
                <Select
                  // value={filterData?.lead}
                  onChange={e => {
                    handleSelectionChange(e, "lead")
                    // setResetFilter(prevState => ({
                    //   ...prevState, // Spread the previous state to keep its values
                    //   lead: e, // Update the 'campaign' key with the value of 'e'
                    // }))
                  }}
                  options={filter?.leadStages?.map((item, index) => ({
                    label: item?.lead_Stage,
                    value: item?._id,
                  }))}
                  isMulti
                  placeholder="Select Lead Stage"
                  menuPortalTarget={document.body}
                  styles={{
                    control: base => ({
                      ...base,
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      border: "1.5px solid #5932EA",
                      color: "#5932EA",
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-2 g-3">
              <Col sm={6} md={4} lg={4}>
                {filterData && (
                  <Select
                    value={getSelectedActions(filterData)}
                    onChange={e => {
                      let finalData = { ...filterData }
                      actionOptions.forEach(item => {
                        delete finalData[item.value]
                      })
                      if (e) {
                        e?.map(item => {
                          finalData[item?.value] = true
                        })
                      }

                      handleFilter(finalData)
                      setFilterData(finalData)
                    }}
                    options={actionOptions}
                    isMulti
                    placeholder="Actions"
                    menuPortalTarget={document.body}
                    styles={{
                      control: base => ({
                        ...base,
                        borderRadius: "10px",
                        paddingLeft: "10px",
                        border: "1.5px solid #5932EA",
                        color: "#5932EA",
                      }),
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                )}
              </Col>
              <Col sm={6} md={4} lg={4}>
                <Select
                  // value={filterData?.market}
                  onChange={e => {
                    handleSelectionChange(e, "market")
                    // setResetFilter(prevState => ({
                    //   ...prevState, // Spread the previous state to keep its values
                    //   market: e, // Update the 'campaign' key with the value of 'e'
                    // }))
                  }}
                  options={filter?.markets?.map(item => ({
                    label: item?.name,
                    value: item?._id,
                  }))}
                  isMulti
                  placeholder="Select Market"
                  menuPortalTarget={document.body}
                  styles={{
                    control: base => ({
                      ...base,
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      border: "1.5px solid #5932EA",
                      color: "#5932EA",
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </Col>
              <Col sm={6} md={4} lg={4}>
                <Select
                  // value={filterData?.campaign}
                  onChange={e => {
                    handleSelectionChange(e, "campaign")
                    // setResetFilter(prevState => ({
                    //   ...prevState, // Spread the previous state to keep its values
                    //   campaign: e, // Update the 'campaign' key with the value of 'e'
                    // }))
                  }}
                  options={filter?.campaigns?.map((item, index) => ({
                    label: item?.name,
                    value: item?._id,
                  }))}
                  isMulti
                  placeholder="Select Campaign"
                  menuPortalTarget={document.body}
                  styles={{
                    control: base => ({
                      ...base,
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      border: "1.5px solid #5932EA",
                      color: "#5932EA",
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {response && (
              <Alert
                color={
                  response == "Something went wrong" ? "danger" : "success"
                }
              >
                {response}
              </Alert>
            )}

            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <DataTable
              key={tableReloadKey}
              columns={prospectColumns}
              data={prospectData}
              fixedHeader
              className="c-table"
              pagination
              paginationServer
              paginationTotalRows={totalProspectNumber}
              paginationPerPage={rowsPerPage}
              onChangeRowsPerPage={rows => {
                setRowsPerPage(rows)
                getProspects(1, rows)
                setPage(1)
                setTableReloadKey(Math.random())
              }}
              onChangePage={page => {
                setPage(page)
                getProspects(page)
              }}
              keyField={"id"}
              selectableRows
              onSelectedRowsChange={state => {
                const selectedIds = state.selectedRows?.map(row => row?.id)
                setSelectedRows(selectedIds)
              }}
              selectedRows={selectedRows}
              customStyles={customStyles}
              // currentPage={page}
            />
          </div>
        </Container>
      </div>
      {dripRemoveConfirm?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          info
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            removeDrip(dripRemoveConfirm?.dripProspectId)
          }}
          onCancel={() => {
            setDripRemoveConfirm({ isOpen: false })
          }}
        >
          <div className="d-flex flex-column align-items-start gap-1 p-2 border-bottom mb-2">
            <div>
              <strong>Drip Start Date: </strong>{" "}
              {moment
                .utc(dripRemoveConfirm.dripData.dripStartDate)
                .local()
                .format("YYYY-MM-DD hh:mm:ss A")}
            </div>
          </div>
          You want to remove drip? You won't be able to revert this!
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={dripModal}
        toggle={() => {
          tog_drip()
        }}
        backdrop="static"
        centered={true}
        className="drip-select-modal"
        key={dripReloadKey}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Select A Drip Automations</h5>
          <button
            type="button"
            onClick={() => {
              tog_drip()
              setDripTemplateDetail({ isOpen: false, id: null, data: {} })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="drip-select-container">
            <div key={dripReloadKey}>
              {!dripTemplateDetail?.isOpen ? (
                <div className="drip-aut-list">
                  <table className="c-table table">
                    <thead>
                      <tr>
                        <th>Drip Name</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {dripTemplates &&
                        dripTemplates?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item?.name}</td>
                              <td>
                                <button
                                  className="main-btn sm secondary-btn min-btn"
                                  onClick={() => {
                                    getDripTemplateDetail(item?._id)
                                  }}
                                >
                                  <span>View Messages</span>
                                </button>
                              </td>
                              <td>
                                <button
                                  className="main-btn sm white-icon min-btn"
                                  onClick={() => {
                                    addDrip(item?._id)
                                  }}
                                >
                                  <img src={dripIconBorder} alt="" />
                                  <span>Select</span>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="drip-messages-container">
                  <h3 className="head">{dripTemplateDetail?.data?.name}</h3>
                  <div className="drip-message-main-container">
                    {dripTemplateDetail?.data?.data?.map((item, index) => {
                      return (
                        <div className="drip-message-wrap" key={index}>
                          <div className="top">
                            <h3 className="head">Message {index + 1}</h3>
                            <span>Day {item?.day}</span>
                          </div>
                          <p className="para">{item?.message}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
            {/* drip automations END*** */}

            {/* drip automation messages */}

            {/* drip automation messages END*** */}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              if (dripTemplateDetail?.isOpen) {
                setDripTemplateDetail({
                  isOpen: false,
                  id: null,
                  data: {},
                })
              } else {
                setDripTemplateDetail({
                  isOpen: false,
                  id: null,
                  data: {},
                })
                setDripModal(false)
              }
            }}
          >
            {dripTemplateDetail?.isOpen ? "Back" : "Cancel"}
          </button>
          {dripTemplateDetail?.isOpen ? (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                addDrip()
              }}
            >
              Select
            </button>
          ) : null}
        </div>
      </Modal>

      {/* add the prospect modal  */}
      <Modal
        isOpen={addProspect.isOpen}
        toggle={() => {
          toggleAddProspectModal()
        }}
        className="c-modal modal-lg"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body">
          <h5 className="modal-title mt-0 mb-4 ">Add Prospect</h5>
          <button
            type="button"
            onClick={() => {
              setAddProspect({ isOpen: false })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {!campaignList.length && (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          )}

          <AvForm
            onValidSubmit={(e, v) => handleValidSubmit(e, v)}
            className="needs-validation"
          >
            <Row>
              <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="select"
                    name="campaign"
                    label="Campaign"
                    errorMessage="Select Campaign"
                    validate={{
                      required: { value: true },
                    }}
                    value=""
                  >
                    <option value="">Select Campaign</option>
                    {campaignList.length > 0 &&
                      campaignList.map(item => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </AvField>
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="text"
                    name="phone"
                    label="Phone"
                    errorMessage="Phone is required"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[+0-9 ]+$",
                        errorMessage: "Enter valid phone",
                      },
                    }}
                  ></AvField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="text"
                    name="first_name"
                    label="First Name"
                    errorMessage="First Name is required"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[A-Za-z0-9 ]+$",
                        errorMessage: "Enter valid first name",
                      },
                    }}
                  ></AvField>
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="text"
                    name="last_name"
                    label="Last Name"
                    errorMessage="Last Name is required"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[A-Za-z0-9 ]+$",
                        errorMessage: "Enter valid last name ",
                      },
                    }}
                  ></AvField>
                </FormGroup>
              </Col>
              {/* <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="text"
                    name="phone"
                    label="Phone"
                    errorMessage="Phone is required"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[+0-9 ]+$",
                        errorMessage: "Enter valid phone",
                      },
                    }}
                  ></AvField>
                </FormGroup>
              </Col> */}
              <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="text"
                    name="paddress"
                    label="Property Address"
                    errorMessage="Property Address is required"
                    validate={{ required: { value: true } }}
                  ></AvField>
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="text"
                    name="pcity"
                    label="Property City"
                    errorMessage="Property City is required"
                    validate={{ required: { value: true } }}
                  ></AvField>
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="text"
                    name="pstate"
                    label="Property State"
                    errorMessage="Property State is required"
                    validate={{ required: { value: true } }}
                  ></AvField>
                </FormGroup>
              </Col>
              <Col md={6} sm={6}>
                <FormGroup className="mb-3">
                  <AvField
                    type="text"
                    name="pzip"
                    label="Property Zip"
                    errorMessage="Property Zipcode is required"
                    validate={{ required: { value: true } }}
                  ></AvField>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12} className={"text-end"}>
                <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className="main-btn secondary-btn"
                    onClick={() => {
                      toggleAddProspectModal()
                    }}
                    role="button"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button type="submit" color="primary" className="main-btn">
                    <span>Submit</span>
                  </Button>{" "}
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Prospects
