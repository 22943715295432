import {
  EXPORT_DNC_LIST,
  EXPORT_DNC_LIST_SUCCESS,
  API_ERROR,
  IMPORT_DNC_LIST,
  IMPORT_DNC_LIST_SUCCESS,
  RESET_DNC_LIST,
  RESET_DNC_LIST_SUCCESS
} from "./actionTypes"

export const exportDncList = () => {
  return {
    type: EXPORT_DNC_LIST
  }
}

export const exportDncListSuccess = message => {
  return {
    type: EXPORT_DNC_LIST_SUCCESS,
    payload: message,
  }
}

export const resetDncList = () => {
  return {
    type: RESET_DNC_LIST
  }
}

export const resetDncListSuccess = message => {
  return {
    type: RESET_DNC_LIST_SUCCESS,
    payload: message,
  }
}

export const importDncList = data => {
  return {
    type: IMPORT_DNC_LIST,
    payload:data
  }
}

export const importDncListSuccess = message => {
  return {
    type: IMPORT_DNC_LIST_SUCCESS,
    payload: message,
  }
}


export const getDncListError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
