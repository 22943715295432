import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  BreadcrumbItem,
  Button,
  Spinner,
  ButtonToggle,
  Badge,
  Input,
  PaginationItem,
  PaginationLink,
  Pagination,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"
//import { getUsers } from "../../store/Users/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import moment from "moment"

import { getUsers, addUser, editUser, deleteUser } from "store/Users/actions"

const Users = () => {
  const { loading, apiError, users, successMsg, totalPages } = useSelector(
    state => state.Users
  )
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [userSelect, setUserSelect] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [keyword, setKeyword] = useState("")
  const [params, setParams] = useState({ page: 1, limit: 10, keyword: "" })
  const onClickEditButton = user => {
    setUserSelect(() => user)
    setShowModal(true)
  }
  const removeUser = user => {
    setUserSelect(user)
    setIsShowAlert(true)
  }

  const handleValidSubmit = (e, v) => {
    if (userSelect?._id) {
      dispatch(editUser(userSelect?._id, v))
    } else {
      dispatch(addUser(v))
    }
    setShowModal(false)
    setTimeout(() => {
      setParams({ ...params, page: userSelect?._id ? params.page : 1 })
    }, 1000)
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getUsers(params))
  }, [params])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Row className="align-items-center breadcrumb-box">
            <Col xs={7}>
              <div className="page-title-box">
                <h4 className="font-size-18">Users</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Users</BreadcrumbItem>
                </ol>
              </div>
            </Col>
            <Col xs={3}>
              <div className="input-group">
                <input
                  type="text"
                  onChange={e => {
                    setKeyword(e.target.value)
                  }}
                  className="form-control"
                  placeholder="Search By Name..."
                  aria-label="Search By Name..."
                  aria-describedby="button-addon2"
                ></input>
                <button
                  onClick={e => {
                    setParams({ ...params, page: 1, keyword: keyword })
                  }}
                  className="btn btn-primary has-icon"
                  type="button"
                  id="button-addon2"
                >
                  <i className={"fas fa-search"}></i>
                </button>
              </div>
            </Col>
            <Col xs={2}>
              <div className="float-end">
                <div className={"button-items"}>
                  <button
                    onClick={() => {
                      setUserSelect(null)
                      setShowModal(true)
                    }}
                    className="btn btn-primary waves-effect waves-light"
                  >
                    <i className="fas fa-plus-circle me-1"></i> Add User
                  </button>
                </div>
              </div>
            </Col>
            <Col md={12} className={"pb-2"}>
              <span
                className={
                  "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                }
              ></span>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              {apiError && typeof apiError === "string" ? (
                <Alert color="danger">{apiError}</Alert>
              ) : null}

              {apiError && typeof apiError === "object" ? (
                apiError.response ? (
                  <Alert color="danger">{apiError.response.data.error}</Alert>
                ) : (
                  <Alert color="danger">{apiError.message}</Alert>
                )
              ) : null}

              {successMsg ? <Alert color="success">{successMsg}</Alert> : null}

              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table veltrix-table">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Type</Th>
                        <Th>Status</Th>
                        <Th>Date</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loading ? (
                        <Tr>
                          <Td colSpan={6} className={"text-center"}>
                            <Spinner className="ms-2" color="primary" />
                          </Td>
                        </Tr>
                      ) : null}
                      {loading == false && users.length <= 0 ? (
                        <Tr>
                          <Td valign={"middle"} colSpan={6}>
                            No users found.
                          </Td>
                        </Tr>
                      ) : null}
                      {users
                        ? users.map((item, index) => (
                            <Tr key={item._id}>
                              <Td valign={"middle"}>{item.name}</Td>
                              <Td valign={"middle"}>{item.email}</Td>
                              <Td valign={"middle"}>{item.type}</Td>
                              <Td valign={"middle"}>
                                {item.isBlocked ? (
                                  <Badge className="btn p-2 btn-danger waves-effect waves-light">Block</Badge>
                                ) : (
                                  <Badge className="btn p-2 btn-primary waves-effect waves-light">
                                    Unlock
                                  </Badge>
                                  // <Badge className="p-2 bg-primary">
                                  //   Unlock
                                  // </Badge>
                                )}
                              </Td>
                              <Td valign={"middle"}>
                                {moment(item.createdAt).format("LLL")}
                              </Td>
                              <Td valign={"middle"}>
                                <div style={{ display: "flex", gap: 16 }}>
                                  <Button
                                    color={"outline-danger"}
                                    className={"has-icon"}
                                    onClick={() => removeUser(item)}
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </Button>
                                  <Button
                                    color={"outline-primary"}
                                    className={"has-icon me-2"}
                                    onClick={() => onClickEditButton(item)}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                </div>
                              </Td>
                            </Tr>
                          ))
                        : null}
                    </Tbody>
                  </Table>
                  {totalPages > 1 && users.length > 0 && (
                    <Pagination
                      aria-label="Page navigation example"
                      listClassName="justify-content-center"
                    >
                      {params.page > 1 ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={e => {
                              setParams({ ...params, page: 1 })
                            }}
                            href="#"
                            tabIndex="-1"
                          >
                            First
                          </PaginationLink>
                        </PaginationItem>
                      ) : (
                        <PaginationItem disabled>
                          <PaginationLink href="#" tabIndex="-1">
                            First
                          </PaginationLink>
                        </PaginationItem>
                      )}

                      {params.page > 1 ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={e => {
                              setParams({ ...params, page: params.page - 1 })
                            }}
                            href="#"
                            tabIndex="-1"
                          >
                            Previous
                          </PaginationLink>
                        </PaginationItem>
                      ) : (
                        <PaginationItem disabled>
                          <PaginationLink href="#" tabIndex="-1">
                            Previous
                          </PaginationLink>
                        </PaginationItem>
                      )}

                      <PaginationItem active>
                        <PaginationLink href="#">
                          {params.page}
                          <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      {params.page < totalPages ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={() =>
                              setParams({ ...params, page: params.page + 1 })
                            }
                            href="#"
                          >
                            {params.page + 1}
                            <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>
                      ) : null}

                      {params.page < totalPages ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={e => {
                              setParams({ ...params, page: params.page + 1 })
                            }}
                            href="#"
                          >
                            Next
                          </PaginationLink>
                        </PaginationItem>
                      ) : (
                        <PaginationItem disabled>
                          <PaginationLink href="#">Next</PaginationLink>
                        </PaginationItem>
                      )}

                      {params.page < totalPages ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={e => {
                              setParams({ ...params, page: totalPages })
                            }}
                            href="#"
                          >
                            Last
                          </PaginationLink>
                        </PaginationItem>
                      ) : (
                        <PaginationItem disabled>
                          <PaginationLink href="#">Last</PaginationLink>
                        </PaginationItem>
                      )}
                    </Pagination>
                  )}
                  {isShowAlert && (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmBtnText="Yes, remove it"
                      confirmBtnBsStyle="primary"
                      cancelBtnBsStyle="dark"
                      onConfirm={() => {
                        if (userSelect?._id) {
                          dispatch(deleteUser(userSelect?._id))
                        }
                        setIsShowAlert(false)
                        setTimeout(() => {
                          dispatch(getUsers(params))
                        }, 1000)
                      }}
                      onCancel={() => {
                        setIsShowAlert(false)
                      }}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  )}
                  <Modal
                    isOpen={showModal}
                    toggle={() => {
                    }}
                    backdrop="static"
                    centered={true}
                  >
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={handleValidSubmit}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">
                          {userSelect ? "Edit" : "Create"} User
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setShowModal(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Row>
                          <Col>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Full Name
                              </Label>
                              <AvField
                                value={userSelect?.name}
                                name="name"
                                placeholder="Full Name"
                                type="text"
                                errorMessage="Enter Full Name"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value: "^[A-Za-z0-9 ]+$",
                                    errorMessage: "Enter valid full Name",
                                  },
                                }}
                                id="validationCustom01"
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <AvField
                                value={userSelect?.email}
                                name="email"
                                label="Email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                required
                              />
                            </FormGroup>
                            {!userSelect && (
                              <FormGroup className="mb-3">
                                <AvField
                                  name="password"
                                  label="Password"
                                  value=""
                                  type="password"
                                  required
                                  placeholder="Enter password"
                                />
                              </FormGroup>
                            )}
                            <FormGroup className="mb-3">
                              <AvField
                                type="select"
                                name="type"
                                label="Type"
                                validate={{
                                  required: { value: true },
                                }}
                                value={userSelect?.type}
                              >
                                <option value="">Select Type</option>
                                <option value="ADMIN">Admin</option>
                                <option value="MEMBER">Member</option>
                              </AvField>
                            </FormGroup>
                          </Col>
                          {userSelect && (
                            <Col lg="12">
                              <FormGroup className="mb-3 d-flex">
                                <AvField
                                  name="isBlocked"
                                  type="checkbox"
                                  className="form-check-input"
                                  id="invalidCheck"
                                  value={userSelect?.isBlocked}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="invalidCheck"
                                >
                                  Block User
                                </Label>
                              </FormGroup>
                            </Col>
                          )}
                          <Col md={12} className={"text-end"}>
                            <button
                              type="button"
                              className="btn btn-dark me-2"
                              onClick={() => {
                                setShowModal(false)
                              }}
                            >
                              Cancel
                            </button>
                            <button type="submit" className="btn btn-primary">
                              {userSelect ? "Update" : "Create"}
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </AvForm>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Users
