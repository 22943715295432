import {
  ADD_TEMPLATE,
  ADD_TEMPLATE_SUCCESS,
  API_ERROR,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  EDIT_TEMPLATE,
  EDIT_TEMPLATE_SUCCESS,
  GET_TEMPLATE,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_GROUPED_BY_CATEGORY,
  GET_TEMPLATES_GROUPED_BY_CATEGORY_SUCCESS,
  GET_NEGATIVE_KEYWORDS,
  GET_NEGATIVE_KEYWORDS_SUCCESS,
  MANAGE_NEGATIVE_KEYWORDS_SUCCESS,
  MANAGE_NEGATIVE_KEYWORDS,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
} from "./actionTypes"
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
} from "../categories/actionTypes"
import { GET_OPT_OUTS, GET_OPT_OUTS_SUCCESS } from "../optouts/actionTypes"

export const getTemplate = template_id => {
  return {
    type: GET_TEMPLATE,
    payload: template_id,
  }
}
export const getNegativeKeywords = () => {
  return {
    type: GET_NEGATIVE_KEYWORDS,
  }
}

export const getTemplateSuccess = template => {
  return {
    type: GET_TEMPLATE_SUCCESS,
    payload: template,
  }
}

export const getTemplatesGroupedByCategory = templates =>{
  return {
    type : GET_TEMPLATES_GROUPED_BY_CATEGORY,
    payload : templates
  }
}
export const getTemplatesGroupedByCategorySuccess = template => {
  return {
    type : GET_TEMPLATES_GROUPED_BY_CATEGORY_SUCCESS,
    payload : template
  }

}
export const getTemplatesGroupedByCategoryError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
export const getCategories = () => {
  return {
    type: GET_CATEGORIES,
  }
}

export const getCategoriesSuccess = categories => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  }
}

export const getOptOuts = () => {
  return {
    type: GET_OPT_OUTS,
  }
}

export const getOptOutsSuccess = optOuts => {
  return {
    type: GET_OPT_OUTS_SUCCESS,
    payload: optOuts,
  }
}

export const getTemplates = categoryId => {
  return {
    type: GET_TEMPLATES,
    payload: categoryId,
  }
}

export const getTemplatesSuccess = templates => {
  return {
    type: GET_TEMPLATES_SUCCESS,
    payload: templates,
  }
}
export const getNegativeKeywordsSuccess = keywords => {
  return {
    type: GET_NEGATIVE_KEYWORDS_SUCCESS,
    payload: keywords,
  }
}

export const getNegativeKeywordsError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
export const getTemplatesError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const editTemplate = template => {
  return {
    type: EDIT_TEMPLATE,
    payload: template,
  }
}

export const editTemplateSuccess = message => {
  return {
    type: EDIT_TEMPLATE_SUCCESS,
    payload: message,
  }
}
export const manageNegativeKeywords = keywords => {
  return {
    type: MANAGE_NEGATIVE_KEYWORDS,
    payload: keywords,
  }
}

export const manageNegativeKeywordsSuccess = message => {
  return {
    type: MANAGE_NEGATIVE_KEYWORDS_SUCCESS,
    payload: message,
  }
}

export const addTemplate = template => {
  return {
    type: ADD_TEMPLATE,
    payload: template,
  }
}

export const addTemplateSuccess = message => {
  return {
    type: ADD_TEMPLATE_SUCCESS,
    payload: message,
  }
}
export const deleteTemplate = template => {
  return {
    type: DELETE_TEMPLATE,
    payload: template,
  }
}

export const deleteTemplateSuccess = message => {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    payload: message,
  }
}

export const editCategory = category => {
  return {
    type: EDIT_CATEGORY,
    payload: category,
  }
}

export const editCategorySuccess = message => {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    payload: message,
  }
}

export const getCategoriesError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
