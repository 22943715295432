import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

import Emailtemplatealert from "../pages/EmailTemplate/email-template-alert"
import Emailtemplatebasic from "../pages/EmailTemplate/email-template-basic"
import Emailtemplatebilling from "../pages/EmailTemplate/email-template-billing"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import DashboardMain from "../pages/Dashboard-main"
// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"

// dripautomation
import DripAutomation from "../pages/Dripautomation/DripAutomation"
import CreateDripAutomation from "../pages/Dripautomation/CreateDripAutomation"
import UpdateDripAutomation from "../pages/Dripautomation/UpdateDripAutomation"
// Tags
import Tags from "../pages/Tags/Tags"
import ManageColors from "pages/Tags/ManageColors"

// Import-Export
import ImportExportPage from "../pages/Import-Export/ImportExportPage"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import TypiconsIcon from "../pages/Icons/IconTypicons"
import IconIon from "../pages/Icons/IconIon"
import ThemifyIcon from "../pages/Icons/IconThemify"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesInvoice from "../pages/Utility/PagesInvoice"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import PagesGallery from "../pages/Utility/PagesGallery"
import PagesDirectory from "../pages/Utility/PagesDirectory"
import Campaigns from "../pages/Campaigns/Campaigns"
import AddCampaign from "../pages/Campaigns/AddCampaign"
import Categories from "../pages/Categories/Categories"
import QuickReplies from "../pages/QuickReplies/QuickReplies"
import Templates from "../pages/Templates/Templates"
import TemplatesSetting from "../pages/Templates/TemplatesSetting"
import AddTemplate from "../pages/Templates/AddTemplate"
import OptOuts from "../pages/OptOuts/OptOuts"
import EditTemplate from "../pages/Templates/EditTemplate"
import LeadStages from "../pages/LeadStages/LeadStages"
import Markets from "../pages/Markets/Markets"
import MarketConfig from "../pages/Markets/MarketConfig"
import PtcrmSettings from "../pages/PtcrmSetting/PtcrmSettings"
import Notifications from "../pages/Notifications/Notifications"
import Reminder from "pages/Notifications/Reminder"
import DncList from "../pages/DncList/DncList"
import WrongNumber from "pages/WrongNumber/WrongNumber"
import Litigator from "pages/Litigator/Litigator"
import Rules from "../pages/Rules/Rules"
import Prospects from "../pages/Prospects/Prospects"
import AddProspect from "../pages/Prospects/AddProspect"
import Numbers from "../pages/Numbers/Numbers"
import DetailProspect from "../pages/Prospects/DetailProspect"
import ProspectNotes from "../pages/Prospects/ProspectNotes"
import ChangePassword from "../pages/ChangePassword/ChangePassword"

import CampaignSend from "../pages/Campaigns/CampaignSend"
import CampaignMetrics from "../pages/Campaigns/CampaignMetrics"
import CampaignScheduler from "../pages/Campaigns/CampaignScheduler"
import CampaignProspectsMessagesNotes from "../pages/Campaigns/CampaignProspectsMessagesNotes"
import CampaignUploadHistory from "../pages/Campaigns/CampaignUploadHistory"
import AddProspects from "../pages/Campaigns/AddProspects"
import Users from "pages/Users/Users"
import MessageUnread from "pages/Message/message"
import ContactCart from "pages/Contact/ContactCart"
import AddContact from "pages/Contact/AddContact"
import UploadContact from "pages/Contact/UploadContact"
import ListManagementPhoneNumber from "pages/ListManagementPhoneNumber/ListManagementPhoneNumber"
import UploadProspects from "pages/Prospects/UploadProspects"
import DncListing from "pages/DncList/DncListing"
import ImportDncFile from "pages/DncList/ImportDncFile"
import AddDncManually from "pages/DncList/AddDncManually"
import RestrictedDates from "../pages/RestrictedDates/RestrictedDates"
import Notes from "pages/Notifications/Notes"

const userRoutes = [
  { path: "/campaigns", component: Campaigns },
  { path: "/campaign/:id", component: CampaignMetrics },
  { path: "/campaign/:id/send", component: CampaignSend },
  { path: "/campaign/:id/scheduler", component: CampaignScheduler },
  {
    path: "/campaign/:id/prospects_messages_notes",
    component: CampaignProspectsMessagesNotes,
  },
  { path: "/tags", component: Tags },
  { path: "/manageColors", component: ManageColors },
  { path: "/campaign/:id/upload_history", component: CampaignUploadHistory },
  { path: "/campaign/:id/upload_history", component: CampaignUploadHistory },
  { path: "/campaign/:id/add-prospect", component: AddProspects },
  { path: "/add-campaign", component: AddCampaign },

  { path: "/templates", component: Templates },
  { path: "/templates/add", component: AddTemplate },
  { path: "/templates/edit/:id", component: EditTemplate },
  { path: "/categories", component: Categories },
  { path: "/quick-replies", component: QuickReplies },
  { path: "/templates-setting", component: TemplatesSetting },

  { path: "/import-export", component: ImportExportPage },
  { path: "/restricted-dates", component: RestrictedDates },

  { path: "/optouts", component: OptOuts },
  { path: "/lead-stages", component: LeadStages },
  { path: "/markets", component: Markets },
  { path: "/marketConfig", component: MarketConfig },
  { path: "/ptcrm", component: PtcrmSettings },
  { path: "/notifications", component: Notifications },
  { path: "/reminders", component: Reminder },
  { path: "/dnclist", component: DncList },
  { path: "/restrictedContact/wrongNumber", component: WrongNumber },
  { path: "/restrictedContact/litigator", component: Litigator },
  { path: "/skipRules", component: Rules },
  { path: "/restrictedContact/dnc-list", component: DncListing },
  { path: "/import-Restricted-Contact", component: ImportDncFile },
  { path: "/add-Restricted-Contact", component: AddDncManually },
  { path: "/notes", component: Notes },

  { path: "/prospects", component: Prospects },
  { path: "/prospects/add", component: AddProspect },
  { path: "/prospect/:id", component: DetailProspect },
  { path: "/prospect/:id/notes", component: ProspectNotes },
  { path: "/upload-prospects", component: UploadProspects },

  { path: "/numbers", component: Numbers },

  { path: "/change-password", component: ChangePassword },

  { path: "/dashboard", component: Dashboard },
  { path: "/dashboard2", component: DashboardMain },
  { path: "/users", component: Users },
  { path: "/message-unread", component: MessageUnread },
  { path: "/contact-cart", component: ContactCart },
  { path: "/add-contact", component: AddContact },
  { path: "/upload-bulk", component: UploadContact },

  // //calendar
  { path: "/calendar", component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-compose", component: EmailCompose },

  // Email Template
  { path: "/email-template-alert", component: Emailtemplatealert },
  { path: "/email-template-basic", component: Emailtemplatebasic },
  { path: "/email-template-billing", component: Emailtemplatebilling },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },

  // Icons
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },
  { path: "/icons-ion", component: IconIon },
  { path: "/icons-themify", component: ThemifyIcon },
  { path: "/icons-typicons", component: TypiconsIcon },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-invoice", component: PagesInvoice },
  { path: "/pages-directory", component: PagesDirectory },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },
  { path: "/pages-gallery", component: PagesGallery },

  // unReadMessages
  { path: "/management-phone-number", component: ListManagementPhoneNumber },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },

  // NEW ROUTES
  { path: "/drip-automation", component: DripAutomation },
  { path: "/create-drip-automation", component: CreateDripAutomation },
  { path: "/update-drip-automation/:id", component: UpdateDripAutomation },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/authentication/restpassword/:token", component: Recoverpw },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
