import React, { useEffect, useState, useRef } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
import axios from "axios"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvField, AvForm } from "availity-reactstrap-validation"
import close from "../../assets/images/close.svg"
import illus from "../../assets/images/chat-illustration.svg"

const TemplatesSetting = () => {
  useEffect(() => {
    getCategories()
    getNegativeKeywords()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false) // for circular spinner

  // new states for update template setting

  const [activeTab, setActiveTab] = useState("manageNegativeKeywords")
  const [reloadKey, setReloadKey] = useState(Math.random())
  const [categories, setCategories] = useState([])
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [newKeyword, setNewKeyword] = useState("")
  const [selectedCategory, setSelectedCategory] = useState()
  const [validationPayload, setValidationPayload] = useState({})
  const [errMessage, setErrMessage] = useState("")

  const getCategories = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/template/pullCategories",
        config
      )

      if (response?.data) {
        setCategories(response?.data?.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }
  const getNegativeKeywords = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/template/listNegativeKeywords/",
        config
      )

      if (response?.data) {
        setNegativeKeywords(response?.data?.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  // const handleAddQuickReply = async value => {
  //   setIsLoading(true)
  //   const data = {
  //     title: value?.title,
  //     message: value?.message,
  //   }
  //   try {
  //     const response = await axios.post(
  //       process.env.REACT_APP_PORT + "/api/template/createQR/",
  //       data,
  //       config
  //     )
  //     if (response) notify("success", "Quick Reply Created Successfully")
  //     setIsLoading(false)
  //     getCategories()
  //     setDataArr({
  //       message: "",
  //       messageCursorPosition: 0,
  //     })
  //     setModal({ isOpen: false })
  //   } catch (error) {
  //     setIsLoading(false)
  //     notify("error", error.response?.data?.message)
  //   }
  // }
  const handleEditCategory = async () => {
    setIsLoading(true)
    const data = {
      id: selectedCategory?._id,
      name: selectedCategory?.name,
      alternatemessage: selectedCategory?.alternate_message,
      validations: { ...validationPayload },
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT +
          `/api/template/updateCategory/${selectedCategory?._id}`,
        data,
        config
      )
      if (response) notify("success", "Category updated Successfully")
      setIsLoading(false)
      getCategories()
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }

  const categoryChangeHandler = id => {
    const category = categories.filter(item => item._id === id)?.[0]
    setSelectedCategory(category)
    setReloadKey(Math.random())
    setValidationPayload({ ...category.validations })
  }

  const removeNegativeKeyword = index => {
    const arr = negativeKeywords
    arr.splice(index, 1)
    setNegativeKeywords(arr)
    setReloadKey(Math.random())
  }

  const validationFields = [
    {
      name: "Minimum characters",
      text: "Minimum of 8 characters",
      key: "minChar",
      type: "number",
    },
    {
      name: "Minimum text spinners",
      text: "Minimum of 8 characters",
      key: "minTextSpinner",
      type: "number",
    },
    {
      name: "Minimum element in a text spinner",
      text: "Minimum of 8 characters",
      key: "minElementInTextSpinner",
      type: "number",
    },
    {
      name: "Merge field required",
      text: "Minimum of 8 characters",
      key: "isMergeFieldRequired",
      type: "checkbox",
    },
    {
      name: "No negative keywords required",
      text: "Minimum of 8 characters",
      key: "isNoNegativeKeywordRequired",
      type: "checkbox",
    },
    {
      name: "Merge field must be valid",
      text: "Minimum of 8 characters",
      key: "validMergeFieldRequired",
      type: "checkbox",
    },
    {
      name: "Text spinner must be valid",
      text: "Minimum of 8 characters",
      key: "validTextSpinnerRequired",
      type: "checkbox",
    },
  ]

  const addNegativeKeyword = () => {
    const arr = negativeKeywords
    const exist = arr.filter(item => item === newKeyword)

    if (exist.length) {
      setErrMessage("Keyword Already Exists")
      // this.setState({
      //   errMessage: "Keyword Already Exists",
      // })
    } else {
      arr.push(newKeyword)
      setNegativeKeywords(arr)
      setReloadKey(Math.random())
      setNewKeyword("")
      setErrMessage("")

      // this.setState({
      //   negativeKeywordsList: arr,
      //   reloadKey: Math.random(),
      //   newKeyword: "",
      //   errMessage: "",
      // })
    }
  }

  const saveNegativeKeywords = async () => {
    const newNegativeKeyWords = {
      data: negativeKeywords,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/template/manageNegativeKeyword",
        newNegativeKeyWords,
        config
      )
      if (response) notify("success", "Negative Keywords updated Successfully")
      setIsLoading(false)
      getNegativeKeywords()
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }

  const handleInputKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault()
      addNegativeKeyword()
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Templates Setting | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2">
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    Templates Setting
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/#">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Templates Setting</BreadcrumbItem>
                  </ol>
                </div>
              </Col>

              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <Row>
              <Col lg={12} className="mb-2">
                <div className="c-card">
                  <Nav tabs className="nav-tabs-custom cc-tabs">
                    <NavItem>
                      <button
                        className={
                          activeTab === "manageNegativeKeywords"
                            ? "nav-link active"
                            : "nav-link "
                        }
                        onClick={() => {
                          setActiveTab("manageNegativeKeywords")
                        }}
                      >
                        <span
                          className="d-sm-block"
                          style={{
                            color:
                              activeTab === "manageNegativeKeywords"
                                ? "white"
                                : "black",
                          }}
                        >
                          {" "}
                          <strong> Manage Negative Keywords </strong>
                        </span>
                      </button>
                    </NavItem>
                    <NavItem>
                      <button
                        className={
                          activeTab === "manageValidations"
                            ? "nav-link active"
                            : "nav-link "
                        }
                        onClick={() => {
                          setActiveTab("manageValidations")
                        }}
                      >
                        <span
                          className="d-sm-block"
                          style={{
                            color:
                              activeTab === "manageValidations"
                                ? "white"
                                : "black",
                          }}
                        >
                          <strong> Manage Validations</strong>{" "}
                        </span>
                      </button>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>

            <div className="c-card">
              {activeTab === "manageNegativeKeywords" ? (
                <Row className="mt-2">
                  <Col>
                    <CardTitle className="h4">Negative Keywords </CardTitle>
                    <div className="d-flex flex-wrap p-0 ">
                      <div className="text-muted">
                        To add new keyword click on{" "}
                        {/* <i className="fas fa-check text-success"></i>  */}
                        <b className="text-black">Enter </b>
                        and to save all changes click on{" "}
                        <b className="text-black">Save</b> button
                      </div>
                      {/* <div
                        className="d-flex flex-wrap py-3 gap-3 w-100 bg-white p-4 my-2 mb-3 "
                        key={reloadKey}
                      > */}
                      {/* {negativeKeywords &&
                    negativeKeywords.length ? (
                       negativeKeywords.map((item, index) => (
                         <div
                           key={index}
                           className="d-flex justify-content-center align-items-center gap-2 ps-2 bg-light rounded-2 border"
                         >
                           <input
                             value={item}
                             className="border-0 bg-light"
                             readOnly
                           />
                           <Button
                             className={
                               "has-icon me-1 small text-danger bg-transparent"
                             }
                             color={"danger"}
                             title="Remove"
                             onClick={() => removeNegativeKeyword(index)}
                           >
                             <i className="fas fa-times"></i>
                           </Button>
                         </div>
                       ))
                     ) : (
                       <div className="text-center w-100 d-flex justify-content-center align-items-center">
                         No Keywords found
                       </div>
                     )} */}
                      {/* <div className="d-flex justify-content-center align-items-center gap-2 ps-2 bg-light rounded-2 border">
                       <input
                         className="border-0 bg-light"
                         placeholder="Enter New Keyword"
                         value={newKeyword}
                         onChange={e => {
                          
                           setNewKeyword(e.target.value)
                         }}
                       />
                       <Button
                         className={
                           "has-icon me-1 small text-success bg-transparent"
                         }
                         color={"success"}
                         title="Add"
                         disabled={
                           !(newKeyword &&newKeyword.trim().length)
                         }
                         onClick={() => addNegativeKeyword()}
                       >
                         <i className="fas fa-check"></i>
                       </Button>
                     </div> */}
                      <div className="mt-3">
                        <form>
                          <div className="form-group">
                            <div className="inp-grp">
                              <div className="d-flex flex-column">
                                <input
                                  key={reloadKey}
                                  type={"text"}
                                  id="name"
                                  name="name"
                                  placeholder="Add Negative Keyword"
                                  onKeyPress={e => {
                                    handleInputKeyPress(e)
                                  }}
                                  onChange={e => {
                                    setNewKeyword(e.target.value.trim())
                                  }}
                                  className={"form-control mb-2"}
                                />
                                <div className="d-flex gap-2 py-2 flex-wrap">
                                  {negativeKeywords.map((word, index) => (
                                    <div
                                      className="badge badge-pill  p-2 border border-primary border-1 px-3 "
                                      key={index}
                                    >
                                      <span
                                        className=""
                                        style={{
                                          color: "#5932ea",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {word}
                                      </span>
                                      <i
                                        className="fas fa-times text-danger ms-2 cursor-pointer  fa-lg"
                                        style={{ color: "#5932ea" }}
                                        onClick={() => {
                                          removeNegativeKeyword(index)
                                        }}
                                      ></i>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md={12}>
                    {errMessage && errMessage.length > 0 ? (
                      <span className="error">{errMessage}</span>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col md={12} className={"text-end"}>
                    <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                      <Button
                        className="main-btn secondary-btn"
                        role="button"
                        onClick={() => {
                          getNegativeKeywords()
                        }}
                        //  disabled={!isChanged}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        onClick={() => saveNegativeKeywords()}
                        className="main-btn"
                        //  disabled={!isChanged}
                      >
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="mt-2">
                  <Col>
                    <CardTitle className="h4">Template Validations</CardTitle>
                    <div className="d-flex flex-wrap py-1 px-2 bg-white my-3">
                      <CardBody>
                        <AvForm
                          className="needs-validation"
                          key={reloadKey}
                          onValidSubmit={() => handleEditCategory()}
                        >
                          <Row>
                            <Col xl="6">
                              <FormGroup className="mb-3">
                                <AvField
                                  type="select"
                                  name="category"
                                  label="Category"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={selectedCategory?._id}
                                  onChange={e => {
                                    categoryChangeHandler(e.target.value)
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Category
                                  </option>
                                  {categories?.length > 0 &&
                                    categories.map(item => (
                                      <option key={item?._id} value={item?._id}>
                                        {item?.name}
                                      </option>
                                    ))}
                                </AvField>
                              </FormGroup>
                            </Col>

                            {selectedCategory ? (
                              validationFields.map((item, index) => {
                                return (
                                  <Col xl="6" key={index}>
                                    <FormGroup className="mb-3">
                                      {item.type === "number" ? (
                                        <AvField
                                          name={item.name}
                                          placeholder={item.name}
                                          type={item.type}
                                          label={item.name}
                                          errorMessage={`Enter ${item.name}`}
                                          className="form-control"
                                          validate={{
                                            required: {
                                              value: true,
                                            },
                                            pattern: {
                                              value: "^[1-9]\\d*$", // regular expression to match positive numbers only
                                              errorMessage:
                                                "Enter valid positive value",
                                            },
                                          }}
                                          value={
                                            selectedCategory?.validations?.[
                                              item.key
                                            ] === "true"
                                              ? true
                                              : selectedCategory?.validations?.[
                                                  item.key
                                                ] === "false"
                                              ? false
                                              : selectedCategory?.validations?.[
                                                  item.key
                                                ]
                                          }
                                          onChange={e => {
                                            const inputValue = e.target.value
                                            let tempObj = {
                                              ...validationPayload,
                                            }
                                            if (inputValue === "false") {
                                              tempObj[item.key] = true
                                            } else if (inputValue === "true") {
                                              tempObj[item.key] = false
                                            } else {
                                              tempObj[item.key] = inputValue
                                            }

                                            setValidationPayload({ ...tempObj })
                                          }}
                                        ></AvField>
                                      ) : item.type === "checkbox" ? (
                                        <div className="cus-checkbox">
                                          <input
                                            type={item.type}
                                            id={item.name}
                                            name={item.name}
                                            className="form-control cus-checkbox"
                                            validate={{
                                              required: {
                                                value: false,
                                              },
                                            }}
                                            defaultChecked={
                                              selectedCategory?.validations?.[
                                                item.key
                                              ] === true
                                                ? true
                                                : selectedCategory
                                                    ?.validations?.[
                                                    item.key
                                                  ] === false
                                                ? false
                                                : selectedCategory
                                                    ?.validations?.[item.key]
                                            }
                                            onChange={e => {
                                             
                                              let tempObj = {
                                                ...validationPayload,
                                              }
                                              if (e.target.checked === false) {
                                                tempObj[item.key] = false
                                                
                                              } else if (
                                                e.target.checked === true
                                              ) {
                                                tempObj[item.key] = true
                                                
                                              }
                                              setValidationPayload({
                                                ...tempObj,
                                              })
                                             
                                            }}
                                          />
                                          <label htmlFor={item.name}>
                                            <span>{item.name}</span>
                                          </label>
                                        </div>
                                      ) : // <span className="cus-checkbox">

                                      //   <AvField
                                      //   sx={{paddingLeft : '0em'}}
                                      //     name={item.name}
                                      //     placeholder={item.name}
                                      //     type={item.type}
                                      //     className="form-control cus-checkbox"
                                      //     label={item.name}
                                      //     validate={{
                                      //       required: {
                                      //         value: false,
                                      //       },
                                      //     }}
                                      //     value={
                                      //       selectedCategory?.validations?.[
                                      //         item.key
                                      //       ] === "true"
                                      //         ? true
                                      //         : selectedCategory
                                      //             ?.validations?.[
                                      //             item.key
                                      //           ] === "false"
                                      //         ? false
                                      //         : selectedCategory
                                      //             ?.validations?.[item.key]
                                      //     }
                                      //     onChange={e => {
                                      //       let tempObj = {
                                      //         ...validationPayload,
                                      //       }
                                      //       if (e.target.value === "false") {
                                      //         tempObj[item.key] = true
                                      //       } else if (
                                      //         e.target.value === "true"
                                      //       ) {
                                      //         tempObj[item.key] = false
                                      //       }
                                      //       setValidationPayload({
                                      //         ...tempObj,
                                      //       })
                                      //     }}
                                      //   ></AvField>
                                      //  </span>
                                      null}
                                    </FormGroup>
                                  </Col>
                                )
                              })
                            ) : (
                              <div
                                className="chat-illus illus-empty-state "
                                style={{ height: "40vh" }}
                              >
                                <img src={illus} alt="" />
                                <h3>Please Select Category </h3>
                              </div>
                            )}
                          </Row>
                          {selectedCategory ? (
                            <Col md={12} className={"text-end"}>
                              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                                <Button
                                  className="main-btn secondary-btn"
                                  role="button"
                                  onClick={() => {
                                    categoryChangeHandler(selectedCategory?._id)
                                  }}
                                  disabled={!selectedCategory}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className="main-btn"
                                  type="submit"
                                  color="primary"
                                  // onClick={() => this.handleValidationUpdate()}
                                >
                                  Save
                                </Button>
                              </div>
                            </Col>
                          ) : null}
                        </AvForm>
                      </CardBody>
                    </div>
                  </Col>
                  {/* <Col md={12}>
              {this.state.errMessage && this.state.errMessage.length > 0 ? (
                <span className="error">{this.state.errMessage}</span>
              ) : (
                ""
              )}
            </Col> */}
                </Row>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TemplatesSetting
