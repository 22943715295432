import React from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Alert, BreadcrumbItem, Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Modal, Row} from "reactstrap";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {Link, Redirect, withRouter} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {addCampaign,  getMarkets} from "../../store/campaigns/actions";


class AddCampaign extends React.Component
{
    componentDidMount() {
        this.props.getMarkets();
    }

    handleValidSubmit(event, values) {
        this.props.addCampaign({
            campaignName:values.campaign_name,
            market:values.market,
            delay:values.campaign_delay,
        });
    }

    render()
    {
        if(this.props.successMsg) {
            return <Redirect to="/campaigns"></Redirect>
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Add Campaign | TextLaunchPad.com </title>
                    </MetaTags>
                    <div className="container-fluid">

                        <Row className="align-items-center breadcrumb-box">
                            <Col xs={12}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Add New Campaign</h4>
                                    <ol className="breadcrumb mb-0">
                                        <BreadcrumbItem>
                                            <Link to="/">TextLaunchPad</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            Campaigns
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            Add New Campaign
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </Col>
                            <Col md={12} className={'pb-2'}>
                                <span className={'border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block'}></span>
                            </Col>
                        </Row>


                        <Row className="justify-content-center pt-4">
                            <Col xl="6">
                                <Card>
                                    <CardBody>
                                        {this.props.apiError && typeof this.props.apiError === 'string' ? (
                                            <Alert color="danger" >
                                                {this.props.apiError}
                                            </Alert>
                                        ) : null}

                                        {this.props.apiError && typeof this.props.apiError === 'object' ? (

                                            (this.props.apiError.response ? (
                                                <Alert color="danger" >
                                                    {this.props.apiError.response.data.error}
                                                </Alert>
                                            ):(
                                                <Alert color="danger" >
                                                    {this.props.apiError.message}
                                                </Alert>
                                            ))
                                        ) : null}

                                        {this.props.successMsg ? (
                                            <Alert color="success" >
                                                {this.props.successMsg}
                                            </Alert>
                                        ) : null}
                                        <AvForm
                                            onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                                            className="needs-validation">
                                            <Row>
                                                <Col xl="12">
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            name="campaign_name"
                                                            placeholder="Campaign Name"
                                                            type="text"
                                                            errorMessage="Enter Campaign Name"
                                                            className="form-control"
                                                            validate={{ required: { value: true },pattern:{value: '^[A-Za-z0-9 ]+$',errorMessage:"Enter valid campaign name"} }}
                                                            id="validationCustom01"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="6">
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="market"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            value=""
                                                        >
                                                            <option value="">Select Market</option>
                                                            {this.props.markets.length > 0 && this.props.markets.map(item=>(<option key={item._id} value={item._id}>{item.name}</option>)) }
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="6">
                                                    <FormGroup className="mb-3">

                                                        <AvField
                                                            name="campaign_delay"
                                                            placeholder="Delay Interval Per Text Message(s)"
                                                            type="number"
                                                            errorMessage="Enter Delay"
                                                            className="form-control"
                                                            validate={{ required: { value: true },number:{value:true},min:{value:1, errorMessage: "Delay cannot be less than or equal to 0 seconds"} }}
                                                            id="validationCustom02"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <div className="text-end py-2">
                                                <Link to="/campaigns" className="btn btn-dark me-2" role="button">Cancel</Link>
                                                <Button type="submit" color="primary" >Submit </Button>{" "}
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

AddCampaign.propTypes = {
    apiError: PropTypes.any, 
    markets: PropTypes.array,
    successMsg: PropTypes.any,
    addCampaign: PropTypes.func,
    getMarkets: PropTypes.func,
}

const mapStatetoProps = state => {
    const { apiError, successMsg,  markets, addCampaign, getMarkets } = state.Campaign
    return { apiError, successMsg, markets, addCampaign, getMarkets }
}

export default withRouter(
    connect(mapStatetoProps, { addCampaign, getMarkets })(AddCampaign)
)
