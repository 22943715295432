import React, { useEffect, useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Spinner,
  Label,
  Input,
} from "reactstrap"
import Joi from "joi"
import MetaTags from "react-meta-tags"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
import axios from "axios"

import toast, { Toaster } from "react-hot-toast"

const PtcrmSettings = ({ props }) => {
  useEffect(() => {
    getPtcrmSetting()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false)
  // const [ptcrmSetting, setPtcrmSetting] = useState([])
  const [formReloadKey, setFormReloadKey] = useState(Math.random())
  const [tempState, setTempState] = useState({})
  const [askIfProsAvailCheck , setAkIfProsAvailCheck] = useState(false)

  const formRowData = [
    {
      key: "PTCRM",
      title: "Push To CRM (Webhook)",
      type: "text",
      validation: Joi.string().required().trim().label("Push To CRM (Webhook)"),
    },
    {
      key: "companyname",
      title: "Company Name",
      type: "text",
      validation: Joi.string().required().trim().min(3).label("Company Name"),
    },
    {
      key: "companyalias",
      title: "Rep Name Alias",
      type: "text",
      validation: Joi.string().required().trim().min(2).label("Rep Name Alias"),
    },

    {
      key: "phoneReceive",
      title: "Text Forward Phone Number",
      type: "text",
      validation: Joi.string()
        .required()
        .trim()
        .label("Text Forward Phone Number"),
    },
   

    {
      key: "autofilter",
      title: "Auto Filter",
      individual: true,
      type: "text",
      validation: Joi.bool().required().label("Auto Filter"),
    },
    {
      key: "autodndfilter",
      title: "Auto Dead",
      individual: true,
      type: "text",
      validation: Joi.bool().required().label("Auto Dead"),
    },
    {
      key: "isMessageForwardEnabled",
      title: "Message Forwarding ",
      individual: true,
      type: "text",
      validation: Joi.bool().required().label("Message Forwarding"),
    },
    {
      key: "askIfDuplicatePros",
      title: "Ask if Prospect available",
      individual: true,
      type: "text",
      validation: Joi.bool().required().label("Ask If Prospect available"),
    },
    {
      key: "prosDuplicateDefaultAction",
      title: "Default Prospect Duplicate Action",
      individual: true,
      type: "text",
      validation: Joi.string(),
    },
  ]

  const createInitialFormState = data => {
    let obj = {}
    formRowData.map(item => {
      obj[item.key] = data?.[item.key]
    })
    return obj
  }
  const [errors, setErrors] = useState({})
  const [isValidate, setIsValidate] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [formState, setFormState] = useState(createInitialFormState())

  const handleChange = (name, value) => {
    
    let obj = { ...formState, [name]: value }
    if (isValidate) {
      const { error } = validateForm(obj)
      if (error) {
        setIsDisabled(true)
        const validationErrors = {}
        error.details.forEach(detail => {
          validationErrors[detail.path[0]] = detail.message.replaceAll('"', "")
        })
        setErrors({ ...validationErrors })
      } else {
        setIsDisabled(false)
        setErrors({})
      }
    }
    setFormState(obj)
  }

  const validateForm = (state = formState) => {
   
    let temObj = {}
    formRowData.map(item => {
      temObj[item.key] = item.validation
    })
    const schema = Joi.object(temObj)

    return schema.validate(state, { abortEarly: false })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsValidate(true)
    const { error } = validateForm()
    if (error) {
      setIsDisabled(true)
      const validationErrors = {}
      error.details.forEach(detail => {
        validationErrors[detail.path[0]] = detail.message.replaceAll('"', "")
      })
      setErrors(validationErrors)
    } else {
      // let payload = {
      //   configData: {
      //     companyname: formState.companyname,
      //     companyalias: formState.companyalias,
      //     autofilter: formState.autofilter,
      //     autodndfilter: formState.autodndfilter,
      //     PTCRM: formState.PTCRM,
      //     askIfDuplicatePros : formState.askIfDuplicatePros,

      //   },
      //   mobileAlertData: {
      //     // phoneSend: formState.phoneSend,
      //     phoneReceive: formState.phoneReceive,
      //     isMessageForwardEnabled: formState.isMessageForwardEnabled,
      //   },
      // }
      // console.log(formState?.askIfDuplicatePros , 'vlaue of ask if props available')
      // console.log(formState?.prosDuplicateDefaultAction , 'vlaue of ask if duplicate props available')

      // if(formState?.prosDuplicateDefaultAction)
      // {
      //   if(formState.askIfDuplicatePros)
      //   {
      //     payload({...payload , })
      //   }

      // }

      let payload = {
        configData: {
          companyname: formState.companyname,
          companyalias: formState.companyalias,
          autofilter: formState.autofilter,
          autodndfilter: formState.autodndfilter,
          PTCRM: formState.PTCRM,
          askIfDuplicatePros: formState.askIfDuplicatePros,
        },
        mobileAlertData: {
          phoneReceive: formState.phoneReceive,
          isMessageForwardEnabled: formState.isMessageForwardEnabled,
        },
      };
      
     
      if (!formState.askIfDuplicatePros && formState.prosDuplicateDefaultAction) {
        payload.configData.prosDuplicateDefaultAction = formState.prosDuplicateDefaultAction;
      }

     
      

      try {
        setIsLoading(true)
        const response = await axios.put(
          process.env.REACT_APP_PORT + "/api/setting/updatePTCRMConfig",
          payload,
          config
        )
        if (response?.data) {
          notify("success", "Config updated successfully!")
          getPtcrmSetting()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.data?.message)
        setIsLoading(false)
      }
    }
  }

  const getPtcrmSetting = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/setting/pullPTCRM",
        config
      )
      if (response?.data) {
        const newData = {
          ...response?.data?.config,
          ...response?.data?.setting,
        }
        // setPtcrmSetting(newData)
        setFormState(createInitialFormState(newData))
        setAkIfProsAvailCheck(response?.data?.config?.askIfDuplicatePros)
        setFormReloadKey(Math.random())
        setErrors({})
        setIsDisabled(false)
        setIsValidate(false)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  // const handleInputKeyPress = (event, item) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault()
  //     const val = event.target.value.trim()
  //     if (val && !formState[item.key]?.includes(val)) {
  //       const updatedData = formState[item.key]
  //       updatedData.push(event.target.value)
  //       handleChange(item.key, updatedData)
  //       setTempState({ ...tempState, [item.key]: "" })
  //     }
  //   }
  // }

  const renderInput = item => {
    switch (item.type) {
      case "text": {
        return (
          <input
            type={item.type}
            id="name"
            name="name"
            value={formState[item.key]}
            onChange={e => {
              handleChange(item.key, e.target.value)
            }}
            // onKeyPress={e => {
            //   handleInputKeyPress(e, item)
            // }}
            className={
              errors[item.key] ? "form-control err-inp" : "form-control"
            }
          />
        )
      }
      case "textarea": {
        return (
          <textarea
            type={item.type}
            id="name"
            name="name"
            value={formState[item.key]}
            onChange={e => {
              handleChange(item.key, e.target.value)
            }}
            className={
              errors[item.key] ? "form-control err-inp" : "form-control"
            }
          />
        )
      }

      default:
        break
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Push To CRM | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2">
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Config</h4>
                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/#">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>Account Settings</BreadcrumbItem>
                    <BreadcrumbItem active>Config</BreadcrumbItem>
                  </ol>
                </div>
              </Col>

              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <Card>
              <CardBody>
                <form onSubmit={handleSubmit} key={formReloadKey}>
                  <div className="row g-3 p-3">
                    {formRowData.map((item, index) => {
                      return !item.individual ? (
                        <div
                          className={
                            item.classes || "col-lg-6 col-md-12 col-12"
                          }
                          key={index}
                        >
                          <div className="form-group">
                            <div className="inp-grp">
                              <label htmlFor={item.key} className="form-label">
                                {item.title}
                              </label>

                              {renderInput(item)}
                              {errors[item.key] && (
                                <span className="text-danger position-absolute">
                                  {errors[item.key]}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null
                    })}
                    {/* <div className="row mt-1">
                      <div className="form-group">
                        <div className="inp-grp">
                          <label
                            htmlFor={"messageSample"}
                            className="form-label"
                          >
                            Message Samples
                          </label>
                          <div className="d-flex gap-2 flex-column">
                            {formState?.messageSamples &&
                              formState?.messageSamples?.map((item, index) => (
                                <div className="d-flex">
                                  <textarea
                                    id="name"
                                    name="name"
                                    value={formState.messageSamples[index]}
                                    onChange={e => {
                                      const mssgSamples =
                                        formState.messageSamples
                                      mssgSamples[index] = e.target.value
                                      handleChange(
                                        "messageSamples",
                                        mssgSamples
                                      )
                                    }}
                                    className="form-control"
                                  />
                                  <div className="d-flex gap-2 align-items-center flex-column p-1 justify-content-center">
                                    <Button
                                      onClick={() => {
                                        const mssgSamples =
                                          formState.messageSamples
                                        mssgSamples.splice(index, 1)
                                        handleChange(
                                          "messageSamples",
                                          mssgSamples
                                        )
                                      }}
                                      className="has-icon"
                                      color="outline-danger"
                                      disabled={
                                        formState?.messageSamples?.length < 3
                                      }
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </Button>
                                    {formState?.messageSamples?.length - 1 ===
                                      index && (
                                      <Button
                                        onClick={() => {
                                          const mssgSamples =
                                            formState.messageSamples
                                          mssgSamples.push("")
                                          handleChange(
                                            "messageSamples",
                                            mssgSamples
                                          )
                                        }}
                                        className="has-icon"
                                        color="outline-primary"
                                      >
                                        <i className="fas fa-plus"></i>
                                      </Button>
                                    )}
                                  </div>
                                  {errors[item.key] && (
                                    <span className="text-danger position-absolute">
                                      {errors[item.key]}
                                    </span>
                                  )}
                                </div>
                              ))}
                            {errors["messageSamples"] && (
                              <span className="text-danger ">
                                {errors["messageSamples"]}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row mt-1">
                      <div className="col-lg-6 col-md-12 col-12 mt-2 ">
                        <div className="from-group">
                          <div className="inp-grp">
                            <div className="cus-checkbox">
                              <input
                                type="checkbox"
                                id="c3"
                                name="isMessageForwardEnabled"
                                checked={
                                  formState?.isMessageForwardEnabled || false
                                }
                                onChange={e => {
                                  handleChange(
                                    "isMessageForwardEnabled",
                                    !formState?.isMessageForwardEnabled
                                  )
                                }}
                              />
                              <label htmlFor="c3" className="text-start">
                                <span>
                                  Message Forwarding, (Choose whether you
                                  receive messages on your mobile number)
                                </span>
                              </label>
                            </div>
                            {errors["isMessageForwardEnabled"] && (
                              <span className="text-danger position-absolute">
                                {errors["isMessageForwardEnabled"]}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-12 mt-2 ps-lg-4   ">
                        <div className="from-group">
                          <div className="inp-grp">
                            <div className="cus-checkbox">
                              <input
                                type="checkbox"
                                id="c2"
                                name="autodndfilter"
                                checked={formState?.autodndfilter}
                                onChange={() => {
                                  handleChange(
                                    "autodndfilter",
                                    !formState?.autodndfilter
                                  )
                                }}
                              />
                              <label htmlFor="c2" className="text-start">
                                <span>
                                  Auto Dead, (Choose whether your campaigns mark
                                  prospects as auto-dead)
                                </span>
                              </label>
                            </div>
                            {errors["autodndfilter"] && (
                              <span className="text-danger position-absolute">
                                {errors["autodndfilter"]}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-2">
                        <div className="from-group">
                          <div className="inp-grp">
                            <div className="cus-checkbox">
                              <input
                                type="checkbox"
                                id="c1"
                                name="autofilter"
                                checked={formState?.autofilter}
                                onChange={() => {
                                  handleChange(
                                    "autofilter",
                                    !formState?.autofilter
                                  )
                                }}
                              />
                              <label htmlFor="c1" className="text-start">
                                <span>
                                  Auto Filter, (Choose whether your campaigns
                                  filters out "STOP" messages)
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-12 mt-2 ps-lg-4   ">
                        <div className="from-group">
                          <div className="inp-grp">
                            <div className="cus-checkbox">
                              <input
                                type="checkbox"
                                id="c4"
                                name="askIfDuplicatePros"
                                checked={formState?.askIfDuplicatePros}
                                onChange={() => {
                                  handleChange(
                                    "askIfDuplicatePros",
                                    !formState?.askIfDuplicatePros
                                  )
                                  setAkIfProsAvailCheck(!formState?.askIfDuplicatePros)

                                  
                                }}
                              />
                              <label htmlFor="c4" className="text-start">
                                <span>
                                  Ask every time if duplicate prospects are found{" "}
                                </span>
                              </label>
                            </div>
                           {!askIfProsAvailCheck ?  <div className=" mt-2 d-flex justify-content-flex-start gap-4" >
                              <span className=" d-flex justify-content-flex-start gap-2">
                              <Input
                                type="radio"
                                id="customRadioInline1"
                                name="contactType"
                                className="form-check-input"
                                defaultChecked={formState?.prosDuplicateDefaultAction === 'ignore'}
                                onChange={() => {
                                  handleChange(
                                    "prosDuplicateDefaultAction",
                                    'ignore'
                                  )
                                }}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline1"
                              >
                                Ignore
                              </Label>

                              </span>
                              <span className=" d-flex justify-content-flex-start gap-2">
                              <Input
                                type="radio"
                                label="wrongNumber  "
                                id="forWrongNumber"
                                className="form-check-input"
                                name="contactType"
                                defaultChecked={formState?.prosDuplicateDefaultAction === 'override'}
                                onChange={() => {
                                  handleChange(
                                    "prosDuplicateDefaultAction",
                                    'override'
                                  )
                                }}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="forWrongNumber"
                              >
                                Override
                              </Label>

                              </span>


                            </div> : null}
                            <div className="d-flex gap-2">
                              
                            </div>

                            {errors["askIfDuplicatePros"] && (
                              <span className="text-danger position-absolute">
                                {errors["askIfDuplicatePros"]}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex justify-content-end gap-2">
                        {/* className="main-btn secondary-btn" */}
                        <button
                          className={`main-btn secondary-btn`}
                          type="reset"
                          onClick={() => {
                            getPtcrmSetting()
                          }}
                        >
                          <span>Cancel</span>
                        </button>
                        <button
                          className={`main-btn ${
                            isDisabled ? "opacity-50" : ""
                          }`}
                          type="submit"
                          disabled={isDisabled}
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PtcrmSettings
