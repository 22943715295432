import {
  GET_USERS,
  GET_USERS_SUCCESS,
  API_ERROR,
  ADD_USER,
  ADD_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
} from "./actionTypes"

export const getUsers = params => {
  return {
    type: GET_USERS,
    payload: params,
  }
}

export const getUsersSuccess = data => {
  return {
    type: GET_USERS_SUCCESS,
    payload: data,
  }
}

export const getUserError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const editUser = (id, user) => {
  return {
    type: EDIT_USER,
    payload: { id, user },
  }
}

export const editUserSuccess = message => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: message,
  }
}

export const addUser = user => {
  return {
    type: ADD_USER,
    payload: user,
  }
}

export const addUserSuccess = message => {
 
  return {
    type: ADD_USER_SUCCESS,
    payload: message,
  }
}
export const deleteUser = id => {
  return {
    type: DELETE_USER,
    payload: id,
  }
}

export const deleteUserSuccess = message => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: message,
  }
}
