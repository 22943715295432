import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {FORGET_PASSWORD, RECOVER_PASSWORD, VERIFY_RECOVER_PASSWORD_TOKEN} from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userRecoverPasswordError,
  verifyRecoverPasswordToken, userRecoverPasswordSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
  postJwtRecoverPwd,
  postJwtRecoverPwdVerify,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* recoverUserPassword({ payload: {user, history} }) {
  try {
    const response = yield call(postJwtRecoverPwd,{
      password: user.password,
      token: user.token,
    })
    yield put(userRecoverPasswordSuccess('Password changed successfully'));
  } catch (error) {
    yield put(userRecoverPasswordError(error))
  }
}

function* verifyPasswordToken({ payload: token }) {
  try {
    const response = yield call(postJwtRecoverPwdVerify,  {
      token: token
    })
  } catch (error) {
    yield put(userRecoverPasswordError(error))
  }
}

function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email)
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

      const response = yield call(postJwtForgetPwd,  {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    } else {
      const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Reset link are sended to your mailbox, check there first"
          )
        )
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(VERIFY_RECOVER_PASSWORD_TOKEN, verifyPasswordToken)
}

export function* watchUserPasswordRecover() {
  yield takeEvery(RECOVER_PASSWORD, recoverUserPassword)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
  yield all([fork(watchUserPasswordRecover)])
}

export default forgetPasswordSaga
