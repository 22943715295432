import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  EXPORT_DNC_LIST, IMPORT_DNC_LIST, RESET_DNC_LIST
} from "./actionTypes"

import {
  exportDncListSuccess, getDncListError, importDncListSuccess, resetDncListSuccess
} from "./actions"

import {exportDncList, importDncList, resetDncList} from "../../helpers/fakebackend_helper";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getDncExportApi() {
  try {
    const response = yield call(exportDncList)
    yield put(exportDncListSuccess('Dnc List Exported'));
  } catch (error) {
    yield put(getDncListError(error))
  }
}

function* getDncResetApi() {
  try {
    const response = yield call(resetDncList)
    yield put(resetDncListSuccess('DNC List Reset'));
  } catch (error) {
    yield put(getDncListError(error))
  }
}


function* importDncListApi({ payload: data }) {
  try {
    const response = yield call(importDncList,data)

    yield put(importDncListSuccess('Completed!'));
  } catch (error) {
    yield put(getDncListError(error))
  }
}

export function* watchDncList() {
  yield takeEvery(EXPORT_DNC_LIST, getDncExportApi)
  yield takeEvery(RESET_DNC_LIST, getDncResetApi)
  yield takeEvery(IMPORT_DNC_LIST, importDncListApi)
}

function* DncListSaga() {
  yield all([fork(watchDncList)])
}

export default DncListSaga
