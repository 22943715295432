export const API_ERROR = "API_ERROR"
export const SEARCH_PROSPECTS_BY_MESSAGE = "SEARCH_PROSPECTS_BY_MESSAGE"
export const SEARCH_PROSPECTS_FOR = "SEARCH_PROSPECTS_FOR"
export const FILTER_PROSPECTS = "FILTER_PROSPECTS"
export const GET_PROSPECTS = "GET_PROSPECTS"
export const GET_PROSPECTS_SUCCESS = "GET_PROSPECTS_SUCCESS"
export const GET_CAMPAIGNS_BY_MARKET = "GET_CAMPAIGNS_BY_MARKET"
export const GET_CAMPAIGNS_BY_MARKET_SUCCESS = "GET_CAMPAIGNS_BY_MARKET_SUCCESS"
export const COUNT_ALL_PROSPECTS = "COUNT_ALL_PROSPECTS"
export const COUNT_ALL_PROSPECTS_SUCCESS = "COUNT_ALL_PROSPECTS_SUCCESS"
export const ADD_PROSPECT = "ADD_PROSPECT"
export const ADD_PROSPECT_SUCCESS = "ADD_PROSPECT_SUCCESS"
export const EXPORT_PROSPECTS = "EXPORT_PROSPECTS"
export const EXPORT_PROSPECTS_SUCCESS = "EXPORT_PROSPECTS_SUCCESS"
export const DELETE_PROSPECTS = "DELETE_PROSPECTS"
export const DELETE_PROSPECTS_SUCCESS = "DELETE_PROSPECTS_SUCCESS"
export const REMOVE_PROSPECT_FROM_DNC = "REMOVE_PROSPECT_FROM_DNC"
export const REMOVE_PROSPECT_FROM_DNC_SUCCESS = "REMOVE_PROSPECT_FROM_DNC_SUCCESS"
export const GET_PROSPECT = "GET_PROSPECT"
export const GET_PROSPECT_SUCCESS = "GET_PROSPECT_SUCCESS"

export const GET_NOTES = "GET_NOTES"
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS"

export const ADD_NOTE = "ADD_NOTE"
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS"

export const EDIT_NOTE = "EDIT_NOTE"
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS"

export const DELETE_NOTE = "DELETE_NOTE"
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS"

export const UPDATE_PROSPECT = "UPDATE_PROSPECT"
export const UPDATE_PROSPECT_SUCCESS = "UPDATE_PROSPECT_SUCCESS"


export const UPDATE_PROSPECT_DNC = "UPDATE_PROSPECT_DNC"
export const UPDATE_PROSPECT_DNC_SUCCESS = "UPDATE_PROSPECT_DNC_SUCCESS"

export const UPDATE_PROSPECT_VERIFIED = "UPDATE_PROSPECT_VERIFIED"
export const UPDATE_PROSPECT_VERIFIED_SUCCESS = "UPDATE_PROSPECT_VERIFIED_SUCCESS"

export const REMOVE_PROSPECT_VERIFIED = "REMOVE_PROSPECT_VERIFIED"
export const REMOVE_PROSPECT_VERIFIED_SUCCESS = "REMOVE_PROSPECT_VERIFIED_SUCCESS"

export const UPDATE_PROSPECT_WRONG_NUMBER = "UPDATE_PROSPECT_WRONG_NUMBER"
export const UPDATE_PROSPECT_WRONG_NUMBER_SUCCESS = "UPDATE_PROSPECT_WRONG_NUMBER_SUCCESS"

export const REMOVE_PROSPECT_WRONG_NUMBER = "REMOVE_PROSPECT_WRONG_NUMBER"
export const REMOVE_PROSPECT_WRONG_NUMBER_SUCCESS = "REMOVE_PROSPECT_WRONG_NUMBER_SUCCESS"

export const UPDATE_PROSPECT_QUALIFIED = "UPDATE_PROSPECT_QUALIFIED"
export const UPDATE_PROSPECT_QUALIFIED_SUCCESS = "UPDATE_PROSPECT_QUALIFIED_SUCCESS"

export const REMOVE_PROSPECT_QUALIFIED = "REMOVE_PROSPECT_QUALIFIED"
export const REMOVE_PROSPECT_QUALIFIED_SUCCESS = "REMOVE_PROSPECT_QUALIFIED_SUCCESS"


export const UPDATE_PROSPECT_PRIORITY = "UPDATE_PROSPECT_PRIORITY"
export const UPDATE_PROSPECT_PRIORITY_SUCCESS = "UPDATE_PROSPECT_PRIORITY_SUCCESS"

export const REMOVE_PROSPECT_PRIORITY = "REMOVE_PROSPECT_PRIORITY"
export const REMOVE_PROSPECT_PRIORITY_SUCCESS = "REMOVE_PROSPECT_PRIORITY_SUCCESS"

export const UPDATE_PROSPECT_LEAD_STAGE = "UPDATE_PROSPECT_LEAD_STAGE"
export const UPDATE_PROSPECT_LEAD_STAGE_SUCCESS = "UPDATE_PROSPECT_LEAD_STAGE_SUCCESS"

export const PUSH_TO_WEBHOOK = "PUSH_TO_WEBHOOK"
export const PUSH_TO_WEBHOOK_SUCCESS = "PUSH_TO_WEBHOOK_SUCCESS"


export const SEND_CONVERSATION = "SEND_CONVERSATION"
export const SEND_CONVERSATION_SUCCESS = "SEND_CONVERSATION_SUCCESS"


