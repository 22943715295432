export const API_ERROR = "API_ERROR"
export const GET_QUICK_REPLIES = "GET_QUICK_REPLIES"
export const GET_QUICK_REPLIES_SUCCESS = "GET_QUICK_REPLIES_SUCCESS"
export const EDIT_QUICK_REPLY = "EDIT_QUICK_REPLY"
export const EDIT_QUICK_REPLY_SUCCESS = "EDIT_QUICK_REPLY_SUCCESS"
export const ADD_QUICK_REPLY = "ADD_QUICK_REPLY"
export const ADD_QUICK_REPLY_SUCCESS = "ADD_QUICK_REPLY_SUCCESS"
export const DELETE_QUICK_REPLY = "DELETE_QUICK_REPLY"
export const DELETE_QUICK_REPLY_SUCCESS = "DELETE_QUICK_REPLY_SUCCESS"

