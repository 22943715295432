import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  GET_CATEGORIES,
  EDIT_CATEGORY,
  ADD_CATEGORY,
  DELETE_CATEGORY,
} from "./actionTypes"

import {
  addCategorySuccess,
  editCategorySuccess,
  getCategoriesError,
  getCategoriesSuccess,
} from "./actions"

import {
  addCategory,
  deleteCategory,
  editCategory,
  getCategories,
} from "../../helpers/fakebackend_helper"

const delay = ms => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getCategoriesApi() {
  try {
    const response = yield call(getCategories)
    yield put(getCategoriesSuccess(response.data))
  } catch (error) {
    yield put(getCategoriesError(error))
  }
}

function* addCategoryApi({ payload: category }) {
  try {
    const response = yield call(addCategory, category)
    yield put(addCategorySuccess("Category added successfully"))
    yield delay(800)
    const response1 = yield call(getCategories)
    yield put(getCategoriesSuccess(response1.data))
  } catch (error) {
    yield put(getCategoriesError(error))
  }
}

function* editCategoryApi({ payload: category }) {
  try {
    const response = yield call(editCategory, category)
    yield put(editCategorySuccess("Category updated successfully"))
    yield delay(1000)
    const response1 = yield call(getCategories)
    yield put(getCategoriesSuccess(response1.data))
  } catch (error) {
    yield put(getCategoriesError(error))
  }
}

function* deleteCategoryApi({ payload: category }) {
  try {
    const response = yield call(deleteCategory, category)
    yield put(editCategorySuccess("Category deleted successfully"))
    yield delay(1000)
    const response1 = yield call(getCategories)
    yield put(getCategoriesSuccess(response1.data))
  } catch (error) {
    yield put(getCategoriesError(error))
    yield delay(5000)
    const response1 = yield call(getCategories)
    yield put(getCategoriesSuccess(response1.data))
  }
}

export function* watchCategories() {
  yield takeEvery(GET_CATEGORIES, getCategoriesApi)
  yield takeEvery(EDIT_CATEGORY, editCategoryApi)
  yield takeEvery(ADD_CATEGORY, addCategoryApi)
  yield takeEvery(DELETE_CATEGORY, deleteCategoryApi)
}

function* CategoriesSaga() {
  yield all([fork(watchCategories)])
}

export default CategoriesSaga
