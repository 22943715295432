import React, {useState} from "react"
import MetaTags from 'react-meta-tags';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  BreadcrumbItem, Button, Spinner
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getNotifications} from '../../store/notifications/actions';
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation"
import moment from 'moment'
import {downloadNotificationFile} from "../../helpers/fakebackend_helper";
class Notifications extends React.Component
{

  constructor(props) {

    super(props);

    this.state = {
      confirm_alert: false,
      lead_stage_id: null,
      modal_center: false,
      modal_add: false,
      title:""
    };

  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }



  componentDidMount() {
    this.props.getNotifications();
  }

  downloadFile(id, name) {
    downloadNotificationFile(id).then((res) => {
      

      // let url = window.URL.createObjectURL(blob);
      // let link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', name + '.csv');
      // link.click();
      this.downloadCsvFunction(res, name);
    })
  }
  //created new function for download csv file
   downloadCsvFunction = (data, filename) => {
    const csvContent = "data:text/csv;charset=utf-8," + data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render()
  {
    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Notifications | TextLaunchPad.com </title>
            </MetaTags>
            <div className="container-fluid">
              <Row className="align-items-center breadcrumb-box">
                <Col xs={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Notifications</h4>
                    <ol className="breadcrumb mb-0">
                      <BreadcrumbItem>
                        <Link to="/">TextLaunchPad</Link>
                      </BreadcrumbItem>
                      {/* <BreadcrumbItem >
                        Notifications
                      </BreadcrumbItem> */}
                      <BreadcrumbItem active>
                        Notifications
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </Col>
                <Col md={12} className={'pb-2'}>
                  <span className={'border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block'}></span>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  {this.props.apiError && typeof this.props.apiError === 'string' ? (
                      <Alert color="danger" >
                        {this.props.apiError}
                      </Alert>
                  ) : null}

                  {this.props.apiError && typeof this.props.apiError === 'object' ? (

                      (this.props.apiError.response ? (
                          <Alert color="danger" >
                            {this.props.apiError.response.data.error}
                          </Alert>
                      ):(
                          <Alert color="danger" >
                            {this.props.apiError.message}
                          </Alert>
                      ))
                  ) : null}

                  {this.props.successMsg ? (
                      <Alert color="success" >
                        {this.props.successMsg}
                      </Alert>
                  ) : null}

                  <div className="table-rep-plugin">
                    <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                    >
                      <Table id="tech-companies-1" className="table veltrix-table" >
                        <Thead>
                          <Tr>
                            <Th>Name</Th>
                            <Th>Size</Th>
                            <Th>Date</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          { this.props.loading ? (
                              <Tr>
                                <Td colSpan={4} className={'text-center'}>
                                  <Spinner className="ms-2" color="primary" />
                                </Td>
                              </Tr>
                          ) : null }
                          { this.props.loading==false && this.props.notifications.length<=0 ? (
                              <Tr>
                                <Td valign={'middle'} colSpan={4}>No notifications found.</Td>
                              </Tr>
                          ) : null }
                          { this.props.notifications ?  ( this.props.notifications.map((item, index) => (
                              <Tr key={item._id}>
                                <Td valign={'middle'}>{item.name}</Td>
                                <Td valign={'middle'}>{item.size /1000} KB</Td>
                                <Td valign={'middle'}>{moment(item.createdAt).format('LLL')}</Td>
                                <Td valign={'middle'}>{<div onClick={()=>this.downloadFile(item._id,item.name)} className="btn btn-sm btn-primary">Download</div>}</Td>
                              </Tr>
                          )) ) : null }
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
    )
  }
}

Notifications.propTypes = {
  loading: PropTypes.bool,
  apiError: PropTypes.any,
  notifications: PropTypes.array,
  getNotifications: PropTypes.func,
  successMsg: PropTypes.any,
}

const mapStatetoProps = state => {
  const { loading, apiError, successMsg, notifications, getNotifications } = state.Notifications
  return { loading, apiError, successMsg, notifications, getNotifications }
}

export default withRouter(
    connect(mapStatetoProps, { getNotifications })(Notifications)
)
