import {
  GET_PTCRM_SETTINGS, GET_PTCRM_SETTINGS_SUCCESS,
  API_ERROR, SAVE_PTCRM_SETTING, SAVE_PTCRM_SETTING_SUCCESS, SAVE_PTCRM_FILTER, SAVE_PTCRM_FILTER_SUCCESS
} from "./actionTypes"

export const getPtcrm = () => {
  return {
    type: GET_PTCRM_SETTINGS
  }
}

export const getPtcrmSuccess = ptcrmSettings => {
  return {
    type: GET_PTCRM_SETTINGS_SUCCESS,
    payload: ptcrmSettings,
  }
}


export const getPtcrmError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}


export const savePtcrmSettings = (ptcrmSetting) => {
  return {
    type: SAVE_PTCRM_SETTING,
    payload: ptcrmSetting,
  }
}

export const savePtcrmSettingsSuccess = (message) => {
  return {
    type: SAVE_PTCRM_SETTING_SUCCESS,
    payload: message,
  }
}

export const savePtcrmFilter = (ptcrmSetting) => {
  return {
    type: SAVE_PTCRM_FILTER,
    payload: ptcrmSetting,
  }
}

export const savePtcrmFilterSuccess = (message) => {
  return {
    type: SAVE_PTCRM_FILTER_SUCCESS,
    payload: message,
  }
}
