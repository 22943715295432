import React from "react";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Alert, BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col, Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Modal,
    Row
} from "reactstrap";
import {Link, Redirect, withRouter} from "react-router-dom";
import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {exportDncList, importDncList, resetDncList} from "../../store/dnclist/actions";
import Switch from "react-switch";

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Import
        </div>
    )
}

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Remove
        </div>
    )
}

class DncList extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            import_type:false,
            file:null
        };
    }


    resetDncListFunc()
    {
        this.props.resetDncList();
    }

    exportDncListFunc()
    {
        this.props.exportDncList();
    }

    handleValidSubmit(event, values) {

        const formdata = new FormData();
        formdata.append('file', this.state.file);
        formdata.append('action', this.state.import_type);

        




        this.props.importDncList(formdata);

        /*this.props.savePtcrmSettings({
            companyname:this.state.companyname,
            PTCRM:this.state.PTCRM,
        });*/
    }

    render()
    {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>DNC List | TextLaunchPad.com </title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Row className="align-items-center breadcrumb-box">
                            <Col xs={6}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">DNC List</h4>
                                    
                                    <ol className="breadcrumb mb-0">
                                        <BreadcrumbItem>
                                            <Link to="/">TextLaunchPad</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            Account Settings
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            DNC List
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </Col>
                            <Col md={12} className={'pb-2'}>
                                <span className={'border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block'}></span>
                            </Col>
                        </Row>

                        <Row className={'pt-4'}>
                            <Col>
                                {this.props.apiError && typeof this.props.apiError === 'string' ? (
                                    <Alert color="danger">
                                        {this.props.apiError}
                                    </Alert>
                                ) : null}

                                {this.props.apiError && typeof this.props.apiError === 'object' ? (
                                    (this.props.apiError.response ? (
                                        <Alert color="danger">
                                            {this.props.apiError.response.data.error}
                                        </Alert>
                                    ):(
                                        <Alert color="danger">
                                            {this.props.apiError.message}
                                        </Alert>
                                    ))
                                ) : null}

                                {this.props.successMsg ? (
                                    <Alert color="success">
                                        {this.props.successMsg}
                                    </Alert>
                                ) : null}
                            </Col>
                        </Row>
                            <Row className={'justify-content-center'}>
                                <Col md="6" >
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={12} className={'mb-3 text-end'}>
                                                <Button
                                                    type={'button'}
                                                    color="primary"
                                                    className={'me-2'}
                                                    onClick={() => {
                                                        this.exportDncListFunc()
                                                    }} >Export
                                                </Button>
                                                <Button
                                                    type={'button'}
                                                    color="primary"
                                                    onClick={() => {
                                                        this.resetDncListFunc()
                                                    }}>Reset
                                                </Button>
                                            </Col>
                                        </Row>
                                        <AvForm onValidSubmit={(e, v) => this.handleValidSubmit(e, v)} className="needs-validation">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3">
                                                        <label>Import/Remove</label>
                                                        <div className="square-switch">
                                                            <input
                                                                type="checkbox"
                                                                switch="none"
                                                                id="Import"
                                                                label="Import"
                                                                name="Import"
                                                                onChange={(e)=>{
                                                                    this.setState({
                                                                        import_type:!this.state.import_type
                                                                    })
                                                                }}
                                                            />
                                                            <label htmlFor="Import" data-on-label="" data-off-label=""/>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">
                                                            Upload File
                                                        </Label>
                                                        <AvField
                                                            name="upload_file"
                                                            type="file"
                                                            validate={{required:{value:true}}}
                                                            className="form-control"
                                                            id="validationCustom02"
                                                            onChange={(e) => {
                                                               this.setState({
                                                                    file:e.target.files[0]
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div className="text-end">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className=""
                                                >
                                                    <>{this.state.import_type ? 'Remove' : 'Import'}</>
                                                </Button>{" "}
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

DncList.propTypes = {
    apiError: PropTypes.any,
    successMsg: PropTypes.any,
    exportDncList: PropTypes.func,
    resetDncList: PropTypes.func,
    importDncList: PropTypes.func,
}

const mapStatetoProps = state => {
    const { apiError, successMsg,  exportDncList, resetDncList, importDncList } = state.DncList
    return { apiError, successMsg, exportDncList, resetDncList, importDncList }
}

export default withRouter(
    connect(mapStatetoProps, { exportDncList, resetDncList, importDncList })(DncList)
)
