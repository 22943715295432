import React, { useEffect, useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
import Joi from "joi"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
// import "./WrongNumbers.scss"
import classnames from "classnames"
import axios from "axios"
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import dripIconBorder from "../../assets/images/drip-icon-border.svg"
import dripIconFilled from "../../assets/images/drip-icon-filled.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import debounce from "lodash.debounce"
import incomingMessage from "../../assets/images/incomingMessage.svg"
import outgoingMessage from "../../assets/images/outgoingMessage.svg"
import close from "../../assets/images/close.svg"
import index from "pages/Blog/BlogGrid"

const MarketConfig = ({ props }) => {
  useEffect(() => {
    getMarketConfig()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false)

  const [response, setResponse] = useState("") // for success or error response
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedRows, setSelectedRows] = useState([])
  const [totalLeadStage, setTotalLeadStage] = useState()
  const [marketConfig, setMarketConfig] = useState([])
  const [filterData, setFilterData] = useState({ searchBy: "prospect" })
  const [formReloadKey, setFormReloadKey] = useState(Math.random())
  const [tempState, setTempState] = useState({})

  const formRowData = [
    {
      key: "optInKeywords",
      title: "OptIn Keywords",
      type: "text",
      isKeyword: true,
      validation: Joi.array()
        .items(Joi.string())
        .min(2)
        .label("OptIn Keywords"),
    },

    {
      key: "helpKeywords",
      isKeyword: true,
      title: "Help Keywords",
      type: "text",
      validation: Joi.array().items(Joi.string()).min(2).label("Help Keywords"),
    },
    {
      key: "optOutKeywords",
      title: "OptOut Keywords",
      isKeyword: true,
      type: "text",
      validation: Joi.array()
        .items(Joi.string())
        .min(2)
        .label("OptOut Keywords"),
    },
    {
      key: "usAppToPersonUsecase",
      title: "Us App To Person Usecase",
      type: "text",
      validation: Joi.string()
        .required()
        .trim()
        .max(50)
        .label("Us App To Person Usecase"),
    },
    {
      key: "optInMessage",
      title: "Opt In Message",
      type: "textarea",
      validation: Joi.string()
        .required()
        .trim()
        .min(10)
        .label("Opt In Keywords"),
    },
    {
      key: "optOutMessage",
      title: "Opt Out Message",
      type: "textarea",
      validation: Joi.string()
        .required()
        .trim()
        .min(10)
        .label("Opt Out Message"),
    },

    {
      key: "helpMessage",
      title: "Help Message",
      type: "textarea",
      validation: Joi.string().required().trim().max(50).label("Help Message"),
    },
    {
      key: "description",
      title: "Description",
      type: "textarea",
      validation: Joi.string().required().trim().min(30).label("Description"),
    },
    {
      key: "messageFlow",
      title: "Message Flow",
      type: "textarea",
      validation: Joi.string().required().trim().label("Message Flow"),
      classes: "col-lg-12 col-md-12 col-12",
    },
    {
      key: "messageSamples",
      title: "Message Samples",
      type: "textarea",
      individual: true,
      validation: Joi.array()
        .items(Joi.string())
        .min(2)
        .label("Message Samples"),
    },

    {
      key: "hasEmbeddedLinks",
      title: "Has Embedded Links",
      individual: true,
      type: "text",
      validation: Joi.bool().required().label("Has Embedded Links"),
    },
    {
      key: "hasEmbeddedPhone",
      title: "Has Embedded Phone",
      individual: true,
      type: "text",
      validation: Joi.bool().required().label("Has Embedded Phone"),
    },
  ]

  const createInitialFormState = data => {
    let obj = {}
    formRowData.map(item => {
      obj[item.key] = data?.[item.key] || ""
    })
    return obj
  }
  const [errors, setErrors] = useState({})
  const [isValidate, setIsValidate] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [formState, setFormState] = useState(createInitialFormState())

  const handleChange = (name, value) => {
    let obj = { ...formState, [name]: value }
    if (isValidate) {
      const { error } = validateForm(obj)
      if (error) {
        setIsDisabled(true)
        const validationErrors = {}
        error.details.forEach(detail => {
          validationErrors[detail.path[0]] = detail.message.replaceAll('"', "")
        })
        setErrors({ ...validationErrors })
      } else {
        setIsDisabled(false)
        setErrors({})
      }
    }
    setFormState(obj)
  }

  const validateForm = (state = formState) => {
    let temObj = {}
    formRowData.map(item => {
      temObj[item.key] = item.validation
    })
    const schema = Joi.object(temObj)

    return schema.validate(state, { abortEarly: false })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsValidate(true)
    const { error } = validateForm()
    if (error) {
      setIsDisabled(true)
      const validationErrors = {}
      error.details.forEach(detail => {
        validationErrors[detail.path[0]] = detail.message.replaceAll('"', "")
      })
      setErrors(validationErrors)
    } else {
      // Valid form submission
      const payload = {
        ...formState,
      }
      try {
        setIsLoading(true)
        const response = await axios.put(
          process.env.REACT_APP_PORT + "/api/market/updateMarketConfig",
          { ...payload },
          config
        )
        if (response?.data) {
          notify("success", "Market Config updated successfully!")
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.data?.message)
        setIsLoading(false)
      }
    }
  }

  const getMarketConfig = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/market/getMarketConfig",
        config
      )
      if (response?.data) {
        setMarketConfig(response?.data)
        setFormState(createInitialFormState(response.data))
        setFormReloadKey(Math.random())
        setErrors({})
        setIsDisabled(false)
        setIsValidate(false)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const handleInputKeyPress = (event, item) => {
    if (event.key === "Enter") {
      event.preventDefault()
      const val = event.target.value.trim()
      if (val && !formState[item.key]?.includes(val)) {
        const updatedData = formState[item.key]
        updatedData.push(event.target.value)
        handleChange(item.key, updatedData)
        setTempState({ ...tempState, [item.key]: "" })
      }
    }
  }

  const renderInput = item => {
    switch (item.type) {
      case "text": {
        return item.isKeyword ? (
          <div className="d-flex flex-column">
            <input
              type={item.type}
              value={tempState[item.key]}
              id="name"
              name="name"
              onKeyPress={e => {
                handleInputKeyPress(e, item)
              }}
              onChange={e => {
                setTempState({
                  ...tempState,
                  [item.key]: e.target.value?.trim()?.toUpperCase(),
                })
              }}
              className={
                errors[item?.key] ? "form-control err-inp" : "form-control"
              }
            />
            <div className="d-flex gap-2 py-2 flex-wrap">
              {formState?.[item?.key] &&
                formState?.[item?.key]?.map((word, index) => (
                  <div className="badge badge-pill bg-indigo p-2" key={index}>
                    <span>{word}</span>
                    <i
                      className="fas fa-times text-danger ms-2 cursor-pointer  fa-lg"
                      onClick={() => {
                        const words = formState?.[item?.key]
                        words.splice(index, 1)
                        handleChange(item?.key, words)
                      }}
                    ></i>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <input
            type={item.type}
            id="name"
            name="name"
            value={formState[item.key]}
            onChange={e => {
              handleChange(item.key, e.target.value)
            }}
            // onKeyPress={e => {
            //   handleInputKeyPress(e, item)
            // }}
            className={
              errors[item.key] ? "form-control err-inp" : "form-control"
            }
          />
        )
      }
      case "textarea": {
        return (
          <textarea
            type={item.type}
            id="name"
            name="name"
            value={formState[item.key]}
            onChange={e => {
              handleChange(item.key, e.target.value)
            }}
            // onKeyPress={e => {
            //   handleInputKeyPress(e, item)
            // }}
            className={
              errors[item.key] ? "form-control err-inp" : "form-control"
            }
          />
        )
      }

      default:
        break
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Market Config | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2">
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    {totalLeadStage ? `${totalLeadStage}` : null} Market Config
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/dashboard2">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to="/markets">Markets</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Market Config</BreadcrumbItem>
                  </ol>
                </div>
              </Col>

              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <Card>
              <CardBody>
                <form onSubmit={handleSubmit} key={formReloadKey}>
                  <div className="row g-3 p-3">
                    {formRowData.map((item, index) => {
                      return !item.individual ? (
                        <div
                          className={
                            item.classes || "col-lg-6 col-md-12 col-12"
                          }
                          key={index}
                        >
                          <div className="form-group">
                            <div className="inp-grp">
                              <label htmlFor={item.key} className="form-label">
                                {item.title}
                              </label>

                              {renderInput(item)}
                              {errors[item.key] && (
                                <span className="text-danger position-absolute">
                                  {errors[item.key]}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null
                    })}
                    <div className="row mt-1">
                      <div className="form-group">
                        <div className="inp-grp">
                          <label
                            htmlFor={"messageSample"}
                            className="form-label"
                          >
                            Message Samples
                          </label>
                          <div className="d-flex gap-2 flex-column">
                            {formState?.messageSamples &&
                              formState?.messageSamples?.map((item, index) => (
                                <div className="d-flex">
                                  <textarea
                                    id="name"
                                    name="name"
                                    value={formState.messageSamples[index]}
                                    onChange={e => {
                                      const mssgSamples =
                                        formState.messageSamples
                                      mssgSamples[index] = e.target.value
                                      handleChange(
                                        "messageSamples",
                                        mssgSamples
                                      )
                                    }}
                                    className="form-control"
                                  />
                                  <div className="d-flex gap-2 align-items-center flex-column p-1 justify-content-center">
                                    <Button
                                      onClick={() => {
                                        const mssgSamples =
                                          formState.messageSamples
                                        mssgSamples.splice(index, 1)
                                        handleChange(
                                          "messageSamples",
                                          mssgSamples
                                        )
                                      }}
                                      className="has-icon"
                                      color="outline-danger"
                                      disabled={
                                        formState?.messageSamples?.length < 3
                                      }
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </Button>
                                    {formState?.messageSamples?.length - 1 ===
                                      index && (
                                      <Button
                                        onClick={() => {
                                          const mssgSamples =
                                            formState.messageSamples
                                          mssgSamples.push("")
                                          handleChange(
                                            "messageSamples",
                                            mssgSamples
                                          )
                                        }}
                                        className="has-icon"
                                        color="outline-primary"
                                      >
                                        <i className="fas fa-plus"></i>
                                      </Button>
                                    )}
                                  </div>
                                  {errors[item.key] && (
                                    <span className="text-danger position-absolute">
                                      {errors[item.key]}
                                    </span>
                                  )}
                                </div>
                              ))}
                            {errors["messageSamples"] && (
                              <span className="text-danger ">
                                {errors["messageSamples"]}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="from-group">
                          <div className="inp-grp">
                            <div className="cus-checkbox">
                              <input
                                type="checkbox"
                                id="c1"
                                name="hasEmbeddedPhone"
                                checked={formState?.hasEmbeddedPhone}
                                on
                                onChange={() => {
                                  handleChange(
                                    "hasEmbeddedPhone",
                                    !formState?.hasEmbeddedPhone
                                  )
                                }}
                              />
                              <label htmlFor="c1">
                                <span>Has Embedded Phone?</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="from-group">
                          <div className="inp-grp">
                            <div className="cus-checkbox">
                              <input
                                type="checkbox"
                                id="c2"
                                name="hasEmbeddedLinks"
                                checked={formState?.hasEmbeddedLinks}
                                on
                                onChange={() => {
                                  handleChange(
                                    "hasEmbeddedLinks",
                                    !formState?.hasEmbeddedLinks
                                  )
                                }}
                              />
                              <label htmlFor="c2">
                                <span>Has Embedded Links?</span>
                              </label>
                            </div>
                            {errors["hasEmbeddedLinks"] && (
                              <span className="text-danger position-absolute">
                                {errors["hasEmbeddedLinks"]}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex justify-content-end gap-2">
                        {/* className="main-btn secondary-btn" */}
                        <button
                          className={`main-btn secondary-btn`}
                          type="reset"
                          onClick={() => {
                            getMarketConfig()
                          }}
                        >
                          <span>Cancel</span>
                        </button>
                        <button
                          className={`main-btn ${
                            isDisabled ? "opacity-50" : ""
                          }`}
                          type="submit"
                          disabled={isDisabled}
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MarketConfig
