import axios from "axios"
import { post, del, get, put } from "./api_helper"
import * as url from "./url_helper"
import {
  CHANGE_PASSWORD,
  DELETE_NOTE,
  FILTER_PROSPECTS,
  GET_NOTES,
  PUSH_TO_WEBHOOK,
  SEND_CONVERSATION,
  UPDATE_PROSPECT,
  UPDATE_PROSPECT_DNC,
  UPDATE_PROSPECT_LEAD_STAGE,
  UPDATE_PROSPECTS_VERIFIED,
  AUTO_SEND_CAMP,
  CANCEL_AUTO_SEND_CAMP,
  COUNT_TEMPLATE_BY_CAT,
  GET_BATCH,
  GET_CAMPAIGN_GETSCHEDULE,
  GET_CAMPAIGN_UPLOADS_HISTROY,
  GET_TEMPLATE_BY_CAT,
  SEND_MESSAGE,
  UPDATE_SCHEDULER,
  EXISTS_PROSPECTS,
  UPLOAD_PROSPECTS,
  CAMPAIGN_FILTER_PROSPECTS,
} from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => post(url.POST_FAKE_REGISTER, data)

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postRecoverPwd
const postJwtRecoverPwd = data =>
  post(url.POST_RECOVER_PASSWORD + data.token, data, {
    headers: {
      "x-auth-token": data.token,
    },
  })
const postJwtRecoverPwdVerify = data =>
  get(url.POST_RECOVER_PASSWORD_VERIFY + data.token, {
    headers: {
      "x-auth-token": data.token,
    },
  })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
//export const getCategories = () => get(url.GET_CATEGORIES)

export const getCampaigns = () => get(url.GET_CAMPAIGNS)
export const getFollowUpCampaigns = () => get(url.GET_FOLLOW_UP_CAMPAIGNS)
export const editCampaign = data => put(url.EDIT_CAMPAIGN, data)
export const deleteCampaign = data => post(url.DELETE_CAMPAIGN + data)
export const addCampaign = data => post(url.ADD_CAMPAIGN, data)
export const getCampaign = id => get(url.GET_CAMPAIGN + id)
export const getCampaignCountStatus = id =>
  get(url.GET_CAMPAIGN_COUNT_STATUS + id)
export const getCampaignMetrics = id => get(url.GET_CAMPAIGN_METRICS + id)
export const downloadCampaignCountStatus = data =>
  get(url.DOWNLOAD_CAMPAIGN_COUNT_STATUS + data.id + "?query=" + data.category)
export const getCampaignCountProspects = id =>
  get(url.GET_CAMPAIGN_COUNT_PROSPECTS + id)
export const getBatch = id => get(url.GET_BATCH + id)
export const getTemplatesByCat = data =>
  get(
    url.GET_TEMPLATE_BY_CAT +
      data.id +
      "/" +
      data.camp +
      "/" +
      data.count +
      "/" +
      data.procount +
      "/" +
      data.bulkid
  )
export const countTempletebyCat = id => get(url.COUNT_TEMPLATE_BY_CAT + id)

export const sendMessage = data => post(url.SEND_MESSAGE, data)
export const prospectUpdatestatus = data =>
  get(url.PROSPECT_UPDATESTATUS + data.id + "/" + data.camp)
export const campaignUpdatecampskip = data =>
  put(url.CAMPAIGN_UPDATECAMPSKIP, data)
export const campaignUpdatecamp = data => put(url.CAMPAIGN_UPDATECAMP, data)
export const autoSendCamp = data => post(url.AUTO_SEND_CAMP, data)
export const cancelAutoSendCamp = data =>
  get(url.CANCEL_AUTO_SEND_CAMP + data.id + "/" + data.exist)
export const getCampaignUploadsHistroy = data =>
  get(url.GET_CAMPAIGN_UPLOADS_HISTROY + data)
export const getScheduler = data => get(url.GET_CAMPAIGN_GETSCHEDULE + data)
export const createScheduler = data => post(url.UPDATE_SCHEDULER, data)
export const updateScheduler = data =>
  put(url.UPDATE_SCHEDULER + data.id, data.data)
export const cancelSchedule = data => get(url.CANCEL_SCHEDULE + data.id)
export const pauseSchedule = data => get(url.PAUSE_SCHEDULE + data.id)
export const startSchedule = data => get(url.START_SCHEDULE + data.id)
export const exportCampProsData = data =>
  get(url.EXPORT_CAMP_PROS_DATA + data.id + "?lead=" + data.data)
export const existsProspects = data => post(url.EXISTS_PROSPECTS, data)

//Categories
export const getCategories = () => get(url.GET_CATEGORIES)
export const editCategory = data => post(url.EDIT_CATEGORY + data.id, data)
export const deleteCategory = data => post(url.DELETE_CATEGORY + data)
export const addCategory = data => post(url.ADD_CATEGORY, data)

//Quick Replies
export const getQuickReplies = () => get(url.GET_QUICK_REPLIES)
export const editQuickReply = data => post(url.EDIT_QUICK_REPLY + data.id, data)
export const deleteQuickReply = data => post(url.DELETE_QUICK_REPLY + data)
export const addQuickReply = data => post(url.ADD_QUICK_REPLY, data)

//Templates
export const getTemplates = (query = "") => get(url.GET_TEMPLATES + query)
export const getTemplatesGroupedByCategory = (query = null) =>
  get(url.GET_TEMPLATES_GROUPED_BY_CATEGORY + (query ? `?${query}` : ""))
export const getTemplate = data => get(url.GET_TEMPLATE + data)
export const editTemplate = data => post(url.EDIT_TEMPLATE + data.id, data)
export const deleteTemplate = data => post(url.DELETE_TEMPLATE + data)
export const addTemplate = data => post(url.ADD_TEMPLATE, data)
export const manageNegativeKeywords = data =>
  post(url.MANAGE_NEGATIVE_KEYWORDS, data)
export const getNegativeKeywords = () => get(url.GET_NEGATIVE_KEYWORDS)

//Optouts
export const getOptOuts = () => get(url.GET_OPT_OUTS)
export const editOptOut = data => put(url.EDIT_OPT_OUT + data.id, data)
export const deleteOptOut = data => del(url.DELETE_OPT_OUT + data)
export const addOptOut = data => post(url.ADD_OPT_OUT, data)

//Numbers
export const getNumbers = () => get(url.GET_NUMBERS)
export const getFiltersNumbers = data => post(url.GET_FILTER_NUMBERS, data)
export const editNumber = data => put(url.EDIT_NUMBER + data.id, data)
export const deleteNumber = data => post(url.DELETE_NUMBER + data)
export const activateNumber = data => post(url.ACTIVATE_NUMBER + data)
export const deactivateNumber = data => post(url.DEACTIVATE_NUMBER + data)
export const addNumber = data => post(url.ADD_NUMBER, data)

//LeadStages
export const getLeadStages = () => get(url.GET_LEAD_STAGES)
export const deleteLeadStage = data => post(url.DELETE_LEAD_STAGE + data)
export const addLeadStage = data => post(url.ADD_LEAD_STAGE, data)

//Markets
export const getMarkets = () => get(url.GET_MARKETS)
export const getAvailableMarkets = query =>
  get(url.GET_AVAILABLE_MARKETS + query)
export const editMarket = data => put(url.EDIT_MARKET + data.id, data)
export const deleteMarket = data => post(url.DELETE_MARKET + data)
export const addMarket = data => post(url.ADD_MARKET, data)

//Settings => Push to CRM
export const getPtcrmSetting = () => get(url.GET_PTCRM_SETTING)
export const updatePtcrmSetting = data => post(url.SAVE_PTCRM_SETTING, data)
export const updatePtcrmFilter = data => post(url.SAVE_PTCRM_FILTER, data)

//Notifications
export const getNotifications = () => get(url.GET_NOTIFICATIONS)
//old-1
// export const downloadNotificationFile = data =>
//   get(url.DOWNLOAD_NOTIFICATION_FILE + data)
//new-1
export const downloadNotificationFile = async data => {
  const notificationFileUrl = url.DOWNLOAD_NOTIFICATION_FILE + data
  let res = await get(notificationFileUrl)
  return res
}

//DNC List
export const exportDncList = () => get(url.EXPORT_DNC_LIST)
export const resetDncList = () => get(url.RESET_DNC_LIST)

//Users
export const getUsers = params => get(url.USER_API_URL, { params })
export const updateUser = ({ id, user: params }) =>
  put(`${url.USER_API_URL}/${id}`, params)
export const deleteUser = id => del(`${url.USER_API_URL}/${id}`)
export const addUser = params => post(url.USER_API_URL, params)

/*let authUser = localStorage.getItem('authUser');
let token = false;
if(authUser) {
  let user = JSON.parse(authUser);
  token = user.user.token;
} else {
  token = false;
}
const config = { headers: { 'content-type': 'multipart/form-data','Authorization':token} };
export const importDncList = (data) => post(url.IMPORT_DNC_LIST,data,config)*/
export const importDncList = async data => {
  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user.user.token
  } else {
    token = false
  }
  const config = {
    headers: { "content-type": "multipart/form-data", Authorization: token },
  }
  return await axios
    .post(process.env.REACT_APP_PORT + url.IMPORT_DNC_LIST, data, config)
    .then(response => response.data)
}

//Prospects
export const getProspects = data => get(url.GET_PROSPECTS + data)
export const getNotes = data => get(url.GET_NOTES + data)
export const getProspect = data => get(url.GET_PROSPECT + data)
export const filterProspects = data => post(url.FILTER_PROSPECTS, data)
export const searchProspectsByMsg = data =>
  get(url.SEARCH_PROSPECTS_BY_MSG + data)
export const searchProspectsFor = data => get(url.SEARCH_PROSPECTS_FOR + data)
export const exportProspects = data => post(url.EXPORT_PROSPECTS, data)
export const countAllProspects = data => post(url.COUNT_ALL_PROSPECTS, data)
export const addProspect = data => post(url.ADD_PROSPECT, data)
export const deleteProspects = data => post(url.DELETE_PROSPECTS, data)
export const removeProspectsFromDnc = data =>
  put(url.REMOVE_PROSPECT_FROM_DNC, data)
export const updateProspectDNC = data =>
  put(url.UPDATE_PROSPECT_DNC + data.id, data)
export const updateProspect = data => put(url.UPDATE_PROSPECT + data.id, data)
export const updateProspectVerified = data =>
  put(url.UPDATE_PROSPECT_VERIFIED + data.id, data)
export const removeProspectVerified = data =>
  put(url.REMOVE_PROSPECT_VERIFIED + data.id, data)
export const updateProspectWrongNumber = data =>
  put(url.UPDATE_PROSPECT_WRONG_NUMBER + data.id, data)
export const removeProspectWrongNumber = data =>
  put(url.REMOVE_PROSPECT_WRONG_NUMBER + data.id, data)
export const updateProspectQualified = data =>
  put(url.UPDATE_PROSPECT_QUALIFIED + data.id, data)
export const removeProspectQualified = data =>
  put(url.REMOVE_PROSPECT_QUALIFIED + data.id, data)
export const updateProspectPriority = data =>
  put(url.UPDATE_PROSPECT_PRIORITY + data.id, data)
export const removeProspectPriority = data =>
  put(url.REMOVE_PROSPECT_PRIORITY + data.id, data)
export const pushToWebhook = data => post(url.PUSH_TO_WEBHOOK + data.id, data)
export const updateProspectLeadStage = data =>
  put(url.UPDATE_PROSPECT_LEAD_STAGE + data.id, data)
export const getCampaignsByMarket = data =>
  post(url.GET_CAMPAIGNS_BY_MARKET, data)
export const CampaignFilterProspects = data =>
  post(url.CAMPAIGN_FILTER_PROSPECTS + data.id, data)

export const uploadProspects = async data => {
  const {
    firstname,
    lastname,
    phone,
    email,
    mailing_address,
    mailing_state,
    mailing_city,
    mailing_zip,
    property_address,
    property_state,
    property_city,
    property_zip,
    custom1,
  } = data

  const formdata = new FormData()
  formdata.append("file", data.uploadfile)

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user.user.token
  } else {
    token = false
  }
  const config = {
    headers: { "content-type": "multipart/form-data", Authorization: token },
  }
  const res = await axios.post(
    process.env.REACT_APP_PORT + "/api/campaign/pullValues",
    formdata,
    config
  )

  const campaign_id = data.campaign_id
  const value = res.data.data.value
  const fileName = data.uploadfile.name
  const contact = {
    campaign_id,
    firstname,
    lastname,
    phone,
    email,
    mailing_address,
    mailing_state,
    mailing_city,
    mailing_zip,
    property_address,
    property_state,
    property_city,
    property_zip,
    fileName,
    value,
    custom1,
  }
  const head = {
    headers: {
      "x-auth-token": token,
    },
  }
  return await axios.post(
    process.env.REACT_APP_PORT + "/api/campaign/createProspects",
    contact,
    head
  )
}

export const uploadProspectsFile = async data => {
  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user.user.token
  } else {
    token = false
  }
  const config = {
    headers: { "content-type": "multipart/form-data", Authorization: token },
  }
  return await axios
    .post(process.env.REACT_APP_PORT + url.UPLOAD_PROSPECTS, data, config)
    .then(response => response.data)
}

export const addNote = data => post(url.CREATE_NOTE, data)
export const editNote = data => put(url.UPDATE_NOTE + data.id, data)
export const deleteNote = data => del(url.DELETE_NOTE + data.id, data)
export const sendConversation = data => post(url.SEND_CONVERSATION, data)

//Change Password
export const changePassword = data => post(url.CHANGE_PASSWORD, data)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtRecoverPwd,
  postJwtRecoverPwdVerify,
  postJwtProfile,
}
