import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  CHANGE_PASSWORD
} from "./actionTypes"

import {
  changePasswordSuccess, getChangePasswordError
} from "./actions"

import {changePassword} from "../../helpers/fakebackend_helper";

function* changePasswordApi({ payload: data }) {
  try {
    const response = yield call(changePassword,data)
    yield put(changePasswordSuccess('Settings updated successfully'));

  } catch (error) {
    yield put(getChangePasswordError(error))
  }
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePasswordApi)
}

function* ChangePasswordSaga() {
  yield all([fork(watchChangePassword)])
}

export default ChangePasswordSaga
