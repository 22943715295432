import React, { useEffect, useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
// import "./WrongNumbers.scss"
import classnames from "classnames"
import axios from "axios"
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import close from "../../assets/images/close.svg"
import illus from "../../assets/images/chat-illustration.svg"
import { AvField, AvForm } from "availity-reactstrap-validation"
import CSVReader from "react-csv-reader"
import SweetAlert from "react-bootstrap-sweetalert"

const DNCList = () => {
  useEffect(() => {
    getDncList()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  //=============================page 1==================================//
  const [dncList, setDncList] = useState([]) //for wrongNumbers of dnc
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState("") // for success or error response
  const [select, setSelect] = useState([]) //for selected checkbox
  const [totalCount, setTotalCount] = useState(0) //for totalCount number of dnc
  const [page, setPage] = useState(1) //for page number
  const [toShow_1, setToShow_1] = useState(0) //for limit
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedRows, setSelectedRows] = useState([])
  const [totalDnc, setTotalDnc] = useState()
  const [tableReloadKey, setTableReloadKey] = useState(Math.random())

  // new states
  const [addDncList, setAddDncList] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [selectFile, setSelectFile] = useState(false)
  const [confirmUploadSweetAlert, setConfirmUploadSweetAlert] = useState(false)
  const [fileHeaders, setFileHeaders] = useState([])
  const [formState, setFormState] = useState({})
  const [upload, setUpload] = useState([])
  const [csvData, setCsvData] = useState({})
  const [finalData, setFinalData] = useState(null)
  const [counts, setCounts] = useState({
    inValidCount: 0,
    totalCount: 0,
    validCount: 0,
  })
  // const [formData, setFormData] = useState({
  //   first_name: "first_name",
  //   last_name: "last_name",
  //   phone: "phone",
  //   dnc:"dnc"
  // })

  const formData ={
    first_name: "first_name",
    last_name: "last_name",
    phone: "phone",
    dnc:"dnc"

  }

  const getDncList = async (crrPage = page, limit = rowsPerPage) => {
    const queryParams = new URLSearchParams({ page: crrPage, limit })
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/dnc/pullDncs?" + queryParams,
        config
      )
      if (response.data) {
        setIsLoading(false)
        setDncList(response.data.data.dncs)
        setTotalCount(Math.ceil(+response.data.data.totalCount / limit))
        // setTotalCount(response.data.data.totalCount)
        setTotalDnc(response.data.data.totalCount)
        setTableReloadKey(Math.random())
      }
    } catch (error) {
      setDncList([])
      notify("error", error.data.message)
      setIsLoading(false)
    }
  }

  const handleRowSelection = state => {
    setSelectedRows(state.selectedRows)
  }

  //delete contact by id
  const deleteFromDnc = async id => {
    if (!id) return

    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT + `/api/dnc/deleteDnc/${id}`,
        config
      )

      if (response.status == 200) {
        notify("success", "DNC deleted successfully ")
        setLoading_Del(false)
        getDncList()
      }
    } catch (err) {
      notify("error", err)
    }
  }

  //delete selected contact
  const [loading_Del, setLoading_Del] = useState(false)
  const delete_selected_contact = async () => {
    setLoading_Del(true)
    const data = {
      ids: selectedRows,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dnc/deleteDncs",
        data,
        config
      )

      if (response.status == 200) {
        notify("success", "DNC's deleted successfully")
        // setResponse("Numbers deleted successfully from DNC")
        getDncList()
        setSelectedRows([])
        setLoading_Del(false)
      }
    } catch (err) {
      notify("error", err)
    }
  }

  const downloadDncCsv = (data, filename) => {
    const csvData = convertToCSVTemplate(data)
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const convertToCSVTemplate = data => {
    const csv = data.map(item => item.key).join(",")
    return `${csv}\n`
  }

  //download csv file
  const downloadCsv = data => {
    // Define the keys to include in the CSV
    const keys = ["first_name", "last_name", "phone"]
    // Convert array of objects data to CSV format
    const csv = convertToCsv(data, keys)
    // Create a blob object with the CSV data
    const blob = new Blob([csv], { type: "text/csv" })
    // Create a temporary <a> tag with the download attribute
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.setAttribute("download", "data.csv")
    // Append the <a> tag to the body and click it
    document.body.appendChild(link)
    link.click()
    // Remove the <a> tag from the body
    document.body.removeChild(link)
  }

  const convertToCsv = (data, keys) => {
    // Define the headers for the CSV file
    const headers = keys.join(",")
    // Map over each object in the data array and convert it to a CSV row
    const rows = data.map(obj => {
      // Extract the values for the desired keys
      const values = keys.map(key => obj[key])
      // Convert the values to a CSV row
      return values.join(",")
    })
    // Combine the headers and rows into a single CSV string
    return `${headers}\n${rows.join("\n")}`
  }

  // Data table columns and data
  const wrongNumberColumns = [
    {
      name: "First Name",
      selector: row => row.firstName,
    },
    {
      name: "Last Name",
      selector: row => row.lastName,
    },

    {
      name: "Phone Number",
      selector: row => row.phoneNumber,
    },
    {
      name: "Action",
      selector: row => row.action,
    },
  ]

  const wrongNumberData = dncList?.map((item, index) => ({
    id: item?._id,
    firstName: item?.first_name,
    lastName: item?.last_name,

    phoneNumber: item?.phone,
    action: (
      <Button
        onClick={() => deleteFromDnc(item?._id)}
        className={"has-icon mx-1"}
        color={"outline-danger"}
      >
        <i className="fas fa-trash-alt"></i>
      </Button>
    ),
  }))

  const updatedFormData = [
    {
      key: "first_name",
      defaultValue: "FirstName",
      label: "First Name",
      // required: true,
    },
    {
      key: "last_name",
      defaultValue: "LastName",
      label: "Last Name",
      // required: true,
    },
    {
      key: "dnc",
      defaultValue: "dnc",
      label: "DNC",
      required: true,
    },

    {
      key: "phone",
      defaultValue: "Phone",
      label: "Phone",
      required: true,
    },
  ]

  const handleDownloadCSV = () => {
    downloadDncCsv(updatedFormData, "defaultDNC.csv")
  }

  const handleFileUpload = (data, fileInfo) => {
    let csvHeaders = data[0]
    const headerIndexData = {}
    csvHeaders.forEach((item, index) => {
      headerIndexData[item] = index
    })

    const csvDataWithoutHeaders = data.slice(1)

    if (
      csvDataWithoutHeaders?.every(value => {
        return (
          value === "" ||
          (Array.isArray(value) && value.length === 0) ||
          value.every(value => {
            return value === ""
          })
        )
      })
    ) {
      csvHeaders = [""]
    }

    setFileHeaders(csvHeaders)
    setCsvData({
      headerIndexData: headerIndexData,
      data: csvDataWithoutHeaders,
      fileName: fileInfo.name,
    })

    let formObj = {}
    updatedFormData.forEach(item => {
      formObj[item.key] = getValidValue(item.defaultValue)
    })
    setFormState(prevFormState => ({
      ...prevFormState,
      ...formObj,
    }))
  }

  const getValidValue = val => {
    const cleanedQuery = val.replace(/[_-\s]/g, "").toLowerCase()

    for (let i = 0; i < fileHeaders.length; i++) {
      const cleanedItem = fileHeaders[i].replace(/[_-\s]/g, "").toLowerCase()
      if (cleanedQuery === cleanedItem) {
        return fileHeaders[i]
      }
    }

    return undefined
  }

  const validatePhoneNumber = phone => {
    const cleanPhone = phone.replace(/\D/g, "")

    if (cleanPhone.length === 11 && cleanPhone.startsWith("1")) {
      return `+${cleanPhone}`
    } else if (cleanPhone.length > 10 || cleanPhone.length < 10) {
      return false
    } else if (cleanPhone.length === 10) {
      return `+1${cleanPhone}`
    }
  }

  const dncSubmitHandler = async (event, values) => {
    const data = []
    const validatedData = []
    const errorRows = []
    const compulsaryKeys = ["dnc", "phone" ]

    csvData?.data?.forEach((item, index) => {
      const prospect = {}
      const keys = Object.keys(formData)
      keys.forEach(key => {
        if (item[csvData.headerIndexData[values[key]]])
          prospect[key] = item[csvData.headerIndexData[values[key]]]
      })
      let isValid = true
      for (let i = 0; i < compulsaryKeys.length; i++) {
        const ck = compulsaryKeys[i]
        if (!prospect[ck]) {
          isValid = false
          break
        }
      }
      if (isValid) {
        data.push(prospect)
      } else {
        errorRows.push(index + 2)
      }
    })

    data.forEach((item, index) => {
      const validPhone = validatePhoneNumber(item.phone)
      const isDuplicate = validatedData.some(dup => dup.phone === validPhone)
      if (isDuplicate) {
        errorRows.push(index + 2)
      } else {
        if (validPhone) {
          validatedData.push({ ...item, phone: validPhone })
        } else {
          errorRows.push(index + 2)
        }
      }
    })

    const payload = {
      data: validatedData,
    }

   
    if (errorRows.length) {
      setConfirmUploadSweetAlert(true)
      setCounts({
        inValidCount: errorRows.length,
        totalCount: csvData?.data.length || 0,
        validCount: validatedData.length,
        inValidEntriesIndex: errorRows.sort((a, b) => a - b),
      })
      setFinalData(payload)
    } else {
      handleCreateBulkDnc(payload)
      setCounts({
        inValidCount: errorRows.length,
        totalCount: csvData?.data.length || 0,
        validCount: validatedData.length,
        inValidEntriesIndex: errorRows.sort((a, b) => a - b),
      })
    }
  }

  const convertToCsvUploadedFile = (data, keys) => {
    const headers = keys.join(",")
    const rows = data.map(obj => {
      const values = keys.map(key => obj[key])
      return values.join(",")
    })
    return `${headers}\n${rows.join("\n")}`
  }

  const handleCreateBulkDnc = async payload => {
    const csv = convertToCsvUploadedFile(
      payload.data,
      Object.keys(payload.data[0])
    )
    const formData = new FormData()
    const csvBlob = new Blob([csv], { type: "text/csv" })
    formData.append("csvFile", csvBlob, "dnc.csv")
   
    let formData1 = {
      data: JSON.stringify(payload?.data),
    }

    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }

 

    setConfirmUploadSweetAlert(false)
    setIsLoading(false)

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dnc/importByCsv",
        formData,
        config
      )

      if (response.data) {
        setIsLoading(false)
        setAddDncList(false)
        setConfirmUploadSweetAlert(false)
        setFileHeaders([])
        getDncList()

        notify("success", "DNC's are imported successfully")
      }
    } catch (err) {
      setIsLoading(false)
      setAddDncList(false)
      setConfirmUploadSweetAlert(false)

      setFileHeaders([])
      notify("error", err?.response?.data?.error, err?.data?.message)
    }
  }

  const handleCreateDnc = async (e, value) => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dnc/createDnc",
        value,
        config
      )

      if (response.data) {
        setIsLoading(false)
        getDncList()
        setAddModal(false)
        notify("success", "DNC created successfully")
      }
    } catch (err) {
      setIsLoading(false)
      notify("error", err?.response?.data?.error)
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={5}>
              <div className="page-title-box">
                <h4 className="font-size-18" style={{ display: "inline" }}>
                  {totalDnc ? `${totalDnc}` : null} DNCs
                </h4>

                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Restricted Contacts</BreadcrumbItem>
                  <BreadcrumbItem active>DNCs </BreadcrumbItem>
                </ol>
              </div>
            </Col>
            <Col xs={7}>
              <div className="float-end">
                <div className={"button-items d-flex st flex-wrap gap-2"}>
                  {selectedRows && selectedRows.length ? (
                    <button
                      onClick={() => delete_selected_contact()}
                      disabled={loading_Del}
                      className="main-btn error-btn"
                      role="button"
                    >
                      <i className="fas fa-trash-alt text-white"></i>

                      <span>Delete ({selectedRows.length})</span>
                    </button>
                  ) : null}

                  <button
                    className="main-btn border-btn success-btn"
                    onClick={() => {
                      handleDownloadCSV()
                    }}
                    type="button"
                  >
                    <i className="fas fa-download text-indigo"></i>
                    <span>Default Template</span>
                  </button>

                  <button
                    className="main-btn  border-btn"
                    onClick={() => {
                      setAddDncList(true)
                    }}
                  >
                    <img src={plusPurple} alt="" />
                    <span>Import File</span>
                  </button>

                  {/* <Link className="main-btn" to="/add-Restricted-Contact">
                    <img src={plus} alt="" />
                    <span>Add Contact 123</span>
                  </Link> */}

                  <button
                    onClick={() => {
                      setAddModal(true)
                    }}
                    className="main-btn"
                  >
                    <img src={plus} alt="" />
                    <span>Add Contact</span>
                  </button>
                  <button
                    onClick={() => downloadCsv(dncList)}
                    className="main-btn success-btn"
                    role="button"
                  >
                    <i className="fas fa-download me-2 text-white"></i>
                    <span>Export CSV</span>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {response && (
              <Alert
                color={
                  response == "Something went wrong" ? "danger" : "success"
                }
              >
                {response}
              </Alert>
            )}

            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <DataTable
              key={tableReloadKey}
              columns={wrongNumberColumns}
              data={wrongNumberData}
              fixedHeader
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={totalCount}
              paginationPerPage={rowsPerPage}
              onChangeRowsPerPage={rows => {
                setRowsPerPage(rows)
                setPage(1)
                getDncList(1, rows)
                setTableReloadKey(Math.random())
              }}
              onChangePage={page => {
                setPage(page)
                getDncList(page)
              }}
              className="c-table"
              selectableRows
              onSelectedRowsChange={state => {
                const selectedIds = state.selectedRows.map(row => row.id)
                setSelectedRows(selectedIds)
              }}
              keyField={"id"}
            />
          </div>
        </Container>
      </div>

      {confirmUploadSweetAlert ? (
        <SweetAlert
          title="Are you sure?"
          info
          showCancel
          confirmBtnText={`Continue with ${
            counts.validCount > 1
              ? counts.validCount + " Entries"
              : counts.validCount + " Entry"
          } `}
          confirmBtnDisabled={!counts.validCount}
          cancelBtnText="Back"
          confirmBtnBsStyle={!counts.validCount ? "secondary" : "primary"}
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            if (counts.validCount) {
              handleCreateBulkDnc(finalData)
            }
          }}
          onCancel={() => {
            setConfirmUploadSweetAlert(false)
          }}
        >
          <div className="d-flex flex-column align-items-start gap-1 p-2 border-bottom mb-2">
            <div>
              <p className="mb-1 ">
                Total DNC's :
                <strong> {counts.totalCount} </strong> || Valid Entry:
                <strong> {counts.validCount} </strong> || Invalid Entry:
                <strong> {counts.inValidCount}</strong>
              </p>
              <p className="mb-1 ">
                Invalid Rows Index :{" "}
                <strong>{counts.inValidEntriesIndex?.join(" , ")}</strong>
              </p>
            </div>
          </div>
          Do you want to continue with Valid Entries ? You aren't able to Upload
          the Invalid Entries
          {/* You want to remove drip? You won't be able to revert this! */}
        </SweetAlert>
      ) : null}

      <Modal
        isOpen={addDncList}
        toggle={() => {
          setAddDncList(!addDncList)
          setFileHeaders([])
        }}
        className="c-modal modal-lg"
        centered={true}
      >
        <div className="modal-body">
          <h5 className="modal-title mt-0 mb-4 ">Add DNC</h5>
          <button
            type="button"
            onClick={() => {
              setAddDncList(!addDncList)
              setFileHeaders([])
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          <CardBody>
            {selectFile ? (
              <Alert color="danger">{"Please select file"}</Alert>
            ) : null}

            <>
              <AvForm className="needs-validation">
                <Row className={"px-2"}>
                  <Col md={12}>
                    <div className="d-flex justify-content-start align-items-center gap-2  ">
                      <Label htmlFor="fileUploader" className="mb-0">
                        Upload file{" "}
                      </Label>
                      <CSVReader
                        onFileLoaded={handleFileUpload}
                        id="fileUploader"
                      />
                    </div>
                  </Col>
                </Row>
              </AvForm>
              {fileHeaders && fileHeaders.length > 1 ? (
                <AvForm
                  onValidSubmit={(e, v) => dncSubmitHandler(e, v)} // uncomment it
                  className="needs-validation"
                >
                  <div>
                    <Row className="py-1 px-1">
                      {updatedFormData.map((item, index) => (
                        <Col key={index} md={6} xl={6} sm={12} xs={12}>
                          <Label>{item.label}</Label>
                          <AvField
                            type="select"
                            onChange={e => {
                              setFormState(prevState => ({
                                ...prevState.formState,
                                [item.key]: getValidValue(e.target.value),
                              }))
                            }}
                            validate={{
                              ...(item.required
                                ? {
                                    required: {
                                      value: true,
                                      errorMessage: `Please select ${item.label}`,
                                      pattern: {
                                        value: /^[^\s]+$/,
                                        errorMessage:
                                          "Value cannot be only whitespace",
                                      },
                                    },
                                  }
                                : {}),
                            }}
                            name={item.key}
                            value={getValidValue(item.defaultValue)}
                          >
                            <option value={null} key={index}>
                              Select {item.label}
                            </option>
                            {fileHeaders.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </AvField>
                        </Col>
                      ))}
                      <Col md={12}>
                        <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                          <Button
                            className="main-btn secondary-btn"
                            onClick={() => {
                              setAddDncList(!addDncList)
                              setUpload([])
                              setFileHeaders([])
                            }}
                            role="button"
                          >
                            <span>Cancel</span>
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="main-btn"
                            // disabled={
                            //   !(
                            //     formState.first_name&&
                            //     formState.last_name&&
                            //     formState.phone
                            //   )
                            // }
                          >
                            <span>Submit</span>
                          </Button>{" "}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              ) : (
                <div className="chat-illus illus-empty-state">
                  {fileHeaders.length == 1 ? (
                    <>
                      <img src={illus} alt="" />
                      <h3 className="text-danger">Please Upload Valid File</h3>
                    </>
                  ) : (
                    <>
                      {" "}
                      <img src={illus} alt="" />
                      <h3>Please Upload File</h3>
                      <Link
                        to="#"
                        className={"text-indigo text-decoration-underline"}
                        onClick={() => {
                          handleDownloadCSV()
                        }}
                      >
                        Download Default Template
                      </Link>
                    </>
                  )}
                </div>
              )}
            </>
          </CardBody>
        </div>
      </Modal>

      <Modal
        isOpen={addModal}
        toggle={() => {
          setAddModal(!addModal)
        }}
        className="c-modal"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">Add DNC</h5>
          <button
            type="button"
            onClick={() => {
              setAddModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleCreateDnc(e, v)}
              className="needs-validation"
            >
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      name="first_name"
                      placeholder="First Name"
                      type="text"
                      errorMessage="Enter First Name"
                      className="form-control cc-form-control"
                      validate={{
                        
                        pattern: {
                          value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                          errorMessage: "Enter valid First name",
                        },
                      }}
                      id="validationCustom01"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      name="last_name"
                      placeholder="Last Name"
                      type="text"
                      errorMessage="Enter Last Name"
                      className="form-control cc-form-control"
                      validate={{
                        
                        pattern: {
                          value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                          errorMessage: "Enter valid Last name",
                        },
                      }}
                      id="validationCustom01"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      name="phone"
                      placeholder="Phone Number"
                      type="text"
                      errorMessage="Enter Phone Number"
                      className="form-control cc-form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: /^[0-9]{9,10}$/,
                          errorMessage: "Enter valid Phone Number",
                        },
                      }}
                     
                      id="validationCustom01"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setAddModal(false)
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Button>
                <Button type="submit" color="primary" className="main-btn">
                  <span>Submit</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DNCList
