import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Alert,
} from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import SweetAlert from "react-bootstrap-sweetalert"
import Axios from "axios"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  const [showBackupCodesModal, setShowBackupCodesModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [backupCodes, setBackupCodes] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const authUser = localStorage.getItem("authUser")
  const token = authUser ? JSON.parse(authUser)?.user?.token : undefined

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break
      default:
        break
    }
  }

  const handleGenerateBackupCodes = async () => {
    setShowConfirmModal(false)
    setShowBackupCodesModal(true)
    setIsLoading(true)
    try {
      const response = await Axios.get(
        process.env.REACT_APP_PORT + `/api/auth/generateBackupCodes`,
        config
      )

      if (response.data) {
        setBackupCodes(response.data.backupCodes.join(" "))
        notify("success", "Backup codes generated successfully")
      } else {
        throw new Error("Failed to generate backup code")
      }
    } catch (err) {
      notify("error", err.response?.data?.error || err.message)
      setShowBackupCodesModal(false)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(backupCodes)
      .then(() => notify("success", "Backup codes copied to clipboard"))
      .catch(() => notify("error", "Failed to copy backup codes"))
  }

  return (
    <React.Fragment>
      <Toaster />
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className={"fas fa-user fa-lg text-white"}></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag={Link} to="/change-password">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Change Password")}{" "}
          </DropdownItem>
          <DropdownItem onClick={() => setShowConfirmModal(true)}>
            <i className="bx bx-lock font-size-16 align-middle me-1" />
            {props.t("Generate Backup Codes")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      {showConfirmModal && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, generate new code"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="secondary"
          onConfirm={handleGenerateBackupCodes}
          onCancel={() => setShowConfirmModal(false)}
        >
          Old backup code will not work anymore. Do you want to proceed?
        </SweetAlert>
      )}

      {showBackupCodesModal && (
        <SweetAlert
          title="Backup Codes"
          onConfirm={() => setShowBackupCodesModal(false)}
          onCancel={() => setShowBackupCodesModal(false)}
          customButtons={
            <div className="d-flex gap-3 align-items-center justify-content-end mt-3">
              <button
                className="main-btn secondary-btn"
                onClick={() => setShowBackupCodesModal(false)}
              >
                <span>Close</span>
              </button>
              <button className="main-btn" onClick={handleCopyToClipboard}>
                <i className="fas fa-copy me-2 text-white"></i>
                <span>Copy to Clipboard</span>
              </button>
            </div>
          }
          className="p-0 m-0"
        >
          {isLoading ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : (
            <div>
              <p>Here is your backup code :</p>
              <Alert
                color="light"
                className="text-primary p-3 rounded"
                style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {backupCodes}
              </Alert>
              {/* <div className="d-flex gap-3 align-items-center justify-content-end mt-3">
                <button
                  className="main-btn secondary-btn"
                  onClick={() => setShowBackupCodesModal(false)}
                >
                  <span>Close</span>
                </button>
                <button
                  className="main-btn"
                  onClick={handleCopyToClipboard}
                >
                  <i className="fas fa-copy me-2 text-white"></i>
                  <span>Copy to Clipboard</span>
                </button>
              </div> */}
            </div>
          )}
        </SweetAlert>
      )}
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
