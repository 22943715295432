import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  GET_OPT_OUTS,
  EDIT_OPT_OUT,
  ADD_OPT_OUT,
  DELETE_OPT_OUT
} from "./actionTypes"

import {
  addOptOutSuccess, deleteOptOutSuccess,
  editOptOutSuccess,
  getOptOutsError,
  getOptOutsSuccess
} from "./actions"

import {addOptOut, deleteOptOut, editOptOut, getOptOuts} from "../../helpers/fakebackend_helper";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getOptOutsApi() {
  try {
    const response = yield call(getOptOuts)
    yield put(getOptOutsSuccess(response.data));
  } catch (error) {
    yield put(getOptOutsError(error))
  }
}


function* addOptOutApi({ payload: optOut }) {
  try {
    const response = yield call(addOptOut,optOut)
    yield put(addOptOutSuccess('OptOut added successfully'));
    yield delay(800);
    const response1 = yield call(getOptOuts)
    yield put(getOptOutsSuccess(response1.data));
  } catch (error) {
    yield put(getOptOutsError(error))
  }
}

function* editOptOutApi({ payload: optOut }) {
  try {
    const response = yield call(editOptOut,optOut)
    yield put(editOptOutSuccess('OptOut updated successfully'));
    yield delay(1000);
    const response1 = yield call(getOptOuts)
    yield put(getOptOutsSuccess(response1.data));

  } catch (error) {
    yield put(getOptOutsError(error))
  }
}

function* deleteOptOutApi({ payload: optOut }) {
  try {
    const response = yield call(deleteOptOut,optOut)
    yield put(deleteOptOutSuccess('OptOut deleted successfully'));
    yield delay(1000);
    const response1 = yield call(getOptOuts)
    yield put(getOptOutsSuccess(response1.data));

  } catch (error) {
    yield put(getOptOutsError(error))
  }
}

export function* watchOptOuts() {
  yield takeEvery(GET_OPT_OUTS, getOptOutsApi)
  yield takeEvery(EDIT_OPT_OUT, editOptOutApi)
  yield takeEvery(ADD_OPT_OUT, addOptOutApi)
  yield takeEvery(DELETE_OPT_OUT, deleteOptOutApi)
}

function* OptOutsSaga() {
  yield all([fork(watchOptOuts)])
}

export default OptOutsSaga
