import {
  ADD_OPT_OUT, ADD_OPT_OUT_SUCCESS,
  API_ERROR, DELETE_OPT_OUT, DELETE_OPT_OUT_SUCCESS, EDIT_OPT_OUT, EDIT_OPT_OUT_SUCCESS,
  GET_OPT_OUTS,
  GET_OPT_OUTS_SUCCESS
} from "./actionTypes"

export const getOptOuts = () => {
  return {
    type: GET_OPT_OUTS
  }
}

export const getOptOutsSuccess = optOuts => {
  return {
    type: GET_OPT_OUTS_SUCCESS,
    payload: optOuts,
  }
}


export const getOptOutsError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}


export const editOptOut = (optOut) => {
  return {
    type: EDIT_OPT_OUT,
    payload: optOut,
  }
}

export const editOptOutSuccess = (message) => {
  return {
    type: EDIT_OPT_OUT_SUCCESS,
    payload: message,
  }
}

export const addOptOut = (optOut) => {
  return {
    type: ADD_OPT_OUT,
    payload: optOut,
  }
}

export const addOptOutSuccess = (message) => {
  return {
    type: ADD_OPT_OUT_SUCCESS,
    payload: message,
  }
}
export const deleteOptOut = (optOut) => {
  return {
    type: DELETE_OPT_OUT,
    payload: optOut,
  }
}

export const deleteOptOutSuccess = (message) => {
  return {
    type: DELETE_OPT_OUT_SUCCESS,
    payload: message,
  }
}
