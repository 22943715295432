import React from "react";
import _debounce from "lodash.debounce"
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Alert, BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col, Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Modal,
    Row
} from "reactstrap";
import {Link, Redirect, withRouter} from "react-router-dom";
import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {addProspect, getCampaigns} from "../../store/prospects/actions";


class AddProspect extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.getCampaigns();
    }

    handleValidSubmit(event, values) {
        let pdata = {
            first_name:values.first_name,
            last_name:values.last_name,
            phone:values.phone,
            // email:values.email,
            // mstate:values.mstate,
            // maddress:values.maddress,
            // mcity:values.mcity,
            // mzip:values.mzip,
            pstate:values.pstate,
            paddress:values.paddress,
            pcity:values.pcity,
            pzip:values.pzip,
            // custom:values.custom,
            campaign:values.campaign
        };
        const data = {
            data: pdata
        };
        this.props.addProspect(data);
    }


    render()
    {
        if(this.props.successMsg) {
            return <Redirect to="/prospects"></Redirect>
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Add Prospect | TextLaunchPad.com </title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Row className="align-items-center">
                            <Col xs={12}>
                                <div className="page-title-box">
                                    <h4 className="font-size-18">Prospects</h4>
                                    <ol className="breadcrumb mb-0">
                                        <BreadcrumbItem>
                                            <Link to="/">TextLaunchPad</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <Link to="/prospects">Prospects</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            Add New Prospect
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </Col>
                            <Col md={12} className={'pb-2'}>
                                <span className={'border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block'}></span>
                            </Col>
                        </Row>

                        <Row className={'justify-content-center pt-4'}>
                            <Col xl="6">
                                <Card>
                                    <CardBody>
                                        {this.props.apiError && typeof this.props.apiError === 'string' ? (
                                            <Alert color="danger" >
                                                {this.props.apiError}
                                            </Alert>
                                        ) : null}

                                        {this.props.apiError && typeof this.props.apiError === 'object' ? (
                                            (this.props.apiError.response ? (
                                                <Alert color="danger" >
                                                    {this.props.apiError.response.data.error}
                                                </Alert>
                                            ):(
                                                <Alert color="danger" >
                                                    {this.props.apiError.message}
                                                </Alert>
                                            ))
                                        ) : null}

                                        {this.props.successMsg ? (
                                            <Alert color="success" >
                                                {this.props.successMsg}
                                            </Alert>
                                        ) : null}

                                        <AvForm
                                            onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                                            className="needs-validation">
                                            <Row>
                                                <Col>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="campaign"
                                                            label="Campaign"
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            value=""
                                                        >
                                                            <option value="">Select Campaign</option>
                                                            {this.props.campaigns.length > 0 && this.props.campaigns.map(item=>(<option key={item._id} value={item._id}>{item.name}</option>)) }
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="first_name"
                                                            label="First Name"
                                                            validate={{ required: { value: true },pattern:{value: '^[A-Za-z0-9 ]+$',errorMessage:"Enter valid name"} }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="last_name"
                                                            label="Last Name"
                                                            validate={{ required: { value: true },pattern:{value: '^[A-Za-z0-9 ]+$',errorMessage:"Enter valid name"} }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="email"
                                                            name="email"
                                                            label="Email"
                                                            validate={{ required: { value: false },email: true }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="phone"
                                                            label="Phone"
                                                            validate={{ required: { value: true },pattern:{value: '^[+0-9 ]+$',errorMessage:"Enter valid phone"} }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}
                                            {/* <Row>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="maddress"
                                                            label="Mailing Address"
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="mcity"
                                                            label="Mailing City"
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}
                                            {/* <Row>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="mstate"
                                                            label="Mailing State"
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="mzip"
                                                            label="Mailing Zipcode"
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                            </Row> */}

                                            <Row>
                                            <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="phone"
                                                            label="Phone"
                                                            validate={{ required: { value: true },pattern:{value: '^[+0-9 ]+$',errorMessage:"Enter valid phone"} }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="paddress"
                                                            label="Property Address"
                                                            validate={{ required: { value: true } }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="pcity"
                                                            label="Property City"
                                                            validate={{ required: { value: true } }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="pstate"
                                                            label="Property State"
                                                            validate={{ required: { value: true } }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="pzip"
                                                            label="Property Zipcode"
                                                            validate={{ required: { value: true } }}
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col md={6}>
                                                    <FormGroup className="mb-3">
                                                        <AvField
                                                            type="text"
                                                            name="custom"
                                                            label="Custom"
                                                        >
                                                        </AvField>
                                                    </FormGroup>
                                                </Col> */}

                                                <Col md={12} className={'text-end'}>
                                                    <Link to="/prospects"
                                                          className="btn btn-dark me-2"
                                                          role="button">
                                                        Cancel
                                                    </Link>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className=""
                                                    >
                                                        Submit
                                                    </Button>{" "}
                                                </Col>
                                            </Row>


                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

AddProspect.propTypes = {
    apiError: PropTypes.any,
    campaigns: PropTypes.array,
    successMsg: PropTypes.any,
    addProspect: PropTypes.func,
    getCampaigns: PropTypes.func,
}

const mapStatetoProps = state => {
    const { apiError, successMsg,  campaigns, optOuts, addProspect, getCampaigns } = state.Prospects
    return { apiError, successMsg, campaigns, optOuts, addProspect, getCampaigns }
}

export default withRouter(
    connect(mapStatetoProps, { addProspect, getCampaigns })(AddProspect)
)
