import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  GET_MARKETS,
  EDIT_MARKET,
  ADD_MARKET,
  DELETE_MARKET, GET_AVAILABLE_MARKETS
} from "./actionTypes"

import {
  addMarketSuccess, deleteMarketSuccess,
  editMarketSuccess, getAvailableMarketsSuccess,
  getMarketsError,
  getMarketsSuccess
} from "./actions"

import {addMarket, deleteMarket, editMarket, getAvailableMarkets, getMarkets} from "../../helpers/fakebackend_helper";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getMarketsApi() {
  try {
    const response = yield call(getMarkets)
    yield put(getMarketsSuccess(response.data));
  } catch (error) {
    yield put(getMarketsError(error))
  }
}

function* getAvailableMarketsApi({ payload : code }) {
  try {
    const response = yield call(getAvailableMarkets,"?code="+code+"&limit=2")
    if(response.data[0]){
      yield put(getAvailableMarketsSuccess(response.data));
      yield put(getMarketsError(null))
    }else{
      yield put(getMarketsError("Market not found"))
    }
  } catch (error) {
    yield put(getMarketsError(error))
  }
}


function* addMarketApi({ payload: market }) {
  try {
    const response = yield call(addMarket,market)
    yield put(addMarketSuccess('Market added successfully'));
    yield delay(800);
    const response1 = yield call(getMarkets)
    yield put(getMarketsSuccess(response1.data));
  } catch (error) {
    yield put(getMarketsError(error))
  }
}

function* editMarketApi({ payload: market }) {
  try {
    const response = yield call(editMarket,market)
    yield put(editMarketSuccess('Market updated successfully'));
    yield delay(1000);
    const response1 = yield call(getMarkets)
    yield put(getMarketsSuccess(response1.data));

  } catch (error) {
    yield put(getMarketsError(error))
  }
}

function* deleteMarketApi({ payload: market }) {
  try {
    const response = yield call(deleteMarket,market)
    yield put(deleteMarketSuccess('Market deleted successfully'));
    yield delay(1000);
    const response1 = yield call(getMarkets)
    yield put(getMarketsSuccess(response1.data));

  } catch (error) {
    yield put(getMarketsError(error))
  }
}

export function* watchMarkets() {
  yield takeEvery(GET_MARKETS, getMarketsApi)
  yield takeEvery(GET_AVAILABLE_MARKETS, getAvailableMarketsApi)
  yield takeEvery(EDIT_MARKET, editMarketApi)
  yield takeEvery(ADD_MARKET, addMarketApi)
  yield takeEvery(DELETE_MARKET, deleteMarketApi)
}

function* MarketsSaga() {
  yield all([fork(watchMarkets)])
}

export default MarketsSaga
