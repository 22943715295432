import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Button,
  Input,
  BreadcrumbItem,
  CardTitle,
  CardBody,
  Modal,
  Label,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import toast, { Toaster } from "react-hot-toast"
import Select from "react-select"
import close from "../../assets/images/close.svg"

const tabs = [
  { id: 1, name: "Categories", key: "categories" },
  { id: 2, name: "Drips", key: "drips" },
  { id: 3, name: "Templates", key: "templates" },
  { id: 4, name: "Quick replies", key: "quickReplies" },
  { id: 5, name: "Prospects", key: "prospects" },
]

const actionOptions = [
  { label: "Drip", value: "isDripAdded" },
  { label: "DNC", value: "dnc" },
  { label: "Replied", value: "replied" },
  { label: "Priority", value: "isPriority" },
  { label: "Wrong Number", value: "Wrong_Number" },
  { label: "Verified", value: "verified" },
  { label: "Qualified Lead", value: "isLead" },
  { label: "Unread", value: "isUnread" },
]

const ImportExportPage = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].name)
  const [file, setFile] = useState(null)
  const [inputKey, setInputKey] = useState(Date.now())
  const [exportModal, setExportModal] = useState(false)
  const [leadStages, setLeadStages] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [selectedLeadStages, setSelectedLeadStages] = useState([])
  const [selectedActions, setSelectedActions] = useState([])
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const authUser = localStorage.getItem("authUser")
  const token = authUser ? JSON.parse(authUser)?.user?.token : undefined

  useEffect(async () => {
    if (activeTab === "Prospects") {
      await fetchLeadStages()
    }
  }, [activeTab])

  const fetchLeadStages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/api/prospect/getFilters`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      setLeadStages(response.data.leadStages)
      setCampaigns(response.data.campaigns)
    } catch (error) {
      console.error("Error fetching lead stages:", error)
    }
  }

  const generateTimestamp = () => {
    const date = new Date()

    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = String(date.getFullYear()).slice(-2)
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = String(date.getSeconds()).padStart(2, "0")

    return `${day}${month}${year}-${hours}${minutes}${seconds}`
  }

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  const handleFileChange = e => {
    setFile(e.target.files[0])
  }

  const handleImport = async () => {
    if (!file) {
      notify("error", "Please select a file to import")
      return
    }

    if (file?.name?.split(".")?.pop() !== "csv" || file?.type !== "text/csv") {
      notify("error", "File type must be CSV")
      return
    }

    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append("csvFile", file)

      const response = await axios.post(
        process.env.REACT_APP_PORT +
          `/api/import-export/importByCsv/${
            tabs.find(tab => tab.name === activeTab).key
          }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.data) {
        notify("success", response.data.message)
        resetFileInput()
      }
    } catch (err) {
      notify(
        "error",
        "Error importing file: " + (err.response?.data?.error || err.message)
      )
    } finally {
      setIsLoading(false)
    }
  }

  const resetFileInput = () => {
    setFile(null)
    setInputKey(Date.now())
  }

  const handleExport = async () => {
    if (activeTab === "Prospects") {
      setExportModal(true)
    } else {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PORT}/api/import-export/exportToCsv/${
            tabs.find(tab => tab.name === activeTab).key
          }`,
          {
            responseType: "blob",
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `${activeTab.toLowerCase()}-${generateTimestamp()}.csv`
        )
        document.body.appendChild(link)
        link.click()
        link.remove()
        notify("success", `${activeTab} exported successfully`)
      } catch (err) {
        notify(
          "error",
          "Error exporting file: " + (err.response?.data?.error || err.message)
        )
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleProspectExport = async () => {
    setIsLoading(true)
    try {
      const rowQuery = {}
      if (selectedLeadStages && selectedLeadStages?.length) {
        rowQuery.lead = selectedLeadStages.map(stage => stage.value)
      }
      if (selectedActions && selectedActions?.length) {
        rowQuery.actions = selectedActions.map(action => action.value)
      }
      if (selectedCampaigns && selectedCampaigns?.length) {
        rowQuery.campaign = selectedCampaigns.map(campaign => campaign.value)
      }
      const query = new URLSearchParams({
        ...rowQuery,
      }).toString()

      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/api/import-export/exportToCsv/prospects?${query}`,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `prospects-${generateTimestamp()}.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      notify("success", "Prospects exported successfully")
    } catch (err) {
      notify(
        "error",
        "Error exporting prospects: " +
          (err.response?.data?.error || err.message)
      )
    } finally {
      setExportModal(false)
      setSelectedLeadStages([])
      setSelectedActions([])
      setSelectedCampaigns([])
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Import-Export | TextLaunchPad.com </title>
        </MetaTags>

        {isLoading ? (
          <div className="spinner-wr">
            <Spinner style={{ color: "#5932ea" }} />
          </div>
        ) : null}

        <Container fluid>
          <div className="mb-2">
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    Import - Export
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/#">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Import-Export</BreadcrumbItem>
                  </ol>
                </div>
              </Col>

              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            <Row>
              <Col lg={12} className="mb-2">
                <div className="c-card">
                  <Nav tabs className="nav-tabs-custom cc-tabs">
                    {tabs.map(tab => (
                      <NavItem key={tab.id}>
                        <Button
                          className={
                            activeTab === tab.name
                              ? "nav-link active text-success"
                              : "nav-link "
                          }
                          onClick={() => {
                            setActiveTab(tab.name)
                          }}
                        >
                          <span
                            className="d-sm-block"
                            style={{
                              color: activeTab === tab.name ? "white" : "black",
                            }}
                          >
                            <strong>{tab.name}</strong>
                          </span>
                        </Button>
                      </NavItem>
                    ))}
                  </Nav>
                </div>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix mt-2">
            <Row className="g-3">
              <Col md={6}>
                <div className="c-card h-100">
                  <CardBody className="d-flex flex-column">
                    <CardTitle tag="h4" className="mb-1">
                      Import {activeTab}
                    </CardTitle>
                    <div className="text-muted">
                      Upload file that has been exported from the system only!
                    </div>
                    <div className="d-flex flex-row align-items-center mt-3 gap-3 justify-content-between">
                      <div className="c-card">
                        <Input
                          key={inputKey}
                          type="file"
                          onChange={handleFileChange}
                          accept=".csv"
                          className="mb-3"
                          style={{ cursor: "pointer", maxWidth: "200px" }}
                        />
                      </div>
                      <Button
                        onClick={handleImport}
                        className="d-flex align-items-center main-btn justify-content-center mt-auto"
                      >
                        <i className="fas fa-upload me-2"></i>
                        <span>Import CSV</span>
                      </Button>
                    </div>
                  </CardBody>
                </div>
              </Col>
              <Col md={6}>
                <div className="c-card h-100">
                  <CardBody className="d-flex flex-column justify-content-between h-100">
                    <div>
                      <CardTitle tag="h4" className="mb-3">
                        Export {activeTab}
                      </CardTitle>
                      <div className="text-muted">
                        Export the file to backup existing data and restore it
                        using the exported file!
                      </div>
                    </div>
                    <div className="d-flex mt-4">
                      <Button
                        onClick={handleExport}
                        className="d-flex align-items-center main-btn success-btn justify-content-center mt-auto"
                      >
                        <i className="fas fa-download me-2"></i>
                        <span>Export CSV</span>
                      </Button>
                    </div>
                  </CardBody>
                </div>
              </Col>
            </Row>
          </div>

          <TabContent activeTab={activeTab}>
            {tabs.map(tab => (
              <TabPane tabId={tab.id} key={tab.id}>
                <Row className="mt-3">
                  <Col sm="6">
                    <h4>Import {tab.name}</h4>
                    <button
                      className="main-btn"
                      type="button"
                      onClick={handleImport}
                      role="button"
                    >
                      <i className="fas fa-upload me-2 text-white"></i>
                      <span>Import CSV</span>
                    </button>
                  </Col>
                  <Col sm="6">
                    <h4>Export {tab.name}</h4>
                    <button
                      className="main-btn success-btn"
                      role="button"
                      onClick={handleExport}
                    >
                      <i className="fas fa-download me-2 text-white"></i>
                      <span>Export to CSV</span>
                    </button>
                  </Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
          <Modal
            isOpen={exportModal}
            toggle={() => {
              setExportModal(false)
              setSelectedLeadStages([])
              setSelectedActions([])
              setSelectedCampaigns([])
            }}
            centered
            className="c-modal"
          >
            <div className="modal-body p-4 position-relative">
              <h5 className="modal-title mb-4">Export Prospects</h5>
              <button
                type="button"
                onClick={() => {
                  setExportModal(false)
                  setSelectedLeadStages([])
                  setSelectedActions([])
                  setSelectedCampaigns([])
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={close} alt="" />
              </button>

              <div className="mb-3">
                <Label htmlFor="leadStages" className="mb-2">
                  Lead Stages
                </Label>
                <Select
                  isMulti
                  name="leadStages"
                  options={leadStages.map(
                    ({ lead_Stage: label, _id: value }) => ({ label, value })
                  )}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selected => setSelectedLeadStages(selected)}
                  styles={{
                    control: base => ({
                      ...base,
                      minHeight: "40px",
                    }),
                    valueContainer: base => ({
                      ...base,
                      padding: "0 8px",
                    }),
                  }}
                  placeholder="Select Lead Stages"
                  noOptionsMessage={() => "No options available"}
                />
              </div>

              <div className="mb-3">
                <Label htmlFor="actions" className="mb-2">
                  Actions
                </Label>
                <Select
                  isMulti
                  name="actions"
                  options={actionOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selected => setSelectedActions(selected)}
                  styles={{
                    control: base => ({
                      ...base,
                      minHeight: "40px",
                    }),
                    valueContainer: base => ({
                      ...base,
                      padding: "0 8px",
                    }),
                  }}
                  placeholder="Select Actions"
                  noOptionsMessage={() => "No options available"}
                />
              </div>

              <div className="mb-4">
                <Label htmlFor="campaigns" className="mb-2">
                  Campaigns
                </Label>
                <Select
                  isMulti
                  name="campaigns"
                  options={campaigns.map(({ name: label, _id: value }) => ({
                    label,
                    value,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selected => setSelectedCampaigns(selected)}
                  styles={{
                    control: base => ({
                      ...base,
                      minHeight: "40px",
                    }),
                    valueContainer: base => ({
                      ...base,
                      padding: "0 8px",
                    }),
                  }}
                  placeholder="Select Campaigns"
                  noOptionsMessage={() => "No options available"}
                />
              </div>

              <div className="d-flex justify-content-end gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setExportModal(false)
                    setSelectedLeadStages([])
                    setSelectedActions([])
                    setSelectedCampaigns([])
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleProspectExport}
                  color="primary"
                  className="main-btn"
                >
                  Export
                </Button>
              </div>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ImportExportPage
