import React, { useState } from "react"
import MetaTags from "react-meta-tags"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  BreadcrumbItem,
  Progress,
  Button,
  Spinner,
  Collapse,
} from "reactstrap"
import drop from "../../assets/images/drop.svg"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
  editTemplate,
  getTemplates,
  deleteTemplate,
  getTemplatesGroupedByCategory,
} from "../../store/templates/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
import plus from "../../assets/images/white-plus.svg"
import toast, { Toaster } from "react-hot-toast"

class Templates extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirm_alert: false,
      template_name: null,
      template_id: null,
      template_delay: null,
      modal_center: false,
      totalCount: 0,
      rowsPerPage: 10,
      page: 1,
      expandedTemplateId: null,
      tableReloadKey: Math.random(),
      templates: [],
      showStats: true,
    }
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_center() {
    this.removeBodyCss()
    this.setState({
      modal_center: !this.state.modal_center,
    })
  }
  confirmText() {
    return this.state.template_name
  }

  editTemplate(name, id, delay) {
    this.setState({
      modal_center: true,
      template_name: name,
      template_id: id,
      template_delay: delay,
    })
  }

  deleteMe(id) {
    this.setState({
      template_id: id,
      confirm_alert: true,
    })
  }

  componentDidMount() {
    this.props.getTemplatesGroupedByCategory({
      page: this.state.page,
      limit: this.state.rowsPerPage,
    })
  }

  componentDidUpdate(prevProps) {
    if (
      !!(
        !prevProps.groupedTemplates?.totalCategoriesCount &&
        this.props.groupedTemplates?.totalCategoriesCount
      )
    ) {
      this.setState({
        totalCount: this.props?.groupedTemplates?.totalCategoriesCount,
      })
    }
    if (
      !!(
        !prevProps.groupedTemplates?.groupedTemplates?.length &&
        this.props.groupedTemplates?.groupedTemplates?.length
      )
    ) {
      this.setState({
        templates: this.props?.groupedTemplates?.groupedTemplates,
      })
    }
    if (
      prevProps.groupedTemplates?.totalTemplatesCount !==
      this.props.groupedTemplates?.totalTemplatesCount
    ) {
      this.setState({
        templates: this.props?.groupedTemplates?.groupedTemplates,
        totalCount: this.props?.groupedTemplates?.totalCategoriesCount,
      })
    }

    if (!prevProps.successMsg && this.props.successMsg) {
      this.notify("success", this.props.successMsg)
    } else if (
      this.props.apiError &&
      typeof this.props.apiError === "string" &&
      !prevProps.apiError
    ) {
      this.notify("error", this.props.apiError)
    } else if (
      !prevProps.apiError &&
      this.props.apiError &&
      typeof this.props.apiError === "object"
    ) {
      if (this.props.apiError.response) {
        this.notify("error", this.props.apiError.response.data.error)
      } else {
        this.notify("error", this.props.apiError.message)
      }
    }
  }

  handleValidSubmit(event, values) {
    this.setState({
      modal_center: false,
    })

    this.props.editTemplate({
      id: this.state.template_id,
      name: values.template_name,
      delay: values.template_delay,
    })
  }

  notify(type, text) {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        toast.error(text)
        break

      default:
        break
    }
  }

  getTemplateStatusColor(status) {
    switch (status) {
      case "No Data":
        return "text-body"
      case "Healthy":
        return "text-success"
      case "Warning":
        return "text-warning"
      case "Critical":
        return "text-danger"

      default:
        break
    }
  }

  render() {
    // data table columns
    const templateColumns = [
      {
        name: "Category",
        selector: row => row.category,
        maxWidth: "150px",
        sortable: true,
        id: "category",
      },
      {
        name: "Message",
        selector: row => row.name,
        minWidth: "620px",
      },
    ]
    const templateStatsColumns = [
      {
        name: "Category",
        selector: row => row.category,
        maxWidth: "150px",
        sortable: true,
        id: "category",
      },
      {
        name: "Name",
        selector: row => row.name,
        maxWidth: "120px",
      },
      {
        name: "Message",
        selector: row => row.message,
        // maxWidth: "300px",
        grow: 2,
      },
      {
        name: "Health",
        selector: row => row.health,
        maxWidth: "100px",
        sortable: true,
      },
      {
        name: "Total Sent",
        selector: row => row.totalSent,
        maxWidth: "120px",
        sortable: true,
      },
      {
        name: "Delivered",
        selector: row => row.deliveredCount,
        maxWidth: "120px",
        sortable: true,
      },
      {
        name: "Undelivered",
        selector: row => row.undeliveredCount,
        maxWidth: "125px",
        sortable: true,
      },
      {
        name: "Blocked",
        selector: row => row.blockedCount,
        maxWidth: "100px",
        sortable: true,
      },
      {
        name: "Received",
        selector: row => row.receivedCount,
        maxWidth: "120px",
        sortable: true,
      },
      {
        name: "Actions",
        selector: row => row.actions,
        maxWidth: "150px",
      },
    ]

    const templateData = this.state.templates?.map((item, index) => ({
      id: item?._id,
      name: (
        <>
          {/* <p>Message 1</p> */}

          <div className="template-mssg-wr">
            <div className="w-100 d-flex align-items-center gap-5 template-row">
              <div>
                <div className="template-mssgs mb-2">
                  <span>
                    <i>{item.templates[0]?.name}</i>
                  </span>
                  <p>{item.templates[0]?.message}</p>
                  <span className="health text-center">
                    <i
                      className={`${this.getTemplateStatusColor(
                        item.templates[0]?.health
                      )}`}
                    >
                      {item.templates[0]?.health}
                    </i>
                  </span>
                </div>
                {this.state.expandedTemplateId !== item.category?._id && (
                  <span
                    className="show-more-temp"
                    onClick={() => {
                      this.setState({
                        expandedTemplateId: item.category?._id,
                      })
                    }}
                  >
                    +{item.templates?.length - 1} more
                  </span>
                )}
              </div>
              <div>
                <div className="d-flex align-items-center gap-2 justify-content-end template-btn-grp">
                  {item.templates && item.templates?.length > 1 ? (
                    <p
                      className="more-messages-ind"
                      onClick={() => {
                        if (
                          item.category?._id == this.state.expandedTemplateId
                        ) {
                          this.setState({ expandedTemplateId: null })
                        } else {
                          this.setState({
                            expandedTemplateId: item.category?._id,
                          })
                        }
                      }}
                    >
                      Show{" "}
                      {item.category?._id == this.state.expandedTemplateId
                        ? "Less"
                        : "More"}
                      {item.category?._id == this.state.expandedTemplateId ? (
                        <img
                          src={drop}
                          alt=""
                          style={{ transform: "rotate(180deg)" }}
                        />
                      ) : (
                        <img src={drop} alt="" />
                      )}
                    </p>
                  ) : null}
                  <Link
                    to={"/templates/edit/" + item.templates[0]?._id}
                    className={"has-icon btn btn-outline-primary me-2"}
                  >
                    <i className="fas fa-edit"></i>
                  </Link>
                  <Button
                    className={"has-icon"}
                    color={"outline-danger"}
                    onClick={() => this.deleteMe(item.templates[0]?._id)}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                </div>
              </div>
            </div>
            {item.templates && item.templates?.length > 1 ? (
              <Collapse
                isOpen={item.category?._id == this.state.expandedTemplateId}
              >
                {item.templates?.map((template, index) => {
                  if (index > 0) {
                    return (
                      <div
                        className="w-100 d-flex align-items-center gap-5 template-row"
                        key={index}
                      >
                        <div class="temp-msg-container">
                          <div className="template-mssgs">
                            <span>
                              <i>{template.name}</i>
                            </span>
                            <p className="w-100">{template.message}</p>
                            <span className="health text-center">
                              <i
                                className={`${this.getTemplateStatusColor(
                                  item.templates[0]?.health
                                )}`}
                              >
                                {template?.health}
                              </i>
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex align-items-center gap-2 justify-content-end template-btn-grp">
                            <Link
                              to={"/templates/edit/" + template._id}
                              className={
                                "has-icon btn btn-outline-primary me-2"
                              }
                            >
                              <i className="fas fa-edit"></i>
                            </Link>
                            <Button
                              className={"has-icon"}
                              color={"outline-danger"}
                              onClick={() => this.deleteMe(template._id)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return null
                })}
                {/* <div className="w-100 d-flex align-items-center gap-2 template-row">
                  <Col md={8}>
                    <div className="template-mssgs">
                      <p>Message 2</p>
                    </div>
                  </Col>
                  <Col md={2} className="">
                    <Link
                      to={"/templates/edit/" + item._id}
                      className={"has-icon btn btn-outline-primary me-2"}
                    >
                      <i className="fas fa-edit"></i>
                    </Link>
                  </Col>
                  <Col md={2}>
                    <Button
                      className={"has-icon"}
                      color={"outline-danger"}
                      onClick={() => this.deleteMe(item._id)}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </Button>
                  </Col>
                </div>
                <div className="w-100 d-flex align-items-center gap-2 template-row">
                  <Col md={8}>
                    <div className="template-mssgs">
                      <p>Message 2</p>
                    </div>
                  </Col>
                  <Col md={2} className="">
                    <Link
                      to={"/templates/edit/" + item._id}
                      className={"has-icon btn btn-outline-primary me-2"}
                    >
                      <i className="fas fa-edit"></i>
                    </Link>
                  </Col>
                  <Col md={2}>
                    <Button
                      className={"has-icon"}
                      color={"outline-danger"}
                      onClick={() => this.deleteMe(item._id)}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </Button>
                  </Col>
                </div> */}
              </Collapse>
            ) : null}
          </div>
        </>
      ),
      category: item.category && item.category.name,
      edit: (
        <Link
          to={"/templates/edit/" + item._id}
          className={"has-icon btn btn-outline-primary me-2"}
        >
          <i className="fas fa-edit"></i>
        </Link>
      ),
      delete: (
        <Button
          className={"has-icon"}
          color={"outline-danger"}
          onClick={() => this.deleteMe(item._id)}
        >
          <i className="fas fa-trash-alt"></i>
        </Button>
      ),
    }))

    const templateStatsData = this.state.templates
      ?.flatMap(category =>
        category.templates.map(template => ({
          ...template,
          category: category.category,
        }))
      )
      ?.map(item => {
        return {
          ...item,
          id: item?._id,
          name: item?.name,
          health: (
            <span className={this.getTemplateStatusColor(item.health)}>
              {item.health}
            </span>
          ),
          category: item.category && item.category.name,
          message: <span className="reminder-table-note">{item.message}</span>,
          actions: (
            <>
              <Link
                to={"/templates/edit/" + item._id}
                className={"has-icon btn btn-outline-primary me-2"}
              >
                <i className="fas fa-edit"></i>
              </Link>
              <Button
                className={"has-icon"}
                color={"outline-danger"}
                onClick={() => this.deleteMe(item._id)}
              >
                <i className="fas fa-trash-alt"></i>
              </Button>
            </>
          ),
        }
      })

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Templates | TextLaunchPad.com </title>
          </MetaTags>
          <div className="container-fluid">
            <Toaster />

            <Row className="align-items-center">
              <Col xs={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    {this.props?.groupedTemplates?.totalTemplatesCount
                      ? this.props?.groupedTemplates?.totalTemplatesCount
                      : null}{" "}
                    Templates
                  </h4>
                  {/* <span
                    style={{
                      display: "inline",
                      color: "#8a42e2",
                      fontWeight: "400",
                    }}
                  >
                    {isNaN(this.props?.groupedTemplates?.totalTemplatesCount)
                      ? ""
                      : `  ( ${this.props?.groupedTemplates?.totalTemplatesCount} )`}
                  </span> */}

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/#">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Templates</BreadcrumbItem>
                  </ol>
                </div>
              </Col>
              <Col xs={6}>
                <div className="float-end">
                  <div className="d-flex gap-2">
                    <button
                      className="main-btn secondary-btn"
                      role="button"
                      onClick={() => {
                        this.setState({ showStats: !this.state.showStats })
                      }}
                    >
                      {this.state.showStats ? (
                        <i className="fas fa-eye-slash text-white"></i>
                      ) : (
                        <i className="fas fa-eye text-white"></i>
                      )}

                      <span>
                        {this.state.showStats ? "Hide" : "Show"} Score
                      </span>
                    </button>
                    <Link
                      className="main-btn"
                      to="/templates/add"
                      role="button"
                    >
                      <img src={plus} alt="" />
                      <span>Add Template</span>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
            <Row className={"pt-4"}>
              <Col md={12}>
                {/* {this.props.apiError &&
                typeof this.props.apiError === "string" ? (
                  <Alert color="danger">{this.props.apiError}</Alert>
                ) : null}

                {this.props.apiError &&
                typeof this.props.apiError === "object" ? (
                  <Alert color="danger">
                    {this.props.apiError.response.data.error}
                  </Alert>
                ) : null}

                {this.props.successMsg ? (
                  <Alert color="success">{this.props.successMsg}</Alert>
                ) : null} */}

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    {this.props.loading ? (
                      <div className="spinner-wr">
                        <Spinner style={{ color: "#5932ea" }} />
                      </div>
                    ) : null}
                    <div
                      className={
                        `${this.state.showStats ? "" : "tbl "}` +
                        `${this.state.templates?.length ? "" : "d-none"}`
                      }
                    >
                      {this.state.showStats ? (
                        <DataTable
                          responsive
                          key={this.state.tableReloadKey}
                          columns={templateStatsColumns}
                          data={templateStatsData}
                          fixedHeader
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={this.state.totalCount}
                          paginationPerPage={this.state.rowsPerPage}
                          onChangeRowsPerPage={rows => {
                            this.setState({
                              rowsPerPage: rows,
                              page: 1,
                              tableReloadKey: Math.random(),
                            })
                            this.props.getTemplatesGroupedByCategory({
                              page: 1,
                              limit: rows,
                            })
                          }}
                          onChangePage={page => {
                            this.setState({ page: page })
                            this.props.getTemplatesGroupedByCategory({
                              page,
                              limit: this.state.rowsPerPage,
                            })
                          }}
                          keyField={"id"}
                          defaultSortFieldId={"category"}
                          defaultSortAsc={true}
                        />
                      ) : (
                        <DataTable
                          responsive
                          key={this.state.tableReloadKey}
                          columns={templateColumns}
                          data={templateData}
                          fixedHeader
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={this.state.totalCount}
                          paginationPerPage={this.state.rowsPerPage}
                          onChangeRowsPerPage={rows => {
                            this.setState({
                              rowsPerPage: rows,
                              page: 1,
                              tableReloadKey: Math.random(),
                            })
                            this.props.getTemplatesGroupedByCategory({
                              page: 1,
                              limit: rows,
                            })
                          }}
                          onChangePage={page => {
                            this.setState({ page: page })
                            this.props.getTemplatesGroupedByCategory({
                              page,
                              limit: this.state.rowsPerPage,
                            })
                          }}
                          keyField={"id"}
                          defaultSortFieldId={"category"}
                          defaultSortAsc={true}
                        />
                      )}
                    </div>

                    {this.state.confirm_alert ? (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnText="Yes, remove it"
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="dark"
                        onConfirm={() => {
                          this.setState({ confirm_alert: false })
                          this.props.deleteTemplate(this.state.template_id)
                        }}
                        onCancel={() => {
                          this.setState({ confirm_alert: false })
                        }}
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    ) : null}
                    <Modal
                      isOpen={this.state.modal_center}
                      toggle={() => {
                        this.tog_center()
                      }}
                      backdrop="static"
                      centered={true}
                    >
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">
                            Edit Template Name
                          </h5>
                          <button
                            type="button"
                            onClick={() => {
                              this.setState({
                                modal_center: false,
                              })
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Row>
                            <Col>
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  Template Name
                                </Label>
                                <AvField
                                  name="template_name"
                                  placeholder="Template Name"
                                  type="text"
                                  errorMessage="Enter Template Name"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^[A-Za-z0-9 ]+$",
                                      errorMessage: "Enter valid template name",
                                    },
                                  }}
                                  id="validationCustom01"
                                  value={this.state.template_name}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">
                                  Delay Interval Per Text Message(s)
                                </Label>
                                <AvField
                                  name="template_delay"
                                  placeholder="Delay Interval Per Text Message(s)"
                                  type="number"
                                  errorMessage="Enter Delay"
                                  className="form-control"
                                  validate={{
                                    required: { value: true },
                                    number: { value: true },
                                  }}
                                  id="validationCustom02"
                                  value={this.state.template_delay}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              this.setState({
                                modal_center: false,
                              })
                            }}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Update
                          </button>
                        </div>
                      </AvForm>
                    </Modal>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Templates.propTypes = {
  loading: PropTypes.bool,
  apiError: PropTypes.any,
  templates: PropTypes.array,
  groupedTemplates: PropTypes.object,
  getTemplates: PropTypes.func,
  getTemplatesGroupedByCategory: PropTypes.func,
  successMsg: PropTypes.any,
  editTemplate: PropTypes.func,
  deleteTemplate: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    loading,
    apiError,
    successMsg,
    templates,
    groupedTemplates,
    editTemplate,
    deleteTemplate,
    getTemplates,
    getTemplatesGroupedByCategory,
  } = state.Template
  return {
    loading,
    apiError,
    successMsg,
    templates,
    groupedTemplates,
    getTemplates,
    deleteTemplate,
    editTemplate,
    getTemplatesGroupedByCategory,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    getTemplates,
    editTemplate,
    deleteTemplate,
    getTemplatesGroupedByCategory,
  })(Templates)
)
