import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  BreadcrumbItem,
  Collapse,
} from "reactstrap"
import { Link, Redirect } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import axios from "axios"

import plusicon from "../../assets/images/white-plus.svg"

import drop from "../../assets/images/drop.svg"
import kebabMenu from "../../assets/images/kebab-icon.svg"
import SweetAlert from "react-bootstrap-sweetalert"

import "chartist/dist/scss/chartist.scss"
import DataTable from "react-data-table-component"

//i18n
import { withTranslation } from "react-i18next"
import toast, { Toaster } from "react-hot-toast"

const DripAutomation = props => {
  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  const [expandedTemplateId, setExpandedTemplateId] = useState(null)
  const [dripTemplates, setDripTemplates] = useState([])
  const [tableReloadKey, setTableReloadKey] = useState(Math.random())
  const [showStats, setShowStats] = useState(true)
  const [deleteAlert, setDeleteAlert] = useState({
    isOpen: false,
    index: "",
  })
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    text: "",
  })
  const [shouldRedirect, setShouldRedirect] = useState({
    state: false,
    path: "",
  })

  const getDripTemplates = async () => {
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplates",
      config
    )
    if (response.data) {
      let tempArr = response.data.data.map(item => {
        return { ...item, isExpanded: false }
      })
      setDripTemplates(tempArr)
      setTableReloadKey(Math.random())
    }
  }

  function getTemplateStatusColor(status) {
    switch (status) {
      case "No Data":
        return "text-body"
      case "Healthy":
        return "text-success"
      case "Warning":
        return "text-warning"
      case "Critical":
        return "text-danger"

      default:
        return "text-body"
    }
  }

  const handleDripTemplateDelete = async () => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT +
          "/api/dripTemplate/deleteDripTemplate/" +
          dripTemplates[deleteAlert.index]._id,
        config
      )
      if (response?.data) {
        setDeleteAlert({ isOpen: false, index: "" })
        getDripTemplates()
        notify("success", "Drip Template deleted successfully!")
      }
    } catch (error) {
      notify("error", err?.response?.data?.error)
    }
  }

  function handleExpandToggle(index) {
    let tempArr = [...dripTemplates]
    tempArr[index].isExpanded = !tempArr[index].isExpanded
    setDripTemplates([...tempArr])
  }

  useEffect(() => {
    getDripTemplates()
  }, [])

  const templateColumns = [
    {
      name: "Name",
      id: "name",
      selector: row => row.name,
      maxWidth: "200px",
      sortable: true,
    },
    {
      name: "Message",
      selector: row => row.message,
      minWidth: "620px",
    },
    {
      name: "Edit",
      selector: row => row.edit,
      maxWidth: "70px",
      width: "70px",
    },
    {
      name: "Delete",
      selector: row => row.delete,
      maxWidth: "70px",
      width: "70px",
    },
  ]
  const templateStatsColumns = [
    {
      name: "Name",
      id: "name",
      selector: row => row.name,
      minWidth: "180px",
      sortable: true,
    },
    {
      name: "Day",
      selector: row => row.day,
      minWidth: "10px",
      sortable: true,
    },
    {
      name: "Message",
      selector: row => row.message,
      minWidth: "620px",
    },
    {
      name: "Health",
      selector: row => row.health,
      maxWidth: "100px",
      sortable: true,
    },
    {
      name: "Total Sent",
      selector: row => row.totalSent,
      minWidth: "110px",
      sortable: true,
    },
    {
      name: "Delivered",
      selector: row => row.deliveredCount,
      minWidth: "110px",
      sortable: true,
    },
    {
      name: "Undelivered",
      selector: row => row.undeliveredCount,
      minWidth: "120px",
      sortable: true,
    },
    {
      name: "Blocked",
      selector: row => row.blockedCount,
      maxWidth: "100px",
      sortable: true,
    },
    {
      name: "Received",
      selector: row => row.receivedCount,
      minWidth: "110px",
      sortable: true,
    },
    {
      name: "Edit",
      selector: row => row.edit,
      maxWidth: "70px",
      width: "70px",
    },
    {
      name: "Delete",
      selector: row => row.delete,
      maxWidth: "70px",
      width: "70px",
    },
  ]

  const templateData = dripTemplates?.map((item, index) => ({
    // id: item?._id,
    message: (
      <>
        {/* <p>Message 1</p> */}

        <div className="template-mssg-wr">
          <div className="w-100 d-flex align-items-center gap-5 template-row">
            <div>
              <div className="template-mssgs mb-2">
                <span>
                  <i>Day {item.data[0]?.day}</i>
                </span>
                <p>{item.data[0]?.message}</p>
                <span className="health text-center">
                  <i
                    className={`${getTemplateStatusColor(
                      item.data[0]?.health
                    )}`}
                  >
                    {item.data[0]?.health}
                  </i>
                </span>
              </div>
              {expandedTemplateId !== item._id && (
                <span
                  className="show-more-temp"
                  onClick={() => {
                    setExpandedTemplateId(item?._id)
                  }}
                >
                  +{item.data?.length - 1} more
                </span>
              )}
            </div>
            <div>
              <div className="d-flex align-items-center gap-2 justify-content-end template-btn-grp">
                {item.data && item.data?.length > 1 ? (
                  <p
                    className="more-messages-ind"
                    // style={{ right: "2.5em" }}
                    onClick={() => {
                      if (item?._id == expandedTemplateId) {
                        setExpandedTemplateId(null)
                      } else {
                        setExpandedTemplateId(item?._id)
                      }
                    }}
                  >
                    Show {item?._id == expandedTemplateId ? "Less" : "More"}
                    {item?._id == expandedTemplateId ? (
                      <img
                        src={drop}
                        alt=""
                        style={{ transform: "rotate(180deg)" }}
                      />
                    ) : (
                      <img src={drop} alt="" />
                    )}
                  </p>
                ) : null}
                {/* <Link
                  to={`/update-drip-automation/${item._id}`}
                  className={"has-icon btn btn-outline-primary me-2"}
                >
                  <i className="fas fa-edit"></i>
                </Link>
                <Button
                  onClick={() => {
                    setDeleteAlert({ isOpen: true, index })
                  }}
                  className={"has-icon"}
                  color={"outline-danger"}
                >
                  <i className="fas fa-trash-alt"></i>
                </Button> */}
              </div>
            </div>
          </div>
          {item.data && item.data?.length > 1 ? (
            <Collapse isOpen={item?._id == expandedTemplateId}>
              {item.data?.map((template, index) => {
                if (index > 0) {
                  return (
                    <div
                      className="w-100 d-flex align-items-center gap-5 template-row"
                      key={index}
                    >
                      <div class="temp-msg-container">
                        <div className="template-mssgs">
                          <span>
                            <i>Day {template.day}</i>
                          </span>
                          <p className="w-100">{template.message}</p>
                          <span className="health text-center">
                            <i
                              className={`${getTemplateStatusColor(
                                template.health
                              )}`}
                            >
                              {template?.health}
                            </i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex align-items-center gap-2 justify-content-end template-btn-grp">
                          {/* <Link
                            to={`/update-drip-automation/${item._id}`}
                            className={"has-icon btn btn-outline-primary me-2"}
                          >
                            <i className="fas fa-edit"></i>
                          </Link> */}
                          {/* <Button
                            onClick={() => {
                              setDeleteAlert({ isOpen: true, index })
                            }}
                            className={"has-icon"}
                            color={"outline-danger"}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </Button> */}
                        </div>
                      </div>
                    </div>
                  )
                }
                return null
              })}
            </Collapse>
          ) : null}
        </div>
      </>
    ),
    name: item && item.name,
    edit: (
      <Link
        to={`/update-drip-automation/${item._id}`}
        className={"has-icon btn btn-outline-primary me-2"}
      >
        <i className="fas fa-edit"></i>
      </Link>
    ),
    delete: (
      <Button
        onClick={() => {
          setDeleteAlert({ isOpen: true, index })
        }}
        className={"has-icon"}
        color={"outline-danger"}
      >
        <i className="fas fa-trash-alt"></i>
      </Button>
    ),
  }))
 
  const templateStatsData = dripTemplates
    ?.flatMap(outerItem =>
      outerItem.data.map(template => ({
        ...template,
        name: outerItem.name,
        mainId: outerItem._id,
      }))
    )
    ?.map((item, index) => ({
      ...item,
      health: (
        <span className={getTemplateStatusColor(item.health)}>
          {item.health}
        </span>
      ),
      message: <span className="reminder-table-note">{item.message}</span>,

      edit: (
        <Link
          to={`/update-drip-automation/${item.mainId}`}
          className={"has-icon btn btn-outline-primary me-2"}
        >
          <i className="fas fa-edit"></i>
        </Link>
      ),
      delete: (
        <Button
          onClick={() => {
            setDeleteAlert({ isOpen: true, index })
          }}
          className={"has-icon"}
          color={"outline-danger"}
        >
          <i className="fas fa-trash-alt"></i>
        </Button>
      ),
    }))

  return (
    <React.Fragment>
      <Toaster />
      {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}

      <div className="page-content">
        <MetaTags>
          <title>Drip Automation | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h4 className="font-size-18">
                  {dripTemplates?.length ? dripTemplates?.length : ""} Drip
                  Automations
                </h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>

                  <BreadcrumbItem active>Drip Automations </BreadcrumbItem>
                </ol>
                {/* <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to TextLaunchPad Dashboard</li>
                </ol> */}
              </Col>

              <Col md="4">
                <div className="d-flex gap-2">
                  <Link to="/create-drip-automation" className="main-btn left">
                    <img src={plusicon} alt="" />
                    <span>Create New</span>
                  </Link>
                  <button
                    className="main-btn secondary-btn"
                    role="button"
                    onClick={() => {
                      setShowStats(!showStats)
                    }}
                  >
                    {showStats ? (
                      <i className="fas fa-eye-slash text-white"></i>
                    ) : (
                      <i className="fas fa-eye text-white"></i>
                    )}

                    <span>{showStats ? "Hide" : "Show"} Score</span>
                  </button>
                </div>
              </Col>
              <Col md="12" sm="12" lg="12" xl="12" className="mt-4">
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
            <Row className={"pt-4"}>
              <Col md={12}>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <div
                      className={
                        `drip-table ${showStats ? "tbl " : "tbl "}` +
                        `${dripTemplates?.length ? "" : "d-none"}`
                      }
                    >
                      {showStats ? (
                        <DataTable
                          responsive
                          key={tableReloadKey}
                          columns={templateStatsColumns}
                          data={templateStatsData}
                          fixedHeader
                          highlightOnHover
                          pagination
                          paginationPerPage={10}
                          // paginationRowsPerPageOptions={[5, 15, 25, 50]}
                          defaultSortFieldId={"name"}
                          defaultSortAsc={true}
                        />
                      ) : (
                        <DataTable
                          responsive
                          key={tableReloadKey}
                          columns={templateColumns}
                          data={templateData}
                          fixedHeader
                          highlightOnHover
                          pagination
                          paginationPerPage={10}
                          // paginationRowsPerPageOptions={[5, 15, 25, 50]}
                          defaultSortFieldId={"name"}
                          defaultSortAsc={true}
                        />
                      )}
                    </div>
                    {deleteAlert && deleteAlert.isOpen ? (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnText="Yes, remove it"
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="dark"
                        onConfirm={() => {
                          handleDripTemplateDelete()
                        }}
                        onCancel={() => {
                          setDeleteAlert({ isOpen: false, index: "" })
                        }}
                      >
                        You want to delete{" "}
                        <strong>
                          {" "}
                          {dripTemplates[deleteAlert.index]?.name}
                        </strong>
                        , <br /> You won't be able to revert this!
                      </SweetAlert>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {alert && alert.isOpen ? (
            <Alert color={alert.type}>{alert.text}</Alert>
          ) : null}
          {/* <div className="mt-3">
            <div className="c-card">
              <div className=""> */}
          {/* <Table
                  id="tech-companies-1"
                  className="table veltrix-table c-table"
                >
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Messages</Th>
                     
                      <Th data-priority="3">Edit</Th>
                      <Th data-priority="3">Delete</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dripTemplates && dripTemplates.length ? (
                      dripTemplates.map((item, index) => {
                        return (
                          <Tr key={index}>
                            <Td valign="middle">{item.name}</Td>
                            <Td valign="middle">
                              <div className="d-day-container">
                                <div
                                  className={`d-day-messages-wrap ${
                                    item.isExpanded ? "active" : ""
                                  }`}
                                >
                                  {item.data &&
                                    item.data.map((mssg, mssgIndex) => {
                                      return (
                                        <>
                                          <div
                                            className="d-messages-container"
                                            key={mssgIndex}
                                          >
                                            <div className="d-day">
                                              <span>Day {mssg.day}</span>
                                            </div>
                                            <div className="d-day-messages-container">
                                              <div className={`d-day-messages`}>
                                                <p>{mssg.message}</p>
                                              </div>
                                              {!item.isExpanded && (
                                                <p
                                                  className="more-messages-ind"
                                                  onClick={() => {
                                                    handleExpandToggle(index)
                                                  }}
                                                >
                                                  +{item.data.length - 1} more
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}

                                
                                </div>

                               
                                {item.data && item.data.length > 1 && (
                                  <div
                                    className="show-more"
                                    onClick={() => handleExpandToggle(index)}
                                  >
                                    <p>
                                      Show {item.isExpanded ? "Less" : "More"}
                                    </p>
                                    {item.isExpanded ? (
                                      <img
                                        src={drop}
                                        alt=""
                                        style={{ transform: "rotate(180deg)" }}
                                      />
                                    ) : (
                                      <img src={drop} alt="" />
                                    )}
                                  </div>
                                )}
                              </div>
                            </Td>
                            
                            <Td valign="middle">
                              <Button
                                onClick={() => {
                                  setShouldRedirect({
                                    state: true,
                                    path: `/update-drip-automation/${item._id}`,
                                  })
                                }}
                                className={"has-icon me-2"}
                                color={"outline-primary"}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Button>
                            </Td>
                            <Td>
                              <Button
                                onClick={() => {
                                  setDeleteAlert({ isOpen: true, index })
                                }}
                                className={"has-icon"}
                                color={"outline-danger"}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                            </Td>
                          </Tr>
                        )
                      })
                    ) : (
                      <Tr>
                        <Td>No Drip Templates Found</Td>
                      </Tr>
                    )}
                    
                  </Tbody>
                </Table> */}
          {/* </div>
            </div>
          </div> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

export default withTranslation()(DripAutomation)
