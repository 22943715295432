import {
  ADD_CAMPAIGN,
  ADD_CAMPAIGN_SUCCESS,
  API_ERROR,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_SUCCESS,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_FOLLOW_UP_CAMPAIGNS,
  GET_FOLLOW_UP_CAMPAIGNS_SUCCESS,
  GET_MARKETS,
  GET_MARKETS_SUCCESS,

  GET_CAMPAIGN,
  GET_CAMPAIGN_SUCCESS,
  GET_COUNT_STATS,
  GET_COUNT_STATS_SUCCESS,
  DOWNLOAD_COUNT_STATS_STATS,
  GET_CAMPAIGN_METRICS,
  GET_CAMPAIGN_METRICS_SUCCESS,
  GET_CAMPAIGN_COUNT_PROSPECTS,
  GET_CAMPAIGN_COUNT_PROSPECTS_SUCCESS,
  GET_BATCH,
  GET_BATCH_SUCCESS,
  GET_CLIENT_DATA,
  GET_CLIENT_DATA_SUCCESS,
  COUNT_TEMPLATE,
  COUNT_TEMPLATE_SUCCESS,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_STATUS_PROS,
  AUTO_SEND_CAMP,
  AUTO_SEND_CAMP_SUCCESS,
  CANCEL_AUTO_SEND_CAMP,
  GET_UPLOADS_HISTROY,
  GET_UPLOADS_HISTROY_SUCCESS,
  GET_SCHEDULER_SUCCESS,
  GET_SCHEDULER,
  CREATE_SCHEDULER,
  UPDATE_SCHEDULER,
  PAUSE_SCHEDULE,
  START_SCHEDULE,
  CANCEL_SCHEDULE,
  EXPORT_CAMP_PROS_DATA,
  EXPORT_CAMP_PROS_DATA_SUCCESS,
  EXIST_PROSPECTS_SUCCESS,
  EXIST_PROSPECTS,
  UPLOAD_PROSPECTS,
  UPLOAD_PROSPECTS_SUCCESS,
  UPLOAD_PROSPECTS_FILE,
  UPLOAD_PROSPECTS_FILE_SUCCESS,
  FILTER_PROSPECTS,
  FILTER_PROSPECTS_SUCCESS, CAMPAIGN_FILTER_PROSPECTS_SUCCESS, UPDATE_PROPS
} from "./actionTypes"

import { GET_CATEGORIES, GET_CATEGORIES_SUCCESS } from "../categories/actionTypes";
import { GET_LEAD_STAGES, GET_LEAD_STAGES_SUCCESS } from "../leadStages/actionTypes";
import { GET_QUICK_REPLIES, GET_QUICK_REPLIES_SUCCESS } from "../quick-replies/actionTypes";
import { SEND_CONVERSATION, SEND_CONVERSATION_SUCCESS } from "../prospects/actionTypes";
import { CAMPAIGN_FILTER_PROSPECTS } from "../../helpers/url_helper";

//FOR UPDATE PROPS
export const update_props = (data) => {
  return {
    type: UPDATE_PROPS,
    payload: data
  }
}

export const getCampaigns = () => {
  return {
    type: GET_CAMPAIGNS
  }
}

export const getFollowUpCampaigns = () => {
  return {
    type: GET_FOLLOW_UP_CAMPAIGNS
  }
}

export const getCampaignsSuccess = campaigns => {
  return {
    type: GET_CAMPAIGNS_SUCCESS,
    payload: campaigns,
  }
}
export const getFollowUpCampaignsSuccess = campaigns => {
  return {
    type: GET_FOLLOW_UP_CAMPAIGNS_SUCCESS,
    payload: campaigns,
  }
}

export const campaignFilterProspects = (data) => {
  return {
    type: CAMPAIGN_FILTER_PROSPECTS,
    payload: data
  }
}

export const campaignFilterProspectsSuccess = message => {
  return {
    type: CAMPAIGN_FILTER_PROSPECTS_SUCCESS,
    payload: message,
  }
}

export const uploadProspectsFile = (data) => {
  return {
    type: UPLOAD_PROSPECTS_FILE,
    payload: data
  }
}

export const uploadProspectsFileSuccess = upload => {
  return {
    type: UPLOAD_PROSPECTS_FILE_SUCCESS,
    payload: upload,
  }
}

export const uploadProspects = (data) => {
  return {
    type: UPLOAD_PROSPECTS,
    payload: data
  }
}

export const uploadProspectsSuccess = message => {
  return {
    type: UPLOAD_PROSPECTS_SUCCESS,
    payload: message,
  }
}

export const getCampaignsError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const editCampaign = (campaign) => {
  return {
    type: EDIT_CAMPAIGN,
    payload: campaign,
  }
}

export const editCampaignSuccess = (message) => {
  return {
    type: EDIT_CAMPAIGN_SUCCESS,
    payload: message,
  }
}

export const addCampaign = (campaign) => {
  return {
    type: ADD_CAMPAIGN,
    payload: campaign,
  }
}

export const addCampaignSuccess = (message) => {
  return {
    type: ADD_CAMPAIGN_SUCCESS,
    payload: message,
  }
}

export const getMarkets = () => {
  return {
    type: GET_MARKETS
  }
}

export const getMarketsSuccess = markets => {
  return {
    type: GET_MARKETS_SUCCESS,
    payload: markets,
  }
}

export const deleteCampaign = (campaign) => {
  return {
    type: DELETE_CAMPAIGN,
    payload: campaign,
  }
}

export const deleteCampaignSuccess = (message) => {
  return {
    type: DELETE_CAMPAIGN_SUCCESS,
    payload: message,
  }
}


/* this action for campaign detail page  */

export const getCampaign = (id) => {
  return {
    type: GET_CAMPAIGN,
    payload: id,
  }
}

export const getCampaignSuccess = campaign => {
  return {
    type: GET_CAMPAIGN_SUCCESS,
    payload: campaign,
  }
}

export const getCountStats = (id) => {
  return {
    type: GET_COUNT_STATS,
    payload: id,
  }
}

export const getCountStatsSuccess = countstatus => {
  return {
    type: GET_COUNT_STATS_SUCCESS,
    payload: countstatus,
  }
}

export const downloadStats = (id, category) => {
  return {
    type: DOWNLOAD_COUNT_STATS_STATS,
    payload: {
      id: id,
      category: category
    },
  }
}

export const getCampaignMetrics = (id) => {
  return {
    type: GET_CAMPAIGN_METRICS,
    payload: id,
  }
}

export const getCampaignMetricsSuccess = campaign_metrics => {
  return {
    type: GET_CAMPAIGN_METRICS_SUCCESS,
    payload: campaign_metrics,
  }
}

export const countProspects = (id) => {
  return {
    type: GET_CAMPAIGN_COUNT_PROSPECTS,
    payload: id,
  }
}

export const countProspectsSuccess = count => {
  return {
    type: GET_CAMPAIGN_COUNT_PROSPECTS_SUCCESS,
    payload: count,
  }
}

export const getCategories = () => {
  return {
    type: GET_CATEGORIES,
  }
}

export const getCategoriesSuccess = categories => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  }
}

export const getBatch = (id) => {
  return {
    type: GET_BATCH,
    payload: id,
  }
}

export const getBatchSuccess = campaign => {
  return {
    type: GET_BATCH_SUCCESS,
    payload: campaign,
  }
}

export const getTemplatesbycat = (data) => {
  return {
    type: GET_CLIENT_DATA,
    payload: data,
  }
}

export const getTemplatesbycatSuccess = data => {
  return {
    type: GET_CLIENT_DATA_SUCCESS,
    payload: data,
  }
}

export const countTempletebyCat = (data) => {
  return {
    type: COUNT_TEMPLATE,
    payload: data,
  }
}

export const countTempletebyCatSuccess = data => {
  return {
    type: COUNT_TEMPLATE_SUCCESS,
    payload: data,
  }
}

export const sendPropects = (data) => {
 
  return {
    type: SEND_MESSAGE,
    payload: data,
  }
}

export const sendPropectsSuccess = data => {
  return {
    type: SEND_MESSAGE_SUCCESS,
    payload: data,
  }
}
export const updateMessageStatusPros = data => {
  return {
    type: UPDATE_MESSAGE_STATUS_PROS,
    payload: data,
  }
}
export const autoSendCamp = data => {
  return {
    type: AUTO_SEND_CAMP,
    payload: data,
  }
}
export const autoSendCampSuccess = data => {
  return {
    type: AUTO_SEND_CAMP_SUCCESS,
    payload: data,
  }
}
export const cancelAutoSendCamp = data => {
  return {
    type: CANCEL_AUTO_SEND_CAMP,
    payload: data,
  }
}


/* get-uploads-histroy */
export const getUploadsHistroy = data => {
  return {
    type: GET_UPLOADS_HISTROY,
    payload: data,
  }
}
export const getUploadsHistroySuccess = data => {
  return {
    type: GET_UPLOADS_HISTROY_SUCCESS,
    payload: data,
  }
}

export const getScheduler = data => {
  return {
    type: GET_SCHEDULER,
    payload: data,
  }
}
export const getSchedulerSuccess = data => {
  return {
    type: GET_SCHEDULER_SUCCESS,
    payload: data,
  }
}
export const updateScheduler = data => {
  return {
    type: UPDATE_SCHEDULER,
    payload: data,
  }
}
export const createScheduler = data => {
  return {
    type: CREATE_SCHEDULER,
    payload: data,
  }
}

export const cancelSchedule = data => {
  return {
    type: CANCEL_SCHEDULE,
    payload: data,
  }
}
export const pauseSchedule = data => {
  return {
    type: PAUSE_SCHEDULE,
    payload: data,
  }
}
export const startSchedule = data => {
  return {
    type: START_SCHEDULE,
    payload: data,
  }
}


export const getLeadStages = () => {
  return {
    type: GET_LEAD_STAGES
  }
}
export const getLeadStagesSuccess = leadStages => {
  return {
    type: GET_LEAD_STAGES_SUCCESS,
    payload: leadStages,
  }
}

export const exportCampProsData = (data) => {
  return {
    type: EXPORT_CAMP_PROS_DATA,
    payload: data,
  }
}
export const exportCampProsDataSuccess = data => {
  return {
    type: EXPORT_CAMP_PROS_DATA_SUCCESS,
    payload: data,
  }
}
export const getQuickReplies = () => {
  return {
    type: GET_QUICK_REPLIES
  }
}

export const getQuickRepliesSuccess = categories => {
  return {
    type: GET_QUICK_REPLIES_SUCCESS,
    payload: categories,
  }
}

export const sendConversation = (message) => {
  return {
    type: SEND_CONVERSATION,
    payload: message
  }
}

export const sendConversationSuccess = message => {
  return {
    type: SEND_CONVERSATION_SUCCESS,
    payload: message,
  }
}

export const existsProspect = (data) => {
  return {
    type: EXIST_PROSPECTS,
    payload: data
  }
}

export const existsProspectSuccess = message => {
  return {
    type: EXIST_PROSPECTS_SUCCESS,
    payload: message,
  }
}
