import {
  API_ERROR,
  EXPORT_DNC_LIST,
  EXPORT_DNC_LIST_SUCCESS,
  IMPORT_DNC_LIST,
  IMPORT_DNC_LIST_SUCCESS,
  RESET_DNC_LIST,
  RESET_DNC_LIST_SUCCESS
} from "./actionTypes"

const initialState = {
  apiError: null,
  successMsg: null,
}

const DncList = (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_DNC_LIST:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case EXPORT_DNC_LIST_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null
      }
      break
    case IMPORT_DNC_LIST:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case IMPORT_DNC_LIST_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null
      }
    case RESET_DNC_LIST:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case RESET_DNC_LIST_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default DncList
