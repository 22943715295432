import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"

import CampaignsSaga from "./campaigns/saga"
import TemplatesSaga from "./templates/saga"
import CategoriesSaga from "./categories/saga"
import QuickRepliesSaga from "./quick-replies/saga"
import OptOutsSaga from "./optouts/saga"
import LeadStagesSaga from "./leadStages/saga"
import MarketsSaga from "./markets/saga"
import PtcrmSettingSaga from "./ptcrm/saga"
import NotificationsSaga from "./notifications/saga"
import usersSaga from "./Users/saga"
import DncListSaga from "./dnclist/saga"
import ProspectsSaga from "./prospects/saga"
import NumbersSaga from "./numbers/saga"
import ChangePasswordSaga from "./changePassword/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    CampaignsSaga(),
    TemplatesSaga(),
    CategoriesSaga(),
    QuickRepliesSaga(),
    OptOutsSaga(),
    LeadStagesSaga(),
    MarketsSaga(),
    PtcrmSettingSaga(),
    NotificationsSaga(),
    usersSaga(),
    DncListSaga(),
    ProspectsSaga(),
    NumbersSaga(),
    ChangePasswordSaga(),
    LayoutSaga(),
    fork(calendarSaga),
  ])
}
