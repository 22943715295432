import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  GET_PTCRM_SETTINGS,
  SAVE_PTCRM_SETTING,
  SAVE_PTCRM_FILTER
} from "./actionTypes"

import {
  getPtcrmError,
  getPtcrmSuccess, savePtcrmFilterSuccess, savePtcrmSettingsSuccess

} from "./actions"

import {getPtcrmSetting, updatePtcrmFilter, updatePtcrmSetting} from "../../helpers/fakebackend_helper";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getPtcrmSettingApi() {
  try {
    const response = yield call(getPtcrmSetting)
    yield put(getPtcrmSuccess(response.data));
  } catch (error) {
    yield put(getPtcrmError(error))
  }
}


function* updatePtcrmSettingApi({ payload: ptcrmSetting }) {
  try {
    const response = yield call(updatePtcrmSetting,ptcrmSetting)
    yield put(savePtcrmSettingsSuccess('Settings updated successfully'));
    yield delay(1000);
    const response1 = yield call(getPtcrmSetting)
    yield put(getPtcrmSuccess(response1.data));

  } catch (error) {
    yield put(getPtcrmError(error))
  }
}

function* updatePtcrmFilterApi({ payload: ptcrmSetting }) {
  try {
    const response = yield call(updatePtcrmFilter,ptcrmSetting)
    yield put(savePtcrmFilterSuccess('Settings updated successfully'));
    yield delay(1000);
    const response1 = yield call(getPtcrmSetting)
    yield put(getPtcrmSuccess(response1.data));

  } catch (error) {
    yield put(getPtcrmError(error))
  }
}

export function* watchOptOuts() {
  yield takeEvery(GET_PTCRM_SETTINGS, getPtcrmSettingApi)
  yield takeEvery(SAVE_PTCRM_SETTING, updatePtcrmSettingApi)
  yield takeEvery(SAVE_PTCRM_FILTER, updatePtcrmFilterApi)
}

function* PtcrmSettingSaga() {
  yield all([fork(watchOptOuts)])
}

export default PtcrmSettingSaga
