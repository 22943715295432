import {
  ADD_CATEGORY, ADD_CATEGORY_SUCCESS,
  API_ERROR, DELETE_CATEGORY, DELETE_CATEGORY_SUCCESS, EDIT_CATEGORY, EDIT_CATEGORY_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS
} from "./actionTypes"

export const getCategories = () => {
  return {
    type: GET_CATEGORIES
  }
}

export const getCategoriesSuccess = categories => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: categories,
  }
}


export const getCategoriesError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}


export const editCategory = (category) => {
  return {
    type: EDIT_CATEGORY,
    payload: category,
  }
}

export const editCategorySuccess = (message) => {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    payload: message,
  }
}

export const addCategory = (category) => {
  return {
    type: ADD_CATEGORY,
    payload: category,
  }
}

export const addCategorySuccess = (message) => {
  return {
    type: ADD_CATEGORY_SUCCESS,
    payload: message,
  }
}
export const deleteCategory = (category) => {
  return {
    type: DELETE_CATEGORY,
    payload: category,
  }
}

export const deleteCategorySuccess = (message) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: message,
  }
}
