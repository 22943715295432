import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Campaign from "./campaigns/reducer"
import Category from "./categories/reducer"
import Template from "./templates/reducer"
import QuickReply from "./quick-replies/reducer"
import OptOut from "./optouts/reducer"
import LeadStage from "./leadStages/reducer"
import Market from "./markets/reducer"
import PtcrmSetting from "./ptcrm/reducer"
import Notifications from "./notifications/reducer"
import Users from "./Users/reducer"
import DncList from "./dnclist/reducer"
import Prospects from "./prospects/reducer"
import Numbers from "./numbers/reducer"
import ChangePassword from "./changePassword/reducer"

//Calendar
import calendar from "./calendar/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Campaign,
  Template,
  QuickReply,
  Category,
  OptOut,
  LeadStage,
  Market,
  PtcrmSetting,
  Notifications,
  Users,
  DncList,
  Prospects,
  Numbers,
  ChangePassword,
  Profile,
  calendar,
})

export default rootReducer
