import React, { useEffect, useState, useRef } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
import axios from "axios"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvField, AvForm } from "availity-reactstrap-validation"
import close from "../../assets/images/close.svg"

const OptOuts = () => {
  useEffect(() => {
    getOptOuts()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false) // for circular spinner
  const [tableReloadKey, setTableReloadKey] = useState(Math.random()) // for table reload key
  const [optOuts, setOptOuts] = useState([]) // for listing of OptOut
  const [deleteOptOut, setDeleteOptOut] = useState({ isOpen: false }) // for delete quick reply
  const [modal, setModal] = useState({ isOpen: false }) // for add and edit modal

  const getOptOuts = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/template/pullOptOut",
        config
      )

      if (response?.data) {
        setOptOuts(response?.data?.data)
        setIsLoading(false)
        setTableReloadKey(Math.random())
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const handleDeleteOptOut = async id => {
    setIsLoading(true)
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT + `/api/template/optout/${id}`,
        config
      )

      if (response) notify("success", "OptOut Deleted Successfully")
      getOptOuts()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.error)
    }
  }

  const optOutColumn = [
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      cell: row => row?.title,
      minWidth: "150px",
    },
    {
      name: "Message",
      selector: row => row.message,
      sortable: true,
      cell: row => row?.message,
      minWidth: "400px",
    },
    {
      name: "Edit",
      selector: row => row.edit,
      sortable: true,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            onClick={() => {
              openModalHandler("updateOptOut", row)
            }}
            color={"outline-primary"}
            className={"has-icon me-2"}
          >
            <i className="fas fa-edit"></i>
          </Button>
        </div>
      ),
      minWidth: "80px",
      // maxWidth: "170px",
    },
    {
      name: "Delete",
      selector: row => row._id,
      sortable: false,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            onClick={() => {
              setDeleteOptOut({ isOpen: true, data: row })
            }}
            color={"outline-danger"}
            className={"has-icon"}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      ),
      minWidth: "80px",
    },
  ]

  const customStyles = {
    table: {
      width: "100%", // Set the overall table width to 100% to cover the screen
    },
  }

  const openModalHandler = (action, data = {}) => {
    switch (action) {
      case "AddOptOut": {
        setModal({
          isOpen: true,
          modalTitle: "Add OptOut",
          confirmText: "Save",
          action,
        })
        break
      }
      case "updateOptOut": {
        setModal({
          isOpen: true,
          modalTitle: "Update OptOut",
          confirmText: "Update",
          ...data,
          action,
        })
        break
      }

      default:
        break
    }
  }

  const handleModalSubmit = (e, value) => {
    switch (modal.action) {
      case "AddOptOut":
        handleAddOptOut(value)
        break
      case "updateOptOut":
        handleEditOptOut(value, modal?._id)

        break
    }
  }

  const handleAddOptOut = async value => {
    setIsLoading(true)
    const data = {
      title: value?.title,
      message: value?.message,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/template/saveoptout/",
        data,
        config
      )
      if (response) notify("success", "OptOut Created Successfully")
      setIsLoading(false)
      getOptOuts()
      setModal({ isOpen: false })
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }
  const handleEditOptOut = async (value, optOutId) => {
    setIsLoading(true)
    const data = {
      title: value?.title,
      message: value?.message,
      id: optOutId,
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + "/api/template/optout/" + optOutId,
        data,
        config
      )
      if (response) notify("success", "OptOut updated Successfully")
      setIsLoading(false)

      getOptOuts()

      setModal({ isOpen: false })
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>OptOuts | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2">
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    {/* {optOuts?.length ? `${optOuts?.length}` : null}{" "} */}
                    OptOuts
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/#">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>Account Settings</BreadcrumbItem>
                    <BreadcrumbItem active>OptOuts</BreadcrumbItem>
                  </ol>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="float-end">
                  <div className={"button-items d-flex st flex-wrap gap-2"}>
                    <button
                      className="main-btn"
                      onClick={() => {
                        openModalHandler("AddOptOut")
                      }}
                      type="button"
                    >
                      <img src={plus} alt="" />
                      <span> Add OptOut</span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <DataTable
              key={tableReloadKey}
              columns={optOutColumn}
              data={optOuts}
              fixedHeader
              className="c-table"
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationTotalRows={optOuts?.length}
              keyField={"id"}
              customStyles={customStyles}
            />
          </div>
        </Container>
      </div>

      {/* Add and edit OptOut modal  */}
      <Modal
        isOpen={modal?.isOpen}
        toggle={() => {
          setModal({ isOpen: !modal?.isOpen })
        }}
        className="c-modal"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">{modal?.modalTitle}</h5>
          <button
            type="button"
            onClick={() => {
              setModal({ isOpen: !modal?.isOpen })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleModalSubmit(e, v)}
              className="needs-validation"
            >
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <AvField
                      name="title"
                      placeholder="Title"
                      type="text"
                      errorMessage="Enter Title"
                      className="form-control"
                      value={modal?.title ? modal?.title : ""}
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                          errorMessage: "Enter valid title",
                        },
                      }}
                      id="validationCustom01"
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <AvField
                      name="message"
                      placeholder="Message"
                      type="textarea"
                      errorMessage="Enter Message"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        maxLength: { value: 320 },
                      }}
                      id="validationCustom02"
                      value={modal?.message ? modal?.message : ""}
                      rows={4}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setModal({ isOpen: false })
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Button>
                <Button type="submit" color="primary" className="main-btn">
                  <span>Submit</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>

      {/* Delete OptOut sweetAlert  */}

      {deleteOptOut?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            handleDeleteOptOut(deleteOptOut?.data?._id)
            setDeleteOptOut({ isOpen: false })
          }}
          onCancel={() => {
            setDeleteOptOut({ isOpen: false })
          }}
        >
          You won't be able to revert{" "}
          <strong>{deleteOptOut?.data?.title}</strong>
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default OptOuts
