import {
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  API_ERROR,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: true,
  markets: [],
  categories: [],
  apiError: null,
}

const Category = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      state = {
        ...state,
        loading: true,
        categories: [],
        apiError: null,
      }
      break
    case GET_CATEGORIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        categories: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload, loading: false }
      break
    case EDIT_CATEGORY:
      state = {
        ...state,
        category: action.payload,
      }
      break
    case EDIT_CATEGORY_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break

    case ADD_CATEGORY:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_CATEGORY_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_CATEGORY:
      state = {
        ...state,
        category: action.payload,
      }
      break
    case DELETE_CATEGORY_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Category
