import React from "react"
import {
  BreadcrumbItem,
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  Row,
  CardBody,
  Alert,
  Spinner,
  Progress,
} from "reactstrap"
import { Link } from "react-router-dom"
import Conversations from "./Conversations"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import plus from "../../assets/images/white-plus.svg"
import axios from "axios"
import illus from "../../assets/images/chat-illustration.svg"
import CSVReader from "react-csv-reader"
import SweetAlert from "react-bootstrap-sweetalert"
import close from "../../assets/images/close.svg"
import plusIcon from "../../assets/images/plus-icon.svg"
import toast, { Toaster } from "react-hot-toast"
import io from "socket.io-client"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
// const socket = io.connect(process.env.REACT_APP_PORT)

class CampaignHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      select_file: false,
      modal_center: false,
      csv_total_count: 0,
      csv_progress: 0,
      replied: "",
      file: null,
      addProspect: false,
      selectedLeadStages: [],
      upload: [],
      fileHeaders: [],
      csvData: {},
      isLoading: false,
      confirmUploadSweetAlert: false,
      counts: {
        inValidCount: 0,
        totalCount: 0,
        validCount: 0,
      },
      formSubmitted: {
        isSubmitted: false,
        progressPer: 0,
      },

      fileName: null,
      finalData: null,
      formData: {
        firstName: "FirstName",
        lastName: "LastName",
        mailingAddress: "MailingAddress",
        mailingCity: "MailingCity",
        mailingState: "MailingState",
        mailingZip: "MailingZip",
        propertyAddress: "PropertyAddress",
        propertyCity: "PropertyCity",
        propertyState: "PropertyState",
        propertyZip: "PropertyZip",
        phone: "Phone",
        phoneType: "PhoneType",
        carrierName: "CarrierName",
      },
      formState: {},
      duplicateProspect : {
        isOpen :false, 
        data : []
      }
    }

    this.showdata = [
      { value: false, label: "Not replied" },
      { value: true, label: "Replied" },
      { value: "both", label: "All" },
    ]

    let authUser = localStorage.getItem("authUser")
    if (authUser) {
      let user = JSON.parse(authUser)
      this.socket = io(process?.env.REACT_APP_PORT, {
        //process?.env.REACT_APP_PORT,
        //"http://192.168.29.121:5001"
        query: {
          email: user.user.name,
        },
      })
    }
    this.socket.on("CSVfileprogressmessages", (email, i, total) => {
      // counts: {
      //   inValidCount: errorRows.length,
      //   totalCount: data.length,
      //   validCount: validatedData.length,
      //   inValidEntriesIndex: errorRows,
      // },
      if (
        !(
          this.state.formSubmitted?.currentCount &&
          i < this.state.formSubmitted?.currentCount
        )
      ) {
        const temoObj = {
          isSubmitted: true,
          progressPer: (i / total) * 100,
          currentCount: i,
        }
        this.setState({ formSubmitted: temoObj })
      }
      if (i == total - 1) {
        this.setState({
          formSubmitted: { isSubmitted: false },
          isLoading: false,
          addProspect: false,
          confirmUploadSweetAlert: false,
          fileHeaders: [],
        })
        window.location.reload()
      }
    })
  }

  updatedFormData = [
    {
      key: "firstName",
      defaultValue: "FirstName",
      label: "First Name",
      required: true,
    },
    {
      key: "lastName",
      defaultValue: "LastName",
      label: "Last Name",
      required: true,
    },
    {
      key: "mailingAddress",
      defaultValue: "MailingAddress",
      label: "Mailing Address",
    },
    {
      key: "mailingCity",
      defaultValue: "MailingCity",
      label: "Mailing City",
    },
    {
      key: "mailingState",
      defaultValue: "MailingState",
      label: "Mailing State",
    },
    {
      key: "mailingZip",
      defaultValue: "MailingZip",
      label: "Mailing Zip",
    },
    {
      key: "propertyAddress",
      defaultValue: "PropertyAddress",
      label: "Property Address",
    },
    {
      key: "propertyCity",
      defaultValue: "PropertyCity",
      label: "Property City",
    },
    {
      key: "propertyState",
      defaultValue: "PropertyState",
      label: "Property State",
    },
    {
      key: "propertyZip",
      defaultValue: "PropertyZip",
      label: "Property Zip",
    },
    {
      key: "phone",
      defaultValue: "Phone",
      label: "Phone",
      required: true,
    },
    {
      key: "phoneType",
      defaultValue: "PhoneType",
      label: "Phone Type",
    },
    {
      key: "carrierName",
      defaultValue: "CarrierName",
      label: "Carrier Name",
    },
  ]

  convertToCSV(data) {
    const csv = data.map(item => item.key).join(",")
    return `${csv}\n`
  }

  downloadCSV(data, filename) {
    const csvData = this.convertToCSV(data)
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  handleDownloadCSV = () => {
    this.downloadCSV(this.updatedFormData, "prospectTemplate.csv")
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_center() {
    this.removeBodyCss()
    this.setState({
      modal_center: !this.state.modal_center,
    })
  }

  notify(type, text) {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }
  convertToCsv = (data, keys) => {
    // Define the headers for the CSV file
    const headers = keys.join(",")
    // Map over each object in the data array and convert it to a CSV row
    const rows = data.map(obj => {
      // Extract the values for the desired keys
      const values = keys.map(key => obj[key])
      // Convert the values to a CSV row
      return values.join(",")
    })
    // Combine the headers and rows into a single CSV string
    return `${headers}\n${rows.join("\n")}`
  }
  handleValidSubmit(event, values) {
    if (this.state.selectedLeadStages.length > 0 && this.state.replied) {
      let filter = { lead: [] }
      let value = this.state.selectedLeadStages.map(item => item.value)
      filter.lead = value
      filter.status = false
      if (this.state.replied.value == "both") {
        let data = {
          id: this.props.campaign._id,
          filter: filter,
          name: "",
        }
        this.props.campaignFilterProspects(data)
        window.location.reload()
      } else {
        filter.replied = this.state.replied.value
        let data = {
          id: this.props.campaign._id,
          filter: filter,
          name: "",
        }
        this.props.campaignFilterProspects(data)
        window.location.reload()
      }
    }
  }

  handleAddProspectSubmit(event, values) {
    if (values.upload_file === "") {
      this.setState({
        select_file: true,
      })
      return
    } else {
      this.setState({
        select_file: false,
      })
    }

    const formdata = new FormData()
    formdata.append("file", this.state.file)
    // this.props.uploadProspectsFile(formdata)
    this.uploadProspectsFile(formdata)
  }

  async prospectSubmitHandler(event, values) {
    const data = []
    const validatedData = []
    const errorRows = []
    const compulsaryKeys = ["phone", "firstName", "lastName"]

    this.state.csvData?.data?.forEach((item, index) => {
      const prospect = {}
      const keys = Object.keys(this.state.formData)

      keys.forEach(key => {
        if (item[this.state.csvData.headerIndexData[values[key]]])
          prospect[key] = item[this.state.csvData.headerIndexData[values[key]]]
      })
      let isValid = true
      for (let i = 0; i < compulsaryKeys.length; i++) {
        const ck = compulsaryKeys[i]
        if (!prospect[ck]) {
          isValid = false
          break
        }
      }
      if (isValid) {
        data.push(prospect)
      } else {
        errorRows.push(index + 2)
      }
    })

    data.forEach((item, index) => {
      const validPhone = this.validatePhoneNumber(item.phone)
      const isDuplicate = validatedData.some(dup => dup.phone === validPhone)
      if (isDuplicate) {
        errorRows.push(index + 2)
      } else {
        if (validPhone) {
          validatedData.push({ ...item, phone: validPhone })
        } else {
          errorRows.push(index + 2)
        }
      }
    })
    const payload = {
      data: validatedData,
      fileName: this.state.csvData.fileName,
      campaignId: this.props.campaign._id,
    }
    if (errorRows.length) {
      this.setState({
        confirmUploadSweetAlert: true,
        counts: {
          inValidCount: errorRows.length,
          totalCount: this.state.csvData?.data.length,
          validCount: validatedData.length,
          inValidEntriesIndex: errorRows.sort((a, b) => a - b),
        },

        finalData: payload,
      })
    } else {
      
      this.handleCreateBulkProspect(payload)
      this.setState({
        counts: {
          inValidCount: errorRows.length,
          totalCount: this.state.csvData?.data.length,
          validCount: validatedData.length,
          inValidEntriesIndex: errorRows.sort((a, b) => a - b),

        },
      
      })
    }
  }

 
  async handleCreateBulkProspect(payload) {
    // const payload = {
    //   data: validatedData,
    //   fileName: this.state.csvData.fileName,
    //   campaignId: this.props.campaign._id,
    // }
    const csv = this.convertToCsv(payload.data, Object.keys(payload.data[0]))

    // Create a FormData object and append the CSV file
    const formData = new FormData()
    const csvBlob = new Blob([csv], { type: "text/csv" })
    formData.append("csvFile", csvBlob, "prospects.csv")
    formData.append("fileName", this.state.csvData.fileName)
    formData.append("campaignId", this.props.campaign._id)
    

    this.setState({ isLoading: true })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({
      isLoading: false,
      // addProspect: false,
      confirmUploadSweetAlert: false,
      formSubmitted: { isSubmitted: true, progressPer: 0 },
    })

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/campaign/createBulkProspects",
        formData,
        config
      )

      if (response.data) {
        this.setState({
          isLoading: false,
          addProspect: false,
          confirmUploadSweetAlert: false,
          formSubmitted: { isSubmitted: false },
          fileHeaders: [],
        })

        window.location.reload();

        this.notify("success", "Prospects are created successfully")
      }

      // this.setState({ upload: response?.data?.data })
    } catch (err) {
      this.setState({
        isLoading: false,
        addProspect: false,
        confirmUploadSweetAlert: false,
        formSubmitted: { isSubmitted: false },
        fileHeaders: [],

        duplicateProspect : {
          isOpen :true , 
          data : err?.response?.data ? err?.response?.data : []
          // prospectData :err?.response?.data[0]?.count,
          // campaignData :err?.response?.data[0]?.campaign

        }
      })
     
    }
  }
 
  async uploadProspectsFile(data) {
    // this.setState({ isLoading: true })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/campaign/upload",
        data,
        config
      )
      this.setState({ upload: response?.data?.data })
    } catch (err) {
      // this.setState({ isLoading: false })
      // this.notify("error", err)
    }
  }

  handleFileUpload = (data, fileInfo) => {
    // Assuming the first row in the CSV file contains headers
    let csvHeaders = data[0]
    const headerIndexData = {}
    csvHeaders.forEach((item, index) => {
      headerIndexData[item] = index
    })

    // Set headers to state

    // Remove the headers row from the data
    const csvDataWithoutHeaders = data.slice(1)

    // Perform validation or other operations on csvDataWithoutHeaders

    // Set the data to state
    if (
      csvDataWithoutHeaders?.every(value => {
        return (
          value === "" ||
          (Array.isArray(value) && value.length === 0) ||
          value.every(value => {
            return value === ""
          })
        )
      })
    ) {
      csvHeaders = [""]
    }
    this.setState({
      fileHeaders: csvHeaders,
      csvData: {
        headerIndexData,
        data: csvDataWithoutHeaders,
        fileName: fileInfo.name,
      },
    })
    let formObj = {}
    this.updatedFormData.forEach(item => {
      formObj[item.key] = this.getValidValue(item.defaultValue)
    })
    this.setState({ formState: { ...this.state.formState, ...formObj } })
  }

  getValidValue(val) {
    // return undefined
    // let val = value
    // if (this.state.formState[key]) {
    //   val = this.state.formState[key]
    // }
    const cleanedQuery = val.replace(/[_-\s]/g, "").toLowerCase()

    for (let i = 0; i < this.state.fileHeaders.length; i++) {
      const cleanedItem = this.state.fileHeaders[i]
        .replace(/[_-\s]/g, "")
        .toLowerCase()
      if (cleanedQuery === cleanedItem) {
        return this.state.fileHeaders[i]
      }
    }

    return undefined
  }

  validatePhoneNumber = phone => {
    const cleanPhone = phone.replace(/\D/g, "")

    if (cleanPhone.length === 11 && cleanPhone.startsWith("1")) {
      return `+${cleanPhone}`
    } else if (cleanPhone.length > 10 || cleanPhone.length < 10) {
      return false
    } else if (cleanPhone.length === 10) {
      return `+1${cleanPhone}`
    }
  }


  toggleDuplicateProspect()
  {
  
    this.setState({
      duplicateProspect : {
        isOpen :!this.state.duplicateProspect?.isOpen , 
        data : [] ,
      },
      addProspect : false,
      fileHeaders: [],
    })
  }

  async handleFinallyCreateBulkProspect(payload , action) {
    
    
    const csv = this.convertToCsv(payload.data, Object.keys(payload.data[0]))

    
    const formData = new FormData()
    const csvBlob = new Blob([csv], { type: "text/csv" })
    formData.append("csvFile", csvBlob, "prospects.csv")
    formData.append("fileName", this.state.csvData.fileName)
    formData.append("campaignId", this.props.campaign._id)
    formData.append('prosDuplicateDefaultAction' , action)
    

    this.setState({ isLoading: true })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({
      isLoading: false,
      duplicateProspect : {
        isOpen :false,
        data : []
      },
      addProspect: true,
      formSubmitted: { isSubmitted: true, progressPer: 0 },
    })

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/campaign/createBulkProspects",
        formData,
        config
      )

      if (response.data) {
        this.setState({
          isLoading: false,
          addProspect: false,
          confirmUploadSweetAlert: false,
          formSubmitted: { isSubmitted: false },
          fileHeaders: [],
        })
        window.location.reload();

        this.notify("success", "Prospects are created successfully")
      }

      // this.setState({ upload: response?.data?.data })
    } catch (err) {
      this.setState({
        isLoading: false,
        addProspect: false,
        confirmUploadSweetAlert: false,
        formSubmitted: { isSubmitted: false },
        fileHeaders: [],
      })
      this.notify("error", err?.response?.data?.error, err?.data?.message)
    }
  }
  render() {
    return (
      <React.Fragment>
        <Toaster />
        <Row className="align-items-center breadcrumb-box">
          <Col sm={6} md={6} xl={7}>
            <div className="page-title-box d-flex flex-row align-items-end gap-3">
              <h4 className="page-title font-size-md">
                {this.props.campaign?.name
                  ? `${this.props.campaign?.name}`
                  : "Campaigns"}
              </h4>
            </div>
          </Col>
          <Col sm={6} md={6} xl={5}>
            <div className="d-flex flex-wrap gap-3 justify-content-end">
              {this.props.campaign ? (
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <button
                    className="main-btn"
                    onClick={() => {
                      this.setState({ addProspect: !this.state.addProspect })
                    }}
                    type="button"
                  >
                    <img src={plus} alt="" />
                    <span>Add New Prospect</span>
                  </button>
                  <button
                    className="main-btn border-btn"
                    onClick={() => {
                      this.handleDownloadCSV()
                    }}
                    type="button"
                  >
                    <i className="fas fa-download text-indigo"></i>
                    <span>Default Template</span>
                  </button>
                </div>
              ) : null}
            </div>
          </Col>
          {/* this.state.confirmUploadSweetAlert */}
          {this.state.confirmUploadSweetAlert ? (
            <SweetAlert
              title="Are you sure?"
              info
              showCancel
              confirmBtnText={`Continue with ${
                this.state.counts.validCount > 1
                  ? this.state.counts.validCount + " Entries"
                  : this.state.counts.validCount + " Entry"
              } `}
              confirmBtnDisabled={!this.state.counts.validCount}
              cancelBtnText="Back"
              confirmBtnBsStyle={
                !this.state.counts.validCount ? "secondary" : "primary"
              }
              cancelBtnBsStyle="dark"
              // cancelBtnCssClass={`main-btn secondary-btn`}
              // confirmBtnCssClass={`main-btn ${!this.state.counts.validCount  ? "opacity-50" : ""}`}
              onConfirm={() => {
                if (this.state.counts.validCount) {
                  this.handleCreateBulkProspect(this.state.finalData)
                }
              }}
              onCancel={() => {
                // setDripRemoveConfirm({ isOpen: false })
                this.setState({ confirmUploadSweetAlert: false })
              }}
            >
              <div className="d-flex flex-column align-items-start gap-1 p-2 border-bottom mb-2">
                <div>
                  <p className="mb-1 ">
                    Total Prospect:
                    <strong> {this.state.counts.totalCount} </strong> || Valid
                    Entry:<strong> {this.state.counts.validCount} </strong> ||
                    Invalid Entry:
                    <strong> {this.state.counts.inValidCount}</strong>
                  </p>
                  <p className="mb-1 ">
                    Invalid Rows Index :{" "}
                    <strong>
                      {this.state.counts.inValidEntriesIndex.join(" , ")}
                    </strong>
                  </p>
                  {/* {moment
                .utc(dripRemoveConfirm.dripData.dripStartDate)
                .local()
                .format("YYYY-MM-DD hh:mm:ss A")} */}
                </div>
              </div>
              Do you want to continue with Valid Entries ? You aren't able to
              Upload the Invalid Entries
              {/* You want to remove drip? You won't be able to revert this! */}
            </SweetAlert>
          ) : null}
          <Col md={12} className={"pb-2"}>
            <span
              className={
                "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
              }
            ></span>
          </Col>
          {this.state.isLoading ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : null}
        </Row>
        {/* follow up modal  */}
        <Modal
          isOpen={this.state.modal_center}
          toggle={() => {
            this.tog_center()
          }}
          backdrop="static"
          centered={true}
        >
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Follow Up</h5>
              <button
                type="button"
                onClick={() => {
                  this.setState({
                    modal_center: false,
                  })
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Lead Stage</Label>
                    <Select
                      isMulti={true}
                      onChange={values => {
                        if (values) {
                          this.setState({
                            selectedLeadStages: values,
                          })
                        } else {
                          this.setState({
                            selectedLeadStages: [],
                          })
                        }
                      }}
                      options={
                        this.props.leadStages &&
                        this.props.leadStages.map((item, index) => {
                          return { label: item.lead_Stage, value: item._id }
                        })
                      }
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom02">
                      Include Prospects who have responded?
                    </Label>
                    <Select
                      value={this.state.replied}
                      onChange={e => {
                        this.setState({
                          replied: e,
                        })
                      }}
                      options={this.showdata}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className={"text-end"}>
                <Col>
                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    onClick={() => {
                      this.setState({
                        modal_center: false,
                      })
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Create
                  </button>
                </Col>
              </Row>
            </div>
          </AvForm>
        </Modal>

        {/* add prospect modal  */}
        <Modal
          isOpen={this.state.addProspect}
          toggle={() => {
            this.setState({
              addProspect: !this.state.addProspect,
              fileHeaders: [],
            })
          }}
          className="c-modal modal-lg"
          centered={true}
        >
          <div className="modal-body">
            <h5 className="modal-title mt-0 mb-4 ">Add Prospect</h5>
            <button
              type="button"
              onClick={() => {
                this.setState({
                  addProspect: !this.state.addProspect,
                  fileHeaders: [],
                })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>

            <CardBody>
            

              {this.state.select_file ? (
                <Alert color="danger">{"Please select file"}</Alert>
              ) : null}

              {this.state.formSubmitted.isSubmitted ? (
                <Row className="gy-3">
                  {/*  */}
                  {
                    <>
                      <Col md={4}>
                        <div className="c-card ic-card p-3">
                          <p className="mb-0">
                            Total Prospect:
                            <strong> {this.state.counts?.totalCount} </strong>
                          </p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="c-card ic-card p-3">
                          <p className="mb-0">
                            Valid Prospect:
                            <strong> {this.state.counts?.validCount} </strong>
                          </p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="c-card ic-card p-3">
                          <p className="mb-0">
                            Invalid Prospect:
                            <strong> {this.state.counts?.inValidCount}</strong>
                          </p>
                        </div>
                      </Col>
                    </>
                  }
                  <Col md={12} className="mt-3">
                    Status:{" "}
                    <strong>
                      {(this.state.formSubmitted?.progressPer).toFixed(2)}%
                    </strong>
                  </Col>
                  <Col md={12}>
                    {
                      <Progress
                        style={{
                          height: "16px",
                          borderRadius: "20px",
                          marginBottom: "10px",
                        }}
                        color="success"
                        value={
                          isNaN(this.state.formSubmitted?.progressPer)
                            ? 0
                            : (this.state.formSubmitted?.progressPer).toFixed(2)
                        }
                      ></Progress>
                    }
                  </Col>
                </Row>
              ) : (
                <>
                  <AvForm
                    // onValidSubmit={(e, v) => this.handleAddProspectSubmit(e, v)}
                    className="needs-validation"
                  >
                    <Row className={"px-2"}>
                      <Col md={12}>
                        <div className="d-flex justify-content-start align-items-center gap-2  ">
                          <Label htmlFor="fileUploader" className="mb-0">
                            Upload file{" "}
                          </Label>
                          <CSVReader
                            onFileLoaded={this.handleFileUpload}
                            id="fileUploader"
                          />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  {this.state.fileHeaders &&
                  this.state.fileHeaders.length > 1 ? (
                    <AvForm
                      onValidSubmit={(e, v) => this.prospectSubmitHandler(e, v)}
                      className="needs-validation"
                    >
                      <div>
                        <Row className="py-1 px-1">
                          {this.updatedFormData.map((item, index) => (
                            <Col key={index} md={6} xl={6} sm={12} xs={12}>
                              <Label>{item.label}</Label>
                              <AvField
                                type="select"
                                onChange={e => {
                                  this.setState(prevState => ({
                                    formState: {
                                      ...prevState.formState,
                                      [item.key]: this.getValidValue(
                                        e.target.value
                                      ),
                                    },
                                  }))
                                }}
                                validate={{
                                  ...(item.required
                                    ? {
                                        required: {
                                          value: true,
                                          errorMessage: `Please select ${item.label}`,
                                          pattern: {
                                            value: /^[^\s]+$/,
                                            errorMessage:
                                              "Value cannot be only whitespace",
                                          },
                                        },
                                      }
                                    : {}),
                                }}
                                name={item.key}
                                value={this.getValidValue(
                                  // item.key,
                                  item.defaultValue
                                )}
                              >
                                <option value={null} key={index}>
                                  Select {item.label}
                                </option>
                                {this.state.fileHeaders.map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </AvField>
                            </Col>
                          ))}
                          <Col md={12}>
                            <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                              <Button
                                className="main-btn secondary-btn"
                                onClick={() => {
                                  this.setState({
                                    addProspect: !this.state.addProspect,
                                    upload: [],
                                    fileHeaders: [],
                                  })
                                }}
                                role="button"
                              >
                                <span>Cancel</span>
                              </Button>
                              <Button
                                type="submit"
                                color="primary"
                                className="main-btn"
                                disabled={
                                  !(
                                    this.state.formState.firstName &&
                                    this.state.formState.lastName &&
                                    this.state.formState.phone
                                  )
                                }
                              >
                                <span>Submit</span>
                              </Button>{" "}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </AvForm>
                  ) : (
                    <div className="chat-illus illus-empty-state">
                      {this.state.fileHeaders.length == 1 ? (
                        <>
                          <img src={illus} alt="" />
                          <h3 className="text-danger">
                            Please Upload Valid File
                          </h3>
                        </>
                      ) : (
                        <>
                          {" "}
                          <img src={illus} alt="" />
                          <h3>Please Upload File</h3>
                          <Link
                            to="#"
                            className={"text-indigo text-decoration-underline"}
                            onClick={() => {
                              this.handleDownloadCSV()
                            }}
                          >
                            Download Default Template
                          </Link>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </div>
        </Modal>



        {/* showing duplicate prospect data  */}

        <Modal
          isOpen={this.state.duplicateProspect?.isOpen}
          toggle={() => {
            this.toggleDuplicateProspect()
          }}
          className="c-modal"
          centered={true}
        >
          <div className="modal-body ">
            <h5 className="modal-title mt-0 mb-3">Duplicate Prospects</h5>
            <button
              type="button"
              onClick={() => {
                this.toggleDuplicateProspect()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>

            <div className="select-modal-wr">
              <Table responsive="sm" className="table veltrix-table">
                <Thead style={{ textAlign: "center" }}>
                  <Tr>
                    {/* <Th>Action</Th> */}
                    <Th>Campaign Name </Th>
                    <Th>Count</Th>
                  </Tr>
                </Thead>

                <Tbody style={{ textAlign: "center" }}>
                  {
                   this.state.duplicateProspect?.data.length && this.state.duplicateProspect.data?.map((item, index)=>{
                    return(
                      <Tr key={index}>
                           <Td valign="middle">
                            <div className="file-name">
                              <p className="overflow-200">
                                {item?.campaign?.name}   
                              </p>
                            </div>
                          </Td>
                          
                          <Td>
                            <div className="file-name justify-content-center text-left">
                              <p className="overflow-200 ">{item?.count}</p>
                            </div>
                          </Td>
                         
                        </Tr>
                    )

                   })
                  }
                      
                        
                     
                </Tbody>
              </Table>
            </div>
            <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2 border-top-2">
              <Button
                
                className=" main-btn border-btn"
                // style={{backgroundColor:"#efefef",border:'1px solid #5932ea', color:'black !important'}}
                onClick={() => {
                  this.handleFinallyCreateBulkProspect(this.state.finalData , 'ignore')
                  
                }}
                role="button"
              >
                <span>Ignore</span>
              </Button>
              <Button
                type="submit"
                color="primary"
                className="main-btn"

               onClick={()=>{
                
                this.handleFinallyCreateBulkProspect(this.state.finalData , 'override')

               }}
              >
                <span>Override</span>
              </Button>{" "}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CampaignHeader
