import {
  GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS,
  API_ERROR
} from "./actionTypes"

export const getNotifications = () => {
  return {
    type: GET_NOTIFICATIONS
  }
}

export const getNotificationsSuccess = notification => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: notification,
  }
}

export const getNotificationError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
