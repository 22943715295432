import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class ApexChartSemiDonut extends React.Component {
  constructor(props) {
      super(props)
    this.state = {
      series: [...this.props.data],
      options: {
        chart: {
          type: "donut",
          width: "100%",
        },
        legend: {
          position: "bottom",
        },
        labels: [
          "Hot Leads",
          "Message Received",
          "Initial Message Sent",
          "Drip",
        ],
        colors: ["#5932EA", "#8CB5C6", "#FFA96B", "#5EC3FF"],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10,
          },
        },
        grid: {
          padding: {
            bottom: -150,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    }
  }

  render() {
    return (
      <div id="chart" className="semidonut-chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
        />
      </div>
    )
  }
}

export default ApexChartSemiDonut
