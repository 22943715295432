import {
  GET_USERS,
  GET_USERS_SUCCESS,
  API_ERROR,
  EDIT_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  ADD_USER_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: true,
  users: [],
  totalPages: 1,

  apiError: null,
}

const Users = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      state = {
        ...state,
        loading: true,
        users: [],
        apiError: null,
      }
      break
    case GET_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        apiError: null,
        successMsg: null,
        ...action.payload,
      }
      break
    case ADD_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case EDIT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Users
