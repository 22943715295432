import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  GET_LEAD_STAGES,
  ADD_LEAD_STAGE,
  DELETE_LEAD_STAGE
} from "./actionTypes"

import {
  addLeadStageSuccess, deleteLeadStageSuccess,
  getLeadStagesError,
  getLeadStagesSuccess
} from "./actions"

import {addLeadStage, deleteLeadStage, getLeadStages} from "../../helpers/fakebackend_helper";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getLeadStagesApi() {
  try {
    const response = yield call(getLeadStages)
    yield put(getLeadStagesSuccess(response.data));
  } catch (error) {
    yield put(getLeadStagesError(error))
  }
}


function* addLeadStageApi({ payload: optOut }) {
  try {
    const response = yield call(addLeadStage,optOut)
    yield put(addLeadStageSuccess('Custom Lead Stage added successfully'));
    yield delay(800);
    const response1 = yield call(getLeadStages)
    yield put(getLeadStagesSuccess(response1.data));
  } catch (error) {
    yield put(getLeadStagesError(error))
  }
}

function* deleteLeadStageApi({ payload: optOut }) {
  try {
    const response = yield call(deleteLeadStage,optOut)
    yield put(deleteLeadStageSuccess('Custom Lead Stage deleted successfully'));
    yield delay(1000);
    const response1 = yield call(getLeadStages)
    yield put(getLeadStagesSuccess(response1.data));

  } catch (error) {
    yield put(getLeadStagesError(error))
  }
}

export function* watchLeadStages() {
  yield takeEvery(GET_LEAD_STAGES, getLeadStagesApi)
  yield takeEvery(ADD_LEAD_STAGE, addLeadStageApi)
  yield takeEvery(DELETE_LEAD_STAGE, deleteLeadStageApi)
}

function* LeadStagesSaga() {
  yield all([fork(watchLeadStages)])
}

export default LeadStagesSaga
