import {
  ACTIVATE_NUMBER,
  ACTIVATE_NUMBER_SUCCESS,
  ADD_NUMBER,
  ADD_NUMBER_SUCCESS,
  API_ERROR,
  DEACTIVATE_NUMBER, DEACTIVATE_NUMBER_SUCCESS,
  DELETE_NUMBER,
  DELETE_NUMBER_SUCCESS,
  GET_FILTER_NUMBERS,
  GET_FILTER_NUMBERS_SUCCESS,
  GET_NUMBERS,
  GET_NUMBERS_SUCCESS
} from "./actionTypes"

import {
  GET_MARKETS,
  GET_MARKETS_SUCCESS
} from "../markets/actionTypes";


export const getMarkets = () => {
  return {
    type: GET_MARKETS
  }
}

export const getMarketsSuccess = markets => {
  return {
    type: GET_MARKETS_SUCCESS,
    payload: markets,
  }
}

export const getNumbers = () => {
  return {
    type: GET_NUMBERS
  }
}

export const getNumbersSuccess = numbers => {
  return {
    type: GET_NUMBERS_SUCCESS,
    payload: numbers,
  }
}

export const getFiltersNumbers = (filters) => {
  return {
    type: GET_FILTER_NUMBERS,
    payload: filters,
  }
}

export const getFiltersNumbersSuccess = numbers => {
  return {
    type: GET_FILTER_NUMBERS_SUCCESS,
    payload: numbers,
  }
}

export const getNumbersError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const addNumber = (number) => {
  return {
    type: ADD_NUMBER,
    payload: number,
  }
}

export const addNumberSuccess = (message) => {
  return {
    type: ADD_NUMBER_SUCCESS,
    payload: message,
  }
}

export const deleteNumber = (number) => {
  return {
    type: DELETE_NUMBER,
    payload: number,
  }
}

export const deleteNumberSuccess = (message) => {
  return {
    type: DELETE_NUMBER_SUCCESS,
    payload: message,
  }
}

export const deactivateNumber = (number) => {
  return {
    type: DEACTIVATE_NUMBER,
    payload: number,
  }
}

export const deactivateNumberSuccess = (message) => {
  return {
    type: DEACTIVATE_NUMBER_SUCCESS,
    payload: message,
  }
}

export const activatedNumber = (number) => {
  return {
    type: ACTIVATE_NUMBER,
    payload: number,
  }
}

export const activatedNumberSuccess = (message) => {
  return {
    type: ACTIVATE_NUMBER_SUCCESS,
    payload: message,
  }
}