import {
  ADD_MARKET,
  ADD_MARKET_SUCCESS,
  API_ERROR,
  DELETE_MARKET,
  DELETE_MARKET_SUCCESS,
  EDIT_MARKET,
  EDIT_MARKET_SUCCESS,
  GET_AVAILABLE_MARKETS,
  GET_AVAILABLE_MARKETS_SUCCESS,
  GET_MARKETS,
  GET_MARKETS_SUCCESS
} from "./actionTypes"

const initialState = {
  loading:true,
  availabilities:[],
  markets:[],
  market:{},
  apiError: null,
}

const Market = (state = initialState, action) => {
  switch (action.type) {
    case GET_MARKETS:
      state = {
        ...state,
        loading:true,
        markets: [],
        apiError: null,
      }
      break
    case GET_MARKETS_SUCCESS:
      state = {
        ...state,
        loading:false,
        markets: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_AVAILABLE_MARKETS:
      state = {
        ...state,
        availabilities: [],
        apiError: null,
      }
      break
    case GET_AVAILABLE_MARKETS_SUCCESS:
      state = {
        ...state,
        availabilities: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload }
      break
    case EDIT_MARKET:
      state = {
        ...state,
        market: action.payload,
      }
      break
    case EDIT_MARKET_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break

    case ADD_MARKET:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_MARKET_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_MARKET:
      state = {
        ...state,
        market: action.payload,
      }
      break
    case DELETE_MARKET_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Market
