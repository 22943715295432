import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import {
  GET_QUICK_REPLIES,
  EDIT_QUICK_REPLY,
  ADD_QUICK_REPLY,
  DELETE_QUICK_REPLY,
} from "./actionTypes"

import {
  addQuickReplySuccess,
  editQuickReplySuccess,
  getQuickRepliesError,
  getQuickRepliesSuccess,
} from "./actions"

import {
  addQuickReply,
  deleteQuickReply,
  editQuickReply,
  getQuickReplies,
} from "../../helpers/fakebackend_helper"

const delay = ms => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getQuickRepliesApi() {
  try {
    const response = yield call(getQuickReplies)
 
    yield put(getQuickRepliesSuccess(response.data))
  } catch (error) {

    yield put(getQuickRepliesError(error))
  }
}

function* addQuickReplyApi({ payload: quickReply }) {
  try {
    const response = yield call(addQuickReply, quickReply)
    yield put(addQuickReplySuccess("Quick Reply added successfully"))
    yield delay(800)
    const response1 = yield call(getQuickReplies)
    yield put(getQuickRepliesSuccess(response1.data))
  } catch (error) {
    yield put(getQuickRepliesError(error))
  }
}

function* editQuickReplyApi({ payload: quickReply }) {
  try {
    const response = yield call(editQuickReply, quickReply)
    yield put(editQuickReplySuccess("Quick Reply updated successfully"))
    yield delay(1000)
    const response1 = yield call(getQuickReplies)
    yield put(getQuickRepliesSuccess(response1.data))
  } catch (error) {

    yield put(getQuickRepliesError(error))
  }
}

function* deleteQuickReplyApi({ payload: quickReply }) {
  try {
    const response = yield call(deleteQuickReply, quickReply)
    yield put(editQuickReplySuccess("Quick Reply deleted successfully"))
    yield delay(1000)
    const response1 = yield call(getQuickReplies)
    yield put(getQuickRepliesSuccess(response1.data))
  } catch (error) {
  
    yield put(getQuickRepliesError(error))
  }
}

export function* watchQuickReplies() {
  yield takeEvery(GET_QUICK_REPLIES, getQuickRepliesApi)
  yield takeEvery(EDIT_QUICK_REPLY, editQuickReplyApi)
  yield takeEvery(ADD_QUICK_REPLY, addQuickReplyApi)
  yield takeEvery(DELETE_QUICK_REPLY, deleteQuickReplyApi)
}

function* QuickRepliesSaga() {
  yield all([fork(watchQuickReplies)])
}

export default QuickRepliesSaga
