import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_SUCCESS,
  VERIFY_RECOVER_PASSWORD_TOKEN,
} from "./actionTypes"

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}


export const userVerifyRecoverPasswordToken = (token) => {
  return {
    type: VERIFY_RECOVER_PASSWORD_TOKEN,
    payload: token ,
  }
}

export const userRecoverPassword = (user, history) => {
  return {
    type: RECOVER_PASSWORD,
    payload: { user, history },
  }
}

export const userRecoverPasswordError = message => {
  return {
    type: RECOVER_PASSWORD_ERROR,
    payload: message,
  }
}

export const userRecoverPasswordSuccess = message => {
  return {
    type: RECOVER_PASSWORD_SUCCESS,
    payload: message,
  }
}
