export const API_ERROR = "API_ERROR"
export const GET_MARKETS = "GET_MARKETS"
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS"
export const EDIT_MARKET = "EDIT_MARKET"
export const EDIT_MARKET_SUCCESS = "EDIT_MARKET_SUCCESS"
export const ADD_MARKET = "ADD_MARKET"
export const ADD_MARKET_SUCCESS = "ADD_MARKET_SUCCESS"
export const DELETE_MARKET = "DELETE_MARKET"
export const DELETE_MARKET_SUCCESS = "DELETE_MARKET_SUCCESS"
export const GET_AVAILABLE_MARKETS = "GET_AVAILABLE_MARKET"
export const GET_AVAILABLE_MARKETS_SUCCESS = "GET_AVAILABLE_MARKETS_SUCCESS"
