export const API_ERROR = "API_ERROR"
export const GET_TEMPLATE = "GET_TEMPLATE"
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS"
export const GET_TEMPLATES = "GET_TEMPLATES"
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS"
export const GET_TEMPLATES_GROUPED_BY_CATEGORY = "GET_TEMPLATES_GROUPED_BY_CATEGORY"
export const GET_TEMPLATES_GROUPED_BY_CATEGORY_SUCCESS = "GET_TEMPLATES_GROUPED_BY_CATEGORY_SUCCESS"
export const EDIT_TEMPLATE = "EDIT_TEMPLATE"
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS"
export const ADD_TEMPLATE = "ADD_TEMPLATE"
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS"
export const DELETE_TEMPLATE = "DELETE_TEMPLATE"
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS"
export const GET_NEGATIVE_KEYWORDS = "GET_NEGATIVE_KEYWORDS"
export const GET_NEGATIVE_KEYWORDS_SUCCESS = "GET_NEGATIVE_KEYWORDS_SUCCESS"
export const MANAGE_NEGATIVE_KEYWORDS = "MANAGE_NEGATIVE_KEYWORDS"
export const MANAGE_NEGATIVE_KEYWORDS_SUCCESS =
  "MANAGE_NEGATIVE_KEYWORDS_SUCCESS"
export const EDIT_CATEGORY = "EDIT_CATEGORY"
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS"
