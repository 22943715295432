
import React, { useState } from "react";
import _debounce from "lodash.debounce";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Alert,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Row,
  Label,
  InputGroup,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./Contact.scss";
import axios from "axios";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddContact = (props) => {
  // const navigate = useHistory();
  const [successMsg, setSuccessMsg] = useState(false);

     let authUser = localStorage?.getItem('authUser');
   let token = false;
   if(authUser) {
     let user = JSON.parse(authUser);
     token = user?.user?.token;
   } else {
     token = false;
   }

  const handleFormSubmit = (event, values) => {
    event.preventDefault();
    setSuccessMsg(true);
  };

  if (successMsg) {
    return <Redirect to="/contact-cart"></Redirect>;
  }

  ///states
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [mailing_address, setMailingAddress] = useState("");
  const [mailing_city, setMailingCity] = useState("");
  const [mailing_state, setMailingState] = useState("");
  const [mailing_zip, setMailingZip] = useState("");
  const [property_address, setPropertyAddress] = useState("");
  const [property_city, setPropertyCity] = useState("");
  const [property_state, setPropertyState] = useState("");
  const [property_zip, setPropertyZip] = useState("");
  const [phone, setPhone] = useState({
    ["0"]: "",
  });
  const [phone_error, setPhoneError] = useState({
    ["0"]: "",
  });
  const [res, setRes] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState({
    state: false,
    path: "",
  })
  //handle submit
  const handleSubmit = async() => {
    const phoneArr = Object.values(phone);
    let error = false
    let phone_error = {};
    phoneArr.map((item, index) => {
      if(!item.length){
        phone_error = {...phone_error, [index]: "Phone number is required"};
        error = true;
      }
    });
    setPhoneError(phone_error);

    if(!first_name.trimStart() || !last_name.trimStart() || !mailing_address.trimStart() || !mailing_city.trimStart() || !mailing_state.trimStart() || !mailing_zip.trimStart() || !property_address.trimStart() || !property_city.trimStart() || !property_state.trimStart() || !property_zip.trimStart() || error ){
      setRes("Please fill all the fields");
      setTimeout(() => {
        setRes("");
      }, 1000);
      return;
    }
    let formdata = new FormData();
    formdata.append("FirstName", first_name);
    formdata.append("LastName", last_name);
    formdata.append("MailingAddress", mailing_address);
    formdata.append("MailingCity", mailing_city); 
    formdata.append("MailingState", mailing_state);
    formdata.append("MailingZip", mailing_zip);
    formdata.append("PropertyAddress", property_address);
    formdata.append("PropertyCity", property_city);
    formdata.append("PropertyState", property_state);
    formdata.append("PropertyZip", property_zip);
    formdata.append("phone", JSON.stringify(phoneArr));
    let data = {
      FirstName: first_name,
      LastName: last_name,
      MailingAddress: mailing_address,
      MailingCity: mailing_city,
      MailingState: mailing_state,
      MailingZip: mailing_zip,
      PropertyAddress: property_address,
      PropertyCity: property_city,
      PropertyState: property_state,
      PropertyZip: property_zip,
      phone: JSON.stringify(phoneArr)
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
      const res = await axios.post(process.env.REACT_APP_PORT+"/api/verifiedcontact/VerifiedContacts", data ,config);
      if(res.status === 200){
        setRes("Successfully submitted");
        setTimeout(() => {
          setRes("");
          // navigate.push("/contact-cart");
          setShouldRedirect({
            state: true,
            path: `/contact-cart`,
          })
          setLoading(false);
        }, 1000);

      }
      else{
        setRes("Something went wrong");
        setTimeout(() => {
          setRes("");
          setLoading(false);
        }, 1000);
      }

  }

  return (      
     <React.Fragment>
        {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}
         <div className="page-content">
           <MetaTags>
             <title>Add Contact | TextLaunchPad.com </title>
           </MetaTags>
           <div className="container-fluid">
             <Row className="align-items-center">
               <Col xs={12}>
                 <div className="page-title-box">
                   <h4 className="font-size-18">Add Contact</h4>
                   <ol className="breadcrumb mb-0">
                     <BreadcrumbItem>
                       <Link to="/">TextLaunchPad</Link>
                     </BreadcrumbItem>
                     <BreadcrumbItem>
                       <Link to="/contact-cart">Contact Cart</Link>
                     </BreadcrumbItem>
                     <BreadcrumbItem active>Add Contact</BreadcrumbItem>
                   </ol>
                 </div>
               </Col>
             </Row>
             <Row className={"justify-content-center pt-4"}>
               <Col xl="6">
                 <Card>
                   <CardBody>
                   {res && <Alert color={res=="Successfully submitted"?"success":"danger"} >{res}</Alert>}
                     <AvForm className="needs-validation">
                       <Row>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                               value={first_name}
                                onChange={(e) => setFirstName(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter first name"
                               name="first name"
                               label="First Name"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter last name"
                               name="last name"
                               label="Last Name"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={mailing_address}
                                onChange={(e) => setMailingAddress(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter Mailing Address"
                               name="mailing address"
                               label="Mailing Address"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={mailing_city}
                                onChange={(e) => setMailingCity(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter Mailing City"
                               name="mailing city"
                               label="Mailing City"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={mailing_state}
                                onChange={(e) => setMailingState(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter Mailing State"
                               name="mailing state"
                               label="Mailing state"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={mailing_zip}
                                onChange={(e) => setMailingZip(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter Mailing Zip"
                               name="mailing zip"
                               label="Mailing zip"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={property_address}
                                onChange={(e) => setPropertyAddress(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter Property Address"
                               name="property address"
                               label="Property Address"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={property_city}
                                onChange={(e) => setPropertyCity(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter property city"
                               name="property city"
                               label="Property city"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={property_state}
                                onChange={(e) => setPropertyState(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter property state"
                               name="property state"
                               label="Property state"
                             ></AvField>
                           </FormGroup>
                         </Col>
                         <Col md={6}>
                           <FormGroup className="mb-3">
                             <AvField
                               required
                                value={property_zip}
                                onChange={(e) => setPropertyZip(e.target.value.trimStart())}
                               type="text"
                               placeholder="Enter property zip"
                               name="property zip"
                               label="Property zip"
                             ></AvField>
                           </FormGroup>
                         </Col>
                        
                       </Row>
                       <Row>
                         <Col md={12}>
                           {Object.keys(phone).map((item, index) => {
                              return (
                                <div className="number-addon">
                                  <FormGroup className="mb-3">
                                    <label for="phone_nu">{"Phone "+(index+1)}</label>
                                    <input
                                      id="phone_nu"
                                      required
                                      value={phone[item]}
                                      onChange={(e) => {
                                        setPhone({...phone, [item]: e.target.value.trimStart()});
                                        setPhoneError({...phone_error, [item]: ""});
                                      }}
                                      type="number"
                                      placeholder="Enter number"
                                      name="number"
                                      className={phone_error[item]?"form-control-number error":"form-control-number"}
                                    ></input>
                                  </FormGroup>
                                  <Button type="button" className="add-number-button">
                                    {index === 0 ? (
                                      <i className="mdi mdi-plus"
                                      onClick={() => {
                                        setPhone({...phone, [Object.keys(phone).length]: ""});
                                        setPhoneError({...phone_error, [Object.keys(phone).length]: ""});
                                        
                                        }}
                                      />
                                    ) :(
                                    <i className="mdi mdi-minus"
                                    onClick={() => {
                                      let temp = {...phone};
                                      delete temp[item];
                                      setPhone(temp);
                                      let temp1 = {...phone_error};
                                      delete temp1[item];
                                      setPhoneError(temp1);
                                    }}
                                    />
                                    )}
                                  </Button>
                                </div>
                              );
                           }  )}
                          
                         </Col>
                       </Row>
                       <Row>
                         <Col md={12} className={"text-end"}>
                           <Link
                             to="/contact-cart"
                             className="btn btn-dark me-2"
                             role="button"
                           >
                             Cancel
                           </Link>
                           <Button type="submit" color="primary" className="h-35"
                            onClick={handleSubmit}
                           >
                             Submit
                           </Button>{" "}
                         </Col>
                       </Row>
                     </AvForm>
                   </CardBody>
                 </Card>
               </Col>
             </Row>
           </div>
         </div>
       </React.Fragment>
       )}

     

     export default AddContact