import {
  ADD_MARKET,
  ADD_MARKET_SUCCESS,
  API_ERROR,
  DELETE_MARKET,
  DELETE_MARKET_SUCCESS,
  EDIT_MARKET,
  EDIT_MARKET_SUCCESS,
  GET_AVAILABLE_MARKETS,
  GET_AVAILABLE_MARKETS_SUCCESS,
  GET_MARKETS,
  GET_MARKETS_SUCCESS
} from "./actionTypes"

export const getMarkets = () => {
  return {
    type: GET_MARKETS
  }
}

export const getMarketsSuccess = markets => {
  return {
    type: GET_MARKETS_SUCCESS,
    payload: markets,
  }
}

export const getAvailableMarkets = (code) => {
  return {
    type: GET_AVAILABLE_MARKETS,
    payload:code
  }
}

export const getAvailableMarketsSuccess = availabilities => {
  return {
    type: GET_AVAILABLE_MARKETS_SUCCESS,
    payload: availabilities,
  }
}


export const getMarketsError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}


export const editMarket = (market) => {
  return {
    type: EDIT_MARKET,
    payload: market,
  }
}

export const editMarketSuccess = (message) => {
  return {
    type: EDIT_MARKET_SUCCESS,
    payload: message,
  }
}

export const addMarket = (market) => {
  return {
    type: ADD_MARKET,
    payload: market,
  }
}

export const addMarketSuccess = (message) => {
  return {
    type: ADD_MARKET_SUCCESS,
    payload: message,
  }
}
export const deleteMarket = (market) => {
  return {
    type: DELETE_MARKET,
    payload: market,
  }
}

export const deleteMarketSuccess = (message) => {
  return {
    type: DELETE_MARKET_SUCCESS,
    payload: message,
  }
}
