import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import axios from "axios"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  CardHeader,
  BreadcrumbItem,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Button,
  Badge,
  Spinner,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import PropTypes from "prop-types"
import {
  Link,
  withRouter,
  BrowserRouter as Router,
  Route,
} from "react-router-dom"
import { batch, connect } from "react-redux"

import {
  getCampaign,
  countProspects,
  getCategories,
  getBatch,
  getTemplatesbycat,
  countTempletebyCat,
  sendPropects,
  updateMessageStatusPros,
  autoSendCamp,
  cancelAutoSendCamp,
  getLeadStages,
  update_props,
} from "../../store/campaigns/actions"

import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select"
import CampaignHeader from "./CampaignHeader"

import magicIcon from "../../assets/images/magicIcon.svg"
import skipIcon from "../../assets/images/skipicon.svg"
import planeWhite from "../../assets/images/planeWhite.svg"
import watchWhite from "../../assets/images/watch.svg"
import sendIllus from "../../assets/images/send-illus.svg"
import toast, { Toaster } from "react-hot-toast"
import close from "../../assets/images/close.svg"
import illus from "../../assets/images/chat-illustration.svg"
import Exclamation from "../../assets/images/triangleExclamation.svg"

////socket functions
import io from "socket.io-client"
// const socket = io.connect(process.env.REACT_APP_PORT)
// "http://192.168.29.95:5001"

import uuid from "react-uuid"
class CampaignSend extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id ? this.props.match.params.id : null,
      counttemp: 0,
      procount: 0,
      skip: 0,
      show: false,
      total: 0,
      index: 0,
      prospect: 0,
      selectedcategory: null,
      selected_cat: null,
      modal_add: false,
      num_of_count: null,
      count_err: "",
      exist: null,
      auto_send_count: 0,
      total_auto_send_count: "",
      success_autosend: false,
      isLoading: true,
      flag_auto_send: true,
      after_cancel_autosend_count: 0,
      crrProspect: null,
      autoSendData: {},
      categoriesOptions: [],
      isTimerRunning: false,
      manualSendDelay: 30,
      prospectCount: this.props.count ? this.props.count : null,
    }
    this.manualSendTimer = null
    let authUser = localStorage.getItem("authUser")
    if (authUser) {
      let user = JSON.parse(authUser)
      // process.env.REACT_APP_PORT
      this.socket = io(process.env.REACT_APP_SOCKET_URL, {
        query: {
          email: user.user.name,
        },
      })
    }

    this.socket.on(
      "automessages",
      async (
        i,
        prospects,
        result,
        exist,
        batch_length,
        sended_msg_count,
        client,
        message,
        uniqueCode
      ) => {
        // const crrUniqueCode = localStorage.getItem("autoSendUniqueCode")
        const crrUniqueCode = this.state.autoSendData?.autoSendUniqueCode

        if (crrUniqueCode) {
          if (uniqueCode === crrUniqueCode) {
            if (this.state.show) {
              await this.setState({
                auto_send_count: i + 1,
                total_auto_send_count: batch_length,
                exist: exist,
                after_cancel_autosend_count: i + 1 + sended_msg_count, ///to set count of sended msg after cancel auto send
                crrProspect: { ...client, body: message },
              })
            } else {
              await this.setState({
                auto_send_count: i + 1,
                total_auto_send_count: batch_length,
                exist: exist,
                after_cancel_autosend_count: i + 1 + sended_msg_count, ///to set count of sended msg after cancel auto send
              })
            }
            // localStorage.setItem(
            //   "autoSendDetail",
            //   JSON.stringify({
            //     auto_send_count: i + 1,
            //     total_auto_send_count: batch_length,
            //     after_cancel_autosend_count: i + 1 + sended_msg_count,
            //   })
            // )

            const autoSendData = {
              auto_send_count: i + 1,
              total_auto_send_count: batch_length,
              after_cancel_autosend_count: i + 1 + sended_msg_count,
            }
            this.updateAutoSendData(autoSendData)
            // this.update_prospect_after_auto_sen()
            if (i == batch_length - 1 && this.state.flag_auto_send) {
              this.setState({
                flag_auto_send: false,
                after_cancel_autosend_count: 0, ///to set count of sended msg after end auto send
                success_autosend: true,
                show: false,
              })
              ///to cancel auto send after send all prospects
              // const data = {
              //   id: this.state.id,
              //   exist: exist,
              // }
              // this.props.cancelAutoSendCamp(data)
              // await this.props.update_props({
              //   send_count: i + 1 + sended_msg_count,
              // })

              await this.props.update_props({
                send_count: i + 1 + sended_msg_count,
              })
              // this.clearAutoLocalStorage()
              this.props.getBatch(this.props.match.params.id)
              await this.props.countProspects(this.state.id)
              this.notify("success", "Auto Send Completed Successfully")
            }
          }
        }
        // else {
        //   if (this.state.show) {
        //     await this.setState({
        //       auto_send_count: i + 1,
        //       total_auto_send_count: batch_length,
        //       exist: exist,
        //       after_cancel_autosend_count: i + 1 + sended_msg_count, ///to set count of sended msg after cancel auto send
        //       crrProspect: { ...client, body: message },
        //     })
        //   } else {
        //     await this.setState({
        //       auto_send_count: i + 1,
        //       total_auto_send_count: batch_length,
        //       exist: exist,
        //       after_cancel_autosend_count: i + 1 + sended_msg_count, ///to set count of sended msg after cancel auto send
        //     })
        //   }
        //   // localStorage.setItem(
        //   //   "autoSendDetail",
        //   //   JSON.stringify({
        //   //     auto_send_count: i + 1,
        //   //     total_auto_send_count: batch_length,
        //   //     after_cancel_autosend_count: i + 1 + sended_msg_count,
        //   //   })
        //   // )
        //   const autoSendData = {
        //     auto_send_count: i + 1,
        //     total_auto_send_count: batch_length,
        //     after_cancel_autosend_count: i + 1 + sended_msg_count,
        //   }
        //   this.updateAutoSendData(autoSendData)
        //   // this.update_prospect_after_auto_sen()
        //   if (i == batch_length - 1 && this.state.flag_auto_send) {
        //     this.setState({
        //       flag_auto_send: false,
        //       after_cancel_autosend_count: 0, ///to set count of sended msg after end auto send
        //       success_autosend: true,
        //       show: false,
        //     })
        //     //to cancel auto send after send all prospects
        //     // const data = {
        //     //   id: this.state.id,
        //     //   exist: exist,
        //     // }
        //     // this.props.cancelAutoSendCamp(data)

        //     await this.props.update_props({
        //       send_count: i + 1 + sended_msg_count,
        //     })
        //     // this.clearAutoLocalStorage()
        //     this.props.getBatch(this.props.match.params.id)
        //     this.notify("success", "Auto Send Completed Successfully")
        //   }
        // }
      }
    )
  }
  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_add() {
    this.removeBodyCss()
    this.setState({
      modal_add: !this.state.modal_add,
    })
  }

  callfunction = async () => {
    //geting current data
    // await this.props.getTemplatesbycat()
    // await this.props.countTempletebyCat()
    this.setState({ isLoading: true })
    let id = this.props.match.params.id
    await this.props.countProspects(id)
    await this.props.getCategories()
    await this.props.getCampaign(id)
    // await this.props.getBatch(id)
    await this.props.getLeadStages()
  }

  componentDidMount() {
    this.callfunction()
  }

  notify(type, text) {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error": {
        toast.error(text)
        this.setState({ isLoading: false })
        break
      }
      default:
        break
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.count !== prevProps.count) {
      this.setState({ prospectCount: this.props.count })
    }

    if (!prevProps.campaign && this.props.campaign) {
      this.setState({
        manualSendDelay: this.props.campaign?.delay,
      })
      if (this.props.categories && this.props.categories.length) {
        const tempArr = []
        this.props.categories.forEach(item => {
          if (!!this.props.campaign.isFollowUp === !!item.forFollowUp) {
            tempArr.push({ value: item._id, label: item.name })
          }
        })
        this.setState({
          categoriesOptions: tempArr,
          isLoading: false,
        })
      }
      this.setState({
        show: this.props.campaign.auto,
        isLoading: this.props.campaign.auto,
      })
      if (this.props.campaign.auto) {
        let autoSendData = this.props.campaign.autoSendData
        if (autoSendData) {
          this.setState({
            autoSendData,
            auto_send_count:
              autoSendData.auto_send_count || this.state.auto_send_count,
            total_auto_send_count:
              autoSendData.total_auto_send_count ||
              this.state.total_auto_send_count,
          })
        }
        if (autoSendData) {
          let campaignData = {
            id: autoSendData.selectedCategoryId,
            camp: this.props.campaign._id,
            count: autoSendData.count,
            procount: autoSendData.procount,
            bulkid: autoSendData.bulkid,
          }
          // let selectedCategory = null
          // if (this.props.categories) {
          //   selectedCategory = this.props.categories.filter(
          //     item => item._id === autoSendData.selectedCategoryId
          //   )
          //   selectedCategory = {
          //     value: selectedCategory?.[0]?._id,
          //     label: selectedCategory?.[0]?.name,
          //   }
          // }
          this.setState({
            selected_cat: autoSendData.selected_cat,
            selectedcategory: autoSendData.selectedCategoryId,
          })
          await this.props.getTemplatesbycat({ ...campaignData })
          await this.props.countTempletebyCat()
        }
      } else {
        // this.clearAutoLocalStorage()
        await this.props.getBatch(this.props.match.params.id)
      }
    }
    if (prevProps.send !== this.props.send) {
      this.setState({
        isLoading: false,
      })
    }
    if (
      !(prevProps.categories && prevProps.categories.length) &&
      this.props.categories &&
      this.props.categories.length &&
      this.props.campaign
    ) {
      const tempArr = []
      this.props.categories.forEach(item => {
        if (!!this.props.campaign.isFollowUp === !!item.forFollowUp) {
          tempArr.push({ value: item._id, label: item.name })
        }
      })
      this.setState({
        categoriesOptions: tempArr,
        isLoading: false,
      })
    }
    if (
      (!prevProps.Prospects && this.props.Prospects) ||
      (prevProps.Prospects &&
        this.props.Prospects &&
        prevProps.Prospects._id !== this.props.Prospects._id)
    ) {
      this.setState({
        crrProspect: {
          ...this.props.Prospects,
          template: this.props.selectedTemplate,
        },
        isLoading: false,
      })
    }
    if (!prevProps.counttemplate && this.props.counttemplate) {
      this.setState({
        counttemp: this.props.counttemplate,
        isLoading: false,
      })
    }

    if (!prevProps.successMsg && this.props.successMsg) {
      this.notify("success", this.props.successMsg)
    } else if (
      this.props.apiError &&
      typeof this.props.apiError === "string" &&
      !prevProps.apiError
    ) {
      this.notify("error", this.props.apiError)
    } else if (
      !prevProps.apiError &&
      this.props.apiError &&
      typeof this.props.apiError === "object"
    ) {
      if (this.props.apiError.response) {
        this.notify("error", this.props.apiError.response.data.error)
      } else {
        this.notify("error", this.props.apiError.message)
      }
    }
  }

  send(message, phone) {
    // console.log(this.state.crrProspect?.template?._id,"--------------------------")
    // return
    this.setState({
      manualSendDelay: this.props.campaign?.delay,
    })
    this.startManualSendTimer()

    const pct = this.state.procount + 1
    const tct = this.state.counttemp + 1

    this.setState({
      procount: pct,
      total: this.state.total + 1,
      isLoading: true,
    })

    // if (tct < this.props.counttemplate) {
    //   this.setState({
    //     // counttemp: 0,
    //   })
    // } else {
    //   this.setState({
    //     procount: 0,
    //     // counttemp: 0,
    //   })
    // }
    if (!(tct < this.props.counttemplate)) {
      this.setState({
        procount: 0,
      })
    }

    this.props.sendPropects({
      id: this.state.selectedcategory,
      camp: this.state.id,
      count: this.generateRandom(0, this.state.counttemp - 1),
      procount: pct,
      bulkid: this.props.bulkid,
      body: message,
      phone: phone,
      prosid: this.state.crrProspect?._id,
      templateId: this.state.crrProspect?.template?._id,
      market: this.props.campaign.market,
      send_count: this.props.send_count + 1,
    })
    //for set prev props
    this.update_total_prop_count()
  }

  skipprospect() {
    const pct = this.state.procount + 1
    const tct = this.state.counttemp + 1

    if (tct < this.props.counttemplate) {
      this.props.updateMessageStatusPros({
        prosid: this.state.crrProspect?._id,
        id: this.state.selectedcategory,
        camp: this.state.id,
        count: tct,
        procount: pct,
        bulk: this.props.bulkid,
        skip: "skip",
      })
      this.setState({
        counttemp: tct,
      })
    } else {
      this.props.updateMessageStatusPros({
        prosid: this.state.crrProspect?._id,
        id: this.state.selectedcategory,
        camp: this.state.id,
        count: 0,
        procount: pct,
        bulk: this.props.bulkid,
        skip: "skip",
      })
      this.setState({
        counttemp: 0,
        procount: 0,
      })
    }

    this.setState({
      procount: pct,
      total: this.state.total + 1,
      skip: this.state.skip + 1,
    })
    //for set prev props
    this.update_total_prop_count()
  }

  async skipProspect() {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    this.setState({ isLoading: true })
    const payload = {
      prospectId: this.state.crrProspect?._id,
      categoryId: this.state.selectedcategory,
      count: this.generateRandom(0, this.state.counttemp - 1),
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/skipProspect",
        payload,
        config
      )
      if (response.data) {
        this.notify("success", "Prospect skipped successfully")
        this.setState({
          crrProspect: response.data,
          skip: this.state.skip + 1,
          isLoading: false,
          prospectCount: this.state.prospectCount - 1,
        })
      }
    } catch (error) {
      this.notify("error", error?.response?.data?.error)
      this.setState({
        crrProspect: null,
        skip: this.state.skip + 1,
        isLoading: false,
        prospectCount: this.state.prospectCount - 1,
      })
    }
  }

  async handleAddSubmitAutocount(event, values) {
    // let id = this.props.match.params.id
    // await this.props.getBatch(id)
    if (this.props.send_count) {
      await this.props.getBatch(this.state.id)
    }
    await this.autoStart()
    this.setState({
      success_autosend: false,
      flag_auto_send: true,
      auto_send_count: 0,
    })

    this.setState({
      modal_add: false,
      total_auto_send_count: this.state.num_of_count,
      num_of_count: "",
    })
  }
  generateRandom(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  async autoStart() {
    this.setState({
      show: true,
    })
    //for set prev props
    this.update_total_prop_count()
    const uniqueCode = uuid()
    let data = {
      cat: this.state.selectedcategory,
      camp: this.state.id,
      counttemp: this.state.counttemp,
      pct: this.state.procount,
      bulkid: this.props.bulkid,
      market: this.props.campaign.market,
      batch_length: +this.state.num_of_count, ///this is for dynamic batch length
      sended_msg_count: this.props.send_count,
      uniqueCode,
    }
    // let localStorageData = {
    //   selectedCategoryId: this.state.selectedcategory,
    //   camp: this.state.id,
    //   procount: 0,
    //   bulkid: this.props.bulkid,
    //   count: this.generateRandom(0, this.state.counttemp - 1),
    // }
    // localStorage.setItem("autoSendData", JSON.stringify(localStorageData))
    // localStorage.setItem("selectedCategory", this.state.selectedcategory)
    // localStorage.setItem("autoSendUniqueCode", uniqueCode)

    const autoSendData = {
      selectedCategoryId: this.state.selectedcategory,
      count: this.generateRandom(0, this.state.counttemp - 1),
      procount: 0,
      bulkid: this.props.bulkid,
      autoSendUniqueCode: uniqueCode,
      auto_send_count: 0,
      total_auto_send_count: this.state.num_of_count,
      after_cancel_autosend_count: this.props.send_count,
      selected_cat: this.state.selected_cat,
    }
    this.setState({ autoSendData })
    await this.updateAutoSendData(autoSendData)
    // id: category.value,
    // camp: this.state.id,
    // count: this.generateRandom(
    //   0,
    //   this.state.counttemp - 1
    // ),
    // procount: 0,
    // bulkid: this.props.bulkid,
    this.props.autoSendCamp(data)
  }
  autoCancelService() {
    // set how many msg send after cancel auto send
    this.props.update_props({
      send_count: this.state.after_cancel_autosend_count,
    })
    this.setState({
      flag_auto_send: true,
      after_cancel_autosend_count: 0,
    })
    // setTimeout(() => {
    this.setState({
      show: !this.state.show,
    })
    // this.clearAutoLocalStorage()
    // this.props.getBatch(this.props.match.params.id)

    // }, 500)
    const data = {
      id: this.state.id,
      exist: this.state.exist,
    }
    this.props.cancelAutoSendCamp(data)
    this.props.getBatch(this.props.match.params.id)
  }

  update_total_prop_count() {
    this.props.update_props({
      previous_total_pros:
        this.props.previous_total_pros != 0
          ? this.props.previous_total_pros
          : this.state.prospectCount,
    })
  }

  update_prospect_after_auto_sen() {
    let selectedCategory = this.state.selectedcategory
    this.props.getTemplatesbycat({
      id: selectedCategory,
      camp: this.state.id,
      count: this.generateRandom(0, this.state.counttemp - 1),
      procount: 0,
      bulkid: this.props.bulkid,
    })
    this.props.countTempletebyCat(selectedCategory)
  }
  startManualSendTimer = () => {
    this.setState({ isTimerRunning: true })
    this.manualSendTimer = setInterval(() => {
      if (this.state.manualSendDelay > 0) {
        this.setState(prevState => ({
          manualSendDelay: prevState.manualSendDelay - 1,
          manualSend: { canSend: false },
        }))
      } else {
        this.stopManualSendTimer()
      }
    }, 1000)
  }

  stopManualSendTimer = () => {
    clearInterval(this.manualSendTimer)
    this.setState({ isTimerRunning: false })
  }

  async updateAutoSendData(autoSendData) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const payload = {
      autoSendData,
      campaignId: this.props.match.params.id,
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + "/api/campaign/updateCampaignAuto/",
        payload,
        config
      )
      if (response.data) {
      }
    } catch (error) {
      this.notify("error", error?.response?.data?.message)
    }
  }

  handleCountDown = () => {
    if (this.state.count > 0) {
      setTimeout(() => {
        this.setState(prevState => ({
          count: prevState.count - 1, // Decrement count by 1
        }))
      }, 2000)
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Campaign | TextLaunchPad.com </title>
          </MetaTags>
          <div className="container-fluid">
            <CampaignHeader
              campaign={this.props.campaign}
              leadStages={this.props.leads}
              campaignFilterProspects={this.props.campaignFilterProspects}
            />
            <Toaster />

            {/* <Row className={"pt-2"}>
              <Col lg={12}>
                {this.props.apiError &&
                typeof this.props.apiError === "string" ? (
                  <Alert color="danger">{this.props.apiError}</Alert>
                ) : null}

                {this.props.apiError &&
                typeof this.props.apiError === "object" ? (
                  this.props.apiError.response ? (
                    <Alert color="danger">
                      {this.props.apiError.response.data.error}
                    </Alert>
                  ) : (
                    <Alert color="danger">{this.props.apiError.message}</Alert>
                  )
                ) : null}

                {this.state.success_autosend ? (
                  <Alert color="success">{"Auto send successfully"}</Alert>
                ) : (
                  <>
                    {this.props.successMsg ? (
                      <Alert color="success">{this.props.successMsg}</Alert>
                    ) : null}
                  </>
                )}
              </Col>
            </Row> */}

            <Row>
              <Col lg={12} className="mb-3">
                <div className="c-card">
                  <Nav tabs className="nav-tabs-custom cc-tabs">
                    <NavItem>
                      <Link
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id}
                      >
                        <span className="d-sm-block">Metrics</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className={"nav-link active"}
                        to={"/campaign/" + this.state.id + "/send"}
                      >
                        <span className="d-sm-block">Send</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/upload_history"}
                      >
                        <span className="d-sm-block">Upload History</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className={"nav-link "}
                        to={
                          "/campaign/" +
                          this.state.id +
                          "/prospects_messages_notes"
                        }
                      >
                        <span className="d-sm-block">Prospects & Messages</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/scheduler"}
                      >
                        <span className="d-sm-block">Scheduler</span>
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>

            {this.props.campaign?.schedulerRunning ? (
              <div
                className="c-card d-flex flex-column justify-content-center align-items-center "
                style={{ paddingTop: "100px", paddingBottom: "100px" }}
              >
                <img
                  className="img-fluid"
                  src={illus}
                  alt=""
                  width={500}
                  height={500}
                />
                <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                  <span className="d-flex flex-row justify-content-center  align-items-center pb-0 mb-0">
                    <img
                      src={Exclamation}
                      style={{ width: "35px", height: "35px" }}
                      alt=""
                    />
                    <h4 className="text-danger mb-0 pb-0">
                      Can't Send Message
                    </h4>
                  </span>
                  <span className="det " style={{ textAlign: "center" }}>
                    <p>An active scheduler is running in this campaign </p>
                  </span>
                </div>
              </div>
            ) : (
              <Row>
                <Col lg={4}>
                  <div className="c-card">
                    <Row>
                      <Col md={12}>
                        <AvForm className="needs-validation">
                          <FormGroup className="mb-3">
                            <h3 className="heading">
                              Select Template Category
                            </h3>
                            <Select
                              style={{
                                boxShadow: "none",
                              }}
                              placeholder={"No category selected"}
                              onChange={category => {
                                this.setState({
                                  selected_cat: category,
                                  isLoading: true,
                                })
                                if (category.value !== "") {
                                  this.setState({
                                    selectedcategory: category.value,
                                  })
                                  this.props.countTempletebyCat(category.value)
                                  setTimeout(() => {
                                    this.props.getTemplatesbycat({
                                      id: category.value,
                                      camp: this.state.id,
                                      count: this.generateRandom(
                                        0,
                                        this.state.counttemp - 1
                                      ),
                                      procount: 0,
                                      bulkid: this.props.bulkid,
                                    })
                                    this.setState({
                                      isLoading: false,
                                    })
                                  }, 500)
                                } else {
                                  this.setState({
                                    selectedcategory: null,
                                  })
                                  this.props.getTemplatesbycat()
                                  this.props.countTempletebyCat()
                                }
                              }}
                              value={this.state.selected_cat}
                              name="category"
                              options={
                                //   this.props.categories.map(item => {
                                //   return { value: item._id, label: item.name }
                                // })
                                this.state.categoriesOptions
                              }
                              className={"from-control"}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </AvForm>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={8}>
                  {this.state.isLoading ? (
                    <div className="spinner-wr">
                      <Spinner style={{ color: "#5932ea" }} />
                    </div>
                  ) : null}
                  {!this.state.crrProspect ? (
                    <div className="c-card">
                      <div className="chat-illus illus-empty-state">
                        <img src={sendIllus} alt="" />
                        <h3>Select Category</h3>
                        <p>Please select the message template category!</p>
                      </div>
                    </div>
                  ) : (
                    <div className="c-card">
                      <Row>
                        {!this.state.show ? (
                          <Col md={6}>
                            <h3 className="send-text">
                              Send Count :{" "}
                              <span style={{ color: "#5932ea" }}>
                                {this.props.send_count}
                              </span>
                              /{this.state.prospectCount}{" "}
                              {this.props.send_count &&
                              !isNaN(
                                this.state.prospectCount - this.props.send_count
                              ) &&
                              this.state.prospectCount - this.props.send_count >
                                0
                                ? `(${
                                    this.state.prospectCount -
                                    this.props.send_count
                                  } Left)`
                                : null}
                            </h3>
                            {/* {this.props.send_count ? (
                            <p>
                              (Remaining :{" "}
                              <strong className="text-accent">
                                {" "}
                                {this.props.count - this.props.send_count}
                              </strong>
                              )
                            </p>
                          ) : null} */}
                            <div>
                              {isNaN(
                                (this.props.send_count * 100) /
                                  this.state.prospectCount
                              )
                                ? 0
                                : (
                                    (this.props.send_count * 100) /
                                    this.state.prospectCount
                                  ).toFixed(2)}
                              %
                            </div>
                          </Col>
                        ) : (
                          <Col md={6}>
                            <h3 className="send-text">
                              Auto Send Count :{" "}
                              <span style={{ color: "#5932ea" }}>
                                {this.state.auto_send_count}
                              </span>
                              /
                              {this.state.total_auto_send_count
                                ? this.state.total_auto_send_count
                                : 0}{" "}
                              {this.state.auto_send_count &&
                              !isNaN(
                                this.state.total_auto_send_count -
                                  this.state.auto_send_count
                              ) &&
                              this.state.total_auto_send_count -
                                this.state.auto_send_count >
                                0
                                ? `(${
                                    this.state.total_auto_send_count -
                                    this.state.auto_send_count
                                  } Left)`
                                : null}
                            </h3>
                            <div>
                              {isNaN(
                                (this.state.auto_send_count * 100) /
                                  this.state.total_auto_send_count
                              )
                                ? 0
                                : (
                                    (this.state.auto_send_count * 100) /
                                    this.state.total_auto_send_count
                                  ).toFixed(2)}
                              %
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md="12">
                          {!this.state.show ? (
                            <Progress
                              style={{
                                height: "16px",
                                borderRadius: "20px",
                              }}
                              color="success"
                              value={
                                isNaN(
                                  (this.props.send_count * 100) /
                                    this.state.prospectCount
                                )
                                  ? 0
                                  : (this.props.send_count * 100) /
                                    this.state.prospectCount
                              }
                            >
                              {/* {isNaN((this.props.send * 100) / this.props.count)
                                ? 0
                                : (this.props.send * 100) / this.props.count} */}
                              {/* % Processing... */}
                            </Progress>
                          ) : (
                            <Progress
                              style={{
                                height: "16px",
                                borderRadius: "20px",
                              }}
                              color="success"
                              value={
                                isNaN(
                                  (this.state.auto_send_count * 100) /
                                    this.state.total_auto_send_count
                                )
                                  ? 0
                                  : (this.state.auto_send_count * 100) /
                                    this.state.total_auto_send_count
                              }
                            >
                              {/* {isNaN(
                                (this.state.auto_send_count * 100) /
                                  this.state.total_auto_send_count
                              )
                                ? 0
                                : (this.state.auto_send_count * 100) /
                                  this.state.total_auto_send_count}
                              % */}
                              Processing...
                            </Progress>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className={"pt-4 pb-2"}>
                          {this.props.name == null ? null : (
                            <h3>Batch# {this.props.name}</h3>
                          )}
                        </Col>
                      </Row>

                      {!this.state.show ? (
                        <Row>
                          <Col md={12}>
                            <div className="d-flex align-items-center gap-2">
                              <button className={"me-2 main-btn circle-btn"}>
                                <img src={planeWhite} alt="" />
                                <span>
                                  Sent{" "}
                                  {/* <Badge className="ms-1 bg-white text-success"> */}
                                  {this.props.send_count}
                                </span>
                                {/* </Badge> */}
                              </button>

                              <button
                                className={"main-btn danger-border circle-btn"}
                              >
                                <img src={skipIcon} alt="" />
                                <span>
                                  Skip{" "}
                                  {/* <Badge className="ms-1 bg-white text-danger"> */}
                                  {this.state.skip}
                                </span>
                                {/* </Badge> */}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      ) : null}

                      {this.state.show ? (
                        <Row>
                          <Col md={3}>
                            <Button color="success">
                              <i className={"fas fa-paper-plane pe-1"}></i> Sent{" "}
                              <Badge className="ms-1 bg-white text-success">
                                {this.state.auto_send_count}
                              </Badge>
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                      <Row className=" pt-4 ">
                        <Col md={12}>
                          <div className="c-card border-0 p-0">
                            {/* <Row>
                            <Col sm={2} md={2}>
                              <div className="user-name-card style-2 style-3">
                                <div className="user-short-name">
                                  {this.state.crrProspect?.first_name
                                    ?.charAt(0)
                                    .toUpperCase() +
                                    this.state.crrProspect?.last_name
                                      ?.charAt(0)
                                      ?.toUpperCase()}
                                </div>
                              </div>
                            </Col>
                            <Col sm={10} md={10}>
                              <div className="user-name-card style-2">
                                <div className="user-short-name">
                                  {this.state.crrProspect?.first_name
                                    ?.charAt(0)
                                    .toUpperCase() +
                                    this.state.crrProspect?.last_name
                                      ?.charAt(0)
                                      ?.toUpperCase()}
                                </div>
                                <div className="relative">
                                  <h6 className="mb-0">
                                    {this.state.crrProspect?.first_name +
                                      " " +
                                      this.state.crrProspect?.last_name}
                                  </h6>
                                  <p>Mobile: {this.state.crrProspect?.phone}</p>
                                  <p className="mb-0">
                                  {[
                                    this.state.crrProspect?.property_address &&
                                    this.state.crrProspect?.property_address+ ", ",
                                    this.state.crrProspect?.property_city &&
                                    this.state.crrProspect?.property_city +
                                        ", ",
                                        this.state.crrProspect?.property_state &&
                                        this.state.crrProspect?.property_state +
                                        ", ",
                                        this.state.crrProspect?.property_zip &&
                                        this.state.crrProspect?.property_zip,
                                  ]}
                                    {/* {this.state.crrProspect?.property_address +
                                      ", " +
                                      this.state.crrProspect?.property_city +
                                      ", " +
                                      this.state.crrProspect?.property_state +
                                      ", " +
                                      this.state.crrProspect?.property_zip} */}
                            {/* </p>
                                </div>
                              </div>
                            </Col>
                          </Row>  */}
                            <Row>
                              <Col md={12}>
                                <div className="pros-card">
                                  <div className="pros-short-name">
                                    {this.state.crrProspect?.first_name
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      this.state.crrProspect?.last_name
                                        ?.charAt(0)
                                        ?.toUpperCase()}
                                  </div>
                                  <div className="pros-detail justify-content-center">
                                    <h6 className="mb-0">
                                      {this.state.crrProspect?.first_name +
                                        " " +
                                        this.state.crrProspect?.last_name}
                                    </h6>
                                    <p>
                                      Mobile: {this.state.crrProspect?.phone}
                                    </p>
                                    <p className="mb-0">
                                      {[
                                        this.state.crrProspect
                                          ?.property_address &&
                                          this.state.crrProspect
                                            ?.property_address + ", ",
                                        this.state.crrProspect?.property_city &&
                                          this.state.crrProspect
                                            ?.property_city + ", ",
                                        this.state.crrProspect
                                          ?.property_state &&
                                          this.state.crrProspect
                                            ?.property_state + ", ",
                                        this.state.crrProspect?.property_zip &&
                                          this.state.crrProspect?.property_zip,
                                      ]}
                                      {/* {this.state.crrProspect?.property_address +
                                      ", " +
                                      this.state.crrProspect?.property_city +
                                      ", " +
                                      this.state.crrProspect?.property_state +
                                      ", " +
                                      this.state.crrProspect?.property_zip} */}
                                    </p>
                                    {/* <Row>
                                    <Col md={12}>
                                      {" "}
                                    </Col>
                                    <Col md={12}>
                                    </Col>
                                    <Col>
                                    </Col>
                                  </Row> */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md="12" className="mt-4">
                          <div className="pros-mssg">
                            <p className="para mb-0">
                              {this.state.crrProspect?.body}
                            </p>
                          </div>
                          <span
                            className="mt-2"
                            style={{ float: "right", fontSize: "12px" }}
                          >
                            {this.state.crrProspect?.body?.length <= 160 ? (
                              <i
                                className="fas fa-check-circle fa-sm me-1"
                                style={{ color: "Green" }}
                              ></i>
                            ) : null}
                            {this.state.crrProspect?.body?.length <= 320 &&
                            this.state.crrProspect?.body?.length > 160 ? (
                              <i
                                className="fas fa-exclamation-circle fa-sm me-1"
                                style={{ color: "orange" }}
                              ></i>
                            ) : null}
                            {this.state.crrProspect?.body?.length > 320 ? (
                              <i
                                className="fas fa-times-circle fa-sm me-1"
                                style={{ color: "red" }}
                              ></i>
                            ) : null}
                            {this.state.crrProspect?.body?.length
                              ? this.state.crrProspect?.body?.length
                              : 0}
                            /320
                          </span>
                        </Col>
                      </Row>

                      {!this.state.show ? (
                        <Row className="pt-4">
                          <Col md={12}>
                            <div className="d-flex align-items-center gap-2">
                              <button
                                disabled={
                                  this.state.procount >= this.state.count ||
                                  this.state.isTimerRunning ||
                                  this.state.show
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  this.send(
                                    this.state.crrProspect?.body,
                                    this.state.crrProspect?.phone
                                  )
                                }}
                                className={`main-btn circle-btn ${
                                  this.state.isTimerRunning ? "disabled" : ""
                                }`}
                              >
                                {!this.state.isTimerRunning ? (
                                  <>
                                    <img src={planeWhite} alt="" />
                                    <span>Send</span>
                                  </>
                                ) : (
                                  <>
                                    <img src={watchWhite} alt="" />
                                    <span>
                                      Send next in {this.state.manualSendDelay}s
                                    </span>
                                  </>
                                )}
                                {/* <i className={"fas fa-paper-plane pe-2"}></i> */}
                              </button>

                              <button
                                disabled={
                                  this.state.procount >= this.state.count ||
                                  this.state.show
                                    ? true
                                    : false
                                }
                                className={"main-btn circle-btn danger-border"}
                                onClick={() => this.skipProspect()}
                                // onClick={() => this.skipprospect()}
                              >
                                <img src={skipIcon} alt="" />
                                <span>Skip</span>
                              </button>

                              {this.props.campaign ? (
                                // {this.props.campaign && this.props.campaign.auto === true ?
                                <button
                                  disabled={
                                    this.state.procount >= this.state.count ||
                                    this.state.show
                                      ? true
                                      : false
                                  }
                                  className={"main-btn circle-btn magic-btn"}
                                  // onClick={() => this.autoStart()}
                                  onClick={() => {
                                    this.setState({ modal_add: true })
                                  }}
                                >
                                  <img src={magicIcon} alt="" />
                                  <span>Auto</span>
                                </button>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row className="pt-4">
                          <Col md={12}>
                            {(this.props.campaign.auto === true ||
                              this.state.show) && (
                              <Button
                                color="dark"
                                className="me-2"
                                onClick={() => this.autoCancelService()}
                              >
                                {/* <i className={'fas fa-times pe-2'}></i> */}
                                Cancel
                              </Button>
                            )}
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </div>

        <Modal
          isOpen={this.state.modal_add}
          toggle={() => {
            this.tog_add()
          }}
          className="c-modal"
          // backdrop="static"
          centered={true}
        >
          <div className="modal-body ">
            {/* <h5 className="modal-title mt-0 mb-3">Enter how many to send</h5> */}

            <button
              type="button"
              onClick={() => {
                this.setState({
                  modal_add: false,
                })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>

            {
              <AvForm
                onValidSubmit={(e, v) => this.handleAddSubmitAutocount(e, v)}
                className="needs-validation"
              >
                <Row>
                  <Col xl="12">
                    <FormGroup className="mb-3 mt-5">
                      <AvField
                        key={"01"}
                        name="code"
                        placeholder="Specify the numbers of prospect to send"
                        type="number"
                        errorMessage={
                          "Please specify the numbers of prospect to send"
                        }
                        className="form-control cc-form-control"
                        validate={{
                          required: { value: true },
                          min: {
                            value: 2,
                            errorMessage:
                              "Please enter a number greater than or equal to 2",
                          },
                          max: {
                            value:
                              this.state.prospectCount - this.props.send_count,
                            errorMessage:
                              "Please enter a number less than or equal to prospect left ",
                          },
                        }}
                        id="validationCustom03"
                        onChange={e => {
                          this.setState({
                            num_of_count: e.target.value,
                          })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className="main-btn secondary-btn circle-btn"
                    onClick={() => {
                      this.setState({
                        modal_add: false,
                      })
                    }}
                    role="button"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="main-btn circle-btn "
                  >
                    <span> Start Auto Send</span>
                  </Button>{" "}
                </div>
              </AvForm>
            }
          </div>
        </Modal>

        {/* <Modal
          isOpen={this.state.modal_add}
          toggle={() => {
            this.tog_add()
          }}
          backdrop="static"
          centered={true}
        >
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => this.handleAddSubmitAutocount(e, v)}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Enter how many to send</h5>

              <button
                type="button"
                onClick={() => {
                  this.setState({
                    modal_add: false,
                  })
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <AvField
                      key={"01"}
                      name="code"
                      placeholder="Enter how many to send"
                      type="number"
                      errorMessage={"Plese enter how many to send"}
                      className="form-control"
                      validate={{
                        required: { value: true },
                        min: {
                          value: 2,
                          errorMessage:
                            "Please enter a number greater than or equal to 2",
                        },
                      }}
                      id="validationCustom03"
                      onChange={e => {
                        this.setState({
                          num_of_count: e.target.value,
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className={"text-end"}>
                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    onClick={() => {
                      this.setState({
                        modal_add: false,
                      })
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Start Auto Send
                  </button>
                </Col>
              </Row>
            </div>
          </AvForm>
        </Modal> */}
      </React.Fragment>
    )
  }
}
CampaignSend.propTypes = {
  apiError: PropTypes.any,
  successMsg: PropTypes.any,
  campaign: PropTypes.object,
  count: PropTypes.number,
  send: PropTypes.number,
  send_count: PropTypes.number,
  batchpros: PropTypes.number,
  name: PropTypes.any,
  bulkid: PropTypes.string,
  categories: PropTypes.array,
  skip: PropTypes.number,
  Prospects: PropTypes.object,
  selectedTemplate: PropTypes.object,
  counttemplate: PropTypes.number,
  getCampaign: PropTypes.func,
  getCategories: PropTypes.func,
  getBatch: PropTypes.func,
  countProspects: PropTypes.func,
  countTempletebyCat: PropTypes.func,
  sendPropects: PropTypes.func,
  autosendcamp: PropTypes.func,
  cancelAutoSendCamp: PropTypes.func,
  leads: PropTypes.array,
  getLeadStages: PropTypes.func,
  //for update props
  update_props: PropTypes.func,
  previous_total_pros: PropTypes.number,
}

const mapStatetoProps = state => {
  const {
    apiError,
    campaign,
    leads,
    successMsg,
    getCampaign,
    countProspects,
    count,
    send,
    send_count,
    batchpros,
    name,
    bulkid,
    getBatch,
    categories,
    getCategories,
    Prospects,
    selectedTemplate,
    getTemplatesbycat,
    countTempletebyCat,
    sendPropects,
    updateMessageStatusPros,
    autoSendCamp,
    cancelAutoSendCamp,
    getLeadStages,
    counttemplate,
    //for update props
    update_props,
    previous_total_pros,
  } = state.Campaign
  return {
    apiError,
    campaign,
    leads,
    successMsg,
    getCampaign,
    countProspects,
    count,
    send,
    send_count,
    batchpros,
    name,
    bulkid,
    getBatch,
    categories,
    getCategories,
    Prospects,
    selectedTemplate,
    getTemplatesbycat,
    countTempletebyCat,
    sendPropects,
    updateMessageStatusPros,
    autoSendCamp,
    cancelAutoSendCamp,
    getLeadStages,
    counttemplate,
    //for update props
    update_props,
    previous_total_pros,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    getCampaign,
    countProspects,
    getCategories,
    getBatch,
    getTemplatesbycat,
    countTempletebyCat,
    sendPropects,
    updateMessageStatusPros,
    autoSendCamp,
    cancelAutoSendCamp,
    getLeadStages,
    update_props,
  })(CampaignSend)
)
