import {
  API_ERROR, CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS
} from "./actionTypes"

const initialState = {
  apiError: null,
}

const ChangePassword = (state = initialState, action) => {
  switch (action.type) {
    case API_ERROR:
      state = { ...state, apiError: action.payload }
      break
    case CHANGE_PASSWORD:
      state = {
        ...state
      }
      break
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ChangePassword
