import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
import MetaTags from "react-meta-tags"
import Axios from "axios"
import { format } from "date-fns"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import SweetAlert from "react-bootstrap-sweetalert"
import toast, { Toaster } from "react-hot-toast"

import plus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"
import moment from "moment"

const RestrictedDates = () => {
  const [restrictedDates, setRestrictedDates] = useState([])
  const [modal, setModal] = useState({ isOpen: false })
  const [isLoading, setIsLoading] = useState(false)
  const [deleteDate, setDeleteDate] = useState({ confirm_alert: false })
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedDay, setSelectedDay] = useState("")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)

  const authUser = localStorage.getItem("authUser")
  const token = authUser ? JSON.parse(authUser)?.user?.token : undefined

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        toast.error(
          typeof text === "string" ? text : "An unexpected error occurred"
        )
        break
      default:
        break
    }
  }

  useEffect(() => {
    getRestrictedDates()
  }, [page, rowsPerPage])

  const getRestrictedDates = async () => {
    setIsLoading(true)
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_PORT}/api/restrictedDates/getAllRestrictedDates?page=${page}&limit=${rowsPerPage}`,
        config
      )
      setRestrictedDates(response.data.restrictedDates)
      setTotalCount(response.data.totalCount)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      notify(
        "error",
        err?.response?.data?.error || "Failed to fetch restricted dates"
      )
    }
  }

  const handleAddRestrictedDate = async () => {
    if (isLoading) return
    setIsLoading(true)
    const data = selectedDate
      ? { date: format(selectedDate, "yyyy-MM-dd") }
      : { day: selectedDay }

    try {
      await Axios.post(
        `${process.env.REACT_APP_PORT}/api/restrictedDates/createRestrictedDate`,
        data,
        config
      )
      setModal({ isOpen: false })
      getRestrictedDates()
      notify("success", "Restricted date/day added successfully")
      setSelectedDate(null)
      setSelectedDay("")
    } catch (err) {
      notify(
        "error",
        err?.response?.data?.error || "Failed to add restricted date/day"
      )
    }
  }

  const handleDeleteRestrictedDate = async id => {
    try {
      setIsLoading(true)
      await Axios.delete(
        `${process.env.REACT_APP_PORT}/api/restrictedDates/deleteRestrictedDate/${id}`,
        config
      )
      getRestrictedDates()
      notify("success", "Restricted date/day deleted successfully")
    } catch (err) {
      notify(
        "error",
        err?.response?.data?.error || "Failed to delete restricted date/day"
      )
    }
  }

  const columns = [
    {
      name: "Restricted Date/Day",
      selector: row => row.day || moment(row.date).local().format("MM-DD-YYYY"),
      sortable: true,
    },
    {
      name: "Created At",
      selector: row =>
        moment(row.createdAt).local().format("MM-DD-YYYY | hh:mm A"),
      sortable: true,
    },
    {
      name: "Delete",
      cell: row => (
        <Button
          onClick={() =>
            setDeleteDate({
              id: row._id,
              confirm_alert: true,
              confirm_button_text: "Yes, remove it",
            })
          }
          className="has-icon mx-1"
          color="outline-danger"
        >
          <i className="fas fa-trash-alt"></i>
        </Button>
      ),
    },
  ]

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Restricted Dates | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={6}>
              <div className="page-title-box">
                <h4 className="font-size-18" style={{ display: "inline" }}>
                  Restricted Dates
                </h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">TextLaunchPad</Link>
                  </li>
                  <li className="breadcrumb-item active">Restricted Dates</li>
                </ol>
              </div>
            </Col>
            <Col xs={6}>
              <div className="float-end">
                <button
                  className="main-btn"
                  type="button"
                  onClick={() => setModal({ isOpen: true })}
                >
                  <img src={plus} alt="" />
                  <span>Add Restricted Date</span>
                </button>
              </div>
            </Col>
          </Row>

          {isLoading ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : null}
          <div className="c-card mt-4">
            <DataTable
              columns={columns}
              data={restrictedDates}
              pagination
              paginationServer
              paginationTotalRows={totalCount}
              onChangeRowsPerPage={setRowsPerPage}
              onChangePage={setPage}
              highlightOnHover
            />
          </div>

          <Modal
            isOpen={modal.isOpen}
            toggle={() => {
              setModal({ isOpen: false })
              setSelectedDay("")
              setSelectedDate(null)
            }}
            centered
            className="c-modal"
          >
            <div className="modal-body">
              <h5 className="modal-title mt-0 mb-3">
                Add Restricted Date / Day
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModal({ isOpen: false })
                  setSelectedDay("")
                  setSelectedDate(null)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={close} alt="" />
              </button>

              <AvForm
                onValidSubmit={handleAddRestrictedDate}
                className="needs-validation"
              >
                <Row>
                  <Col>
                    <FormGroup className="mb-3">
                      <Label htmlFor="field01">Select Date</Label>
                      <div>
                        <DatePicker
                          selected={selectedDate}
                          onChange={date => {
                            setSelectedDate(date)
                            setSelectedDay("")
                          }}
                          minDate={new Date()}
                          placeholderText="Select a date"
                          className="form-control"
                          id="field01"
                        />
                      </div>
                      <div className="d-flex justify-content-center py-2 pt-3">
                        OR{" "}
                      </div>
                      <Label htmlFor="field02">Day</Label>
                      <div className="">
                        <select
                          className="form-control"
                          value={selectedDay}
                          id="field02"
                          onChange={e => {
                            setSelectedDay(e.target.value)
                            setSelectedDate(null)
                          }}
                        >
                          <option value="">Select a day</option>
                          {days.map(day => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          ))}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className="main-btn secondary-btn"
                    onClick={() => {
                      setModal({
                        isOpen: false,
                      })
                      setSelectedDay("")
                      setSelectedDate(null)
                    }}
                    role="button"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="main-btn"
                    disabled={!selectedDate && !selectedDay}
                  >
                    <span>Submit</span>
                  </Button>
                </div>
              </AvForm>
            </div>
          </Modal>

          {deleteDate.confirm_alert && (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnText={deleteDate.confirm_button_text}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              onConfirm={() => {
                handleDeleteRestrictedDate(deleteDate.id)
                setDeleteDate({ confirm_alert: false })
              }}
              onCancel={() => setDeleteDate({ confirm_alert: false })}
            >
              You won't be able to revert this!
            </SweetAlert>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RestrictedDates
