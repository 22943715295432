import { takeEvery, fork, put, all, call } from "redux-saga/effects"
// Login Redux States
import { GET_USERS, ADD_USER, EDIT_USER, DELETE_USER } from "./actionTypes"

import {
  addUserSuccess,
  deleteUserSuccess,
  editUser,
  editUserSuccess,
  getUserError,
  getUsersSuccess,
} from "./actions"

import {
  addUser,
  getUsers,
  updateUser,
  deleteUser,
} from "../../helpers/fakebackend_helper"

const delay = ms => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getUsersApi({ payload }) {
  try {
    const { data } = yield call(getUsers, payload)

    yield put(getUsersSuccess(data))
  } catch (error) {
    yield put(getUserError(error))
  }
}

function* addUserApi({ payload: user }) {
  try {
    yield call(addUser, user)
    yield put(addUserSuccess("User added successfully"))
  } catch (error) {
    yield put(getUserError(error))
  }
}

function* editUserApi({ payload: { id, user } }) {
  try {
    const response = yield call(updateUser, { id, user })
    yield put(editUserSuccess("User updated successfully"))
  } catch (error) {
    yield put(getUserError(error))
  }
}
function* deleteUserApi({ payload: id }) {
  try {
    const response = yield call(deleteUser, id)
    yield put(deleteUserSuccess("User deleted successfully"))
  } catch (error) {
    yield put(getUserError(error))
  }
}

export function* watchUsers() {
  yield takeEvery(GET_USERS, getUsersApi)
  yield takeEvery(ADD_USER, addUserApi)
  yield takeEvery(EDIT_USER, editUserApi)
  yield takeEvery(DELETE_USER, deleteUserApi)
}

function* UsersSaga() {
  yield all([fork(watchUsers)])
}

export default UsersSaga
