import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { get } from "helpers/api_helper"
import { useSelector } from "react-redux"

const SidebarContent = props => {
  const ref = useRef()
  const [tUnreadMsg,setTUnreadMsg ] = useState(0)
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  async function ongGetListUnreadMessages() {
    let params = { 
      page: 1,
      limit: 20,
      currentstatus: "Unread"
     }
    try {
      const resp = await get(process.env.REACT_APP_PORT + "/api/notification/list",{params})
      if (resp && Array.isArray(resp?.data?.docs)) {
        setTUnreadMsg(resp.data.totalDocs)
      }
    } catch (error) {
      console.error(error)
    } 
  }
  let message_popup = useSelector(state => state.Campaign?.successMsg);
  let message_ = useSelector(state => state.Prospects?.successMsg);
  // useEffect(()=>{
  //   // ongGetListUnreadMessages();
  // },[message_popup, message_])

  

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/*<li className="menu-title">{props.t("Main")} </li>*/}
            <li>
              <Link to="/campaigns" className="waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Campaigns")}</span>
              </Link>
            </li>
            <li>
              <Link to="/prospects" className="waves-effect">
                <i className="ti-user"></i>
                <span>{props.t("Prospects")}</span>
              </Link>
            </li>
            <li>
              <Link to="/numbers" className="waves-effect">
                <i className="ti-mobile"></i>
                <span>{props.t("Numbers")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-email"></i>
                <span>{props.t("Templates")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/templates">{props.t("Templates")}</Link>
                </li>
                <li>
                  <Link to="/categories">{props.t("Categories")} </Link>
                </li>
                <li>
                  <Link to="/quick-replies">{props.t("Quick Replies")} </Link>
                </li>
                <li>
                  <Link to="/templates-setting">
                    {props.t("Template Settings")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/notifications" className="waves-effect">
                <i className="ti-bell"></i>
                <span>{props.t("Notifications")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect"> 
                <i className="ti-settings"></i>
                <span>{props.t("Account Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/restrictedContact/dnc-list">{props.t("DNC List")}</Link> 
                  {/* <Link to="/dnclist">{props.t("DNC List")}</Link>  */}
                  <Link to="/optouts">{props.t("Optout")}</Link>
                  <Link to="/ptcrm">{props.t("Config")}</Link>
                  <Link to="/lead-stages">{props.t("Lead Stages")}</Link>
                  <Link to="/markets">{props.t("Markets")}</Link>
                  <Link to="/management-phone-number">
                    {props.t("Mobile Alert")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/users" className="waves-effect">
                <i className="ion ion-md-people"></i>
                <span>{props.t("Users")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/message-unread" className="waves-effect">
                <i className="ti-email"></i>
                <span>{props.t("All Unread")}</span>
                {(tUnreadMsg && tUnreadMsg>0) && 
                <span className="msg-count">{tUnreadMsg}</span>
                }
              </Link>
            </li> */}
            <li>
              <Link to="/contact-cart" className="waves-effect">
                <i className="ti-mobile"></i>
                <span>{props.t("Verified Contacts")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
