import {
  ADD_NOTE, ADD_NOTE_SUCCESS,
  ADD_PROSPECT,
  ADD_PROSPECT_SUCCESS,
  API_ERROR,
  COUNT_ALL_PROSPECTS,
  COUNT_ALL_PROSPECTS_SUCCESS, DELETE_NOTE, DELETE_NOTE_SUCCESS,
  DELETE_PROSPECTS,
  DELETE_PROSPECTS_SUCCESS,
  REMOVE_PROSPECT_FROM_DNC,
  REMOVE_PROSPECT_FROM_DNC_SUCCESS,
   EDIT_NOTE, EDIT_NOTE_SUCCESS,
  EXPORT_PROSPECTS,
  EXPORT_PROSPECTS_SUCCESS,
  FILTER_PROSPECTS,
  GET_CAMPAIGNS_BY_MARKET,
  GET_CAMPAIGNS_BY_MARKET_SUCCESS, GET_NOTES, GET_NOTES_SUCCESS,
  GET_PROSPECT,
  GET_PROSPECT_SUCCESS,
  GET_PROSPECTS,
  GET_PROSPECTS_SUCCESS, PUSH_TO_WEBHOOK, PUSH_TO_WEBHOOK_SUCCESS,
  REMOVE_PROSPECT_PRIORITY,
  REMOVE_PROSPECT_PRIORITY_SUCCESS,
  REMOVE_PROSPECT_QUALIFIED,
  REMOVE_PROSPECT_QUALIFIED_SUCCESS,
  REMOVE_PROSPECT_VERIFIED,
  REMOVE_PROSPECT_VERIFIED_SUCCESS, REMOVE_PROSPECT_WRONG_NUMBER, REMOVE_PROSPECT_WRONG_NUMBER_SUCCESS,
  SEARCH_PROSPECTS_BY_MESSAGE,
  SEARCH_PROSPECTS_FOR, SEND_CONVERSATION, SEND_CONVERSATION_SUCCESS, UPDATE_PROSPECT,
  UPDATE_PROSPECT_DNC,
  UPDATE_PROSPECT_DNC_SUCCESS,
  UPDATE_PROSPECT_LEAD_STAGE,
  UPDATE_PROSPECT_LEAD_STAGE_SUCCESS,
  UPDATE_PROSPECT_PRIORITY,
  UPDATE_PROSPECT_PRIORITY_SUCCESS,
  UPDATE_PROSPECT_QUALIFIED,
  UPDATE_PROSPECT_QUALIFIED_SUCCESS, UPDATE_PROSPECT_SUCCESS,
  UPDATE_PROSPECT_VERIFIED,
  UPDATE_PROSPECT_VERIFIED_SUCCESS, UPDATE_PROSPECT_WRONG_NUMBER, UPDATE_PROSPECT_WRONG_NUMBER_SUCCESS
} from "./actionTypes"
import {GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS} from "../campaigns/actionTypes";
import {GET_LEAD_STAGES} from "../leadStages/actionTypes";
import {GET_MARKETS} from "../markets/actionTypes";
import {GET_QUICK_REPLIES, GET_QUICK_REPLIES_SUCCESS} from "../quick-replies/actionTypes";

export const countProspects = (data) => {
  return {
    type: COUNT_ALL_PROSPECTS,
    payload: data
  }
}

export const countProspectsSuccess = prospectsCount => {
  return {
    type: COUNT_ALL_PROSPECTS_SUCCESS,
    payload: prospectsCount,
  }
}

export const getQuickReplies = () => {
  return {
    type: GET_QUICK_REPLIES
  }
}

export const getQuickRepliesSuccess = categories => {
  return {
    type: GET_QUICK_REPLIES_SUCCESS,
    payload: categories,
  }
}

export const getCampaigns = () => {
  return {
    type: GET_CAMPAIGNS
  }
}

export const getCampaignsSuccess = campaigns => {
  return {
    type: GET_CAMPAIGNS_SUCCESS,
    payload: campaigns,
  }
}

export const getNotes = (prospectId) => {
  return {
    type: GET_NOTES,
    payload: prospectId
  }
}

export const getNotesSuccess = notes => {
  return {
    type: GET_NOTES_SUCCESS,
    payload: notes,
  }
}


export const createNotes = (note) => {
  return {
    type: ADD_NOTE,
    payload: note
  }
}

export const createNotesSuccess = message => {
  return {
    type: ADD_NOTE_SUCCESS,
    payload: message,
  }
}

export const updateNote = (note) => {
  return {
    type: EDIT_NOTE,
    payload: note
  }
}

export const updateNoteSuccess = (message) => {
  return {
    type: EDIT_NOTE_SUCCESS,
    payload: message,
  }
}

export const deleteNote = (note) => {
  return {
    type: DELETE_NOTE,
    payload: note
  }
}

export const deleteNoteSuccess = (message) => {
  return {
    type: DELETE_NOTE_SUCCESS,
    payload: message,
  }
}

export const getProspect = (prospectId) => {
  return {
    type: GET_PROSPECT,
    payload: prospectId
  }
}

export const getProspectSuccess = prospect => {
  return {
    type: GET_PROSPECT_SUCCESS,
    payload: prospect,
  }
}

export const exportProspects = (filter) => {
  return {
    type: EXPORT_PROSPECTS,
    payload:filter
  }
}

export const exportProspectsSuccess = message => {
  return {
    type: EXPORT_PROSPECTS_SUCCESS,
    payload: message,
  }
}

export const filterProspects = (filter) => {
  return {
    type: FILTER_PROSPECTS,
    payload:filter
  }
}

export const getProspects = (offset) => {
  return {
    type: GET_PROSPECTS,
    payload:offset
  }
}

export const getLeadStages = () => {
  return {
    type: GET_LEAD_STAGES
  }
}

export const getMarkets = () => {
  return {
    type: GET_MARKETS
  }
}

export const deleteProspects = (ids) => {
  return {
    type: DELETE_PROSPECTS,
    payload:ids
  }
}

export const deleteProspectsSuccess = message => {
  return {
    type: DELETE_PROSPECTS_SUCCESS,
    payload: message,
  }
}

export const removeProspectsFromDnc = (ids) => {
  return {
    type: REMOVE_PROSPECT_FROM_DNC,
    payload:ids
  }
}
export const removeProspectsFromDncSuccess = (message) => {
  return {
    type: REMOVE_PROSPECT_FROM_DNC_SUCCESS,
    payload:message
  }
}


export const getCampaignsByMarket = (market) => {
  return {
    type: GET_CAMPAIGNS_BY_MARKET,
    payload:market
  }
}

export const getCampaignsByMarketSuccess = (markets) => {
  return {
    type: GET_CAMPAIGNS_BY_MARKET_SUCCESS,
    payload:markets
  }
}

export const searchProspectsByMsg = (msg) => {
  return {
    type: SEARCH_PROSPECTS_BY_MESSAGE,
    payload:msg
  }
}

export const searchProspectsFor = (sfor) => {
  return {
    type: SEARCH_PROSPECTS_FOR,
    payload:sfor
  }
}

export const getProspectsSuccess = prospects => {
  return {
    type: GET_PROSPECTS_SUCCESS,
    payload: prospects,
  }
}


export const getProspectsError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const addProspect = (prospect) => {
  return {
    type: ADD_PROSPECT,
    payload: prospect,
  }
}

export const addProspectSuccess = (message) => {
  return {
    type: ADD_PROSPECT_SUCCESS,
    payload: message,
  }
}

export const updateProspect = (prospect) => {
  return {
    type: UPDATE_PROSPECT,
    payload: prospect
  }
}

export const updateProspectSuccess = (message) => {
  return {
    type: UPDATE_PROSPECT_SUCCESS,
    payload: message,
  }
}

export const updateProspectDNS = (prospect) => {
  return {
    type: UPDATE_PROSPECT_DNC,
    payload: prospect
  }
}

export const updateProspectDNSSuccess = (message) => {
  return {
    type: UPDATE_PROSPECT_DNC_SUCCESS,
    payload: message,
  }
}

export const updateProspectVerified = (prospect) => {
  return {
    type: UPDATE_PROSPECT_VERIFIED,
    payload: prospect
  }
}

export const updateProspectVerifiedSuccess = (message) => {
  return {
    type: UPDATE_PROSPECT_VERIFIED_SUCCESS,
    payload: message,
  }
}

export const removeProspectVerified = (prospect) => {
  return {
    type: REMOVE_PROSPECT_VERIFIED,
    payload: prospect
  }
}

export const removeProspectVerifiedSuccess = (message) => {
  return {
    type: REMOVE_PROSPECT_VERIFIED_SUCCESS,
    payload: message,
  }
}

export const updateProspectWrongNumber = (prospect) => {
  return {
    type: UPDATE_PROSPECT_WRONG_NUMBER,
    payload: prospect
  }
}

export const updateProspectWrongNumberSuccess = (message) => {
  return {
    type: UPDATE_PROSPECT_WRONG_NUMBER_SUCCESS,
    payload: message,
  }
}

export const removeProspectWrongNumber = (prospect) => {
  return {
    type: REMOVE_PROSPECT_WRONG_NUMBER,
    payload: prospect
  }
}

export const removeProspectWrongNumberSuccess = (message) => {
  return {
    type: REMOVE_PROSPECT_WRONG_NUMBER_SUCCESS,
    payload: message,
  }
}

export const updateProspectQualified = (prospect) => {
  return {
    type: UPDATE_PROSPECT_QUALIFIED,
    payload: prospect
  }
}

export const updateProspectQualifiedSuccess = (message) => {
  return {
    type: UPDATE_PROSPECT_QUALIFIED_SUCCESS,
    payload: message,
  }
}

export const removeProspectQualified = (prospect) => {
  return {
    type: REMOVE_PROSPECT_QUALIFIED,
    payload: prospect
  }
}

export const removeProspectQualifiedSuccess = (message) => {
  return {
    type: REMOVE_PROSPECT_QUALIFIED_SUCCESS,
    payload: message,
  }
}

export const updateProspectPriority = (prospect) => {
  return {
    type: UPDATE_PROSPECT_PRIORITY,
    payload: prospect
  }
}

export const updateProspectPrioritySuccess = (message) => {
  return {
    type: UPDATE_PROSPECT_PRIORITY_SUCCESS,
    payload: message,
  }
}

export const removeProspectPriority = (prospect) => {
  return {
    type: REMOVE_PROSPECT_PRIORITY,
    payload: prospect
  }
}

export const removeProspectPrioritySuccess = (message) => {
  return {
    type: REMOVE_PROSPECT_PRIORITY_SUCCESS,
    payload: message,
  }
}

export const updateProspectLeadStage = (prospect) => {
  return {
    type: UPDATE_PROSPECT_LEAD_STAGE,
    payload: prospect
  }
}

export const updateProspectLeadStageSuccess = (message) => {
  return {
    type: UPDATE_PROSPECT_LEAD_STAGE_SUCCESS,
    payload: message,
  }
}

export const pushToWebhook = (prospect) => {
  return {
    type: PUSH_TO_WEBHOOK,
    payload: prospect
  }
}

export const pushToWebhookSuccess = (message) => {
  return {
    type: PUSH_TO_WEBHOOK_SUCCESS,
    payload: message,
  }
}

export const sendConversation = (message) => {
  return {
    type: SEND_CONVERSATION,
    payload: message
  }
}

export const sendConversationSuccess = message => {
  return {
    type: SEND_CONVERSATION_SUCCESS,
    payload: message,
  }
}
