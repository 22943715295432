import {
  ADD_QUICK_REPLY, ADD_QUICK_REPLY_SUCCESS,
  API_ERROR, DELETE_QUICK_REPLY, DELETE_QUICK_REPLY_SUCCESS, EDIT_QUICK_REPLY, EDIT_QUICK_REPLY_SUCCESS,
  GET_QUICK_REPLIES,
  GET_QUICK_REPLIES_SUCCESS
} from "./actionTypes"

const initialState = {
  loading:true,
  markets:[],
  quickReplies: [],
  apiError: null,
}

const QuickReply = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUICK_REPLIES:
      state = {
        ...state,
        loading: true,
        quickReplies: [],
        apiError: null,
      }
      break
    case GET_QUICK_REPLIES_SUCCESS:
      state = {
        ...state,
        loading:false,
        quickReplies: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload,loading:false }
      break
    case EDIT_QUICK_REPLY:
      state = {
        ...state,
        campaign: action.payload,
      }
      break
    case EDIT_QUICK_REPLY_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break

    case ADD_QUICK_REPLY:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_QUICK_REPLY_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_QUICK_REPLY:
      state = {
        ...state,
        campaign: action.payload,
      }
      break
    case DELETE_QUICK_REPLY_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default QuickReply
