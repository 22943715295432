import {
  ADD_NUMBER, ADD_NUMBER_SUCCESS,
  API_ERROR,
  ACTIVATE_NUMBER_SUCCESS,
  DEACTIVATE_NUMBER_SUCCESS,
  DELETE_NUMBER,
  DELETE_NUMBER_SUCCESS,
  EDIT_NUMBER,
  EDIT_NUMBER_SUCCESS,
  GET_NUMBERS,
  GET_NUMBERS_SUCCESS,
  GET_FILTER_NUMBERS,
  GET_FILTER_NUMBERS_SUCCESS
} from "./actionTypes"
import {GET_MARKETS, GET_MARKETS_SUCCESS} from "../markets/actionTypes";

const initialState = {
  loading:true,
  markets:[],
  numbers: [],
  apiError: null,
}

const Numbers = (state = initialState, action) => {
  switch (action.type) {
    case GET_NUMBERS:
      state = {
        ...state,
        numbers: [],
        loading: true,
        apiError: null,
      }
      break
    case GET_NUMBERS_SUCCESS:
      state = {
        ...state,
        loading:false,
        numbers: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case GET_FILTER_NUMBERS:
      state = {
        ...state,
        numbers: [],
        apiError: null,
      }
      break
    case GET_FILTER_NUMBERS_SUCCESS:
      state = {
        ...state,
        numbers: action.payload,
        apiError: null,
        successMsg: null,
      }
      break
    case API_ERROR:
      state = { ...state, apiError: action.payload,loading: false }
      break
    case EDIT_NUMBER:
      state = {
        ...state,
        optOut: action.payload,
      }
      break
    case EDIT_NUMBER_SUCCESS:
      state = {
        ...state,
        successMsg: action.payload,
        apiError: null,
      }
      break

    case ADD_NUMBER:
      state = {
        ...state,
        apiError: null,
        successMsg: null,
      }
      break
    case ADD_NUMBER_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break
    case DELETE_NUMBER:
      state = {
        ...state,
        number: action.payload,
      }
      break
    case DELETE_NUMBER_SUCCESS:
      state = {
        ...state,
        apiError: null,
        successMsg: action.payload,
      }
      break

    case GET_MARKETS:
      state = {
        ...state,
        markets: [],
        apiError: null,
      }
      break
    case GET_MARKETS_SUCCESS:
      state = {
        ...state,
        markets: action.payload,
        apiError: null,
        successMsg: null,
      }
      break

    case ACTIVATE_NUMBER_SUCCESS:
    case DEACTIVATE_NUMBER_SUCCESS:
      state = {
        ...state,
        number_id: null,
        number: null,
        confirm_alert: false,
        activate_modal: false,
        deactivate_modal: false,
        apiError: null,
        successMsg: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default Numbers
